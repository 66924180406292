import { forwardRef } from "react";
import { Button } from "../../../../../components/form";
import Modal from "../../../../../components/modal/Modal";

import classes from "./DeleteCustomerAddressModal.module.css";
import { t } from "../../../../../lib/translations";

const DeleteCustomerAddressModal = (
    { deleteCustomer, item, loading },
    ref
) => {

    const closeModal = () => {
        if (ref.current) {
            ref.current.close();
        }
    };

    return (
        <Modal className={classes.closeIconClass} ref={ref} title={t("address_book.confirm_delete")}>
            <div className={classes.buttons}>
                <Button
                    loading={loading}
                    variant="secondary"
                    onClick={closeModal}
                >
                    {t("address_book.keep_item")}
                </Button>
                <Button
                    loading={loading}
                    onClick={() => { deleteCustomer(item.id) }}
                    variant="primary"
                >
                    {t("address_book.remove_address")}
                </Button>
            </div>
        </Modal>
    );
};

export default forwardRef(DeleteCustomerAddressModal);
