// TODO Remove once addAddress (EA-411),deleteAddress and addOrders (EA-428) are implemented in Kega core
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    authenticated: false,
    token: null,
    token_params: {},
    addresses: [],
    orders: [],
    ordersSearchCriteria: {
        currentPage: 1,
        pageSize: 20,
        totalPages: null,
    }
};

export default createReducer(initialState, {

    [actions.authenticated]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            authenticated: payload
        }
    },

    [actions.addToken]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            token: payload.token,
            token_params: payload.params
        }
    },

    [actions.addCustomerData]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            ...payload
        };
    },

    [actions.addAdresses]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            addresses: payload
        };
    },

    [actions.addAddress]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            addresses: [
                ...state.addresses,
                payload
            ]
        };
    },

    [actions.deleteAddress]: (state, action) => {
        const { payload } = action;
        return {
            ...state,
            addresses: state.addresses.filter(address => address.id !== payload)
        };
    },

    [actions.addOrders]: (state, action) => {
        const { payload: { orders, searchCriteria } } = action;
        let newOrders = orders || [];

        if (searchCriteria) {
            if (searchCriteria.currentPage && searchCriteria.currentPage > 1) {
                newOrders = [
                    ...state.orders,
                    ...newOrders
                ];
            }

            return {
                ...state,
                orders: newOrders,
                ordersSearchCriteria: searchCriteria
            }
        }

        return {
            ...state,
            orders: newOrders
        };
    },

    [actions.addStoreOrders]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            store_orders: payload
        };
    },

    [actions.logout]: () => {
        return initialState;
    },
});
