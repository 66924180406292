import { useRequest, useStorefront } from '@kega/sps-core';

import { t } from '../../lib/translations';
import useSystemMessage from "../useSystemMessage";
import { useState } from "react";

const useStoreStock = () => {
    const { api: { essentielStoreStock } } = useStorefront();


    const { error, loading, setRequest, setSuccess, setError } = useRequest();
    const [cache, setCache] = useState({} );
    const { setSystemErrorMessage } = useSystemMessage();

    const getStoreStock = async (itemId, variantId, uuid) => {

        let key = itemId + '_' + variantId;

        if (cache[key] !== undefined) {
            cache[key].uuid = uuid;
            return cache;
        }

        setCache( (current) => {
            let newCache = current;
            newCache[key] = { quantity: 0 };
            return newCache;
        });

        try {
            setRequest();

            const result = await essentielStoreStock.getStoreStock(itemId, variantId);

            if (result) {
                setSuccess();
                result.getStoreStock.uuid = uuid;
                setCache( (current) => {
                    let newCache = current;
                    newCache[key] = result.getStoreStock;
                    return newCache;
                });


            } else {

                setSystemErrorMessage(t('Something went wrong, please try again later'));
                setError(new Error(t('Something went wrong, please try again later')));
            }
        } catch (error) {

            setCache( (current) => {
                let newCache = current;
                newCache[key] = { quantity: 0 };
                return newCache;
            });

            setError(error);
        }

        return cache;
    };


    return {
        error,
        loading,
        cache,
        getStoreStock
    };
}

export default useStoreStock;
