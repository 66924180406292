import { Builder } from "@builder.io/react/lite";
import { Link } from 'react-router-dom';

import useStoreViews from '../../../../hooks/useStoreViews';

import { Container, Row, Col } from "../../../../components/grid";

import Configure from '../configure/Configure';

import classes from './ShoppingLink.module.css';
import classNames from "classnames";


export const ShoppingLink = ({ shopping_links, link_alignment, size_xl, size_lg, size_md, size_sm, size_xs }) => {
    const { getUrl } = useStoreViews();

    return (
        <>
            {
                shopping_links !== undefined
                    ?
                    <Container margins g-xs={0}>
                        <Row>
                            <Col xl={size_xl} lg={size_lg} md={size_md} sm={size_sm} xs={size_xs}>
                                <div className={classNames(classes.root, classes[link_alignment])} >
                                    {
                                        shopping_links.map((shopping_link, key) => (
                                            <div key={key} className={classes.shopping_link_container}>
                                                <Link
                                                    to={getUrl(`/${shopping_link.url}`)}
                                                >
                                                    <section className={classes.shopping_link}>
                                                        <span className={classNames(classes.link_text, classes.bold)} >
                                                            {shopping_link.bold_name}
                                                        </span>
                                                        <span className={classes.link_text}>
                                                            {shopping_link.name}
                                                        </span>
                                                    </section>
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    :
                    <Configure name="Shopping links" />
            }
        </>
    )
}

Builder.registerComponent(ShoppingLink, {
    name: 'shopping_link',
    friendlyName: 'Shopping Link',
    defaultStyles: {
        marginTop: '0px',
    },
    inputs: [
        {
            name: 'shopping_links',
            friendlyName: 'Shopping Links',
            type: 'list',
            helperText: 'Max 8 links are allowed',
            subFields: [
                {
                    name: 'bold_name',
                    type: 'string',
                    required: true,
                    defaultValue: 'Bold'
                },
                {
                    name: 'name',
                    type: 'string',
                    required: true,
                    defaultValue: 'Name'
                },
                {
                    name: 'url',
                    type: 'string',
                    required: true,
                },
            ],
            onChange: (options) => {
                if (options.get('shopping_links').length > 8) {
                    options.set('shopping_links', options.get('shopping_links').slice(0, 8));
                }
            },
        },
        {
            name: 'link_alignment',
            friendlyName: 'Shopping links position',
            required: true,
            enum: [
                {
                    label: 'Left',
                    value: 'start'
                },
                {
                    label: 'Center',
                    value: 'center',
                },
                {
                    label: 'Right',
                    value: 'end'
                }
            ],
            defaultValue: 'center'
        },
        {
            type: 'string',
            name: 'size_xl',
            friendlyName: 'Size (1440px - …)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_lg',
            friendlyName: 'Size (1024px - 1439px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_md',
            friendlyName: 'Size (768px - 1023px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_sm',
            friendlyName: 'Size (640px - 767px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_xs',
            friendlyName: 'Size (0px - 639px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
    ]
});
