import { useLocation, Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '@kega/sps-core';

import useStoreViews from "../hooks/useStoreViews";

import Header from '../modules/header/Header';
import Footer from '../modules/footer/Footer';
import Errors from '../modules/errors/Errors';
import ErrorBoundary from '../modules/errorboundary/ErrorBoundary';
import SystemMessage from '../components/system_message/SystemMessage';

import BuilderSection from '../modules/builder/BuilderSection';

const Layout = () => {
    const config = useConfig();
    const language = config.get('language');
    const location = useLocation();
    const { currentStoreView, currentStoreCode } = useStoreViews();
    const { hideCountry } = config.get('storeSelector');

    return (
        <ErrorBoundary path={location.pathname}>
            <Helmet
                defaultTitle={'Essentiel Antwerp'}
                titleTemplate={'%s'}
            >
                <html lang={language}/>
                <meta name="google-site-verification" content="iilmac_Zb5xJqy8fJDeA922l_j50pfRlhSyk3rQnYiQ"/>
                {
                    (hideCountry.includes(currentStoreView.country) || hideCountry.includes(currentStoreCode))
                        ? <meta name="robots" content="noindex, nofollow"/>
                        : ''
                }
            </Helmet>

            <Header/>
            <SystemMessage/>
            <main>
                <Outlet />
                <BuilderSection model="newsletter-overlay" />
            </main>

            <Footer />

            <Errors />
        </ErrorBoundary>
    )
}

export default Layout;
