import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useConfig } from '@kega/sps-core';
import { ProductImage } from '@kega/sps-components';

import aa from 'search-insights';

import { decodeUrl } from '../../lib/utils';

import useStoreViews from "../../hooks/useStoreViews";
import useTagManager from '../../hooks/useTagManager';

import Gallery from "../../modules/productdetails/gallery/Gallery";

import { ChevronIcon } from '../icons';
import Price from "../price/Price";
import WishlistButton from '../wishlist/wishlist_button/WishlistButton';
import ColorRelated from './colorrelated/ColorRelated';
import Labels from "./labels/Labels";

import classes from './ProductTile.module.css';
import classNames from 'classnames';

const ProductTile = ({ product, wishlistButton = false, className = '', giftBox = false, query = false, isPLP = false }) => {
    const { sku, name, url_key, description, image_url, image, category_image, hover_image, price, minimal_price, media_gallery, imgproductswatch, category_description } = product;
    const [ tileImage, setTileImage ] = useState(category_image ? category_image : (image_url ? image_url : image?.url));
    const { ecommerceEvent } = useTagManager();
    const config = useConfig();
    const { prefix_product } = config.get('url');
    const { getUrl } = useStoreViews();
    const { index } = config.get('algolia');
    const storeCode = config.get('storeCode');
    const formattedCode = storeCode.replace('_kiosk', '');

    useEffect(() => { 
        setTileImage(category_image ? category_image : (image_url ? image_url : image?.url)) 
    }, [category_image, image_url, image?.url]);

    const height = 1287,
          width = 919;

    const trimmedUrls = media_gallery?.map(url => {
        url = typeof url == 'object' ? url.url : url;
        const parts = url.split("/product/");
        let imageSrc = decodeUrl(parts.length > 1 ? parts[1] : url);

        if (imageSrc !== imgproductswatch && imageSrc !== hover_image && imageSrc !== category_image) {
            try {
                const imageUrl = new URL(imageSrc);
                imageUrl.searchParams.set('width', width);
                imageUrl.searchParams.set('height', height);
                imageSrc = imageUrl.toString();

            } catch (error) {
                //console.error("Please check image URL in ProductTile.js")
            }

            return imageSrc;
        } else {
            return null;
        }
    }).filter(Boolean);

    try {
        const categoryImageUrl = new URL(category_image);
        categoryImageUrl.searchParams.set('width', width);
        categoryImageUrl.searchParams.set('height', height);
        const categoryImage = categoryImageUrl.toString();

        if (categoryImage) {
            trimmedUrls.unshift(categoryImage)
        }
    } catch (error) {
        //console.error("Please check image URL in ProductTile.js")
    }

    const defaultCurrency = config.get('currency') ?? 'EUR';

    const pushLayer = (product) => {
        try {
            ecommerceEvent('select_item', {
                items:[{
                    item_id: product.sku.length > 0 && product.sku[0],
                    item_name: product.name,
                    item_category: product.perfion_productgroupcode,
                    item_size: product?.perfion_modelsizetext,
                    price: price ? price[defaultCurrency].default : product.price_range.minimum_price.regular_price.value,
                    discount: '',
                    currency: defaultCurrency
                }]
            });

            aa(query ? 'clickedObjectIDsAfterSearch' : 'clickedObjectIDs', {
                eventName: query ? 'Product Clicked after search' : 'Product Clicked',
                index: index + formattedCode + '_products',
                objectIDs: [product.objectID],
                queryID: product.__queryID,
                positions: [product.__position]
            });
        } catch (error) {
            //
        }
    }

    let imageSrc = decodeUrl(tileImage);

    try {
        const imageUrl = new URL(imageSrc);
        imageUrl.searchParams.set('width', width);
        imageUrl.searchParams.set('height', height);

        imageSrc = imageUrl.toString();
    } catch (error) {
        //console.error("Please check image URL in ProductTile.js")
    }
    // Algolia data to Magento structure
    const state = {
        sku: (Array.isArray(sku) ? sku[0] : sku),
        name: name,
        description: { html: description?.html },
        color_related_products: product?.color_related_products ?? null,
        perfion_modelsizetext: product?.perfion_modelsizetext ?? null,
        color: product?.perfion_filtercolour,
        image: {
            url: media_gallery ? media_gallery[0] : (image_url ?? product.image?.url)
        },
        price_range: {
            minimum_price: {
                regular_price: { value: price ? minimal_price : product.price_range?.minimum_price.regular_price.value }
            }
        }
    };

    return (
        <div className={classNames(classes.root, className)}>
            {(isPLP && media_gallery && media_gallery.length > 0) ? (
                <Gallery
                    className={classes.product_tile_gallery}
                    images={media_gallery.length === 0 ? [imageSrc] : trimmedUrls}
                    width={width}
                    height={height}
                    alt={name}
                    url={getUrl(`${prefix_product}/${url_key}`)}
                    state={state}
                    isPLP={isPLP}
                    ComponentNext={<div className={classes.next}><ChevronIcon color="#9C9EA1" title={"Slider Left"} direction='left' size={12} /></div>}
                    ComponentPrev={<div className={classes.prev}><ChevronIcon color="#9C9EA1" title={"Slider Right"} direction='right' size={12}  /></div>}
                    matchGallerySlide={true}
                    product={product}
                />
            ) : (
                <Link
                    to={getUrl(`${prefix_product}/${url_key}`)}
                    onClick={() => pushLayer(product)}
                    state={state}
                    className={classes.link}
                    role="button"
                    tabIndex="0"
                >
                    <ProductImage
                        src={imageSrc}
                        width={width}
                        height={height}
                        alt={name}
                        title={name}
                        onMouseOver={() => setTileImage(hover_image ? hover_image : (category_image ? category_image : (image_url ? image_url : image.url)))}
                        onMouseLeave={() => setTileImage(category_image ? category_image : (image_url ? image_url : image.url))}
                        className={classes.product_img} />
                    <Labels product={product} />
                </Link>
            )}
            <div className={classNames(classes.product_info, giftBox && classes.giftbox_info)}>
                <Link
                    to={getUrl(`${prefix_product}/${url_key}`)}
                    state={state}
                    className={classes.link}
                >
                    <div className={classes.product_name}>
                        {category_description || name}
                    </div>
                </Link>

                <Price price={price ? price[defaultCurrency].default : product.price_range.minimum_price.final_price?.value ? product.price_range.minimum_price.final_price?.value : product.price_range.minimum_price.regular_price.value} originalPrice={price ? price[defaultCurrency].default_original : product.price_range.minimum_price.regular_price.value} className={classes.price} />

                {
                    product?.color_related_products && !giftBox && <ColorRelated product={product} />
                }

                {
                    wishlistButton && <WishlistButton product={product} className={classes.wishlist_button} />
                }
            </div>
        </div>
    );
}

export default ProductTile;