import classes from './Configure.module.css';

const Configure = ({ name }) => {
    return (
        <div className={classes.root}>
            Configure {name}
        </div>
    );
}

export default Configure;