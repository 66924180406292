import { useDispatch, useSelector } from 'react-redux';
import { useRequest, useStorefront } from '@kega/sps-core';
import { addCustomerData } from "@kega/sps-core/src/reducers/customer/actions";

const useFetchCustomer = () => {
    const { api: { essentielCustomer } } = useStorefront();
    const dispatch = useDispatch();

    const customerData = useSelector((state) => state.customer);

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const options = {
        custom_attributes: [],
        custom_attributes_address: [
            'vat_id'
        ]
    };

    const fetchCustomerData = async () => {
        try {
            setRequest();
            const result = await essentielCustomer.getDetails(options);
            dispatch(addCustomerData(result));
            setSuccess();

            return result;
        } catch (error) {
            console.log('[useCustomer]', error);
            setError(error);

            return null;
        }
    }

    return {
        error,
        loading,
        customer: customerData,
        fetchCustomerData
    };
}

export default useFetchCustomer;
