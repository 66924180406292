import { forwardRef } from "react";
import { Button } from "../../../../components/form";
import Modal from "../../../../components/modal/Modal";

import classes from "./DeleteItemModal.module.css";
import { t } from "../../../../lib/translations";

const DeleteItemModal = ({ endpoint, item, loading }, ref) => {
    const send = async () => {
        await endpoint(item);

        if (!loading) {
            ref.current.close();
        }
    }

    return (
        <Modal ref={ref} title={t("wishlist.remove_title")} iconClose closeContainerClass={classes.closeContainerClass} modalClassName={classes.modal}>
            <div className={classes.btn}>
                <Button
                    loading={loading}
                    onClick={send}
                    variant="primary"
                >
                    {t("wishlist.remove_item")}
                </Button>

                <Button
                    onClick={() => ref.current.close()}
                    variant="secondary"
                >
                    {t("wishlist.keep_item")}
                </Button>
            </div>
        </Modal>
    );
};

export default forwardRef(DeleteItemModal);
