import AddToCart from '../../../components/product/addtocart/AddToCart';
import WishlistButton from '../../../components/wishlist/wishlist_button/WishlistButton';

import classes from "./BundleProduct.module.css"

const BundleProduct = () => {

    return (
        <div className={classes.root}>
            <p>Not implemented</p>

            <div className={classes.option}>
                <AddToCart product={null} />
                <WishlistButton product={null} />
            </div>

        </div>
    )
}

export default BundleProduct;