import { useConfig } from "@kega/sps-core";

import classes from './Category.module.css';

const Category = ({ data, onClick }) => {
    const config = useConfig();
    const { url: baseUrl } = config.get('magento2');
    const { name, image_url } = data;

    return (
        <div className={classes.root} onClick={onClick} role="button" tabIndex="0">
            <div className={classes.image}>
                {
                    image_url && <img src={baseUrl + image_url} alt={name} />
                }
            </div>
            <span className={classes.name}>{name}</span>
        </div>
    )
}

export default Category;
