import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect } from "react";

import { useCart, useCartId, useConfig } from "@kega/sps-core";
import aa from 'search-insights';

import useCookies from './hooks/useCookies';
import useGeoIp from './hooks/useGeoIp';
import useTagManager from "./hooks/useTagManager";
import useLogoutKioskUser from "./hooks/useLogoutKioskUser";
import useStoreConfig from "./hooks/useStoreConfig";

import Layout from './pages/Layout';
import NotFound from './pages/error/notfound/NotFound';

import AppRoutes from './router/AppRoutes';
import useCheckoutData from "./hooks/checkout/useCheckoutData";
import useCartSync from "./hooks/useCartSync";
import useApplyCoupon from "./hooks/checkout/useApplyCoupon";

const App = () => {
    useCookies();
    const { fetchDetails } = useGeoIp();
    const { initGTM } = useTagManager();
    const { autoLogoutUser } = useLogoutKioskUser();
    const { removeCouponCodeFromCart } = useApplyCoupon();

    const config = useConfig();

    // Use config instead of useStoreViews, since useStoreViews does not work outside of router
    const storeViews = config.get('storeViews');
    const currentStoreCode = config.get('storeCode');
    const isKiosk = currentStoreCode.includes('_kiosk');
    const { getConfig } = useStoreConfig();
    const { appId, apiKey } = config.get('algolia');
    const { extraCartData } = useCheckoutData();
    const { fetchCart, items } = useCart();
    const getCartId = useCartId();
    const { compareCartToHash, setCurrentCartId, cartToHash } = useCartSync();

    useEffect(() => {

        fetchDetails();
        initGTM('GTM-KG7GNVX');

        if (window.location.href.indexOf('size-chart-women') >= 0) {
            window.prerenderReady = true
        }

        getConfig([
            'disable_sale_label',
            'recaptcha_customer_login',
            'recaptcha_contact',
            'recaptcha_place_order',
            'disable_seo_plp_title',
            'disable_seo_plp_description'
        ]);
    }, []);

    useEffect( () => {
        if (items.length === 0) {
            removeCouponCodeFromCart({}).then( () => {});
        }
    }, [items])

    useEffect(() => {
        if (isKiosk) {
            autoLogoutUser();
        }

        aa('init', {
            appId: appId,
            apiKey: apiKey
        });
    }, []);

    useEffect(() => {
        addEventListener('storage', (e) => {

            if (e.key === currentStoreCode + '_new_cart_id') {
                const newCartId = e.newValue;
                setCurrentCartId(newCartId)
                fetchCart(newCartId, extraCartData);
            }

            if (e.key === 'persist:sp-store') {
                const newValue = JSON.parse(e.newValue);
                const cart = JSON.parse(newValue.cart);
                const cartId = getCartId() || cart.cartId;

                const currentHash = sessionStorage.getItem('cart_hash') ?? '';

                if(!compareCartToHash(cart, currentHash) && cartId) {
                    sessionStorage.setItem('cart_hash', cartToHash(cart));
                    fetchCart(cartId, extraCartData);
                }
            }

        })
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate replace to={"/"+config.get('defaultStoreCode')} />} />
                {
                    Object.keys(storeViews).map((storeCode) => {
                        return <Route key={storeCode} path={'/' + storeCode + '/*'} element={<AppRoutes />} />;
                    })
                }
                <Route path="*" element={<Layout />} >
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
