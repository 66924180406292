const getWishlistQuery = `
    query {
        customer {
            wishlists {
                id
                items_count
                items_v2 {
                    items {
                        id
                        product {
                            uid
                            name
                            sku
                            stock_status
                            url_key
                            image {
                                url
                            }
                            description {
                                html
                            }
                            price_range {
                                minimum_price {
                                  regular_price {
                                    currency
                                    value
                                  }
                                }
                                maximum_price {
                                  regular_price {
                                    currency
                                    value
                                  }
                                }
                              }
                              ... on ConfigurableProduct {
                                configurable_options {
                                  attribute_code
                                  attribute_uid
                                  label
                                  position
                                  values {
                                      default_label
                                      label
                                      swatch_data { value }
                                      uid
                                  }
                                }
                                variants {
                                  attributes {
                                      code
                                      label
                                      uid
                                      value_index 
                                  }
          
                                  product {
                                      sku
                                      url_key
                                      name
                                      size
                                      stock_status
                                      swatch_image
                                      price_range {
                                          minimum_price {
                                              regular_price {
                                                  currency
                                                  value
                                              }
                                          }
                                      }
                                      special_price
                                      special_to_date
                                  }
                                }
                            }  
                        }
                    }
                }
            }
        }
    }
`;


export default getWishlistQuery;
