import { useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";

import { t } from "../../../lib/translations";

import useCheckoutAgreements from "../../../hooks/checkout/useCheckoutAgreements";

import Modal from "../../../components/modal/Modal";
import Checkbox from "../../../components/form/checkbox/Checkbox";

import classes from "./CheckoutAgreements.module.css";

const CheckoutAgreements = ({ checkboxRef, checkboxChecked, onCheckboxChange }) => {
    const { getCheckoutAgreements, checkoutAgreements, loading, error } = useCheckoutAgreements();
    const modalRef = useRef();

    useEffect(() => {
        getCheckoutAgreements();
    }, []);

    const openModal = () => {
        modalRef.current?.open();
    };

    const closeModal = () => {
        modalRef.current?.close();
    }

    return (
        checkoutAgreements
            ?
            checkoutAgreements?.checkoutAgreements.map((agreement, key) => {
                return (
                    <div key={key} className={classes.checkout_agreement}>
                        {!loading
                            ?
                            <>
                                <Checkbox
                                    name="terms_conditions"
                                    className={agreement.mode !== 'MANUAL' && classes.hidden}
                                    onChange={onCheckboxChange}
                                    ref={checkboxRef}
                                    defaultChecked={checkboxChecked}
                                    required={true}
                                />
                                <span className={classes.terms_text}>{t('checkout.payment.terms_and_conditions_text')}
                                    <a  className={classes.link} href="#" onClick={openModal}>{t('checkout.payment.terms_and_conditions_link')}</a>
                                </span>
                            </>
                            :
                            <Skeleton />
                        }

                        <Modal
                            className={classes.closeIconClass}
                            modalClassName={classes.checkout_agreement_modal}
                            ref={modalRef}
                            onClose={closeModal}
                        >
                            {!loading
                                ?
                                agreement.content &&  <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: agreement.content }} /> 
                                :
                                <Skeleton />
                            }
                        </Modal>
                    </div>
                )
            })
            :
            ''
    )
}

export default CheckoutAgreements;
