import { useMemo } from 'react';
import { useConfig } from '@kega/sps-core';

import { InstantSearch, Configure } from 'react-instantsearch-dom';

import algoliasearch from 'algoliasearch/lite';

import GiftboxList from './giftboxlist/GiftboxList';

const GiftboxProducts = () => {
    const config = useConfig();
    const storeCode = config.get('storeCode');
    const { index, appId, apiKey } = config.get('algolia');
    const formattedCode = storeCode.replace("_kiosk", "");
    const searchClient = useMemo(() => algoliasearch(appId, apiKey), [appId, apiKey]);
    const query = 'GIFTBOX';

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={index + formattedCode + '_products'}
        >
            <Configure query={query} />
            <GiftboxList />
        </InstantSearch>
    )
}

export default GiftboxProducts;
