import ScrollToTop from "../../utils/ScrollToTop";

import useStoreViews from "../../hooks/useStoreViews";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useNewsletter from "../../hooks/useNewsletter";
import { t } from "../../lib/translations";
import BuilderPage from "../../modules/builder/BuilderPage";
import { Loader } from "@kega/sps-elements";
import NotFound from "../error/notfound/NotFound";
import { useConfig } from "@kega/sps-core";

const NewsletterConfirmSubscription = () => {
    const { id, code } = useParams();
    const [subscriptionStatus, setSubscriptionStatus] = useState();
    const { confirmSubscription } = useNewsletter()
    const config = useConfig();

    const { confirm: { builder_page_id : newsletterConfirmationPageId } }  = config.get('newsletter');

    useEffect(() => {
        confirmSubscription(id,code).then( (result) => {
            if(result){
                setSubscriptionStatus( result.confirmNewsletterSubscription.status)
            }
        })

    }, [])

    return (
        <div style={{ overflow: 'hidden' }}>

            <ScrollToTop />
            {
                subscriptionStatus === 'SUBSCRIBED' &&
                <BuilderPage
                    model="page"
                    url={newsletterConfirmationPageId}
                    renderLoading={() => <Loader />}
                    renderNotFound={() => <NotFound />}
                />
            }

        </div>
    )
}

export default NewsletterConfirmSubscription;