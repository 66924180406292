import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    alerts: {}
};

export default createReducer(initialState, {
    [actions.UpdateProductStockAlerts]: (state, action) => {
        const { payload } = action;
        const { alerts } = state;

        return {
            ...state,
            alerts: {
                ...alerts,
                ...payload
            }
        };
    },

    [actions.SetProductStockAlerts]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            alerts: {
                ...payload
            }
        };
    },

    [actions.RemoveProductStockAlert]: (state, action) => {
        const { payload } = action;
        let newAlerts = { ...state.alerts };

        if (newAlerts.hasOwnProperty(payload.id)) {
            delete newAlerts[payload.id];
        }

        return {
            ...state,
            alerts: {
                ...newAlerts
            }
        };
    },

});
