/* eslint-disable */
const Logo = ({ color = null }) => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 260.16 59.69" role="button">
        <g>
            <path fill={color || 'currentColor'} d="M260.16,36.81v-6.37H242.4V0.57h-7.15v36.24H260.16L260.16,36.81z M204.3,36.81h25.52v-6.37h-18.38V21.8h16.62v-6.47
                h-16.62V6.94h17.86V0.57H204.3V36.81L204.3,36.81z M191.2,36.81h7.14V0.57h-7.14V36.81L191.2,36.81z M188.08,51.51
                c0.96-1.06,1.47-2.49,1.47-4.12c0-3.65-2.64-5.82-5.82-5.82h-8.03v18.12h3.57v-6.21h3.86C185.26,53.48,186.99,52.73,188.08,51.51
                L188.08,51.51z M185.83,47.5c0,1.48-0.78,2.9-3.21,2.9h-3.34v-5.75h3.42C185.02,44.65,185.83,45.95,185.83,47.5L185.83,47.5z
                 M176.39,6.94h10.67V0.57h-28.48v6.37h10.67v29.87h7.14V6.94L176.39,6.94z M143.68,59.69h12.76v-3.18h-9.19v-4.33h8.31v-3.23h-8.31
                v-4.19h8.93v-3.18h-12.5V59.69L143.68,59.69z M145.74,36.81h8.7V0.57h-7.09v25.26L133.94,0.57h-9.06v36.24h7.14V11.34L145.74,36.81
                L145.74,36.81z M125.54,56.28l-2.72-14.7h-3.88l3.7,18.12h5.31l2.67-13.36l2.64,13.36h5.2L142,41.57h-3.7l-2.54,14.7l-3-14.7h-4.3
                L125.54,56.28L125.54,56.28z M93.92,36.81h25.53v-6.37h-18.38V21.8h16.62v-6.47h-16.62V6.94h17.86V0.57H93.92V36.81L93.92,36.81z
                 M98.3,54.21l-6.7-12.63h-4.53v18.12h3.57V46.96l6.86,12.74h4.35V41.57H98.3V54.21L98.3,54.21z M68.91,10.2
                c0-2.59,2.07-4.3,6.21-4.3c6,0,6.47,3.47,6.68,5.44h7.35C88.58,2.64,82.11,0,74.86,0c-6.99,0-13.36,3.57-13.36,10.72
                c0,3.52,1.35,5.95,3.05,7.45c5.75,5.07,18.02,2.28,18.02,8.23c0,4.09-4.25,4.92-7.3,4.92c-4.45,0-6.99-1.97-6.99-5.95h-7.4
                c0.21,8.13,6,12.01,14.08,12.01c9.01,0,14.81-3.83,14.81-11.8c0-4.56-2.43-6.89-5.38-8.28C78.13,14.24,68.91,15.63,68.91,10.2
                L68.91,10.2z M37.43,10.2c0-2.59,2.07-4.3,6.21-4.3c6.01,0,6.47,3.47,6.68,5.44h7.35C57.11,2.64,50.63,0,43.39,0
                C36.4,0,30.03,3.57,30.03,10.72c0,3.52,1.35,5.95,3.05,7.45c5.75,5.07,18.02,2.28,18.02,8.23c0,4.09-4.25,4.92-7.3,4.92
                c-4.45,0-6.99-1.97-6.99-5.95h-7.4c0.21,8.13,6,12.01,14.08,12.01c9.01,0,14.81-3.83,14.81-11.8c0-4.56-2.43-6.89-5.38-8.28
                C46.65,14.24,37.43,15.63,37.43,10.2L37.43,10.2z M0,36.81h25.52v-6.37H7.14V21.8h16.62v-6.47H7.14V6.94h17.86V0.57H0V36.81
                L0,36.81z M74.62,41.57l-5.44,18.12h3.73l0.98-3.62h6.55l1.03,3.62h3.91L79.8,41.57H74.62L74.62,41.57z M74.75,52.99l2.38-8.67
                l2.43,8.67H74.75L74.75,52.99z M118.16,41.57h-14.24v3.18h5.33v14.94h3.57V44.76h5.33V41.57L118.16,41.57z M167.34,41.57h-8.18
                v18.12h3.57v-7.2h4.09c1.48,0,2.3,0.8,2.41,2.12c0.15,1.84-0.05,3.57,0.34,5.08h3.81c-0.13-0.21-0.29-0.6-0.42-1.48
                c-0.1-0.77-0.16-1.89-0.21-4.01c-0.05-1.81-0.96-2.87-2.51-3.29c2.1-0.91,2.85-2.54,2.85-4.4
                C173.09,43.9,171.17,41.57,167.34,41.57L167.34,41.57z M166.7,44.6c1.81,0,2.85,0.86,2.85,2.46c0,1.89-1.71,2.49-3.37,2.49h-3.44
                V44.6H166.7L166.7,44.6z"/>
        </g>
</svg>
);

export default Logo;
