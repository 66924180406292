import { useDispatch } from 'react-redux';

import { updateCart } from '@kega/sps-core/src/reducers/cart/actions';
import { useCartId, useRequest, useStorefront, useCart } from '@kega/sps-core';

const useAddToCart = () => {

    const { api: { essentielCart } } = useStorefront();
    const dispatch = useDispatch();
    const { fetchCart } = useCart()

    const getCartId = useCartId();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const addToCart = async (product, options) => {

        const cartId = getCartId();

        if (!cartId) { return; }

        try {
            setRequest();

            const result = await essentielCart.addItem(cartId, product, options);
            setSuccess();

            if (result) {
                dispatch(updateCart(result));
            }

            if (cartId) {
                await fetchCart(cartId, options);
            }

        } catch (error) {
            console.log('[AddToCart]', error);
            setError(error);
        }
    };

    return {
        error,
        loading,
        addToCart
    };
}

export default useAddToCart;