import { createReducer } from "@reduxjs/toolkit";

import * as actions from './actions';

const initialState = {
    details: {
        country_code: null
    },
    modalOpened: false
};

export default createReducer(initialState, {
    [actions.setDetails]: (state, action ) => {
        const { payload } = action;

        return {
            ...state,
            details: { ...payload }
        }
    },

    [actions.setModalOpened]: (state, action ) => {
        const { payload } = action;

        return {
            ...state,
            modalOpened: !!payload
        }
    }
})