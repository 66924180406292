import { useState } from 'react';
import { Form } from '@kega/sps-elements';
import { useLogin } from '@kega/sps-core';
import { useNavigate } from "react-router-dom";

import { t } from '../../../lib/translations';

import useTagManager from '../../../hooks/useTagManager';
import useStoreViews from '../../../hooks/useStoreViews';
import useSystemMessage from "../../../hooks/useSystemMessage";
import useCreateCustomer from "../../../hooks/customer/useCreateCustomer";
import useCreateMergeCarts from "../../../hooks/useCreateMergeCarts";
import useFetchWishlist from "../../../hooks/wishlist/useFetchWishlist";
import useInputValidation from "../../../hooks/form/useInputValidation";

import { Input, Checkbox, Button, Field } from '../../../components/form';
import { Row } from "../../../components/grid";
import HelperText from '../../../components/helper_text/HelperText';

import classes from './CheckoutRegister.module.css';

const CheckoutRegister = ({ shippingAddress, className = '', lastOrderId, guestEmail }) => {
    const { dataLayer } = useTagManager();
    const { getUrl } = useStoreViews();
    const { create, success, error } = useCreateCustomer();
    const { login } = useLogin();
    const { createMergeCart } = useCreateMergeCarts();
    const { fetchWishlist } = useFetchWishlist();
    const navigate = useNavigate();
    const { password } = useInputValidation();
    const { setSystemSuccessMessage } = useSystemMessage();

    const [loading, setLoading] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [userPassword, setUserPassword] = useState('');
    const [loggingIn, setLoggingIn] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState('');

    const { firstname, lastname } = shippingAddress || {};

    const onSubmit = async ({ valid, values, resetForm }) => {
        const passwordInvalid = password(values?.password);

        setLoading(true);

        if (passwordInvalid) {
            setPasswordValidation(passwordInvalid);
            setLoading(false);
        } else {
            setPasswordValidation(null);
        }

        if (values['password'] !== values['confirm_password']) {
            setPasswordError(true);
            setLoading(false);
        } else {
            setPasswordError(false);
        }

        if (valid && (values['password'] === values['confirm_password']) && !passwordInvalid) {
            setUserPassword(values?.password);

            await create({
                firstname,
                lastname,
                email: guestEmail,
                password: values?.password,
                is_subscribed: values?.is_subscribed,
                order_number: lastOrderId
            });

            try {
                dataLayer.push({
                    'event':  'sign_up',
                    'method': 'success_page'
                });
            } catch (error) {
                //
            }

            setSystemSuccessMessage(t('account.register.success'));
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const loginSuccess = async () => {
        setLoggingIn(true);

        const token = await login({ username: guestEmail, password: userPassword });

        if (token) {
            // Magento specific creat and merge cart function
            await createMergeCart();
            await fetchWishlist(token);

            navigate({
                pathname: getUrl('/account/overview')
            });

            setLoggingIn(false);
            
            setSystemSuccessMessage(t('account.login.success'));

            try {
                dataLayer.push({
                    'event': 'login',
                    'method': 'normal'
                });
            } catch (error) {
                //
            }
        } else {
            navigate({
                pathname: getUrl('/account/overview')
            });

            setLoggingIn(false);
        }
    }

    return (
        <div className={classes.root}>
            {
                !success &&
                <>
                    <h5>
                        {guestEmail}
                    </h5>
                    <Form onSubmit={onSubmit} className={className}>
                        <Row className={classes.checkout_register}>
                            <Field>
                                <Input
                                    name="password"
                                    placeholder={t('customer.form.password')}
                                    label={t('customer.form.password')}
                                    type="password"
                                    error={passwordValidation}
                                    errorMessage={passwordValidation}
                                    required
                                />
                            </Field>
                        </Row>
                        <Row className={classes.checkout_register}>
                            <Field>
                                <Input
                                    name="confirm_password"
                                    placeholder={t('account.register.password_confirm')}
                                    label={t('account.register.password_confirm')}
                                    type="password"
                                    error={passwordError}
                                    required
                                />
                                {
                                    passwordError &&
                                    <div className={classes.password_error}>
                                        {t('account.register.error.password_confirm')}
                                    </div>
                                }
                            </Field>
                        </Row>
                        <Row className={classes.checkout_register}>
                            <Field className={classes.check_wrapper}>
                                <Checkbox
                                    name="is_subscribed"
                                    label={t('checkout.success.register.subscribe')}
                                    checked={false}
                                />
                            </Field>
                        </Row>

                        {
                            <HelperText
                                text={<div dangerouslySetInnerHTML={{
                                    __html: t('account.register.accept',
                                        {
                                            privacy: '<a href="' + getUrl('/privacy-policy') + '">' + t('account.register.privacy') + '</a>',
                                            terms: '<a href="' + getUrl('/terms-conditions') + '">' + t('account.register.terms') + '</a>' }
                                    )
                                }} />} />
                        }

                        <Button
                            type="submit"
                            loading={loading}
                            variant={'primary'}
                            className={classes.checkout_register_btn}
                        >
                            {t('account.register.button')}
                        </Button>
                    </Form>
                </>
            }

            {
                success &&
                <div className={classes.account_created}>
                    <h4>{t('account.register.success')}</h4>

                    <Button
                        type="submit"
                        loading={loggingIn}
                        variant={'primary'}
                        onClick={loginSuccess}
                        className={classes.checkout_register_btn}
                    >
                        {t('account.login.button')}
                    </Button>
                </div>
            }
        </div>
    );
};

export default CheckoutRegister;