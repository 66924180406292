const unsubscribeFromNewsletter = `
    mutation unsubscribeFromNewsletter($email: String!, $reason: String!, $storeCode: String!) {
        unsubscribeFromNewsletter(
            email: $email,
            reason: $reason,
            storeCode: $storeCode
        ) {
            status
        }
    }
`;

export default unsubscribeFromNewsletter;