import { useClearCart, useCustomer, useLogout } from '@kega/sps-core';

const useLogoutKioskUser = () => {
    const logout = useLogout();
    const clearCart = useClearCart();
    const { authenticated } = useCustomer();

    const logoutUser = () => {
        clearCart();
        logout();
    };

    const autoLogoutUser = () => {
        const resetTimer = () => {
            clearTimeout(window.logoutTimer);
            if(!authenticated){
                return;
            }
            window.logoutTimer = setTimeout(logoutUser, (120 * 1000));  // time is in milliseconds
        }

        window.onload = resetTimer;
        window.onmousemove = resetTimer;
        window.onmousedown = resetTimer;  // catches touchscreen presses as well
        window.ontouchstart = resetTimer; // catches touchscreen swipes as well
        window.ontouchmove = resetTimer;  // required by some devices
        window.onclick = resetTimer;      // catches touchpad clicks as well
        window.onkeydown = resetTimer;
    }

    return {
        autoLogoutUser
    };
};

export default useLogoutKioskUser;
