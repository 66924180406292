import { useDispatch } from 'react-redux';
import { useRequest, useStorefront } from '@kega/sps-core';
import { UpdateProductStockAlerts } from '../../redux/product_alert/actions';

import { t } from '../../lib/translations';
import useSystemMessage from "../useSystemMessage";

const useAddProductStockAlert = () => {
    const { api: { essentielProductAlert } } = useStorefront();

    const dispatch = useDispatch();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();
    const { setSystemSuccessMessage, setSystemErrorMessage } = useSystemMessage();

    const addProductStockAlert = async (request) => {

        try {
            setRequest();

            const result = await essentielProductAlert.addProductStockAlert(request);

            if (result && result.alert) {
                dispatch(UpdateProductStockAlerts({
                    [result.alert.id]: result.alert
                }));

                setSuccess();
                setSystemSuccessMessage(result.message || t('product_alert.success'));

                return {
                    success: true,
                    result: result.alert
                };
            } else {
                setSystemErrorMessage(t('Something went wrong, please try again later'));
                setError(new Error(t('Something went wrong, please try again later')));
            }
        } catch (error) {
            console.log('[AddProductStockAlert]', error);
            setError(error);
        }

        return {
            success: false,
            result: {}
        };
    };

    return {
        error,
        loading,
        addProductStockAlert
    };
}

export default useAddProductStockAlert;
