import { Form } from '@kega/sps-elements';

import { Link, useNavigate } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton';

import useUpdateCustomer from '../../../hooks/customer/useUpdateCustomer';
import useSystemMessage from '../../../hooks/useSystemMessage';
import useStoreViews from '../../../hooks/useStoreViews';
import useInputModifiers from "../../../hooks/form/useInputModifiers";
import useInputValidation from "../../../hooks/form/useInputValidation";
import useFetchCustomer from "../../../hooks/customer/useFetchCustomer";

import { t } from '../../../lib/translations';

import { Container, Row, Col } from "../../../components/grid";
import { Input, Field, Button, LinkButton } from '../../../components/form';

import classes from './UpdateCustomer.module.css';

const UpdateCustomer = () => {
    const navigate = useNavigate();
    const { customer, loading: customerLoading } = useFetchCustomer();
    const { loading: updateLoading, update } = useUpdateCustomer();
    const { getUrl } = useStoreViews();
    const { firstname, lastname, email } = customer;
    const { setSystemSuccessMessage } = useSystemMessage();
    const { normalizeSpecialChars } = useInputModifiers();
    const { firstnameValidation, lastnameValidation } = useInputValidation();

    const onSubmit = async ({ valid, values }) => {
        if (valid) {
            const result = await update(values);

            if (result) {
                setSystemSuccessMessage('account.edit.success');
                navigate(getUrl("account/overview"));
            }
        }
    }

    return (
        <Container xl margins g-xs={0} g-sm={1}>
            <h1>
                {t('account.edit.title')}
            </h1>
            <Row>
                <Col>
                    <div className={classes.email}>{email}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={onSubmit}>
                        <Row>
                            <Field lg={5}>
                                {
                                    customerLoading
                                        ?
                                        <div className={classes.skeleton}>
                                            <Skeleton />
                                        </div>
                                        :
                                        <Input name="firstname" placeholder={t('customer.form.firstname')} label={t('customer.form.firstname')} value={firstname} modifiers={[ normalizeSpecialChars() ]} required rules={[ firstnameValidation ]} />
                                }
                            </Field>
                            <Field lg={5}>
                                {
                                    customerLoading
                                        ?
                                        <div className={classes.skeleton}>
                                            <Skeleton />
                                        </div>
                                        :
                                        <Input name="lastname" placeholder={t('customer.form.lastname')} label={t('customer.form.lastname')} value={lastname} modifiers={[ normalizeSpecialChars() ]} required rules={[ lastnameValidation ]} />
                                }
                            </Field>
                        </Row>
                        <Row>
                            <Col md={12} lg={10} className={classes.buttons}>
                                <Button type="submit" variant="primary" loading={updateLoading} className={classes.button}>
                                    {t('account.edit.button')}
                                </Button>
                                <LinkButton to={getUrl('/account/changepassword')} linkComponent={Link} className={classes.button} variant="secondary">
                                    {t('account.change_password.title')}
                                </LinkButton>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container >
    );
}

export default UpdateCustomer;