const checkPasswordResetTokenQuery = `
    query checkResetToken($email: String!, $resetPasswordToken: String!) {
        checkResetPasswordToken(
            email: $email
            resetPasswordToken: $resetPasswordToken
          ) {
            customer_id
            is_valid 
            message 
          }          
    }
`;

export default checkPasswordResetTokenQuery;