import { forwardRef } from "react";

import { Form } from "@kega/sps-elements";
import { Drawer } from '@kega/sps-elements';

import { t } from "../../../../lib/translations";

import useAddProductStockAlert from "../../../../hooks/product_alert/useAddProductStockAlert";

import { Button, Input } from "../../../../components/form";
import { CrossIcon } from "../../../icons";

import classes from "./SubscribeDrawer.module.css";

const SubscribeDrawer = ({ productId, gallery }, ref) => {
    const { loading, addProductStockAlert } = useAddProductStockAlert();

    const firstImage = gallery && gallery.length > 0 ? gallery[0] : null;

    const onSubmit = ({ valid, values }) => {
        if (!valid || !productId || !values.email) {
            return;
        }

        addProductStockAlert({
            product_id: productId,
            email: values.email
        }).then((res) => {
            if (res.success && ref.current) {
                ref.current.close();
            }
        });
    };

    const closeDrawer = () => {
        if (ref.current) {
            ref.current.close();
        }
    };


    return (

        <Drawer onClose={closeDrawer} drawerClassName={classes.drawer} ref={ref} >
            <div className={classes.drawercontainer}>
                <div className={classes.icon}>
                    <CrossIcon title={"Subscribe"} className={classes.close} size={16} onClick={closeDrawer} />
                </div>
                <Form onSubmit={onSubmit}>
                    <div className={classes.description}>
                        <span>{t('product_alert.email_copy')}</span>
                    </div>
                    <Input
                        name="email"
                        type="email"
                        className={classes.email}
                        label={t("Email")}
                        placeholder={t("Email")}
                        required />
                    <Button
                        type="submit"
                        variant="primary"
                        loading={loading}
                        style={{ width: '100%' }}
                    >
                        {t('product_alert.subscribe')}
                    </Button>

                </Form>

            </div>

            {
                firstImage && <img src={firstImage} alt="Product" className={classes.gallery} />
            }

        </Drawer>
    );
};

export default forwardRef(SubscribeDrawer);
