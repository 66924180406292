import { useDispatch, useSelector } from 'react-redux';

import { useCartId, useRequest, useBillingAddress, useShippingAddress } from '@kega/sps-core';
import { setCustomBillingAddress } from "../../redux/checkout/actions";

const useSetAddresses = () => {
    const getCartId = useCartId();
    const dispatch = useDispatch();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const { setBillingAddress } = useBillingAddress();
    const { setShippingAddress } = useShippingAddress();

    const setAddresses = async ({ billingAddressData, shippingAddressData }) => {
        const cartId = getCartId();

        try {
            setRequest();

            const billingAddressId = billingAddressData.id ?? null;
            const shippingAddressId = shippingAddressData.id ?? null;

            delete shippingAddressData.id;
            delete billingAddressData.id;

            await setBillingAddress({
                cartId: cartId,
                address: !billingAddressId ? billingAddressData : null,
                addressId: billingAddressId
            });


            await setShippingAddress({
                cartId: cartId,
                address: !shippingAddressId ? shippingAddressData : null,
                addressId: shippingAddressId
            });

            setSuccess();
        } catch (error) {
            console.log('[SetAddresses]', error);
            setError(error);
        }
    };

    const processAddress = (address) => {
        const countryCode = address?.country_code ? address?.country_code : address.country?.code;
        const street = [
            Array.isArray(address?.street) ? address?.street[0] : address?.street,
            Array.isArray(address?.street) ? address?.street[1] : address?.street_number,
        ];

        if (countryCode === 'US') {
            street.reverse();
        }

        const newAddress = {
            ...address,
            country_code: countryCode,
            region_id: address?.region ? address?.region.region_id : (address.regionId ? address.regionId : null),
            street: street
        }

        delete newAddress.id;
        delete newAddress.regionId;
        delete newAddress.state;
        delete newAddress.region;
        delete newAddress.default_shipping;
        delete newAddress.default_billing;
        delete newAddress?.country;
        delete newAddress?.street_number;
        delete newAddress?.customerAddressId;

        return newAddress;
    }

    const setFromSingleAddress = async (address) => {
        const newAddress = processAddress(address);

        if(address.id) {
            newAddress.id = address.id;
        }

        await setAddresses({
            billingAddressData: newAddress,
            shippingAddressData: newAddress
        })
    };

    const setAddressBilling = async (address) => {
        const newAddress = processAddress(address);
        const cartId = getCartId();

        try {
            setRequest();

            const billingAddressId = address.id || address?.customerAddressId || null;

            const result = await setBillingAddress({
                cartId: cartId,
                address: !billingAddressId ? newAddress : null,
                addressId: billingAddressId
            });

            dispatch(setCustomBillingAddress({
                id: billingAddressId,
                ...result
            }));

            setSuccess();
        } catch (error) {
            console.log('[setAddressBilling]', error);
            setError(error);
        }
    }

    return {
        error,
        loading,
        setAddresses,
        setAddressBilling,
        setFromSingleAddress
    };
}

export default useSetAddresses;
