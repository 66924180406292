import { useState, useMemo, useEffect } from 'react';
import { useShippingAddress, useBillingAddress, useShippingMethods, useCartId, useCart } from '@kega/sps-core';
import { Loader } from "@kega/sps-elements";

import { t } from '../../../../lib/translations';

import useSetAddresses from '../../../../hooks/checkout/useSetAddresses';
import useStoreViews from '../../../../hooks/useStoreViews';
import useFetchCustomer from '../../../../hooks/customer/useFetchCustomer';
import useCheckoutData from "../../../../hooks/checkout/useCheckoutData";

import AddressList from './addresslist/AddressList';
import AddAddress from './addaddress/AddAddress';
import Shipment from "../../shipment/Shipment";

import { Button } from '../../../../components/form';
import ErrorMessage from '../../../../components/messages/ErrorMessage';
import Modal from '../../../../components/modal/Modal';

import classes from './LoggedIn.module.css';

const LoggedIn = ({ handleContinue, stepPath, selected, setSelected, fetchError, fetchLoading, methodes }) => {
    const { customer: { email, addresses = [], authenticated }, loading: loadingCustomer, error: errorCustomer, fetchCustomerData } = useFetchCustomer();
    const { setFromSingleAddress, loading } = useSetAddresses();
    const { currentStoreView } = useStoreViews();
    const { fetchCart } = useCart();
    const { extraCartData } = useCheckoutData();

    const { allowedCountries = {} } = currentStoreView;
    const { setShippingMethods, shippingMethod,error: errorShippingMethod, loading: loadingShippingMethod } = useShippingMethods();

    const [modalOpen, setModalOpen] = useState(false);

    const { shippingAddress, loading: loadingShipping, error: errorShipping } = useShippingAddress();
    const { loading: loadingBilling, error: errorBilling } = useBillingAddress();
    const [isSaving, setIsSaving] = useState(false);
    const [selectedShipping, setSelectedShipping] = useState(shippingAddress?.customerAddressId);

    const getCartId = useCartId();
    const cartId = getCartId();

    const availableAddresses = useMemo(() => {
        return addresses.filter((address) => allowedCountries.includes(address.country_code.toLowerCase()));
    }, [addresses])

    const getDefaultAddress = () => {
        if (availableAddresses.length === 1) {
            return availableAddresses[0].id
        }

        return availableAddresses.find(address => address.default_shipping)?.id || availableAddresses[0]?.id;
    };

    const onShippingChange = async (shippingAddressId) => {
        setSelectedShipping(shippingAddressId);
        await submitAddressById(shippingAddressId);
    }

    const submitAddressById = async (shippingAddressId) => {
        let defaultBilling = null,
            defaultShipping = null,
            currentAddress;

        const selectedAddress = availableAddresses.filter((address) => address.id === shippingAddressId);

        if (selectedAddress.length > 0) {
            currentAddress = selectedAddress[0];
            setSelectedShipping(currentAddress.id);
        } else {
            availableAddresses.forEach((address) => {
                if (!address) {
                    // Ignore empty addresses in state
                } else if (address) {
                    if (!selectedShipping) {
                        if (address.default_billing) {
                            defaultBilling = address;
                        }

                        if (address.default_shipping) {
                            defaultShipping = address;
                        }

                        setSelectedShipping(defaultShipping?.id ?? address?.id);
                    }
                }
            });

            currentAddress = defaultShipping;
        }

        if (currentAddress) {
            await submitAddress(currentAddress);
        }
    };

    const submitAddress = async (address) => {
        setIsSaving(true);
        await setFromSingleAddress(address);
        setIsSaving(false);
    };

    const handleContinueNext = () => {

        setShippingMethods({
            cartId: cartId,
            carrier: selected.carrier,
            method: selected.method
        }).then( (response) => {
            fetchCart(cartId, extraCartData).then(() => {
                handleContinue(stepPath);
            })
        })
    }

    const handleAddAddresses = async () => {
        setModalOpen(false);
        const newCustomerData = await fetchCustomerData();
        if (newCustomerData?.addresses?.length > 0) {
            const newestAddress = newCustomerData.addresses[newCustomerData.addresses.length - 1];

            setSelectedShipping(newestAddress.id);

            await submitAddress(newestAddress);
        }
    };

    useEffect(() => {
        if (!shippingAddress?.customerAddressId) {
            onShippingChange(getDefaultAddress());
        }
    }, [addresses]);

    useEffect(() => {
        if (authenticated) {
            fetchCustomerData();
        }
    }, [authenticated]);

    return (
        <div>
            <div className={classes.logged_email}>
                <h4 className={classes.heading}>{t('checkout.information.register_heading')}</h4>
                <div>{ email }</div>
            </div>

            {
                (errorShipping || errorBilling)
                &&
                <ErrorMessage>
                    {errorShipping && <span><strong>{t('checkout.information.delivery_address_header')}</strong><br /> {errorShipping.message}</span>}
                    {errorBilling && <span><strong>{t('checkout.information.payment_address_header')}</strong><br /> {errorBilling.message}</span>}
                </ErrorMessage>
            }

            <h4 className={classes.heading}>{t('checkout.information.delivery_address_header')}</h4>

            {
                loadingCustomer || !addresses
                    ?
                    <Loader variant="primary" />
                    :
                    <AddressList
                        selectedAddress={selectedShipping}
                        onAddressChange={onShippingChange}
                        onAdd={() => setModalOpen(true)}
                    />
            }

            <Shipment
                setSelected={setSelected}
                shippingMethodError={errorShippingMethod}
                selected={selected}
                fetchError={fetchError}
                fetchLoading={fetchLoading}
                methodes={methodes}
            />

            <div className={classes.next}>
                <Button
                    variant="primary"
                    onClick={handleContinueNext}
                    loading={(loadingShipping || loadingBilling || loading || loadingShippingMethod || isSaving)}
                    disabled={!selected || !selectedShipping}
                >
                    {t('checkout.steps.shipment.btn_next')}
                </Button>
            </div>

            <Modal
                titleClass={classes.title}
                modalClassName={classes.checkout_modal_address_logged_in}
                title={t('checkout.information.form.title')}
                className={classes.closeIconClass} open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <AddAddress
                    loggedInCheckoutForm={true}
                    handleAddAddresses={() => { handleAddAddresses() }}
                />
            </Modal>

        </div>
    )
}

export default LoggedIn;
