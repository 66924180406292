import { useState } from "react";
import { Form, Loader } from '@kega/sps-elements';
import { useCartId } from "@kega/sps-core";

import { t } from "../../../lib/translations";

import useApplyCoupon from "../../../hooks/checkout/useApplyCoupon";
import useSystemMessage from "../../../hooks/useSystemMessage";
import useCheckoutData from "../../../hooks/checkout/useCheckoutData";
import useEstimateShippingMethods from "../../../hooks/checkout/useEstimateShippingMethods";

import { Button, Input, Field } from '../../../components/form';
import { CrossIcon } from "../../../components/icons";

import classes from './PromoCode.module.css';

const PromoCode = () => {
    const { applyCoupon, loading, appliedCoupon } = useApplyCoupon();
    const { setSystemErrorMessage, setSystemSuccessMessage } = useSystemMessage();
    const { extraCartData } = useCheckoutData();
    const { estimateShippingMethods } = useEstimateShippingMethods();
    const getCartId = useCartId();

    const [errors, setErrors] = useState('');

    const onCouponCodeSubmit = ({ values, valid, resetForm }) =>  {
        if (valid) {
            applyCoupon(values?.coupon_code, extraCartData).then((result) => {
                if (result && result.success) {
                    setSystemSuccessMessage(t('checkout.coupon.apply.success'));
                    setErrors('');
                    values.coupon_code= " ";
                    estimateShippingMethods({ cartId: getCartId() }).then( () => {}) ;

                } else {
                    setErrors(t('checkout.coupon.apply.failed'));
                    setSystemErrorMessage(t('checkout.coupon.apply.failed'));
                }
            });

            resetForm();
        }
    }

    const removeCoupon = () => {
        applyCoupon('', extraCartData).then((result) => {
            setSystemSuccessMessage(t('checkout.coupon.remove.success'));
            setErrors('');
            estimateShippingMethods({ cartId: getCartId() }).then( () => {}) ;
        });
    }

    return (
        <Form onSubmit={onCouponCodeSubmit} className={classes.root}>
            {
                appliedCoupon &&
                <Field className={classes.applied_coupons}>
                    <div className={classes.coupon} onClick={removeCoupon}>
                        {'"'+appliedCoupon+'"'}
                        {
                            loading
                                ?
                                <Loader size={9} />
                                :
                                <CrossIcon width={9} height={9} />
                        }
                    </div>
                </Field>
            }
            <Field className={classes.promo_wrapper}>
                <Input
                    className={classes.input}
                    label={t('checkout.cart.promocode.enter_promo_code')}
                    placeholder={t('checkout.cart.promocode.enter_promo_code')}
                    name="coupon_code"
                    error={errors}
                    required />
            </Field>
            <Field>
                <Button
                    loading={loading}
                    variant="primary"
                    type="submit"
                    className={classes.button}
                >
                    {t('checkout.cart.promocode.submit')}
                </Button>
            </Field>
        </Form>
    );

}

export default PromoCode;