const useInputModifiers = () => {
    const specialCharMapping = {
        "Š":"S", "š":"s", "Đ":"Dj", "đ":"dj", "Ž":"Z", "ž":"z", "Č":"C", "č":"c", "Ć":"C", "ć":"c",
        "À":"A", "Á":"A", "Â":"A", "Ã":"A", "Ä":"A", "Å":"A", "Æ":"A", "Ç":"C", "È":"E", "É":"E",
        "Ê":"E", "Ë":"E", "Ì":"I", "Í":"I", "Î":"I", "Ï":"I", "Ñ":"N", "Ò":"O", "Ó":"O", "Ô":"O",
        "Õ":"O", "Ö":"O", "Ø":"O", "Ù":"U", "Ú":"U", "Û":"U", "Ü":"U", "Ý":"Y", "Þ":"B", "ß":"Ss",
        "à":"a", "á":"a", "â":"a", "ã":"a", "ä":"a", "å":"a", "æ":"a", "ç":"c", "è":"e", "é":"e",
        "ê":"e", "ë":"e", "ì":"i", "í":"i", "î":"i", "ï":"i", "ð":"o", "ñ":"n", "ò":"o", "ó":"o",
        "ô":"o", "õ":"o", "ö":"o", "ø":"o", "ù":"u", "ú":"u", "û":"u", "ý":"y", "þ":"b",
        "ÿ":"y", "Ŕ":"R", "ŕ":"r"
    };

    const normalizeSpecialChar = (char) => (char && specialCharMapping[char]) ? char : '';

    const normalizeSpecialChars = (allowDigits = false, allowSpaces = true, allowChars = '') => {
        const regex = new RegExp(`[^A-Za-z${allowDigits ? '0-9' : ''}${allowSpaces ? ' ' : ''}${allowChars}]`, "g");

        return (value) => (
            typeof value === 'string'
                ? value.replace(regex, normalizeSpecialChar)
                : ''
        );
    }

    return {
        normalizeSpecialChars
    }
}

export default useInputModifiers;
