import { Accordion } from '@kega/sps-elements';

import { Col } from "../../../components/grid";
import Copyright from "../copyright/Copyright";
import LinksColumn from "./linkscolumn/LinksColumn";

import classes from './FooterLinks.module.css';

const FooterLinks = ({ links }) => {
    return (
        <Accordion onlyOne>
            {
                links?.length > 0 &&
                links.map((itemColumn) => {
                    return (
                        <Col lg={3} key={itemColumn.title} className={classes.links_container}>
                            <div className={classes.links_column}>
                                <LinksColumn column={itemColumn} />
                            </div>
                            {itemColumn.position === 3 && <div className={classes.copyright}><Copyright /></div>}
                        </Col>
                    );
                })
            }
        </Accordion>
    );
}

export default FooterLinks;
