import { useRequest, useStorefront } from '@kega/sps-core';
import { useDispatch, useSelector } from "react-redux";
import { clearCheckoutData, setCheckoutAgreements } from "../../redux/checkout/actions";

const useCheckoutAgreements = () => {
    const { api: { checkout } } = useStorefront();
    const dispatch = useDispatch();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();
    const { checkoutAgreements } = useSelector((state) => state.essentielCheckout);

    const clear = () => {
        dispatch(clearCheckoutData());
    };

    const getCheckoutAgreements = async () => {
        try {
            setRequest();
            const result = await checkout.getCheckoutAgreements();

            if (result) {
                dispatch(setCheckoutAgreements(result));
            }

            setSuccess();
            return result;
        } catch (error) {
            setError(error);
        }
    }

    return {
        error,
        loading,
        checkoutAgreements,
        getCheckoutAgreements,
        clear
    }
}

export default useCheckoutAgreements;
