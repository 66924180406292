import { Builder } from '@builder.io/react/lite';
import { Container } from "../../../../components/grid";

export const Divider = ({ background_color, full_width }) => {

    return (
        <Container xl={!full_width ? true : null}>
            <div
                style={{ backgroundColor: background_color, height: '1px' }}/>
        </Container>
    );
};

Builder.registerComponent(Divider, {
    name: "Divider",
    static: true,
    defaultStyles: {
        marginTop: "0px",
    },
    inputs: [
        {
            name: 'full_width',
            type: 'boolean',
            defaultValue: true,
        },
        {
            name: 'background_color',
            type: 'color',
            defaultValue: '#000'
        }
    ]
});
