import IconWrapper from '../wrapper/IconWrapper';

const ShoppingbagIcon = ({ color = null, title, ...props }) => {
    return (
        <IconWrapper color={color} {...props}>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" role={title ? null : "presentation"}>
                {title && <title>{title}</title>}
                <path d="M12.9153 4.71166C12.6102 1.96319 11.0847 0 9.05085 0C7.01695 0 5.38983 1.96319 5.08475 4.71166H0L1.83051 16H16.1695L18 4.71166H12.9153ZM6.50847 4.71166C6.71186 2.94479 7.83051 1.47239 8.94915 1.47239C10.1695 1.47239 11.1864 2.94479 11.3898 4.71166H6.50847ZM3.05085 14.6258L1.62712 6.18405H16.3729L14.9492 14.6258H3.05085Z" fill={color ? color : 'black'} />
            </svg>
        </IconWrapper>
    );
}

export default ShoppingbagIcon;
