import Button from "../../form/button/Button";

import classes from "./FacebookButton.module.css";

const FacebookButton = (props) => {
    return (
        <Button className={classes.button} {...props} />
    );
};

export default FacebookButton;
