import { connectInfiniteHits, connectStateResults } from 'react-instantsearch-dom';
import { Link, useNavigate } from "react-router-dom";
import { LinkButton } from "../../../../../components/form";
import Skeleton from "react-loading-skeleton";

import { t } from "../../../../../lib/translations";

import useStoreViews from "../../../../../hooks/useStoreViews";

import classes from './CmsPageGrid.module.css';

const AlgoliaLoadingState = connectStateResults(
    ({ isSearchStalled, children }) => (
        isSearchStalled
            ?
            <div className={classes.root}>
                <Skeleton className={classes.loader_title} />
                <div className={classes.result_container}>
                    {[...Array(8)].map((i) => <Skeleton key={i} className={classes.result_skeleton} />)}
                </div>
            </div>
            :
            children
    )
);

const CmsPageGrid = ({ hits, query }) => {
    const navigate = useNavigate();
    const { getUrl } = useStoreViews();

    const handleBack = (event) => {
        event.preventDefault();

        if (history.length === 2) {
            navigate(getUrl(), { replace: true });
        } else {
            navigate(-1);
        }
    };

    const routeChange = (pageItem) => {
        navigate(getUrl('/' + pageItem.slug));
    };

    return (
        <>
            <AlgoliaLoadingState>
                {
                    hits.length !== 0
                    &&
                (
                    <div className={classes.root}>
                        <span className={classes.page_result_title}>
                            <h4>{t('header.search.all.help.results')}</h4>
                            <h3 className={classes.result_title_query}>{query ? `"${query}"` : null}</h3>
                            <h4>({t('header.search.result.page.results', { count: hits.length })})</h4>
                        </span>
                        <div className={classes.result_container}>
                            {hits.map((pageItem) => (
                                <div key={pageItem.objectID} className={classes.page_item_container} onClick={() => routeChange(pageItem)} role="button" tabIndex="0">
                                    <h3 className={classes.page_item_name}>{pageItem.name}</h3>
                                </div>
                            ))}
                        </div>
                        <div className={classes.button_container}>
                            <LinkButton
                                onClick={handleBack}
                                linkComponent={Link}
                                variant="secondary"
                            >
                                {t('header.search.all.help.results.back')}
                            </LinkButton>
                        </div>
                    </div>
                )
                }
            </AlgoliaLoadingState>
        </>
    );
};

export default connectInfiniteHits(CmsPageGrid);
