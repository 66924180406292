const getProductStockAlertsQuery = `
    query {
        getProductStockAlerts {
            id
            name
            sku
            price
            image
            image_url
            url_key
        }
    }
`;

export default getProductStockAlertsQuery;
