import { useConfig } from "@kega/sps-core";
import { Price as KegaPrice } from "@kega/sps-components";

import classNames from "classnames";
import classes from "./Price.module.css";


const Price = ({ rootClassName, className, locale, currency, originalPrice = 0, price = 0, isCart = false, ...props }) => {
    const config = useConfig();
    const defaultLocale = config.get('locale');
    const defaultCurrency = config.get('currency');
    const isFrenchStoreView = defaultLocale.startsWith("fr-");

    const renderPrice = (amount, additionalClassName) => (
        <>
            {
                (amount < 0) && (
                    <span className={classes.negative}>-</span>
                )
            }
            <KegaPrice
                className={classNames(className, additionalClassName, defaultCurrency)}
                locale={(defaultCurrency === 'EUR' && !isFrenchStoreView) ? 'nl-NL' : locale || defaultLocale}
                currency={currency || defaultCurrency}
                price={Math.abs(amount)}
                {...props} />
        </>
    );

    return (
        <div className={classNames(classes.root, rootClassName, isCart && classes.cart_price) }>
            {
                (originalPrice > 0 && price < originalPrice)
                    ? (
                        <>
                            {renderPrice(price, classes.for)}
                            {renderPrice(originalPrice, classes.from)}
                        </>
                    )
                    : renderPrice(price)
            }
        </div>
    );
};

export default Price;
