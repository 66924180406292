import { Builder } from '@builder.io/react/lite';
import ContactForm from '../../../../components/contact_form/ContactForm';

const ContactFormDetails = () => {
    return (
        <ContactForm />
    );
}

export default ContactFormDetails;

Builder.registerComponent(ContactFormDetails, {
    name: "ContactForm",
    static: true,
    defaultStyles: {
        marginTop: "0px",
    }
});
