import classNames from 'classnames';
import classes from './ColorOptions.module.css';

const ColorOptions = ({ code, values=[], selected=null, onChange=null }) => {

    return (
        <div className={ classes.root } >
            {
                values.map(({ id, value, disabled }) => {

                    let props = {}
                    if (!disabled && onChange) {
                        props.onClick = onChange.bind(this, code, id);
                    }

                    return (
                        <div key={id} className={ classes.container } { ...props }>
                            <div className={ classNames(classes.value, { [classes['selected']]: (selected === id) }, { [classes['disabled']]: disabled } ) } style={{ backgroundColor: value }} />
                        </div>
                    )
                })
            }
        </div>
    );

}

export default ColorOptions;