import { Link } from "react-router-dom";
import { Form, LinkButton, Loader } from '@kega/sps-elements';
import { Button, Checkbox, CounterInput, Input, Radio, Select, Textarea } from '../../components/form';
import FacebookButton from "../../components/facebook/facebook_button/FacebookButton";

import PlacesInput from "../../components/places_input/PlacesInput";

import { t } from '../../lib/translations';
import { Col, Row, Container } from '../../components/grid';

const Styleguide = () => {
    const handleCallback = (childData) => {
    }

    return (
        <Container xl margins g-xs={1} g-sm={3} g-md={2} g-xl={4} >
            <Row>
                <Col>
                    <h1>{t('Styleguide')}</h1>
                </Col>
            </Row>
            <Row>
                <Col md="1"><div style={{ background: '#EDEDED', textAlign: 'center' }}>1</div></Col>
                <Col md="1"><div style={{ background: '#EDEDED', textAlign: 'center' }}>1</div></Col>
                <Col md="1"><div style={{ background: '#EDEDED', textAlign: 'center' }}>1</div></Col>
                <Col md="1"><div style={{ background: '#EDEDED', textAlign: 'center' }}>1</div></Col>
                <Col md="1"><div style={{ background: '#EDEDED', textAlign: 'center' }}>1</div></Col>
                <Col md="1"><div style={{ background: '#EDEDED', textAlign: 'center' }}>1</div></Col>
                <Col md="1"><div style={{ background: '#EDEDED', textAlign: 'center' }}>1</div></Col>
                <Col md="1"><div style={{ background: '#EDEDED', textAlign: 'center' }}>1</div></Col>
                <Col md="1"><div style={{ background: '#EDEDED', textAlign: 'center' }}>1</div></Col>
                <Col md="1"><div style={{ background: '#EDEDED', textAlign: 'center' }}>1</div></Col>
                <Col md="1"><div style={{ background: '#EDEDED', textAlign: 'center' }}>1</div></Col>
                <Col md="1"><div style={{ background: '#EDEDED', textAlign: 'center' }}>1</div></Col>
            </Row>
            <Row>
                <Col md="12"><div style={{ background: '#EDEDED', textAlign: 'center' }}>12</div></Col>
            </Row>
            <Row>
                <Col md="4"><div style={{ background: '#EDEDED', textAlign: 'center' }}>4</div></Col>
                <Col md="8"><div style={{ background: '#EDEDED', textAlign: 'center' }}>8</div></Col>
            </Row>
            <Row>
                <Col md="8"><div style={{ background: '#EDEDED', textAlign: 'center' }}>8</div></Col>
                <Col md="4"><div style={{ background: '#EDEDED', textAlign: 'center' }}>4</div></Col>
            </Row>
            <Row>
                <Col md={6}>
                    <h1>Heading 1</h1>
                    <h2>Heading 2</h2>
                    <h3>Heading 3</h3>
                    <h4>Heading 4</h4>
                    <h5>Heading 5</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac lorem eros. Proin eget viverra purus. Nam in purus id nibh vestibulum fringilla in sit amet sapien. Nullam pellentesque arcu elit, in facilisis neque aliquet vitae. Donec id tristique leo, in vehicula ex. Integer porttitor accumsan enim non facilisis. Nunc et nisl vel sem volutpat ultrices quis nec nunc. Aliquam nibh ligula, pharetra sit amet nisl id, semper posuere turpis. Vestibulum vulputate non lacus pellentesque varius.</p>
                    <p>Integer porttitor accumsan enim non facilisis. Nunc et nisl vel sem volutpat ultrices quis nec nunc. Aliquam nibh ligula, pharetra sit amet nisl id, semper posuere turpis. Vestibulum vulputate non lacus pellentesque varius. Phasellus id nibh sodales, imperdiet tortor vitae, blandit est. Duis venenatis tellus ac turpis vehicula faucibus. In vestibulum dui et facilisis lacinia.</p>
                    <p><a href="/">Link</a></p>
                    <ul>
                        <li>List 1</li>
                        <li>List 2</li>
                        <li>List 3</li>
                    </ul>
                </Col>
            </Row>
            <Form>
                <Row>
                    <Col md={4}>
                        <Input name="email1" type="email" placeholder="Email" label="Email" required />
                    </Col>
                    <Col md={4}>
                        <Input name="email2" type="email" placeholder="Email" label="Email" />
                    </Col>
                    <Col md={4}>
                        <Input name="email3" type="email" placeholder="Email" label="Email" disabled />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PlacesInput />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Textarea
                            name="comment"
                            label="Comment"
                            placeholder="Fill in your comment"
                            labelAnimated
                            required />
                    </Col>
                    <Col md={4}>
                        <Textarea
                            name="comment"
                            label="Comment"
                            placeholder="Fill in your comment"
                            labelAnimated />
                    </Col>
                    <Col md={4}>
                        <Textarea
                            name="comment"
                            label="Comment"
                            placeholder="Fill in your comment"
                            labelAnimated
                            disabled />
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <Select
                            name="enabled"
                            label="Enabled"
                            required
                        >
                            <option value="">Choose option</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </Select>
                    </Col>
                    <Col md={4}>
                        <Select
                            name="enabled"
                            label="Enabled"
                        >
                            <option value="">Choose option</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </Select>
                    </Col>
                    <Col md={4}>
                        <Select
                            name="enabled"
                            label="Enabled"
                            disabled
                        >
                            <option value="">Choose option</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Checkbox
                            name="checkbox-required"
                            label="Checkbox required"
                            required />
                    </Col>
                    <Col md={4}>
                        <Checkbox
                            name="checkbox"
                            label="Checkbox" />
                    </Col>
                    <Col md={4}>
                        <Checkbox
                            name="checkbox-disabled"
                            label="Checkbox disabled"
                            disabled />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Radio
                            name="radio-required"
                            label="Radio required"
                            required />
                    </Col>
                    <Col md={4}>
                        <Radio
                            name="radio"
                            label="Radio" />
                    </Col>
                    <Col md={4}>
                        <Radio
                            name="radio-disabled"
                            label="Radio disabled"
                            disabled />
                    </Col>
                </Row>
                <Col md={4}>
                    <Input name="email7" type="email" placeholder="Email" label="Email" errorMessage='Error message' labelAnimated error required />
                </Col>

                <Col md={12}>
                    <Button
                        type="submit"
                        variant="primary"
                    >
                        Submit
                    </Button>
                </Col>

            </Form>
            <Row>
                <Col>
                    Counter input
                    <CounterInput
                        name="qty"
                        parentCallback={handleCallback} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="primary">
                        Primary
                    </Button>
                </Col>
                <Col>
                    <Button variant="secondary">
                        Secondary
                    </Button>
                </Col>
                <Col>
                    <Button
                        variant="primary"
                        loading={true}
                    >
                        Loading
                    </Button>

                    <Button
                        variant="primary"
                        disabled={true}
                    >
                        Disabled
                    </Button>
                </Col>
                <Col>
                    <LinkButton
                        variant="primary"
                        href="/nl_nl/over-ons"
                        linkComponent={Link}
                    //linkComponent={(props) => <Link to="/nl_nl/over-ons" {...props}>Test x</Link>}
                    >
                        LinkButton
                    </LinkButton>

                    <Link to="/nl_nl/over-ons">Link</Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="primary">
                        ADD NEW SHIPPING ADDRESS
                    </Button>
                </Col>
                <Col>
                    <Button variant="secondary">
                        ADD NEW SHIPPING ADDRESS
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="third">
                        Subscribe
                    </Button>
                </Col>
                <Col>
                    <Button variant="third" disabled>
                        Subscribe
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="fourth">
                        Subscribe
                    </Button>
                </Col>
                <Col>
                    <Button variant="fourth" disabled>
                        Subscribe
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FacebookButton>
                        Subscribe
                    </FacebookButton>
                </Col>
                <Col>
                    <FacebookButton disabled>
                        Subscribe
                    </FacebookButton>
                </Col>
            </Row>
            <Row>
                <Col>
                    Loader
                    <div>
                        <Loader variant="primary" />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Styleguide;
