import { useState, useEffect, useMemo } from "react";
import { useConfig } from "@kega/sps-core";
import { useSelector } from "react-redux";

import { t } from "../lib/translations";

const useLabels = (product) => {
    const [labels, setLabels] = useState([]);
    const { special_price, price_range, news_from_date, news_to_date, stock_status, in_stock, price, is_expected, is_preorder, special_to_date, discount_percentage } = product;
    const config = useConfig();
    const defaultCurrency = config.get('currency') ?? 'EUR';
    const { disable_sale_label } = useSelector((state) => state.storeConfig);
    const specialDateTo = (price && price[defaultCurrency] && price[defaultCurrency].special_to_date !== '') ? price[defaultCurrency].special_to_date :  special_to_date;

    const isSpecialPriceExpired = useMemo(() => {
        const today = Date.now();
        const expireDate = specialDateTo ? Date.parse(specialDateTo) : null;

        return expireDate ? today > expireDate : false;
    }, [specialDateTo]);

    const isNew = useMemo(() => {
        if (!news_from_date || !news_to_date) {
            return false;
        }

        let newDate = new Date(),
            date = newDate.getDate(),
            month = newDate.getMonth()+1,
            year = newDate.getFullYear(),
            currentDate = `${year}-${month<10?`0${month}`:`${month}`}-${date<10?`0${date}`:`${date}`}`;

        return currentDate >= news_from_date.replace(' 00:00:00', '') && currentDate <= news_to_date.replace(' 00:00:00', '');
    }, [news_from_date, news_to_date]);

    const outOfStock = useMemo(() => {
        if (stock_status === 'OUT_OF_STOCK') {
            return true;
        }

        if (typeof in_stock !== 'undefined') {
            if (!in_stock) {
                return true;
            }
        }

        return false;
    }, [stock_status, in_stock]);

    const isOnSale = useMemo(() => {

        if (disable_sale_label) {
            return false;
        }

        if (special_price) {
            return true;
        }

        if (isSpecialPriceExpired) {
            return false;
        }

        const today = Date.now();
        const expireDate = specialDateTo ? Date.parse(specialDateTo) : null;
        const isExpired = expireDate ? today > expireDate : false;

        if (isExpired) {
            return false;
        }

        if (price_range) {
            if (typeof price_range?.minimum_price?.regular_price?.value === 'number') {
                if (price_range?.minimum_price?.regular_price?.value > price_range?.minimum_price?.final_price?.value) {
                    return true;
                }
            }
        }

        if (price) {
            if (typeof price[defaultCurrency]?.default === 'number') {
                if (price[defaultCurrency]?.default_original > price[defaultCurrency]?.default) {
                    return true;
                }
            }
        }

        return false;
    }, [special_price, price_range, disable_sale_label, isSpecialPriceExpired]);

    const discountPercentage = useMemo(() => {
        return (disable_sale_label || (!special_price && !isOnSale) || isSpecialPriceExpired)
            ? null
            : discount_percentage;
    }, [discount_percentage, disable_sale_label, isSpecialPriceExpired]);

    const isExpected = useMemo(() => {
        return is_expected === 'Yes' || is_expected === 1;
    }, [is_expected]);

    const isPreorder = useMemo(() => {
        return is_preorder === 'Yes' || is_preorder === 1;
    }, [is_preorder]);

    useEffect(() => {
        let labelPositions = {
            top_left: [],
            top_right: [],
            bottom_left: [],
            bottom_right: []
        };

        if (isOnSale) {
            labelPositions.bottom_left.push({
                id: "sale",
                label: t('label.sale')
            })
        }

        if (isNew) {
            labelPositions.bottom_left.push({
                id: "new",
                label: t('label.new')
            })
        }

        if (outOfStock && !isExpected) {
            labelPositions.bottom_left.push({
                id: "sold_out",
                label: t('label.sold_out')
            })
        }

        if (isExpected) {
            labelPositions.bottom_left.push({
                id: "is_expected",
                label: t('label.is_expected')
            })
        }

        if (isPreorder) {
            labelPositions.bottom_left.push({
                id: "is_preorder",
                label: t('label.is_preorder')
            })
        }

        if (discountPercentage) {
            labelPositions.bottom_left.push({
                id: "discount_percentage",
                label: t('label.discount_percentage', { percentage: discountPercentage })
            })
        }

        setLabels(labelPositions);
    }, [product])

    return {
        labels
    }
}

export default useLabels;
