import { lazy, Suspense } from 'react';
import { Loader } from '@kega/sps-elements';

const AdyenRedirect = lazy(() => import('./AdyenRedirect'));

const PaymentRedirect = () => {
    return (
        <div>
            <h1>Payment redirect</h1>
            <Suspense fallback={<Loader variant="primary" />}>
                <AdyenRedirect />
            </Suspense>
        </div>
    )
}

export default PaymentRedirect;