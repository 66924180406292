// TODO Remove once addAddress (EA-411),deleteAddress and addOrders (EA-428) are implemented in Kega core
import { createAction } from '@reduxjs/toolkit';

export const authenticated = createAction('customer/authenticated');
export const addToken = createAction('customer/add_token');
export const addCustomerData = createAction('customer/add_data');
export const addAdresses = createAction('customer/add_adresses');
export const addAddress = createAction('customer/add_address');
export const deleteAddress = createAction('customer/delete_address');
export const addOrders = createAction('customer/add_order'); // EA-428
export const addStoreOrders = createAction('customer/add_store_order');
export const logout = createAction('customer/logout');
