import { useSelector } from "react-redux";

import { t } from "../../../lib/translations";

import useI18n from "../../../hooks/useI18n";

import ShipmentComplete from "../shipment/ShipmentComplete";

import classes from "./InformationComplete.module.css";

const InformationComplete = () => {
    const checkout = useSelector(state => state.checkout);
    const { firstname, lastname, telephone, street, postcode, city, country, company } = checkout.shippingAddress || {};
    const { getCountryNameByCode } = useI18n();

    return (
        <div className={classes.complete_information}>
            <div className={classes.info_one}>
                <h5 className={classes.shipping_title}>{t("checkout.payment.shipping_address")}</h5>
                <div>
                    {
                        checkout.shippingAddress &&
                        <>
                            <p>{firstname} {lastname}</p>
                            { company && <p>{company}</p> }
                            <p>
                                {
                                    Array.isArray(street) &&
                                    <span>
                                        {typeof street[0] !== 'undefined' && street[0]} &nbsp;
                                        {typeof street[1] !== 'undefined' && street[1]}
                                    </span>
                                }
                            </p>
                            <p>
                                {postcode} {city} <br></br>
                                {getCountryNameByCode(country?.code)}
                            </p>

                            <p>T +{telephone}</p>
                        </>
                    }
                </div>
            </div>
            <div className={classes.info_two}>
                <ShipmentComplete />
            </div>
        </div>
    );
};

export default InformationComplete;
