const giftcardBalanceQuery = `
query checkBalance($number: String!, $pin: String!)
{
    checkGiftcardBalance(number: $number, pin: $pin) {
      balance {
        currency
        value
      }
      success
    }
}`;

export default giftcardBalanceQuery;