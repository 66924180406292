import { useDispatch } from 'react-redux';
import { useRequest, useStorefront } from '@kega/sps-core';
import useWishlistId from "../../hooks/wishlist/useWishlistId";

import { UpdateWishlist } from '../../redux/wishlist/actions';

const useAddToWishlist = () => {
    const { api: { essentielWishlist } } = useStorefront();

    const dispatch = useDispatch();
    const getWishlistId = useWishlistId();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const addToWishlist = async (product) => {

        let wishlistId = getWishlistId();

        if (wishlistId === null || typeof wishlistId === 'undefined') {
            wishlistId = 0;
        }

        try {
            setRequest();
            const result = await essentielWishlist.addProductToWishlist(wishlistId, product);

            if (result) {
                dispatch(UpdateWishlist(result));
            }

            setSuccess();
        } catch (error) {
            console.log('[AddToWishlist]', error);
            setError(error);
        }
    };

    return {
        error,
        loading,
        addToWishlist
    };
}

export default useAddToWishlist;
