import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useCart, useCartId } from '@kega/sps-core';

import { deleteCookie, getCookie } from "../../lib/cookies";

import useKiosk from "../../hooks/useKiosk";
import useCheckoutData from "../../hooks/checkout/useCheckoutData";
import useStoreViews from "../../hooks/useStoreViews";

import { default as CheckoutModule } from '../../modules/checkout/Checkout';

const Checkout = () => {
    const { fetchCart, totals, items } = useCart();
    const getCartId = useCartId();
    const cartId = getCartId();
    const { isKiosk, currentStoreCode } = useStoreViews();
    const { setCurrentStore } = useKiosk();
    const { extraCartData } = useCheckoutData();

    useEffect( () => {
        if(cartId && isKiosk){

            const kioskStore = localStorage.getItem('pwa_kiosk_id');
            if(kioskStore && kioskStore.length > 0) {
                setCurrentStore(cartId, kioskStore);
            }

            fetchCart(cartId, extraCartData);
        }
    }, [cartId]);

    return (
        <>
            <Helmet>
                <title>Checkout</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <CheckoutModule
                items={items}
                totals={totals} />
        </>
    )
}

export default Checkout;
