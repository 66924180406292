const confirmNewsletterSubscription = `
    mutation confirmNewsletter($id: Int!, $code: String!) {
        confirmNewsletterSubscription(
            id: $id,
            code: $code
        ) {
            status
        }
    }
`;

export default confirmNewsletterSubscription;