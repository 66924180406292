import { useState, useEffect } from 'react';
import { useFetchCustomer } from '@kega/sps-core';
import { Form } from '@kega/sps-elements';

import Skeleton from 'react-loading-skeleton';

import useUpdateNewsletter from '../../../hooks/customer/useUpdateNewsletter';
import useSystemMessage from '../../../hooks/useSystemMessage';

import { t } from '../../../lib/translations';

import BuilderSection from '../../builder/BuilderSection';

import { Container, Row, Col } from "../../../components/grid";
import { Checkbox, Button } from '../../../components/form';

import classes from './Newsletter.module.css';

const Newsletter = () => {
    const { customer, loading: customerLoading } = useFetchCustomer();
    const { error, loading, updateStatus } = useUpdateNewsletter();
    const { is_subscribed } = customer;

    const { setSystemSuccessMessage } = useSystemMessage();

    const [status, setStatus] = useState();
    const [selectedStatus, setSelectedStatus] = useState();

    useEffect(() => {
        setStatus(is_subscribed);
        setSelectedStatus(is_subscribed);
    }, [is_subscribed]);

    const handleChange = (e) => {
        setSelectedStatus(e.target.value);
    }

    const onSubmit = async ({ valid, values }) => {
        if (valid) {
            await updateStatus(values.is_subscribed);

            if (!error) {
                setSystemSuccessMessage(values.is_subscribed ? t('newsletter.success.subscribe') : t('newsletter.success.unsubscribe'));
            }

            setStatus(values.is_subscribed);
            setSelectedStatus(values.is_subscribed);
        }
    }

    return (
        <Container xl margins g-xs={0} g-sm={1}>
            <Row>
                <Col>
                    <h1>{t('newsletter.title')}</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <BuilderSection model="account-newsletter-section" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={onSubmit}>
                        <Row>
                            <Col>
                                {
                                    customerLoading
                                        ?
                                        <div className={classes.skeleton}>
                                            <Skeleton />
                                        </div>
                                        :
                                        <div className={classes.switch_toggle}>
                                            <Checkbox name="is_subscribed" checked={selectedStatus} label={' '} onChange={handleChange} />
                                            <div className={classes.newsletter_label}>
                                                {t('newsletter.subscribe')}
                                            </div>
                                        </div>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="submit" variant="primary" loading={loading} disabled={status === selectedStatus}>
                                    {t('Save')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Newsletter;