import { CartItems } from "@kega/sps-cart";
import { useState } from "react";

import { t } from "../../../lib/translations";

import CheckoutItem from "../checkoutitem/CheckoutItem";
import CartTotals from "../../minicart/carttotals/CartTotals";

import { ChevronIcon } from "../../../components/icons";

import classes from "./CheckoutSummary.module.css";

const CheckoutSummary = ({ items = [], match, loading = false }) => {
    const [toggle, setToggle] = useState(true);
    const toggleSize = 10;

    const handleToggle = () => {
        setToggle(!toggle)
    }

    return (
        <div className={classes.cart_summary}>
            {
                !match && <h4 className={classes.cart_summary_title}>{t("checkout.cart.header")}</h4>
            }
            {items.length > 0 && (
                <div className={classes.cart_summary_content} onClick={() => match ? handleToggle() : false}>
                    <div className={classes.toggle}>
                        {
                            match && <h4 className={classes.cart_summary_title}>{t("checkout.cart.header")}</h4>
                        }
                        <div className={classes.cart_number_title}>
                            ({items.length === 1
                                ? t("checkout.cart.one_item", {
                                    count: items.length,
                                })
                                : t("checkout.cart.items", { count: items.length })})
                        </div>

                        {toggle ? <ChevronIcon className={classes.icon} size={toggleSize} /> : <ChevronIcon className={classes.icon} size={toggleSize} />}
                    </div>
                    {
                        match ?
                            (!toggle && (
                                <>
                                    <CartItems
                                        className={classes.cart_items}
                                        itemComponent={CheckoutItem}
                                        loading={loading}/>

                                    <div className={classes.totals_wrapper}>
                                        <CartTotals isCart={true} loading={loading} className={classes.totals} />
                                    </div>    
                                </>
                            )) :
                            <>
                                <CartItems
                                    className={classes.cart_items}
                                    itemComponent={CheckoutItem}
                                    loading={loading}/>

                                <div className={classes.totals_wrapper}>
                                    <CartTotals isCart={true} loading={loading} className={classes.totals} />
                                </div>
                            </>
                    }
                </div>
            )}
        </div>
    );
};

export default CheckoutSummary;
