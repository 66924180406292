import { customerAbstract } from '@kega/sps-connector';

import getRegisterQuery from '../queries/customer/getRegister.gql';
import getDeleteCustomerAddressQuery from '../queries/customer/getDeleteCustomerAddressQuery.gql';
import updateCustomerAddress from '../queries/customer/updateCustomerAddress.gql';
import updateNewsletterStatus from '../queries/customer/updateNewsletterStatus.gql';
import updateCustomer from '../queries/customer/updateCustomer.gql';
import getCustomerOrders from '../queries/customer/getCustomerOrders.gql'; // TODO Remove once EA-428 is implemented
import getCustomerOrderQuery from '../queries/customer/getCustomerOrder.gql'; // TODO Remove once EA-428 is implemented
import getCustomerStoreOrders from '../queries/customer/getCustomerStoreOrders.gql';
import getCustomerStoreOrder from '../queries/customer/getCustomerStoreOrder';
import resetPassword from '../queries/customer/resetPassword.gql';
import isEmailAvailable from '../queries/customer/isEmailAvailable.gql';
import createCustomerAddress from "../queries/customer/createCustomerAddress.gql";
import getCustomerQuery from "../queries/customer/getCustomer.gql";
import checkResetPasswordTokenGql from "../queries/customer/checkResetPasswordToken.gql";


import parseErrors from "@kega/sps-connector-magento2/src/utils/parseErrors";

import { objectToGqlQuery } from "../../lib/utils";


export default ({ client, tokens }) => {
    const customer = Object.create(customerAbstract);

    customer.createCustomer = (customerPayload) => {

        return new Promise((resolve, reject) => {
            const payload = {
                query: getRegisterQuery,
                variables: {
                    firstname: customerPayload.firstname,
                    lastname: customerPayload.lastname,
                    email: customerPayload.email,
                    password: customerPayload.password,
                    is_subscribed: customerPayload.is_subscribed,
                    order_number: customerPayload.order_number
                }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    customer.deleteAddress = async (addressId) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: getDeleteCustomerAddressQuery,
                variables: {
                    id: addressId
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    customer.updateAddress = async (params) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: updateCustomerAddress,
                variables: {
                    id: params.id,
                    country_code: params.country_code.toUpperCase(),
                    street: [params.street, params.street_number],
                    telephone: params.telephone,
                    company: params.company,
                    vat_id: params.vat_id,
                    postcode: params.postcode,
                    city: params.city,
                    firstname: params.firstname,
                    lastname: params.lastname,
                    default_shipping: params.default_shipping,
                    default_billing: params.default_billing,
                    regionId: params.regionId
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    customer.createAddress = async (params) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: createCustomerAddress,
                variables: {
                    country_code: params.country_code.toUpperCase(),
                    street: [params.street, params.street_number],
                    telephone: params.telephone,
                    company: params.company,
                    vat_id: params.vat_id,
                    postcode: params.postcode,
                    city: params.city,
                    firstname: params.firstname,
                    lastname: params.lastname,
                    default_shipping: params.default_shipping,
                    default_billing: params.default_billing,
                    regionId: params.regionId
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    customer.getDetails = async (options) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const payload = {
                query: getCustomerQuery(options?.custom_attributes, options?.custom_attributes_address),
                variables: {}
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(data.customer);

            }).catch((error) => {
                console.log('[Customer]', error);
                reject(error);
            });
        });
    },

    customer.updateCustomerNewsletter = async (status) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: updateNewsletterStatus,
                variables: {
                    is_subscribed: status
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    // TODO Remove once EA-428 is implemented
    customer.getOrders = async ({ additionalData }, searchCriteria) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            const payload = {
                query: getCustomerOrders(
                    objectToGqlQuery(searchCriteria),
                    additionalData
                ),
                variables: {}
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                const result = (data.customer?.orders || data.customer?.allorders ) || {};
                const resultSearchCriteria = result.page_info || {};

                resolve({
                    orders: result.items || [],
                    searchCriteria: {
                        pageSize: resultSearchCriteria.page_size,
                        currentPage: resultSearchCriteria.current_page,
                        totalPages: resultSearchCriteria.total_pages,
                        totalCount: result.total_count
                    }
                });

            }).catch((error) => {
                console.log('[CustomerOrders]', error);
                reject(error);
            });
        });
    }

    // TODO Remove once EA-428 is implemented
    customer.getOrder = async ({ order_id }, { additionalItemData, additionalData }) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const payload = {
                query: getCustomerOrderQuery(additionalItemData, additionalData),
                variables: {
                    id: order_id
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                const orders = data?.customer
                    ? (data.customer.allorders?.items || [])
                    : []

                resolve(orders[0] || null);

            }).catch((error) => {
                console.log('[CustomerOrder]', error);
                reject(error);
            });
        });
    }

    customer.getStoreOrders = async () => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const payload = {
                query: getCustomerStoreOrders,
                variables: {}
            };
            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }
                resolve(data.getStoreOrders || []);

            }).catch((error) => {
                console.log('[CustomerStoreOrders]', error);
                reject(error);
            });
        });
    }

    // TODO Remove once EA-428 is implemented
    customer.getStoreOrder = async ({ order_id, id_type }, { additionalItemData, additionalData }) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const payload = {
                query: getCustomerStoreOrder(additionalItemData, additionalData),
                variables: {
                    sales_entry_id: order_id,
                    id_type: id_type
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                const orders = data?.getStoreOrder
                    ? (data.getStoreOrder || [])
                    : []

                resolve(orders || null);

            }).catch((error) => {
                console.log('[CustomerStoreOrder]', error);
                reject(error);
            });
        });
    }

    customer.resetPassword = async ({ email, resetPasswordToken, newPassword }) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const payload = {
                query: resetPassword,
                variables: {
                    email: email,
                    resetPasswordToken: resetPasswordToken,
                    newPassword: newPassword
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(data);

            }).catch((error) => {
                console.log('[resetPassword]', error);
                reject(error);
            });
        });
    }

    customer.checkResetPasswordToken = async ({ email, resetPasswordToken }) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const payload = {
                query: checkResetPasswordTokenGql,
                variables: {
                    email: email,
                    resetPasswordToken: resetPasswordToken,
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(data);

            }).catch((error) => {
                console.log('[checkResetPasswordToken]', error);
                reject(error);
            });
        });
    }

    customer.updateCustomer = async (params) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const payload = {
                query: updateCustomer,
                variables: {
                    firstname: params.firstname,
                    lastname: params.lastname
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    customer.isEmailAvailable = async (email) => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: isEmailAvailable,
                variables: {
                    email: email
                }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {
                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(data?.isEmailAvailable?.is_email_available);

            }).catch((error) => {
                reject(error);
            });
        });

    }

    customer.setToken = (token) => {
        tokens.set('customer', {
            token: token,
        });
    }

    customer.removeToken = () => {
        tokens.remove('customer');
    }

    return customer;
};
