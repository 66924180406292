import { WishlistIcon } from "../../../components/icons";

import { NavLink } from "react-router-dom";

import useStoreViews from "../../../hooks/useStoreViews";

import classes from './Wishlist.module.css';

const Customer = () => {
    const { getUrl } = useStoreViews();

    return (
        <div className={classes.root}>
            <NavLink className={classes.container} to={getUrl('/account/wishlist')}><WishlistIcon title={"Customer Wishlist"} height="16" width="18"/></NavLink>
        </div>
    )
}

export default Customer;
