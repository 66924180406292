import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    systemMessage: null,
    messageType: null,
};

export default createReducer(initialState, {
    [actions.SetSystemMessage]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            systemMessage: payload
        }
    },

    [actions.ClearSystemMessage]: () => {
        return initialState;
    }
})
