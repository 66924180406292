import { forwardRef, useImperativeHandle, useState } from "react";
import { Link } from "react-router-dom";
import { Form } from '@kega/sps-elements';
import { useDispatch, useSelector } from "react-redux";
import { useConfig, useRequest, useStorefront } from "@kega/sps-core";
import { addToken, authenticated } from "../../../../../redux/customer/actions";

import { t } from "../../../../../lib/translations";

import useSetGuestEmail from "../../../../../hooks/checkout/useSetGuestEmail";
import useInputValidation from "../../../../../hooks/form/useInputValidation";
import useEmailAvailable from "../../../../../hooks/customer/useEmailAvailable";
import useCreateMergeCarts from "../../../../../hooks/useCreateMergeCarts";
import useStoreViews from "../../../../../hooks/useStoreViews";
import useFetchWishlist from "../../../../../hooks/wishlist/useFetchWishlist";
import useSystemMessage from "../../../../../hooks/useSystemMessage";
import useTagManager from "../../../../../hooks/useTagManager";
import useReCaptcha from "../../../../../hooks/useReCaptcha";
import useCheckoutData from "../../../../../hooks/checkout/useCheckoutData";

import { Button, Input, Field, Checkbox } from "../../../../../components/form";
import { Row } from "../../../../../components/grid";

import classNames from "classnames";
import classes from "./Email.module.css";

const Email = ({ value, emailRef, setEmailValid, emailValid, setGuestUser, guestUser }, ref) => {
    const { guestEmail } = useSelector((state) => state.essentielCheckout);
    const { recaptcha_customer_login: loginReCaptcha } = useSelector((state) => state.storeConfig);
    const { getUrl, isKiosk } = useStoreViews();

    const { success, loading, error, setGuestEmail } = useSetGuestEmail();
    const { loading: emailLoading, error: emailError, success: emailSuccess, isEmailAvailable } = useEmailAvailable();
    const { email: emailValidation, passwordValidation, requiredField } = useInputValidation();

    const { api: { customer } } = useStorefront();
    const dispatch = useDispatch();
    const { setRequest, setSuccess, setError } = useRequest();

    const { createMergeCart } = useCreateMergeCarts();
    const { fetchWishlist } = useFetchWishlist();
    const { setSystemSuccessMessage } = useSystemMessage();
    const { dataLayer } = useTagManager();
    const { checkoutCreateAccountData, setCheckoutAccountData } = useCheckoutData();

    const [loggingIn, setLoggingIn] = useState(false);
    const [isAvailable, setIsAvailable] = useState(true);
    const [createAccount, setCreateAccount] = useState(false);
    const [validated, setValidated] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const config = useConfig();
    const { recaptchakey } = config.get('google');
    const language = config.get('language');

    const { loadToken, loading: loadingReCaptcha } = useReCaptcha({
        key: recaptchakey,
        action: 'login',
        language: language,
        autoLoad: false,
        reCaptchaEnabled: loginReCaptcha !== null && !isAvailable
    });

    const onChange = async (e) => {
        const { target: { name, value } } = e;
    }

    const onBlur = async (e) => {

        const { target: { name, value } } = e;

        if(guestUser && value !== guestEmail) {
            await setGuestEmail(value);
            const isAvailable = await isEmailAvailable(value);
            setIsAvailable(isAvailable);
            setValidated(true);
        }
    };

    const validateEmail = (email) => {
        const valid = !error && !emailError && !emailValidation(email);
        setEmailValid(valid);
    };

    useImperativeHandle(ref, () => ({
        success
    }));

    const onNext = async () => {
        if (isAvailable) {
            setGuestUser(true);

            if (!createAccount) {
                setCheckoutAccountData(null);
            }
        }
    }

    const checkAvailability = async (email) => {
        validateEmail(email);

        const validation = emailValidation(email);
        if (!validation) {
            await setGuestEmail(email);
            const isAvailable = await isEmailAvailable(email);
            setIsAvailable(isAvailable);

            setValidated(true);
        }
    }

    const onSubmit = async ({ valid, values, resetForm }) => {
        if (isAvailable) {
            resetForm();
            if (!validated) {
                await checkAvailability(values?.email);
            }

            if (createAccount) {
                if (values['password'] !== values['confirm_password']) {
                    if (values['password']) {
                        setPasswordError(true);
                    }
                } else {
                    setPasswordError(false);
                    setCheckoutAccountData({
                        register_now: values?.password,
                        newsletter_subscribe: values?.is_subscribed,
                        is_subscribed: values?.is_subscribed
                    })

                    setLoggingIn(true);
                    setTimeout(async () => {
                        await onNext();
                        setLoggingIn(false);
                    }, 300)
                }
            } else {
                setCheckoutAccountData({
                    ...checkoutCreateAccountData
                })
            }

            return;
        }

        if (!valid) {
            return;
        }

        try {
            setLoggingIn(true);

            let headers = {};

            if (loginReCaptcha) {
                const recaptchaToken = await loadToken();

                if (recaptchaToken) {
                    headers = {
                        'X-ReCaptcha': recaptchaToken
                    }
                }
            }

            /**
             * Added api call to prevent customer data being loaded before the new cartId is set
             */
            setRequest();
            const result = await customer.login({ username: values.email, password: values.password }, headers);
            const token = result?.token;

            if (token) {
                // Magento specific create and merge cart function
                await createMergeCart();
                await fetchWishlist(token);

                setSystemSuccessMessage(t('account.login.success'));

                // new cartId is set, now trigger customer data fetch by updating state
                dispatch(addToken({
                    token: token,
                    token_params: result?.params || {}
                }));
                dispatch(authenticated(true));

                setLoggingIn(false);

                try {
                    dataLayer.push({
                        'event': 'login',
                        'method': 'normal'
                    });
                } catch (error) {
                    //
                }
            } else {
                setLoggingIn(false);
            }
        } catch (e) {
            setLoggingIn(false);
        }

        resetForm();
    };

    return (
        <Row className={classes.root}>
            <Form onSubmit={onSubmit}>
                <Field>
                    <Input
                        type="email"
                        ref={emailRef}
                        name="email"
                        rootClassName={classes.email_field}
                        label={t('checkout.information.form.email.caption')}
                        caption={t('checkout.information.form.email.label')}
                        placeholder={t('checkout.information.form.email.label')}
                        value={guestEmail ?? ''}
                        readOnly={loading || loggingIn || createAccount}
                        error={!emailValid}
                        errorMessage={t('form.validation.email')}
                        onBlur={onBlur}
                        onChange={onChange}
                        required
                        autoFocus />
                </Field>
                {!isAvailable && !error && !isKiosk && (
                    <>
                        <Field>
                            <Input
                                type="password"
                                name="password"
                                label={t('checkout.information.form.password.caption')}
                                caption={t('checkout.information.form.password.label')}
                                placeholder={t('checkout.information.form.password.label')}
                                readOnly={loading || emailLoading || loggingIn}
                                required
                                autoFocus />
                        </Field>
                        <Field>
                            <Link className={classes.forgot} to={getUrl('account/forgotpassword')}>
                                {t('checkout.information.form.forgot_password')}
                            </Link>
                        </Field>
                        <Button
                            disabled={loggingIn}
                            loading={loggingIn || emailLoading || loadingReCaptcha}
                            type="submit"
                            variant="primary"
                        >
                            {t('checkout.information.form.submit')}
                        </Button>
                    </>
                )}

                {
                    !isKiosk &&
                    <>
                        {
                            (isAvailable && !validated && !guestUser) &&
                            <Button
                                className={classes.continue_guest_button}
                                type={"submit"}
                                variant="primary"
                                loading={loading || emailLoading}
                                disabled={loading || emailLoading}
                            >
                                {t('checkout.information.form.continue')}
                            </Button>
                        }

                        {
                            (isAvailable && validated && !guestUser) &&
                            <>
                                {
                                    createAccount &&
                                    <>
                                        <Field xs={12}>
                                            <Input
                                                name="password"
                                                placeholder={t('customer.form.password')}
                                                label={t('customer.form.password')}
                                                type="password"
                                                value={checkoutCreateAccountData?.register_now ?? ''}
                                                rules={[ passwordValidation ]}
                                                required
                                            />
                                        </Field>
                                        <Field xs={12}>
                                            <Input
                                                name="confirm_password"
                                                placeholder={t('account.register.password_confirm')}
                                                label={t('account.register.password_confirm')}
                                                type="password"
                                                rules={[ requiredField ]}
                                                error={passwordError}
                                                required
                                            />
                                            {
                                                passwordError &&
                                                <div className={classes.password_error}>
                                                    {t('account.register.error.password_confirm')}
                                                </div>
                                            }
                                        </Field>
                                        <Field className={classes.check_wrapper}>
                                            <Checkbox
                                                name="is_subscribed"
                                                label={t('checkout.information.form.subscribe')}
                                                checked={checkoutCreateAccountData?.is_subscribed ?? false}
                                            />
                                        </Field>
                                    </>
                                }

                                <Button
                                    className={classes.continue_guest_button}
                                    variant="primary"
                                    loading={loggingIn}
                                    disabled={loggingIn}
                                    {...((!createAccount) ? {
                                        onClick: () => setCreateAccount(true)
                                    } : {
                                        type: "submit"
                                    }
                                    )}
                                >
                                    {t('checkout.information.form.register')}
                                </Button>

                                {
                                    !createAccount &&
                                    <Button
                                        className={classes.continue_guest_button}
                                        onClick={() => onNext()}
                                        variant="secondary"
                                    >
                                        {t('checkout.information.form.continue_guest')}
                                    </Button>
                                }
                            </>
                        }
                    </>
                }
            </Form>
        </Row>
    );

};

export default forwardRef(Email);
