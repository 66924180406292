import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useStorefront, useCartId } from '@kega/sps-core';

import { updateCart } from '@kega/sps-core/src/reducers/cart/actions';

const useCreateMergeCarts = () => {
    const { api: { cart } } = useStorefront();
    const dispatch = useDispatch();
    const getCartId = useCartId();

    const createMergeCart = useCallback( async () => {
        const guestCardId = getCartId();

        const { cartId } = await cart.create();

        // When cart is set merge guestcart in to customercart
        if (guestCardId !== null) {
            try {
                const result = await cart.mergeCarts(guestCardId);
                dispatch(updateCart(result));
            } catch (error) {
                console.log('[MergeCart]', error);
            }
        } else {
            dispatch(updateCart({ cartId: cartId }));
        }

    }, [])

    return {
        createMergeCart
    };
}

export default useCreateMergeCarts;