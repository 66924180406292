import IconWrapper from '../wrapper/IconWrapper';

const WishlistIconFilled = ({ color = null, title, ...props }) => {
    return (
        <IconWrapper color={color} {...props}>
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" role={title ? null : "presentation"}>
                {title && <title>{title}</title>}
                <path d="M14.5563 0.162066C12.9563 -0.337934 10.7563 0.262065 8.95632 2.76207C7.15632 0.362065 4.85632 -0.337934 3.25632 0.162066C1.45632 0.862066 -0.343677 2.96207 0.0563225 6.26207C0.456323 10.2621 6.15632 14.6621 8.55632 16.3621L8.95632 16.6621L9.35632 16.3621C11.7563 14.6621 17.3563 10.2621 17.8563 6.26207C18.1563 2.96207 16.4563 0.862066 14.5563 0.162066Z" fill={color ? color : 'black'} />
            </svg>
        </IconWrapper>
    );
}

export default WishlistIconFilled;
