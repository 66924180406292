import { forwardRef } from "react";
import { t } from "../../lib/translations";
import useStoreViews from "../../hooks/useStoreViews";
import useI18n from "../../hooks/useI18n";
import { useConfig } from "@kega/sps-core";
import { Select } from '../form';
import classNames from 'classnames';
import classes from '../form/select/Select.module.css';

const StateDropDown = ({ required, error, value, ...rest_props }, ref) => {
    const selectClasses = classNames(classes.input, error ? classes.error : '');
    const { getCountryNameByCode } = useI18n();
    const config = useConfig();

    const states = config.get('states') ?? []

    const { currentStoreView } = useStoreViews();
    const { allowedCountries = {} } = currentStoreView;

    return (
        <Select  ref={ref} value={value ? value : ''} className={selectClasses} labelClassName={classes['label']} showLabel={false} required={required} error={error} {...rest_props}>
            <option disabled value="">{t('customer.address.form.state')}</option>
            {states.map((region) =>
                <option key={region.id} value={region.id}>{ region.name }</option>
            )}
        </Select>
    );

}

export default forwardRef(StateDropDown);
