import classes from './Page.module.css';

const Page = ({ data, onClick }) => {
    const { name } = data;

    return (
        <div className={classes.root} onClick={onClick} role="button" tabIndex="0">
            <span>{name}</span>
        </div>
    )
}

export default Page;
