import { useEffect, useState, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { Col, Container, Row } from "../../../../components/grid";
import Table from "../../../../components/table/Table";
import Order from "./order/Order";
import Button from "../../../../components/form/button/Button";

import useOrders from "../../../../hooks/customer/useOrders";
import useStoreOrders from "../../../../hooks/customer/useStoreOrders";

import { t } from "../../../../lib/translations";
import { repeatComponent } from "../../../../lib/utils";

import classes from "./Overview.module.css";

const Overview = () => {
    const additionalData = `
        status_id
    `;

    const {
        error,
        loading,
        orders = [],
        fetchOrders,
        searchCriteria: {
            totalPages,
            totalCount
        },
        defaults: {
            pageSize = 3,
        }
    } = useOrders({ additionalData });


    const [currentPage, setCurrentPage] = useState(1);

    const loadingFirst = loading && (currentPage === 1);
    const loadedAll = currentPage >= totalPages;

    const SHOW_LIMIT = 3;

    const { store_orders=[], loading: storefrontLoading, error: storefrontError, fetchStoreOrders } = useStoreOrders();

    const [showAll, setShowAll] = useState(false);
    const [showOrderRows, setShowOrderRows] = useState(true);
    const [inStoreOrdersData, setInStoreOrdersData] = useState(false);


    const filteredStoreOrders = useMemo(() => {
        return store_orders.filter((order) =>  {return (order.status !== 'Canceled' && order.status !== 'Pending Payment')});
    }, [store_orders]);


    const renderItem = (order, isStoreOrder = false) => (
        <Order key={order.number} id={order.number} data={order} storeOrder={isStoreOrder} />
    );

    const onLoadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const toggleRows = (isOrderRows) => {
        setShowOrderRows(isOrderRows);
        setShowAll(false);
    };

    const sortOrdersByDate = (orders) => {
        return orders.sort((a, b) => {
            const dateA = a.order_date || '';
            const dateB = b.order_date || '';

            if (dateB > dateA) {
                return 1;
            } else if (dateB < dateA) {
                return -1;
            } else {
                return 0;
            }
        });
    };

    let showOrders = [...orders];
    let showStoreOrders = [...filteredStoreOrders]

    showStoreOrders = sortOrdersByDate(showStoreOrders);

    showStoreOrders = showAll
        ? showStoreOrders
        : showStoreOrders.slice(0, SHOW_LIMIT);

    const orderRows = loading
        ? repeatComponent(Order, { loading: true }, (pageSize - 1))
        : showOrders.map(order => {
            return renderItem(order)
        });

    const orderStoreRows = storefrontLoading
        ? repeatComponent(Order, { storefrontLoading: true, storeOrder: true }, pageSize)
        : showStoreOrders.map(item => {
            return renderItem(item, true)
        });

    useEffect(() => {
        fetchOrders({
            currentPage: currentPage,
            pageSize: SHOW_LIMIT,
            filter: { status: { in: [ "complete", "processing", "exported_to_lsretail", "order_in_alumio", "closed" ] } }
        });
    }, [currentPage]);

    useEffect(() => {
        if (!inStoreOrdersData) {
            fetchStoreOrders();
            setInStoreOrdersData(true);
        }
    }, [inStoreOrdersData]);

    return (
        <>
            <Container xl margins g-xs={0} g-sm={1}>
                <Row>
                    <Col>
                        <h1>{t('order_history.overview.title')}</h1>
                    </Col>
                    <Col lg={3} className={classes.orders_titles}>
                        <>
                            <span
                                className={`${showOrderRows ? classes.activeOrder : ""}`}
                                onClick={() => toggleRows(true)}
                                role="button"
                                tabIndex="0"
                            >
                                {t('order_history.overview.online_orders')}
                            </span>
                            <span className={classes.separator}>|</span>
                            <span
                                className={`${!showOrderRows ? classes.activeOrder : ""}`}
                                onClick={() => toggleRows(false)}
                                role="button"
                                tabIndex="0"
                            >
                                {t('order_history.overview.store_orders')}
                            </span>
                        </>
                    </Col>
                </Row>
                {error || storefrontError ? (
                    <Row>
                        <Col>
                            <div className={classes.error}>
                                <span>{t('order_history.overview.error')}</span>
                            </div>
                        </Col>
                    </Row>
                ) : null}

                <>
                    {showOrderRows ? (
                        <>
                            <Row>
                                <Col>
                                    <Table className={classes.table}>
                                        <tbody>{orderRows}</tbody>
                                    </Table>
                                </Col>
                            </Row>


                            {showOrders.length === 0 && !loading && (
                                <Row>
                                    <Col>
                                        <div className={classes.error}>
                                            <span>{t('order_history.overview.no_orders')}</span>
                                        </div>

                                    </Col>
                                </Row>
                            )}

                            {showOrders.length > SHOW_LIMIT && (
                                <>
                                    <Row>
                                        <Col className={classes.paging}>
                                            <div className={classes.count}>
                                                {loading ? (
                                                    <div className={classes.countSkeleton}>
                                                        <Skeleton />
                                                    </div>
                                                ) : (
                                                    <span>
                                                        {t('order_history.overview.showing', {
                                                            current: showOrders.length,
                                                            total: totalCount
                                                        })}
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            {!loadingFirst ? (
                                <Button
                                    className={classes.showMore}
                                    variant="primary"
                                    onClick={onLoadMore}
                                    disabled={loading || loadedAll}
                                >
                                    {showAll
                                        ? t('order_history.overview.show_less')
                                        : t('order_history.overview.show_more')}
                                </Button>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <Row>
                                <Col>
                                    <Table className={classes.table}>
                                        <tbody>{orderStoreRows}</tbody>
                                    </Table>
                                </Col>
                            </Row>

                            {showStoreOrders.length === 0 && !storefrontLoading && (
                                <Row>
                                    <Col>
                                        <div className={classes.error}>
                                            <span>{t('order_history.overview.no_orders')}</span>
                                        </div>

                                    </Col>
                                </Row>
                            )}

                            {showStoreOrders.length > SHOW_LIMIT && (
                                <>
                                    <Row>
                                        <Col className={classes.paging}>
                                            <div className={classes.count}>
                                                {storefrontLoading ? (
                                                    <div className={classes.countSkeleton}>
                                                        <Skeleton />
                                                    </div>
                                                ) : (
                                                    <span>
                                                        {t('order_history.overview.showing', {
                                                            current: showStoreOrders.length,
                                                            total: filteredStoreOrders.length
                                                        })}
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            {store_orders.length > 0 ? (
                                <Button
                                    className={classes.showMore}
                                    variant="primary"
                                    onClick={onLoadMore}
                                    disabled={storefrontLoading}
                                >
                                    {showAll
                                        ? t('order_history.overview.show_less')
                                        : t('order_history.overview.show_more')}
                                </Button>
                            ) : null}
                        </>
                    )}
                </>
            </Container>
        </>
    );
};

export default Overview;
