import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form } from '@kega/sps-elements';

import { t } from '../../../lib/translations';
import { Col, Row, Container } from '../../../components/grid';

import { Button, Input, Field } from '../../../components/form';

import useStoreViews from "../../../hooks/useStoreViews";
import useSystemMessage from '../../../hooks/useSystemMessage';
import useResetPassword from "../../../hooks/customer/useResetPassword";
import useInputValidation from "../../../hooks/form/useInputValidation";

import classes from './ResetPassword.module.css';

const ResetPassword = () => {
    const { setSystemSuccessMessage, setSystemErrorMessage } = useSystemMessage();
    const { confirmField, passwordValidation } = useInputValidation();
    const { loading, reset, checkResetToken } = useResetPassword();
    const location = useLocation();
    const navigate = useNavigate();
    const { getUrl } = useStoreViews();

    const [tokenIsValid, setTokenIsValid] = useState(false);
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const onPasswordChange = () => {
        passwordConfirmRef.current?.triggerChange();
    };

    const email = location.state?.email;
    const token = location.state?.token;

    useEffect(async () => {

        const response = await checkResetToken({
            email: email,
            resetPasswordToken: token
        });

        const result = response.checkResetPasswordToken;

        if (result && result.is_valid) {
            setTokenIsValid(true);
        } else {
            
            navigate(getUrl('account/forgotpassword?token_error=1'));
        }


    },[token])

    const onSubmit = async ({ valid, values }) => {
        if (valid) {
            try {

                const response = await reset({
                    email: values.email,
                    resetPasswordToken: token,
                    newPassword: values.pass
                });

                if (response?.resetPassword === true) {
                    setSystemSuccessMessage(t('account.change_password.success'));
                    navigate(getUrl('account/login'));
                }

            } catch (error) {
                setSystemErrorMessage(t('Something went wrong, please try again later'));
            }
        }
    };
    return (
        tokenIsValid &&
        <Container xl margins g-xs={1} g-sm={3} g-md={2} g-xl={4}>
            <Row>
                <Col>
                    <h1 className={classes.title}>{t('account.change_password.title')}</h1>

                    <Form onSubmit={onSubmit}>
                        <Row>
                            <Field md={8}>
                                <Input
                                    name="email"
                                    type="email"
                                    label={t('customer.form.email')}
                                    placeholder={t('customer.form.email')}
                                    value={email ?? ''}
                                    readOnly={!!email}
                                    required />
                            </Field>
                        </Row>
                        <Row>
                            <Field md={8}>
                                <Input
                                    name="pass"
                                    type="password"
                                    label={t('account.change_password.password_new')}
                                    placeholder={t('account.change_password.password_new')}
                                    ref={passwordRef}
                                    rules={[ passwordValidation ]}
                                    onChange={onPasswordChange}
                                    required />
                            </Field>
                        </Row>
                        <Row>
                            <Field md={8}>
                                <Input
                                    name="confirm_password"
                                    type="password"
                                    label={t('account.change_password.password_new_confirm')}
                                    placeholder={t('account.change_password.password_new_confirm')}
                                    ref={passwordConfirmRef}
                                    rules={[ confirmField(passwordRef, t('account.change_password.error.password_confirm')) ]}
                                    required />
                            </Field>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    loading={loading}
                                >
                                    {t('account.change_password.button')}
                                </Button>

                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ResetPassword;
