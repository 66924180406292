import { useConfig } from '@kega/sps-core';

import useStoreViews from './useStoreViews';

const useProductStructuredData = (product) => {
    const config = useConfig();
    const { prefix_product } = config.get('url');
    const { getUrl } = useStoreViews();

    const defaultCurrency = config.get('currency');

    if (!product) { return null; }

    return {
        "@type": "Product",
        "name": product?.name,
        "description": product.meta_description,
        "image": [
            product?.media_gallery
                ?
                product?.media_gallery && product.media_gallery.map((image) => {
                    return (image?.url ? image?.url : image)
                })
                :
                product?.image_url
        ],
        "url": getUrl(`${prefix_product}/${product.url_key}`),
        'itemCondition': 'http://schema.org/NewCondition',
        "sku": product.sku,
        "model": product?.lsr_item_id+'.'+product?.att_text_perfion_colourcode,
        "brand": {
            "@type": "Brand",
            "name": "Essentiel",
            "logo": `${window.location.origin}/images/new-essentiel-logo.svg`,
        },
        "offers": {
            "@type": "Offer",
            "price": product.price_range ? product.price_range?.minimum_price?.final_price?.value : product?.price[defaultCurrency].default,
            "priceCurrency": defaultCurrency,
            "availability": product?.stock_status === 'OUT_OF_STOCK' ? "OutOfStock" : "InStock"
        },
    };
};

export default useProductStructuredData;
