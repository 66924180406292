import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useCartId, useCreateCart, useAddToCart as useKegaAddToCart, useConfig } from '@kega/sps-core';
import { useCartItems, useCartTotals } from "@kega/sps-cart";

import aa from "search-insights";

import { t } from '../../../lib/translations';

import useUi from '../../../hooks/useUi';
import useTagManager from '../../../hooks/useTagManager';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useEstimateShippingMethods from "../../../hooks/checkout/useEstimateShippingMethods";
import useCheckoutData from "../../../hooks/checkout/useCheckoutData";
import useQueryIDMapping from '../../../hooks/algolia/useQueryIDMapping';
import useAddToCart from '../../../hooks/cart/useAddToCart';

import Button from "../../form/button/Button";
import Breadcrumb from '../../breadcrumb/Breadcrumb';

import classes from './AddToCart.module.css';

const AddToCart = ({ product, selectedOption = null, isInStock, isCheckingStock, storeStockError, selectedSizeLabel, giftBox = false, isPreorder = false, preorderDate, personalisedValue, onAddedToCart }) => {
    const { openCart, closeCart, closeAddToCartDrawer, closeGiftboxDrawer } = useUi();
    const { ecommerceEvent } = useTagManager();
    const { match: matchMobile } = useMediaQuery(`(max-width: 640px)`);
    const config = useConfig();
    const totals = useCartTotals();
    const { estimateShippingMethods } = useEstimateShippingMethods();
    const getCartId = useCartId();
    const { createCart } = useCreateCart();
    const items = useCartItems();

    const { addToCart: kegaAddToCart, error } = useKegaAddToCart();
    const { addToCart } = useAddToCart();
    const { extraCartData } = useCheckoutData();
    const { addId } = useQueryIDMapping()
    const { state } = useLocation();

    const { index } = config.get('algolia');
    const storeCode = config.get('storeCode');
    const formattedCode = storeCode.replace('_kiosk', '');

    const [loading, setLoading] = useState(false);
    const [selectedSize, setSelectedSize] = useState(true);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        setSelectedSize(true);
    }, [selectedOption]);


    const defaultCurrency = config.get('currency') ?? 'EUR';

    const handleHover = () => {
        if (!selectedOption) {
            setIsHovered(!isHovered);
        }
    };

    const getDiscountAmount = (price) => {
        const finalPrice = product.price_range.minimum_price.final_price.value;
        let amount = 0;

        if (price === finalPrice) {
            return amount;
        }

        if (finalPrice > 0 && finalPrice < price) {
            amount = price - finalPrice;
        }

        return parseFloat(Math.round(amount * 100) / 100);
    }

    const onAddToCart = async () => {

        setLoading(true);

        let newCartId = null;
        const cartId = getCartId();
        const queryId = state?.queryID;

        if (!cartId) {
            newCartId = await createCart();
            localStorage.setItem(storeCode + '_new_cart_id', newCartId);
        }

        if (personalisedValue) {
            await addToCart({
                product: product,
                quantity: 1,
                selectedOption: selectedOption,
                ...(personalisedValue && { cartItemOptions: { personalised_value: personalisedValue } })
            }, extraCartData);
        } else {
            await kegaAddToCart({
                product: product,
                quantity: 1,
                selectedOption: selectedOption
            }, extraCartData);
        }

        if (onAddedToCart) {
            onAddedToCart();
        }

        if (newCartId || items.length === 0) {
            await estimateShippingMethods({ cartId: newCartId || cartId });
        }

        if (!selectedOption) {
            setSelectedSize(false);
        } else {
            setSelectedSize(true);
            openCart();
        }
        closeAddToCartDrawer();

        if (giftBox) {
            closeGiftboxDrawer();
        }

        try {
            const price = product.price ? product.price[defaultCurrency].default :
                product.price_range.minimum_price.regular_price.value;
            const discount = getDiscountAmount(price);

            const data = {
                value:    totals?.total ? totals.total : price,
                currency: defaultCurrency,
                items:    [{
                    item_id:       product.sku,
                    item_name:     product.name,
                    item_category: product.att_text_perfion_productgroupcode,
                    item_variant:  selectedSizeLabel,
                    price:         price,
                    discount:      discount,
                    currency:      defaultCurrency,
                    quantity:      1,
                    index:         0
                }]
            }
            ecommerceEvent('add_to_cart', data);
        } catch (error) {
            console.log('error pushing to datalayer', error);
        }

        try {
            aa(queryId ? 'addedToCartObjectIDsAfterSearch': 'addedToCartObjectIDs', {
                eventName: queryId ? 'Product Added To Cart (after search)' : 'Product Added To Cart',
                index: index + formattedCode + '_products',
                objectIDs: [product.sku],
                queryID: queryId,
                currency: defaultCurrency,
            });

            if (queryId) {
                addId(product.sku, queryId);
            }
        } catch (error) {
            //
        }

        window.cartCloseTimer = setTimeout(() => {
            closeCart();
        }, 2000);

        setLoading(false);
    }

    return (
        <div className={classes.cartContainer}>
            {
                <div
                    className={classes.buttonSize}
                    onMouseOver={handleHover}
                    onMouseLeave={handleHover}
                >
                    <Button
                        className={`${classes.buttonCart} ${selectedSize ?
                            (selectedSize && isHovered ? classes.hovered : '') : ''}`}
                        variant="primary"
                        onClick={onAddToCart}
                        disabled={!product || !isInStock || !selectedSize && !storeStockError}
                        loading={selectedSize ? (loading || isCheckingStock) : undefined}
                    >
                        {(isHovered && selectedSize) ? t('product.select_size') :
                            isPreorder ? t('product.preorder.add_to_cart') : t('product.add_to_cart')}
                    </Button>

                </div>
            }
            {
                matchMobile && (
                    <div className={classes.breadCrumbMobile}>
                        <Breadcrumb prefix={[{ label: 'Home', path: '/' }]} sufix={[{ label: product?.name }]}/>
                    </div>
                )
            }

            {
                isPreorder &&
                <div className={classes.preorder_caption}>
                    {t('product.preorder.caption', { date: preorderDate || '?' })}
                </div>
            }

            {
                error &&
                <div className={classes.error_message}>
                    {error?.message}
                </div>
            }
        </div>
    );
}

export default AddToCart;
