import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useShippingMethods } from '@kega/sps-core';

import { t } from "../../../lib/translations";

import useStoreViews from '../../../hooks/useStoreViews';

import AdyenForm from "../../..//modules/checkout/payment/AdyenForm";
import Information from "../../../modules/checkout/information/Information";
import CheckoutSteps from "../../../modules/checkout/checkoutsteps/CheckoutSteps";
import InformationComplete from "../../../modules/checkout/information/InformationComplete";

import classes from './CheckoutContainer.module.css';

const CheckoutContainer = ({ onCheckboxChange, setMethodSelected, setSending, acceptedTerms, checkboxRef, checkoutFormRef, sending, methodSelected, match }) => {
    const { getUrl, urlPathWithoutStore } = useStoreViews();
    const { setShippingMethods, shippingMethod, error, loading } = useShippingMethods();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(20);
    const [guestUser, setGuestUser] = useState(false);

    const steps = {
        'checkout/shipment': {
            key: 'shipment',
            stepPath: 'checkout/shipment',
            value: 20,
            title: '',
            stepNumber: 1
        },
        'checkout/payment': {
            key: 'payment',
            stepPath: 'checkout/payment',
            value: 40,
            title: t('checkout.steps.payment.title'),
            stepNumber: 2
        },
        'checkout/success': {
            key: 'success',
            stepPath: 'checkout/success',
            value: 60,
            title: t('checkout.steps.success.title'),
            stepNumber: 3
        }
    };

    const {
        'checkout/shipment': shipment,
        'checkout/payment': payment
    } = steps;

    const nextStep = (path) => {
        let nextStep = Object.keys(steps).find((stepPath) => {
            return steps[path].stepNumber + 1 === steps[stepPath].stepNumber;
        });

        navigate(getUrl(nextStep));
        setCurrentStep(steps[nextStep].value);
    }

    const editStep = (path, value) => {
        if (currentStep > value) {
            navigate(getUrl(path));

            setGuestUser(true);
        }
    }

    useEffect(() => {
        const step = steps[urlPathWithoutStore];
        if (step) {
            setCurrentStep(step.value);
        }
    }, [urlPathWithoutStore]);

    return (
        <>
            <div className={classes.checkout_panel}>
                <CheckoutSteps
                    title={shipment.title}
                    active={currentStep === shipment.value}
                    completed={currentStep > shipment.value}
                >
                    {
                        currentStep > shipment.value &&
                        <div className={classes.step_edit} onClick={() => editStep(shipment.stepPath, shipment.value)}>
                            {t('checkout.steps.edit.title')}
                        </div>
                    }

                    {
                        currentStep === shipment.value
                            ?
                            <Information
                                handleContinue={nextStep}
                                stepPath={shipment.stepPath}
                                setGuestUser={setGuestUser}
                                guestUser={guestUser}
                            />
                            : currentStep > shipment.value &&
                              <InformationComplete />
                    }
                </CheckoutSteps>
            </div>
            <div className={classes.checkout_panel}>
                <CheckoutSteps
                    title={payment.title}
                    active={currentStep === payment.value}
                    completed={currentStep > payment.value}
                >
                    {
                        currentStep > payment.value &&
                        <div className={classes.step_edit} onClick={() => editStep(payment.stepPath, payment.value)}>
                            {t('checkout.steps.edit.title')}
                        </div>
                    }
                    {
                        shippingMethod
                        &&
                        <AdyenForm
                            className={currentStep !== payment.value ? classes.hide_adyen : ''}
                            methodSelected={methodSelected}
                            setMethodSelected={setMethodSelected}
                            setSending={setSending}
                            acceptedTerms={acceptedTerms}
                            sending={sending}
                            onCheckboxChange={onCheckboxChange}
                            checkboxRef={checkboxRef}
                            checkoutFormRef={checkoutFormRef}
                        />
                    }
                </CheckoutSteps>
            </div>
        </>
    )
}

export default CheckoutContainer;
