import { useDispatch, useSelector } from "react-redux";

import {
    clearCheckoutData,
    setCustomBillingAddress,
    setSelectedBillingAddressType,
    setCheckoutCreateAccountData
} from "../../redux/checkout/actions";

const useCheckoutData = () => {
    const dispatch = useDispatch();

    const { lastOrderId, selectedBillingAddressType, customBillingAddress, couponCode, checkoutCreateAccountData, guestEmail } = useSelector((state) => state.essentielCheckout);

    const setSelectedBillingAddressTypeState = (type) => {
        dispatch(setSelectedBillingAddressType(type));
    }

    const setCustomAddressForBilling = (address) => {
        dispatch(setCustomBillingAddress(address))
    }

    const clear = () => {
        dispatch(clearCheckoutData());
    };

    const setCheckoutAccountData = (data) => {
        dispatch(setCheckoutCreateAccountData(data));
    };

    const extraCartData = {
        custom_attributes: [
            'att_text_size',
            'perfion_filtercolour',
            'att_text_perfion_filtercolour',
            'perfion_googlecolour',
            'att_text_perfion_googlecolour',
            'perfion_productgroupcode',
            'att_text_perfion_productgroupcode',
            'url_key',
            'is_preorder',
            'preorder_date'
        ],
        additional_data: [
            `items {
                personalised_value
            }`
        ]
    };

    return {
        lastOrderId,
        selectedBillingAddressType,
        customBillingAddress,
        extraCartData,
        checkoutCreateAccountData,
        appliedCoupon: couponCode,
        guestEmail,
        setCustomAddressForBilling,
        setSelectedBillingAddressTypeState,
        setCheckoutAccountData,
        clear
    }
};

export default useCheckoutData;
