import { useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { Builder } from "@builder.io/react/lite";

import useStoreViews from '../../../../hooks/useStoreViews';

import Configure from "../configure/Configure";
import { Container, Row, Col } from "../../../../components/grid";

import classNames from "classnames";
import classes from './Menu.module.css';

export const Menu = (props) => {
    const ref = useRef();
    const { getUrl } = useStoreViews();
    const { background, background_color, menu_item } = props;

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (ref.current && ref.current.getBoundingClientRect().top > 50) {
            ref.current.classList.remove(classes.sticky);
        } else {
            ref.current.classList.add(classes.sticky);
        }

        if (!document.querySelector('header').classList.value.includes('sticky-header')) {
            ref.current.classList.remove(classes.sticky);
        }
    }

    return (
        <>
            {
                menu_item !== undefined ?
                    <>
                        <div ref={ref} style={{ backgroundColor: background ? background_color : 'transparent' }}>
                            <Container xl margins>
                                <Row>
                                    <Col>
                                        <div className={classes.menu_container}>
                                            {menu_item.map((menu_item, key) => (
                                                <div key={key} className={classes.item}>
                                                    <NavLink className={({ isActive }) => isActive ? classNames(classes.link, classes.active) : classes.link} to={getUrl(menu_item.url)}>
                                                        {menu_item.name}
                                                    </NavLink>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </>
                    :
                    <Configure name="Menu" />
            }
        </>
    );
};

Builder.registerComponent(Menu, {
    name: "Menu",
    static: true,
    defaultStyles: {
        marginTop: '0px',
    },
    inputs: [
        {
            name: 'menu_item',
            friendlyName: 'Menu Item',
            type: 'list',
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                    defaultValue: 'Menu'
                },
                {
                    name: 'url',
                    type: 'string',
                    defaultValue: 'url'
                }
            ],
            onChange: (options) => {
                if (options.get('menu_item').length > 15) {
                    options.set('menu_item', options.get('menu_item').slice(0, 15));
                }
            },
            helperText: 'Max 15 faq items can be added.'
        },
        {
            name: 'background',
            type: "boolean"
        },
        {
            type: 'color',
            name: 'background_color',
            friendlyName: 'Background color',
            defaultValue: '#F7F7F7',
        },
    ]
});
