import { Radio as DefaultRadio } from '@kega/sps-elements';

import classes from './Radio.module.css';

const Radio = ({ required, ...rest_props }) => {
    return (
        <DefaultRadio className={classes.input} labelClassName={classes['label']} required={required} {...rest_props}  />
    );

}

export default Radio;
