import { Helmet } from 'react-helmet-async';

import BuilderSection from '../../modules/builder/BuilderSection';

import Logo from "../../components/logo/Logo";
import { Container } from '../../components/grid';
import ErrorComponent from "../error/errorcomponent/ErrorComponent"

import classNames from "classnames";
import classes from './Error.module.css';

const Error = ({ pageTitle = '', statusCode = 500, fullHeight = false, children }) => {
    const rootClass = classNames(
        classes.root,
        fullHeight ? classes.fullHeight : ''
    );
    return (
        <>
            <Helmet
                defaultTitle={'Storefront'}
                titleTemplate={'%s | Storefront'}
            >
                <title>{pageTitle}</title>
                <meta name="description" content={''} />
                <meta name="prerender-status-code" content={String(statusCode)} />
            </Helmet>

            <Container className={rootClass}>
                {
                    fullHeight && (
                        <div className={classes.logo}>
                            <Logo />
                        </div>
                    )
                }

                {
                    String(statusCode) === '404'
                        ?
                        <BuilderSection model="404-not-found-section" renderNotFound={() => (
                            <ErrorComponent>{children}</ErrorComponent>
                        )} />
                        :
                        <ErrorComponent fullHeight={true}>{children}</ErrorComponent>
                }
            </Container>
        </>
    )
}

export default Error;