import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Accordion, AccordionContent, AccordionHeader, AccordionIcon, AccordionItem } from "@kega/sps-elements";

import { t } from "../../../lib/translations";

import useMediaQuery from "../../../hooks/useMediaQuery";

import { ChevronIcon } from "../../../components/icons";
import { Col, Container, Row } from "../../../components/grid";
import NoResult from "./no_results/NoResult";
import CmsPageResult from "./cmspage_results/CmsPageResult";
import AlgoliaProductList from "../ProductList";

import classNames from "classnames";
import classes from './SearchResults.module.css';

const SearchResults = ({ query }) => {
    const { match: desktop } = useMediaQuery('(min-width: 769px)');
    const algoliaSearchResults = useSelector(state => state.algolia_results);
    const accordionContent = useMemo(() => Object.keys(algoliaSearchResults).slice(1, -1), [algoliaSearchResults]);

    const popular = algoliaSearchResults.popularcategories;
    const categoryData = algoliaSearchResults.categories;
    const cmsPageData = algoliaSearchResults.pages;
    const cmsBlogData = algoliaSearchResults.blogs ?? { items: [] };
    const resultCount = algoliaSearchResults.results;
    const products = [0]; // TODO - Check with actual product data
    const articles = cmsBlogData.items;

    return (
        <div className={classes.root}>
            {
                resultCount > 0 ?
                    <>
                        <Container>
                            <Row>
                                <Col>
                                    <div className={classes.search_query_container}>
                                        <div className={classNames(classes.result_page_title)}>
                                            {t('header.search.result.page.title')}
                                            <strong className={classes.search_query}>{query ? `"${query}"` : null}</strong>
                                            <span className={classes.search_result}>({ resultCount === 1 ? t('header.search.result.page.result', { count: resultCount }) : t('header.search.result.page.results', { count: resultCount })})</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        {
                            desktop ?
                                <>
                                    <div className={classNames(
                                        (cmsBlogData.items.length !== 0 || cmsPageData.items.length !== 0) ? classes.result_container : null,
                                        (cmsBlogData.items.length === 0 || cmsPageData.items.length === 0) ? classes.full_width : null)}>
                                        <Container>
                                            <Row>
                                                <Col lg={6}>
                                                    {cmsPageData.items.length !== 0 ? <CmsPageResult data={cmsPageData.items} query={query}/> : null}
                                                </Col>
                                                <Col lg={6}>
                                                    {cmsBlogData.items.length !== 0 ? <CmsPageResult data={cmsBlogData.items} query={query} blockTitle={'header.search.blogs_block_title'}/>  : null}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    {products.length !== 0 ? <AlgoliaProductList query={query} title={' '} /> : null}
                                </>
                                :
                                <div className={classes.accordion_container}>
                                    {accordionContent.map((accordionItem) => (
                                        <Container key={accordionItem} margins g-xs={1} g-sm={1} g-md={2} g-xl={4} gy-xs={0} gy-sm={0}>
                                            <Row>
                                                <Col>
                                                    {accordionItem === 'pages' && cmsPageData.items.length !== 0 ?
                                                        <Accordion className={classes.accordion_item}>
                                                            <AccordionItem>
                                                                <AccordionHeader className={classes.accordion_heading}>
                                                                    <span className={classes.accordion_title}>{accordionItem}</span>
                                                                    <span className={classes.accordion_result_count}>({cmsPageData.items.length} { cmsPageData.items.length === 1 ? t('header.search.mobile.accordion.result') : t('header.search.mobile.accordion.results')})</span>
                                                                    <AccordionIcon>
                                                                        <ChevronIcon title={"Accordion Search Results"} size={8} />
                                                                    </AccordionIcon>
                                                                </AccordionHeader>
                                                                <AccordionContent className={classes.accordion_content}>
                                                                    <CmsPageResult data={cmsPageData.items} mobile={true} query={query} />
                                                                </AccordionContent>
                                                            </AccordionItem>
                                                        </Accordion>
                                                        : null
                                                    }
                                                    {accordionItem === 'articles' && cmsBlogData.items.length !== 0 ?
                                                        <Accordion className={classes.accordion_item}>
                                                            <AccordionItem>
                                                                <AccordionHeader className={classes.accordion_heading}>
                                                                    <span className={classes.accordion_title}>{accordionItem}</span>
                                                                    <span className={classes.accordion_result_count}>({cmsBlogData.items.length} { cmsBlogData.items.length === 1 ? t('header.search.mobile.accordion.result') : t('header.search.mobile.accordion.results')})</span>
                                                                    <AccordionIcon>
                                                                        <ChevronIcon title={"Accordion Search Results"} size={8} />
                                                                    </AccordionIcon>
                                                                </AccordionHeader>
                                                                <AccordionContent className={classes.accordion_content}>
                                                                    <CmsPageResult data={cmsBlogData.items} mobile={true} query={query} />
                                                                </AccordionContent>
                                                            </AccordionItem>
                                                        </Accordion>
                                                        : null
                                                    }
                                                </Col>
                                            </Row>
                                        </Container>
                                    ))}
                                    {
                                        products.length
                                        &&
                                        <Accordion className={classNames(classes.accordion_item, classes.product_list_accordion)}>
                                            <AccordionItem isOpen={true}>
                                                <AccordionHeader className={classes.accordion_heading}>
                                                    <span className={classes.accordion_title}>Products</span>
                                                    <span className={classes.accordion_result_count}>{resultCount} { resultCount === 1 ? t('header.search.mobile.accordion.result') : t('header.search.mobile.accordion.results')})</span>
                                                    <AccordionIcon>
                                                        <ChevronIcon title={"Accordion Search Results"} size={8} />
                                                    </AccordionIcon>
                                                </AccordionHeader>
                                                <AccordionContent className={classes.accordion_content}>
                                                    <AlgoliaProductList query={query} />
                                                </AccordionContent>
                                            </AccordionItem>
                                        </Accordion>
                                    }
                                </div>
                        }
                    </>
                    :
                    <NoResult query={query} data={popular} />
            }
        </div>
    )
}

export default SearchResults;
