import { useRef, useEffect } from "react";
import { useConfig } from '@kega/sps-core';

import { Input } from "../form";

import { t } from "../../lib/translations";

import classes from './PlacesInput.module.css';

const PlacesInput = ({ onPlaceChange = null, selectedCountry = null, setCustomerAddressForBilling = null } ) => {
    const autoCompleteRef = useRef(null);
    const inputRef = useRef(null);

    const config = useConfig()
    const country = config.get('country').toLowerCase();
    const finalCountry = selectedCountry ? selectedCountry.toLowerCase() : country;

    const options = {
        componentRestrictions: { country: finalCountry },
        fields: ["address_components"]
    };

    useEffect(() => {
        if (!window.google || finalCountry === 'eu') {
            return;
        }

        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current.ref,
            options
        );

        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            if (onPlaceChange) {
                onPlaceChange(place)
            }
        });
    }, [window.google?.maps, selectedCountry, country]);

    return (
        <div className={classes.root}>
            <Input name="places-search" label={t('checkout.information.form.search')} placeholder={t('checkout.information.form.search')} ref={inputRef} />
        </div>
    );
}
 
export default PlacesInput;