import { useCallback, useState } from 'react';

import { useRequest, useStorefront } from '@kega/sps-core';

const useStoreOrder = (params = {}, options = {}) => {
    const { api: { essentielCustomer } } = useStorefront();
    const { success, error, loading, setRequest, setSuccess, setError } = useRequest();
    const [ order, setOrder ] = useState(null);

    const fetchStoreOrder = useCallback( async () => {
        try {
            setRequest();
            const order = await essentielCustomer.getStoreOrder(params, options);
            setOrder(order);
            setSuccess();

            return order;
        } catch (error) {
            console.log('[fetchStoreOrder]', error);
            setError(error);
        }
    }, [])

    return {
        success,
        error,
        loading,
        order,
        fetchStoreOrder
    };
}

export default useStoreOrder;