const setCurrentStoreMutation = `
mutation setStore($cartId: String!, $storeId: String!) {
        setCurrentStore(
            cartId: $cartId
            storeId: $storeId
        ) {
          success
          storeId
        }
    }`;

export default setCurrentStoreMutation;