import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Card from '../../../../components/card/Card';
import Table from '../../../../components/table/Table';
import { LinkButton } from '../../../../components/form';

import Order from '../../orders/overview/order/Order';

import { t } from '../../../../lib/translations';
import { repeatComponent } from "../../../../lib/utils";

import useStoreViews from '../../../../hooks/useStoreViews';
import useOrders from '../../../../hooks/customer/useOrders';

import classes from "./RecentOrders.module.css";

const RecentOrders = () => {
    const additionalData = `
        status_id
    `;

    const { getUrl } = useStoreViews();
    const { loading, orders = [], fetchOrders } = useOrders({ additionalData });

    const filteredOrders = useMemo(() => {
        return orders.filter((order) =>  {return (order.status !== 'Canceled' && order.status !== 'Pending Payment')});
    }, [orders]);

    useEffect(() => {
        fetchOrders({
            filter: { status: { in: [ "complete", "processing", "exported_to_lsretail", "order_in_alumio" ] } }
        });
    }, []);

    let showOrders = [...filteredOrders];

    showOrders.sort((a, b) => {
        const dateA = a.order_date || '';
        const dateB = b.order_date || '';

        if (dateB > dateA) {
            return 1;
        } else if (dateB < dateA) {
            return -1;
        } else {
            return 0;
        }
    });

    showOrders = showOrders.slice(0, 3);

    const renderItem = (order) => (
        <Order key={order.number} id={order.number} data={order} />
    );

    const orderRows = loading
        ? repeatComponent(Order, { loading: true }, 3)
        : showOrders.map(order => {
            return renderItem(order)
        });

    return (
        <div>
            <h2>{t('account.overview.recent_orders.title')}</h2>
            <>
                {
                    showOrders.length === 0
                        ?
                        <Card className={classes.card_wrapper}>
                            {t('order_history.overview.no_orders')}
                        </Card>
                        :
                        <Table className={classes.table}>
                            <tbody>
                                {orderRows}
                            </tbody>
                        </Table>

                }
            </>
            <LinkButton
                className={classes.button}
                linkComponent={Link}
                to={getUrl('account/orders')}
                variant='secondary'
            >
                {t('account.overview.recent_orders.button')}
            </LinkButton>
        </div>
    );
}

export default RecentOrders;