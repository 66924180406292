import { t } from '../../../../lib/translations';

import SearchSection from "../../../header/search/searchsection/SearchSection";

import classes from './NoResult.module.css';

const NoResult = ({ data: items, query }) => {

    return (
        <div className={classes.no_result_root}>
            <div className={classes.no_result_page_title}>
                <h4>
                    {t('header.search.no.result.page.title')}{' '}
                    <span className={classes.search_query}>{query ? `"${query}"` : null}</span>
                </h4>
                <h4 className={classes.no_result_sub_title}>{t('header.search.no.result.page.sub.title')}</h4>
            </div>
            <div className={classes.no_result_categories_container}>
                <div className={classes.no_result_categories}>
                    <SearchSection />
                </div>
            </div>
        </div>
    );
};

export default NoResult;
