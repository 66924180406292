const createCustomerAddress = `
    mutation createCustomerAddress($country_code: CountryCodeEnum!, $street: [String]!, $telephone: String!, $postcode: String!, $city: String!, $firstname: String!, $lastname: String!, $default_shipping: Boolean!, $default_billing: Boolean!, $regionId: Int, $company: String, $vat_id: String) {    
        createCustomerAddress(
          input: {  
            country_code: $country_code
            street: $street
            telephone: $telephone
            postcode: $postcode
            city: $city
            firstname: $firstname
            lastname: $lastname
            company: $company
            default_shipping: $default_shipping
            default_billing: $default_billing
            region : {
                region_id: $regionId
            }
            vat_id: $vat_id
          }
        ) {
          country_code
          street
          telephone
          postcode
          city
          firstname
          lastname
          company
          default_shipping
          default_billing,
          region {
            region_id
          }
          vat_id
        }
    }
`;

export default createCustomerAddress;