import '@kega/sps-elements/dist/styles.css';
import '@kega/sps-components/dist/styles.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

import { Storefront } from '@kega/sps-core';

import configureStore from './redux/store';
import config from './config/config';

import configureApi from './storefront';

import App from './App';

import { setLanguage } from './lib/translations';

import './css/variables.css';
import './css/main.css';


// Get storeCode from url, storeview change needs a refresh
const [storeCode] = window.location.pathname.substring(1).split('/');

const { store, persistor } = configureStore();
const api = configureApi(config, storeCode);

const onBeforeStart = async ({ config }) => {

    return new Promise(async (resolve) => {
        const locale = config.locale;

        await setLanguage(locale);

        resolve();
    });

}

ReactDOM.render(
    <React.StrictMode>
        <Storefront
            store={store}
            persistor={persistor}
            config={config}
            api={api}
            storeCode={storeCode}
            onBeforeStart={onBeforeStart}
        >
            <HelmetProvider>
                <App />
            </HelmetProvider>
        </Storefront>
    </React.StrictMode>,
    document.getElementById('root')
);