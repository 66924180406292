import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '@kega/sps-core';

import useTagManager from "../hooks/useTagManager";
import useStoreViews from "../hooks/useStoreViews";

import MinimizedHeader from '../modules/header/MinimizedHeader';
import MinimizedFooter from '../modules/footer/MinimizedFooter';
import ErrorBoundary from "../modules/errorboundary/ErrorBoundary";
import Errors from "../modules/errors/Errors";
import SystemMessage from '../components/system_message/SystemMessage';
import NavScrollTop from "../modules/navscrolltop/NavScrollTop";

import classes from './MinimalLayout.module.css';
import classNames from "classnames";

const MinimalLayout = () => {
    const config = useConfig();
    const { language } = config.get('locale');
    const { initGTM } = useTagManager();
    const location = useLocation();
    const { currentStoreView, currentStoreCode } = useStoreViews();
    const { hideCountry } = config.get('storeSelector');

    useEffect(() => {

        initGTM('flap')

        document.body.classList.add(classes.minimized_body);

        return () => {
            document.body.classList.remove(classes.minimized_body);
        };
    }, [])

    return (
        <div>
            <ErrorBoundary path={location.pathname}>
                <Helmet>
                    <html lang={language} />
                    {
                        (hideCountry.includes(currentStoreView.country) || hideCountry.includes(currentStoreCode))
                            ? <meta name="robots" content="noindex, nofollow"/>
                            : ''
                    }
                </Helmet>

                <MinimizedHeader />
                <SystemMessage />
                <main className={classNames(classes.main)}>
                    <Outlet />
                </main>

                <MinimizedFooter />

                <Errors />
                <NavScrollTop/>
            </ErrorBoundary>
        </div>
    )
}

export default MinimalLayout;
