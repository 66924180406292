import classNames from 'classnames';
import classes from './Grid.module.css';

const Container = ({ children, className, sm, md, lg, xl, margins, ...restProps }) => {

    const gutter = {
        xs: restProps['g-xs'],
        sm: restProps['g-sm'],
        md: restProps['g-md'],
        lg: restProps['g-lg'],
        xl: restProps['g-xl']
    };

    const gutterY = {
        xs: restProps['gy-xs'],
        sm: restProps['gy-sm'],
        md: restProps['gy-md'],
        lg: restProps['gy-lg'],
        xl: restProps['gy-xl']
    };

    const containerClassName = classNames(classes.container, {
        [classes['container-sm']]: sm,
        [classes['container-md']]: md,
        [classes['container-lg']]: lg,
        [classes['container-xl']]: xl,
        [classes[`g-${gutter.xs}`]]: gutter.xs === 0 ? true : gutter.xs,
        [classes[`g-sm-${gutter.sm}`]]: gutter.sm === 0 ? true : gutter.sm,
        [classes[`g-md-${gutter.md}`]]: gutter.md === 0 ? true : gutter.md,
        [classes[`g-lg-${gutter.lg}`]]: gutter.lg === 0 ? true : gutter.lg,
        [classes[`g-xl-${gutter.xl}`]]: gutter.xl === 0 ? true : gutter.xl,
        [classes[`gy-${gutterY.xs}`]]: gutterY.xs === 0 ? true : gutterY.xs,
        [classes[`gy-sm-${gutterY.sm}`]]: gutterY.sm === 0 ? true : gutterY.sm,
        [classes[`gy-md-${gutterY.md}`]]: gutterY.md === 0 ? true : gutterY.md,
        [classes[`gy-lg-${gutterY.lg}`]]: gutterY.lg === 0 ? true : gutterY.lg,
        [classes[`gy-xl-${gutterY.xl}`]]: gutterY.xl === 0 ? true : gutterY.xl,
        [classes['margins']]: margins
    }, className);

    return (
        <div className={containerClassName} {...restProps}>
            { children }
        </div>
    );
}

export default Container;