import { useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import { Portal } from '@kega/sps-elements';

import { t } from "../../lib/translations";

import useScrollLock from "../../hooks/useScrollLock";

import CrossIcon from '../icons/icons/CrossIcon';

import classNames from "classnames";
import classes from './Modal.module.css';

const Modal = ({ children, className = '', open = false, onClose = null, title = '', showCloseBtn = true, modalClassName = null, closeContainerClass = null, backgroundClassName = null, selectModal = false, iconClose = false, country, currency, description = null }, ref) => {
    const [isOpen, setOpen] = useState(false);
    const [closing, setClosing] = useState(false);
    useScrollLock(isOpen);

    useEffect(() => {
        if (open) {
            openModal();
        } else if (isOpen) {
            setClosing(true)
        }
    }, [open])

    useImperativeHandle(ref, () => ({
        open: () => { openModal(); },
        close: () => { closeModal(); }
    }));

    const onAnimationEnd = () => {
        if (closing) {
            setOpen(false);
            setClosing(false);
        }
    }

    const close = () => {
        if (onClose) {
            onClose();
        } else {
            closeModal();
        }
    }

    const openModal = () => {
        setOpen(true);
    }

    const closeModal = () => {
        setClosing(true);
    }

    backgroundClassName = classNames(classes.background, backgroundClassName, (closing ? classes.backgroundClosing : ''));
    modalClassName = selectModal ? classNames(modalClassName, (closing ? classes.modalClosing : '')) : classNames(classes.modal, modalClassName, (closing ? classes.modalClosing : ''));

    return (
        <Portal>
            {
                isOpen
                &&
                <div
                    className={backgroundClassName}
                    onClick={close}
                    role="button"
                    tabIndex="0"
                >
                    <aside
                        className={modalClassName}
                        onAnimationEnd={onAnimationEnd}
                        onClick={(event) => event.stopPropagation()}
                    >
                        {
                            (title || showCloseBtn) && (
                                <>
                                    {selectModal && <p className={classes.country}>{country} {currency} </p>}
                                    <div className={classes.top}>
                                        <h2 className={selectModal ? classes.selectModalTitle : classes.title}>{title}</h2>
                                        {
                                            showCloseBtn &&
                                            <span
                                                className={classNames(classes.close, closeContainerClass)}
                                                onClick={close}
                                                role="button"
                                                tabIndex="0"
                                            >
                                                {selectModal || iconClose || showCloseBtn ? <CrossIcon size={16} className={classNames(classes.icon, className)} /> : t('Close')}

                                            </span>
                                        }
                                    </div>
                                    {
                                        description &&
                                        <div className={classes.description}>
                                            <span>{description}</span>
                                        </div>
                                    }
                                </>
                            )
                        }
                        <div>
                            {children}
                        </div>
                    </aside>
                </div>
            }
        </Portal>
    )
}

export default forwardRef(Modal);