import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRequest, useStorefront } from '@kega/sps-core';

import { UpdateWishlist } from '../../redux/wishlist/actions';

const useFetchWishlist = () => {
    const { api: { essentielWishlist } } = useStorefront();

    const dispatch = useDispatch();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const fetchWishlist = useCallback(async () => {
    
        try {
            setRequest();
            const result = await essentielWishlist.getWishlist();

            if (result) {
                dispatch(UpdateWishlist(result));
            }

            setSuccess();
        } catch (error) {
            console.log('[FetchWishlist]', error);
            setError(error);
        }
    }, [] );

    return {
        error,
        loading,
        fetchWishlist
    };
}

export default useFetchWishlist;
