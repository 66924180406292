import setCurrentStoreMutation from "../queries/setCurrentStore.gql";
import getStoreConfigQuery from "../queries/getStoreConfigQuery.gql";
export default ({ client }) => {
    const api = {};

    api.setCurrentStore = (cartId, storeId) => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: setCurrentStoreMutation,
                variables: {
                    cartId: cartId,
                    storeId: storeId,
                }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }

                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    api.getStoreConfig = (keys) => {
        return new Promise((resolve, reject) => {


            const payload = {
                query: getStoreConfigQuery(keys),
                variables: {
                }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }

                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    return api;
};
