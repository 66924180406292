import { createAction } from '@reduxjs/toolkit';

export const setLastOrderId = createAction('checkout/set_last_order_id');
export const setPaymentMethodTitle = createAction('checkout/set_payment_method_title');
export const clearCheckoutData = createAction('checkout/clear_checkout_data');
export const setCheckoutAgreements = createAction('checkout/set_checkout_agreements');
export const setGuestEmail = createAction('checkout/set_guest_email');
export const setProducts = createAction('checkout/set_products');
export const setSelectedBillingAddressType = createAction('checkout/set_selected_billing_address_type');
export const setCouponCode = createAction('checkout/set_coupon_code');
export const removeCouponCode = createAction('checkout/remove_coupon_code');
export const setSelectedShippingAddress = createAction('checkout/set_shipping_address');
export const setCustomBillingAddress = createAction('checkout/set_custom_billing_address');
export const setCheckoutCreateAccountData = createAction('checkout/set_checkout_create_account_data');
