import classNames from "classnames";
import { useEffect, useState } from 'react';
import useSystemMessage from '../../hooks/useSystemMessage';

import classes from './SystemMessage.module.css';

const SystemMessage = () => {
    const { systemMessage } = useSystemMessage();
    const { clearSystemMessage } = useSystemMessage();
    const [closing, setClosing] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setClosing(true);
        }, 2000);
        return () => clearTimeout(timer);
    });

    const onAnimationEnd = () => {
        if (closing) {
            clearSystemMessage();
            setClosing(false);
        }
    }

    if (!systemMessage) { return null }

    return (
        <div
            className={classNames(classes.root, classes[systemMessage.messageType])}
            onAnimationEnd={onAnimationEnd}
        >
            <div>
                {systemMessage.systemMessage}
            </div>
        </div>
    )
};

export default SystemMessage;
