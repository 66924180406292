import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { AddUsp } from '../redux/usp/actions';

import { useStorefront } from '@kega/sps-core';

const useUsp = (type, options) => {
    const { api: { essentielTheme } } = useStorefront();

    const dispatch = useDispatch();

    const uspState = useSelector((state) => state.usp.usp);

    const links = useMemo(() => {
        return uspState[type] || {};
    }, [uspState, type]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const uspState = await essentielTheme.getUsp(options);

                dispatch(AddUsp(uspState));

            } catch (error) {
                console.log('[useUsp] ', error);
            }

        }

        if (Object.keys(uspState).length === 0) {
            loadData();
        }

    }, [uspState]);

    return links;
}

export default useUsp;