import classNames from 'classnames';
import classes from './Grid.module.css';

const Col = ({ children, className, xs, sm, md, lg, xl, ...restProps }) => {

    const order = {
        xs: restProps['order-xs'],
        sm: restProps['order-sm'],
        md: restProps['order-md'],
        lg: restProps['order-lg'],
        xl: restProps['order-xl']
    };

    const colClassName = classNames({
        [classes[`col-${xs}`]]: xs,
        [classes[`col-xs-${xs}`]]: xs,
        [classes[`col-sm-${sm}`]]: sm,
        [classes[`col-md-${md}`]]: md,
        [classes[`col-lg-${lg}`]]: lg,
        [classes[`col-xl-${xl}`]]: xl,
        [classes.col]: !xs && !sm && !md && !lg && !xl,
        [classes[`order-${order.xs}`]]: order.xs === 0 ? true : order.xs,
        [classes[`order-sm-${order.sm}`]]: order.sm === 0 ? true : order.sm,
        [classes[`order-md-${order.md}`]]: order.md === 0 ? true : order.md,
        [classes[`order-lg-${order.lg}`]]: order.lg === 0 ? true : order.lg,
        [classes[`order-xl-${order.xl}`]]: order.xl === 0 ? true : order.xl
    }, className);

    return (
        <div className={colClassName}>
            { children }
        </div>
    );
}

export default Col;