import { Select as DefaultSelect } from '@kega/sps-elements';
import { forwardRef } from 'react';

import useStoreViews from '../../../hooks/useStoreViews';

import classNames from 'classnames';
import classes from './Select.module.css';

const Select = ({ showLabel = true, required, error, ...rest_props }, ref) => {
    const inputClasses = classNames(classes.input, error ? classes.error : '');

    return (
        <DefaultSelect ref={ref} className={inputClasses} data-hide-label={!showLabel} labelClassName={classes['label']} required={required} error={error} {...rest_props} />
    );

}

export default forwardRef(Select);