import Skeleton from "react-loading-skeleton";
import { ProductImage } from "@kega/sps-components";
import { useConfig } from "@kega/sps-core";
import { Link } from "react-router-dom";

import Price from "../../../../../components/price/Price";

import useMediaQuery from "../../../../../hooks/useMediaQuery";
import useStoreViews from "../../../../../hooks/useStoreViews";

import { t } from '../../../../../lib/translations';

import classes from './Item.module.css';

const Item = ({ loading, product_name, product_url_key, status, quantity_ordered, product_sale_price = {}, media_gallery = [], perfion_colourdescription, selected_options = [], product = {} }) => {
    const config = useConfig();
    const { prefix_product } = config.get('url');
    const { getUrl } = useStoreViews();
    const { match: matchMobile } = useMediaQuery(`(max-width: 640px)`);

    const { value: price, currency } = product_sale_price;

    const allOptions = [...selected_options];
    if (perfion_colourdescription) {
        allOptions.push({
            label: t('order_history.details.item.color'),
            value: perfion_colourdescription
        });
    }

    const options = allOptions.map((selectedOption, optionIndex) => (
        <span key={optionIndex} className={classes.option}>{selectedOption.value}</span>
    ));

    return (
        <tr className={classes.root}>
            <td className={classes.image}>
                {
                    loading
                        ? <Skeleton className={classes.img} />
                        : (
                            <Link to={getUrl(`${prefix_product}/${product_url_key}`)}>
                                <ProductImage
                                    className={classes.img}
                                    height={60}
                                    src={product?.media_gallery[0]?.url ? product.media_gallery[0].url : '#'}
                                    alt={product_name ?? ''} />
                            </Link>
                        )
                }
            </td>
            {
                matchMobile && (
                    <td className={classes.rightWrapper}>
                        <div className={classes.nameQtyWrapper}>
                            <div className={classes.name}>
                                {
                                    loading
                                        ? <Skeleton />
                                        : <span>{product_name}</span>
                                }
                            </div>
                            <div className={classes.options}>
                                {
                                    loading
                                        ? <Skeleton />
                                        : (options.length ? options : <span>&nbsp;</span>)
                                }
                            </div>
                            <div className={classes.qtyWrapper}>
                                {
                                    loading
                                        ? <Skeleton />
                                        : <span className={classes.qty}>{t('order_history.details.item.qty', { qty: quantity_ordered })}</span>
                                }
                            </div>
                        </div>
                        <div className={classes.statusPriceWrapper}>
                            <div className={classes.statusWrapper}>
                                {
                                    loading
                                        ? <Skeleton />
                                        : <span className={classes.status}>{status}</span>
                                }
                            </div>
                            <div className={classes.priceWrapper}>
                                {
                                    loading
                                        ? <Skeleton />
                                        : (
                                            <Price
                                                className={classes.price}
                                                price={price}
                                                currency={currency} />
                                        )
                                }
                            </div>
                        </div>
                    </td>
                )
            }
            {
                !matchMobile && (
                    <>
                        <td className={classes.description}>
                            <div className={classes.name}>
                                {
                                    loading
                                        ? <Skeleton />
                                        : <span>{product_name}</span>
                                }
                            </div>
                            <div className={classes.options}>
                                {
                                    loading
                                        ? <Skeleton />
                                        : (options.length ? options : <span>&nbsp;</span>)
                                }
                            </div>
                        </td>
                        <td className={classes.qtyWrapper}>
                            {
                                loading
                                    ? <Skeleton />
                                    : <span className={classes.qty}>{t('order_history.details.item.qty', { qty: quantity_ordered })}</span>
                            }
                            <span>&nbsp;</span>
                        </td>
                        <td className={classes.statusWrapper}>
                            {
                                loading
                                    ? <Skeleton />
                                    : <span className={classes.status}>{status}</span>
                            }
                            <span>&nbsp;</span>
                        </td>
                        <td className={classes.priceWrapper}>
                            {
                                loading
                                    ? <Skeleton />
                                    : (
                                        <Price
                                            className={classes.price}
                                            price={price}
                                            currency={currency} />
                                    )
                            }
                            <span>&nbsp;</span>
                        </td>
                    </>
                )
            }
        </tr>
    );
};

export default Item;
