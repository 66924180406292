import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Builder } from "@builder.io/react/lite";
import { Image } from "@builder.io/react";
import { Drawer } from '@kega/sps-elements';

import useNewsletterOverlay from "../../../../hooks/useNewsletterOverlay";

import Newsletter from "../../../../components/newsletter/Newsletter";
import { CrossIcon } from "../../../../components/icons";

import classNames from "classnames";
import classes from './NewsletterOverlay.module.css';

export const NewsletterOverlay = (props) => {
    const { visual_image, paragraph_text } = props;
    const { isNewsletterOverlayOpen, closeNewsletterOverlay } = useNewsletterOverlay();
    const modalRef = useRef(null);

    const location = useLocation();

    const isLive = !Builder.isEditing && !Builder.isPreviewing;

    if (!isLive && !location.search.includes('newsletter-overlay')) { return false; }

    useEffect(() => {
        if (isNewsletterOverlayOpen || !isLive) {
            modalRef.current.open();
        }
    }, []);

    const handleCloseDrawer = () => {
        if(modalRef.current) {
            closeNewsletterOverlay();
            modalRef.current.close();
        }
    }
  
    return (
        <div className={!isLive ? classes.preview_container : null}>
            <Drawer ref={modalRef} onClose={handleCloseDrawer} backgroundClassName={classNames(classes.background, !isLive ? classes.preview : null)} modalClassName={classNames(classes.root, !isLive ? classes.preview : null)}>

                <div className={classes.inner_wrapper}>
                    <div className={classes.newsletter_container}>
                        <div className={classes.icon}>
                            <CrossIcon className={classes.close} size={16} onClick={handleCloseDrawer} />
                        </div>
                        {
                            paragraph_text && <div className={classes.paragraph} dangerouslySetInnerHTML={{ __html: paragraph_text }} />
                        }
                        <Newsletter className={classes.newsletter_input} ref={modalRef} enableCaption={false} />
                    </div>
                    {
                        visual_image &&
                        <div className={classes.image_wrapper}>
                            <Image lazy aspectRatio={0} image={visual_image} />
                        </div>
                    }
                </div>
            </Drawer>
        </div>
    );
};

Builder.registerComponent(NewsletterOverlay, {
    name: "Newsletter Overlay",
    static: true,
    defaultStyles: {
        marginTop: '0px',
    },
    inputs: [
        {
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            name: 'visual_image',
            friendlyName: 'Visual Image',
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
        },
        {
            type: 'richText',
            name: 'paragraph_text',
            friendlyName: 'Paragraph Text',
            defaultValue: 'Paragraph',
        },
        {
            type: 'string',
            name: 'redirect_url',
            friendlyName: 'Redirect Url'
        },
    ]
});
