const setShippingAddressOnCart = `
    mutation setShippingAddressesOnCart($cartId: String!, $address: CartAddressInput, $addressId: Int, $customerNotes: String) {
        setShippingAddressesOnCart(
            input: {
                cart_id: $cartId
                shipping_addresses: [
                    {
                        address: $address
                        customer_address_id: $addressId
                        customer_notes: $customerNotes
                    }
                ]
            }
        ) {
            cart {
                shipping_addresses {
                    firstname
                    lastname
                    company
                    street
                    city
                    postcode
                    telephone
                    country {
                      code
                      label
                    }
                }
            }
        }
    }
`

export default setShippingAddressOnCart;