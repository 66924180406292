import { useCartId, useCustomer } from "@kega/sps-core";
import { useEffect, useState } from "react";

import useEstimateShippingMethods from "../../../hooks/checkout/useEstimateShippingMethods";

import LoggedIn from "./loggedin/LoggedIn";
import Guest from "./guest/Guest";

const Information = ({ handleContinue, stepPath, setGuestUser, guestUser }) => {
    const customer = useCustomer();
    const { authenticated } = customer;
    const [selected, setSelected] = useState();
    const { estimateShippingMethods, error: fetchError, loading: fetchLoading, methodes } = useEstimateShippingMethods();

    const getCartId = useCartId();
    const cartId = getCartId();

    useEffect( async () => {
        if (methodes.length === 0 && !fetchLoading && cartId) {
            await estimateShippingMethods({ cartId: cartId });
        }
    },[methodes, cartId]);

    return authenticated
        ?
        <LoggedIn
            handleContinue={handleContinue}
            stepPath={stepPath}
            selected={selected}
            setSelected={setSelected}
            fetchError={fetchError}
            fetchLoading={fetchLoading}
            methodes={methodes}
        />
        :
        <Guest
            handleContinue={handleContinue}
            stepPath={stepPath}
            selected={selected}
            setSelected={setSelected}
            fetchError={fetchError}
            fetchLoading={fetchLoading}
            methodes={methodes}
            setGuestUser={setGuestUser}
            guestUser={guestUser}
        />
}

export default Information;
