import { useConfig } from "@kega/sps-core";
import { Link } from "react-router-dom";

import { ProductImage } from '@kega/sps-components';

import useStoreViews from "../../../../hooks/useStoreViews";

import Price from "../../../../components/price/Price";
import ColorRelated from "../../../../components/product/colorrelated/ColorRelated";

import classes from './Product.module.css';
import classNames from "classnames";

const Product = ({ data, onClick }) => {
    const config = useConfig();
    const currency = config.get('currency');
    const { getUrl } = useStoreViews();
    const { prefix_product } = config.get('url');

    const { name, price, minimal_price, image_url, color_related_products } = data;
    const prices = (price && price[currency]) ? price[currency] : {};
    const defaultPrice = prices.default || 0;
    const originalPrice = prices.default_original || 0;

    return (
        <div className={classes.root} onClick={onClick} role="button" tabIndex="0">
            <div className={classes.info}>
                <Link to={`${prefix_product}/${data.url_key}`} state={{ 
                    queryID: data?.__autocomplete_queryID ?? null,
                    price_range: {
                        minimum_price: {
                            regular_price: { value: price ? minimal_price : data?.price_range?.minimum_price.regular_price.value }
                        }
                    },
                }}>
                    <div className={classes.image}>
                        <ProductImage
                            src={image_url}
                            height={50}
                            alt={name} />
                    </div>
                    <div className={classes.name}>
                        <span>{name}</span>
                    </div>
                </Link>
                <div className={classes.prices}>
                    <Price
                        price={defaultPrice}
                        originalPrice={originalPrice} />
                </div>
                {
                    color_related_products && <ColorRelated product={data} />
                }
            </div>
        </div>
    )
}

export default Product;
