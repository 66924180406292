import { useEffect } from "react";
import { builder } from "@builder.io/react";

import { env } from "../lib/utils";

import '../modules/builder/components/layout/Dummy';
import '../modules/builder/components/layout/FullRowGrid';
import '../modules/builder/components/layout/TwoColumnGrid';
import '../modules/builder/components/layout/ThreeColumnGrid';
import '../modules/builder/components/layout/FourColumnGrid';
import '../modules/builder/components/layout/SixColumnGrid';
import '../modules/builder/components/layout/FourEightGrid';
import '../modules/builder/components/layout/FlexibleLayout';
import '../modules/builder/components/flash_block/FlashBlock';
import '../modules/builder/components/divider/Divider';
import '../modules/builder/components/image_block/ImageBlock';
import '../modules/builder/components/collections/Collections';
import '../modules/builder/components/faq_menu/FaqMenu';
import '../modules/builder/components/tabs/Tabs';
import '../modules/builder/components/accordion/BuilderAccordion';
import '../modules/builder/components/shopping_link/ShoppingLink';
import '../modules/builder/components/menu/Menu';
import '../modules/builder/components/contact_form/ContactForm';
import '../modules/builder/components/newsletter/Newsletter';
import '../modules/builder/components/newsletter/NewsletterOverlay';
import '../modules/builder/components/sales_banner/SalesBanner';
import '../modules/builder/components/newsletter_unsubscribe/NewsletterUnsubscribe';
import '../modules/builder/components/giftbox/Giftbox';
import '../modules/builder/components/category_image/CategoryImage';

const useBuilder = () => {
    useEffect(() => {
        const apiToken = env('BUILDER_API_TOKEN', '661980337514468f8025436cda38935b')
        builder.init(apiToken);
        builder.apiVersion = 'v3';
    }, [builder, env]);
};

export default useBuilder;
