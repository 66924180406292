import { useStore } from 'react-redux';

const useWishlistList = () => {
    const store = useStore();

    return () => {
        const { wishlist } = store.getState();

        return wishlist.itemsBySku;
    };
}

export default useWishlistList;
