import { Link } from 'react-router-dom';
import { CartItems, useCartItems, useCartTotals } from '@kega/sps-cart';
import { useConfig } from '@kega/sps-core';
import { Drawer } from '@kega/sps-elements';
import { useEffect } from "react";

import useUi from '../../hooks/useUi';
import useStoreViews from '../../hooks/useStoreViews';
import useTagManager from "../../hooks/useTagManager";
import useScrollLock from '../../hooks/useScrollLock';

import { t } from '../../lib/translations';

import { LinkButton } from '../../components/form';
import { CrossIcon } from '../../components/icons';

import CartItem from './cartitem/CartItem';
import CartTotals from './carttotals/CartTotals';
import MiniCartEmptyContent from "./minicartemptycontent/MiniCartEmptyContent";
import GiftboxProducts from '../../components/giftbox/GiftboxProducts';

import classes from './MiniCart.module.css';
import classNames from 'classnames';

const MiniCart = () => {
    const { isCartOpen, closeCart } = useUi();
    const config = useConfig();
    const items = useCartItems();
    const totals = useCartTotals();
    const { getUrl } = useStoreViews();
    const { url } = config.get('magento2');
    const currentStoreCode = config.get('storeCode');
    const currency = config.get('currency');
    const { ecommerceEvent } = useTagManager();

    useScrollLock(isCartOpen);

    useEffect(() => {
        if (isCartOpen) {
            try {
                if (items.length > 0) {
                    const itemData = items.map(({ sku, name, price_range, quantity, att_text_perfion_filtercolour, configurable_options, att_text_perfion_productgroupcode, url_key, prices }, index) => {
                        const price = price_range.minimum_price.final_price?.value ? price_range.minimum_price.final_price?.value : price_range.minimum_price.regular_price.value;
                        const regularPrice = price_range.minimum_price.regular_price.value;
                        const size = configurable_options.length > 0 ? configurable_options[0]?.value_label : '';

                        let amount = 0;
                        let discount = 0;
                        if (regularPrice > 0 && price < regularPrice) {
                            amount = regularPrice - price;
                            discount = parseFloat(Math.round(amount * 100) / 100);
                        }

                        const discounts = prices.discounts ?? null;

                        return {
                            item_id: sku,
                            item_name: name,
                            item_category: att_text_perfion_productgroupcode,
                            price: price,
                            coupon: discounts ? discounts[0].label : '',
                            discount: discounts ? discounts[0].amount.value : 0,
                            currency: currency,
                            quantity: quantity,
                            item_variant: size,
                            index: index,
                        }
                    });

                    const data = {
                        value: totals.subtotal,
                        currency: currency,
                        items: itemData
                    }
                    ecommerceEvent('view_cart', data);
                }
            } catch (error) {
                console.log('error pushing to datalayer', error);
            }
        }
    }, [isCartOpen]);

    return (
        <Drawer open={isCartOpen} onClose={closeCart} drawerClassName={classes.drawer}>
            <div className={classNames(classes.root, items.length === 0 && classes.empty_cart)}>
                <div className={classes.cart_content}>
                    <CrossIcon title={"Mini Cart"} className={classes.close} size={16} onClick={closeCart} />
                    <h3 className={classes.title}>{t('minicart.title')}</h3>
                    {
                        items.length === 0 &&
                        <div className={classes.empty}>
                            {t('minicart.items.empty')}
                            <Link onClick={closeCart} to={getUrl('/')}>{t('minicart.items.empty_link')}</Link>
                        </div>
                    }
                    <CartItems
                        className={classes.cart_items}
                        itemComponent={CartItem} />
                </div>

                {
                    items.length === 0
                        ?
                        <div className={classes.empty_cart_content}>
                            <MiniCartEmptyContent />
                        </div>
                        :
                        <div className={classes.totals_wrapper}>
                            <CartTotals className={classes.totals} />
                            <div className={classes.checkout}>
                                <LinkButton variant="primary" linkComponent={Link} onClick={closeCart} to={getUrl('/checkout/cart')}>
                                    {t('minicart.button.checkout')}
                                </LinkButton>
                            </div>
                        </div>
                }
            </div>
        </Drawer>
    )
}

export default MiniCart;
