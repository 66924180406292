const addContactForm = `
    mutation addCustomerContact($firstname: String!, $lastname: String!, $email: String!,
    $company: String, $telephone: String, $topic: String!, $order_number: String, $message: String!) {
        addCustomerContact(
           input: {
           firstname: $firstname,
           lastname: $lastname,
           email: $email,
           company: $company,
           telephone: $telephone,
           topic: $topic,
           order_number: $order_number,
           message: $message
           }
        ) {
           result
        }
    }
`;

export default addContactForm;
