import { Outlet } from "react-router-dom";
import { Builder } from "@builder.io/react/lite";

import useMediaQuery from "../../hooks/useMediaQuery";

import BuilderSection from "../builder/BuilderSection";
import AccountNav from './account_nav/AccountNav';

import { Col, Row, Container } from '../../components/grid';

import classes from "./Account.module.css";

const Account = () => {
    const { match } = useMediaQuery(`(min-width: 1025px)`);

    return (
        <>
            <Container xl margins g-xs={1} g-md={3} className={classes.root}>
                <Row>
                    {
                        match
                            ?
                            <Col lg="3">
                                <AccountNav className={classes.nav} />
                            </Col>
                            :
                            null
                    }

                    <Col lg="6" xl="7">
                        <Outlet />
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default Account;
