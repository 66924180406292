import { Drawer } from '@kega/sps-elements';
import { useEffect, useRef, useState } from "react";
import { useConfig } from '@kega/sps-core';

import { t } from "../../../lib/translations";

import useUi from '../../../hooks/useUi';

import Filters from './Filters';
import SortBy from "../sortby/SortBy";
import ClearFilters from "../clearfilters/ClearFilters";
import ClearAllFilters from "../clearfilters/ClearAllFilters";

import { CrossIcon } from '../../../components/icons';
import { Button } from '../../../components/form';

import classes from './Filters.module.css';

const FiltersDrawer = ({ configuredFacets = [], defaultRefinement }) => {
    const { isFiltersOpen, closeFilters } = useUi();
    const config = useConfig();
    const storeCode = config.get('storeCode');
    const { index } = config.get('algolia');
    const formattedCode = storeCode.replace("_kiosk", "");
    const [filteredCount, setFilteredCount] = useState([]);

    const footerRef = useRef(null);

    useEffect(() => {
        const stickyFooter = footerRef.current;

        const observer = new IntersectionObserver(
            ([e]) => e.target.classList.toggle('is-sticky', e.intersectionRatio < 1),
            { threshold: [1] }
        );

        observer.observe(stickyFooter);
    }, []);

    const sortByItems = [
        { value: index + formattedCode + '_products', label: t('productlist.sortby.suggested') },
        { value: index + formattedCode + '_products_price_default_asc', label: t('productlist.sortby.price_asc') },
        { value: index + formattedCode + '_products_price_default_desc', label: t('productlist.sortby.price_desc') },
    ];

    return (
        <Drawer
            open={isFiltersOpen}
            onClose={closeFilters}
            direction='right'
            portal={false}
            drawerClassName={classes.drawer}
            role="button"
            tabIndex="0">
            <header className={classes.header}>
                <CrossIcon title={"Mobile Filters"} className={classes.close} size={16} onClick={closeFilters} />
            </header>

            <div className={classes.drawer_content}>
                <SortBy defaultRefinement={defaultRefinement} items={sortByItems} />
                <Filters configuredFacets={configuredFacets} filteredCount={filteredCount} setFilteredCount={setFilteredCount} />
            </div>

            <footer className={classes.footer} ref={footerRef}>
                <ClearAllFilters setFilteredCount={setFilteredCount} />
                <ClearFilters setFilteredCount={setFilteredCount} />
                <Button variant="primary" onClick={closeFilters}>
                    {t('algolia.productlist.filter.submit')}
                </Button>
            </footer>

        </Drawer>
    );
}

export default FiltersDrawer;
