import { useDispatch, useSelector } from "react-redux";
import { useRequest, useStorefront } from "@kega/sps-core";

import { setDetails, setModalOpened as setModalOpenedAction } from "../redux/geoip/actions";

const useGeoIp = () => {
    const { api: { essentielGeoIP } } = useStorefront();
    const { details, modalOpened } = useSelector((state) => state.geoip);
    const dispatch = useDispatch();
    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const fetchDetails = async () => {
        try {
            setRequest();

            const result = await essentielGeoIP.getDetails();

            if (result) {
                dispatch(setDetails(result));
            }

            setSuccess();
        } catch (error) {
            console.log('[getGeoIpDetails]', error);
            setError(error);
        }
    }

    const setModalOpened = (flag) => {
        dispatch(setModalOpenedAction(flag));
    };

    return  {
        loading,
        error,
        fetchDetails,
        setModalOpened,
        details,
        modalOpened
    }
};

export default useGeoIp;