const getCategoryQuery = (attributes = []) => `
    query getCategoryQuery($url_path: String!) {
        categoryList(
            filters: {
                url_path: {
                    eq: $url_path
                }
            }
        ) {
            ...CategoryTreeFragment
        }
    }
    
    fragment CategoryTreeFragment on CategoryTree {
        id
        name
        description
        path
        url_path
        url_key
        canonical_url
        meta_title
        meta_keywords 
        meta_description
        meta_robots
        display_mode
        image
        ${attributes.join('\n')}
    }
`;

export default getCategoryQuery;