import { useRequest, useStorefront } from '@kega/sps-core';

import { t } from '../lib/translations';

const useNewsletter = () => {
    const { api: { essentielNewsletter } } = useStorefront();
    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const confirmSubscription = async (id, code) => {

        try {
            setRequest();

            const result = await essentielNewsletter.confirmSubscription(id, code);

            if (result) {
                setSuccess();
                return result;

            } else {
                setError(new Error(t('Something went wrong, please try again later')));
            }
        } catch (error) {
            setError(error);
        }

        return 'error';
    };

    const unsubscribe = async (email, reason, storeCode) => {

        try {
            setRequest();

            const result = await essentielNewsletter.unsubscribe(email, reason, storeCode);

            if (result) {
                setSuccess();
                return result;

            } else {
                setError(new Error(t('Something went wrong, please try again later')));
            }
        } catch (error) {
            setError(error);
        }

        return 'error';
    };

    return {
        error,
        loading,
        confirmSubscription,
        unsubscribe
    };
}

export default useNewsletter;
