const getCustomerQuery = (attributes = [], attributesAddress = []) => `
    query getCustomer {
        customer {
            firstname
            middlename
            lastname
            prefix
            suffix
            email
            is_subscribed
            addresses {
                id
                firstname
                lastname
                street
                company
                city
                region {
                    region_id
                    region_code
                    region
                }
                postcode
                country_code
                telephone
                default_billing
                default_shipping
                ${attributesAddress.join('\n')}
            }
            ${attributes.join('\n')}
        }
    }
`;

export default getCustomerQuery;