
import { t } from "../../../../../lib/translations";

import useCreateCustomerAddress from "../../../../../hooks/customer/useCreateCustomerAddress";

import AddressForm from '../../../../../components/address_form/AddressForm';

import classes from './AddAddress.module.css';

const AddAddress = ({ handleAddAddresses, loggedInCheckoutForm, billingAddressForm = false, onSetBillingAddressData }) => {
    const { create } = useCreateCustomerAddress();

    return (
        <div className={classes.root}>
            <div>
                <AddressForm
                    loggedInCheckoutForm={loggedInCheckoutForm}
                    billingAddressForm={billingAddressForm}
                    buttonTitle={t('customer.address.form.save.address')}
                    endpoint={create}
                    redirect={false}
                    parentCallback={handleAddAddresses}
                    onSetBillingAddressData={onSetBillingAddressData}
                />
            </div>
        </div>
    );
}

export default AddAddress;
