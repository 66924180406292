const wishlist = `
    fragment WishlistFragment on Wishlist {
      id
      items_count
      items_v2 {
        items {
          id
          quantity
          ... on BundleWishlistItem {
            bundle_options {
              values {
                id
                label
                quantity
              }
            }
          }
          product {
            uid
            id
            sku
            url_key
            name
            image {
                url
            }
            description {
                html
            }
            price_range {
              minimum_price {
                regular_price {
                  currency
                  value
                }
              }
              maximum_price {
                regular_price {
                  currency
                  value
                }
              }
            }
          }
        }
      }
    }
`;

export default wishlist;
