import { Loader } from '@kega/sps-elements';

import BuilderPage from '../../modules/builder/BuilderPage';
import useStoreViews from "../../hooks/useStoreViews";

import NotFound from '../error/notfound/NotFound';
import ScrollToTop from "../../utils/ScrollToTop";

const Builder = () => {

    const { urlPathWithoutStore } = useStoreViews();

    return (
        <div style={{ overflow: 'hidden' }}>
            <ScrollToTop />
            <BuilderPage
                model="page"
                url={urlPathWithoutStore}
                renderLoading={() => <Loader />}
                renderNotFound={() => <NotFound />}
            />
        </div>
    )
}

export default Builder;
