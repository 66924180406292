import getGeoIpDetailsQuery from '../queries/geoip/getGeoIpDetails.gql';

import parseErrors from "@kega/sps-connector-magento2/src/utils/parseErrors";

export default ({ client }) => {
    const api = {};

    api.getDetails = async () => {

        return new Promise((resolve, reject) => {
            const payload = {
                query: getGeoIpDetailsQuery
            };

            client.post('/graphql', payload).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(data.getGeoIpDetails || {});

            }).catch((error) => {
                reject(error);
            });
        });
    }

    return api;
};
