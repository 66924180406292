import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    productId: null,
    previousUrlPath: '',
};

export default createReducer(initialState, {
    [actions.UpdateProductId]: (state, action) => {
        const { payload = {} } = action;
        state.productId = payload;
    },

    [actions.ResetProductId]: (state, action) => {
        const { payload = initialState } = action;
        state.productId = payload.productId;
    },
});
