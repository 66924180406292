import { useConfig } from '@kega/sps-core';
import { Link } from 'react-router-dom';
import LinkButton from '../../../../components/form/button/LinkButton'

import classes from './SubmenuImage.module.css';

const SubmenuImage = ({ image, imageText, buttonText, buttonLink, handleClick }) => {
    const config = useConfig();
    const { url } = config.get('magento2');

    return (
        <div className={classes.root}>
            <img src={url + image} lazy="true" alt="submenu-image" className={classes.submenu_image} />
            <div className={classes.submenu_container}>
                <div className={classes.submenu_image_text_layer}>
                    {
                        imageText &&
                        <h1 className={classes.submenu_image_text} >{imageText}</h1>
                    }
                    {
                        buttonText &&
                        buttonLink &&
                        <LinkButton
                            to={buttonLink}
                            variant="third"
                            linkComponent={Link}
                            onClick={() => handleClick()}
                        >
                            {buttonText}
                        </LinkButton>
                    }
                </div>
            </div>
        </div >
    )
}

export default SubmenuImage;
