import {
    SetSystemMessage,
    ClearSystemMessage,
    ClearSystemMessageType
} from '../redux/system_message/actions';
import { useSelector, useDispatch } from 'react-redux';

const useSystemMessage = () => {
    const dispatch = useDispatch();
    const systemMessage = useSelector(state => state.system_message.systemMessage);

    const setSystemSuccessMessage = (systemMessage) => {
        dispatch(SetSystemMessage({ systemMessage: systemMessage, messageType: 'success' }));
    };

    const setSystemErrorMessage = (systemMessage) => {
        if (typeof systemMessage === 'object') {
            systemMessage = systemMessage?.message || 'Something went wrong, please try again later';
        }

        dispatch(SetSystemMessage({ systemMessage: systemMessage, messageType: 'error' }));
    };

    const clearSystemMessage = () => {
        dispatch(ClearSystemMessage());
        dispatch(ClearSystemMessageType());
    };

    return {
        setSystemSuccessMessage,
        setSystemErrorMessage,
        systemMessage,
        clearSystemMessage
    };
};

export default useSystemMessage;
