import { CounterInput as DefaultCounterInput } from '@kega/sps-elements';

import classNames from 'classnames';
import classes from './CounterInput.module.css';

const CounterInput = ({ variant, className, ...rest_props }) => {
    const counterInputClasses = classNames(classes['root'], classes[variant], className);

    return (
        <DefaultCounterInput rootClassName={counterInputClasses} inputClassName={classes['input']} {...rest_props} min={-1} collapsed  />
    );

}

export default CounterInput;
