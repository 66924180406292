import { createAction } from '@reduxjs/toolkit';

export const cartOpen = createAction('ui/cart_open');
export const cartClose = createAction('ui/cart_close');

export const menuOpen = createAction('ui/menu_open');
export const menuClose = createAction('ui/menu_close');

export const filtersOpen = createAction('ui/filters_open');
export const filtersClose = createAction('ui/filters_close');

export const sortByOpen = createAction('ui/sortby_open');
export const sortByClose = createAction('ui/sortby_close')

export const customerNavOpen = createAction('ui/customer_nav_open');
export const customerNavClose = createAction('ui/customer_nav_close');

export const zoomGalleryOpen = createAction('ui/zoom_gallery_open');
export const zoomGalleryClose = createAction('ui/zoom_gallery_close');

export const addToCartDrawerOpen = createAction('ui/addtocart_drawer_open');
export const addToCartDrawerClose = createAction('ui/addtocart_drawer_close');
export const giftboxDrawerOpen = createAction('ui/giftbox_drawer_open');
export const giftboxDrawerClose = createAction('ui/giftbox_drawer_close');
export const storeSelectorOpen = createAction('ui/store_selector_open');
export const storeSelectorClose = createAction('ui/store_selector_close');