import WishlistFragment from './fragments/WishlistFragment.gql';

const removeProductsFromWishlistMutation = `
    mutation removeProductsFromWishlist($wishlistId: ID!, $wishlistItemsIds:[ID!]!) {
        removeProductsFromWishlist(
            wishlistId: $wishlistId
            wishlistItemsIds: $wishlistItemsIds
        ) {
            wishlist {
                ...WishlistFragment
            }
        }
    }

    ${WishlistFragment}
`;

export default removeProductsFromWishlistMutation;
