import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    disableSaleLabel: null
};

export default createReducer(initialState, {
    [actions.AddDisableSaleLabel]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            disableSaleLabel: payload
        }
    }
});
