import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import Price from "../../../../../components/price/Price";

import useStoreViews from "../../../../../hooks/useStoreViews";
import useI18n from "../../../../../hooks/useI18n";
import useMediaQuery from "../../../../../hooks/useMediaQuery";

import { t } from '../../../../../lib/translations';

import classes from './Order.module.css';

const Order = ({ id, data = {}, storeOrder = false, loading = false }) => {
    const { formatDate } = useI18n();
    const { getUrl } = useStoreViews();
    const { match: matchMobile } = useMediaQuery('(max-width: 640px)');
    const navigate = useNavigate();

    const orderDate = formatDate(data.order_date);

    const onView = () => {
        if (id && !loading) {
            navigate(getUrl("/account/order/" + id), { state: { storeOrder: storeOrder, id_type: data.id_type } });
        }
    }

    return (
        <tr className={classes.root} onClick={onView} role="button" tabIndex="0">
            <td className={classes.dateNumberWrapper}>
                {
                    loading
                        ?
                        <div className={classes.loader}>
                            <Skeleton />
                        </div>
                        :
                        <>
                            {
                                orderDate && (
                                    <>
                                        <span className={classes.date}>{orderDate}</span>
                                        <span className={classes.separator}>|</span>
                                    </>
                                )
                            }
                            <span className={classes.number}>{t('order_history.overview.order.number', { number: storeOrder ? data.id : data.number })}</span>
                        </>
                }
            </td>
            <td className={classes.statusWrapper}>
                {
                    loading
                        ?
                        <div className={classes.loader}>
                            <Skeleton />
                        </div>
                        :
                        <span className={classes.status}>{ t('account.order.status.' + data.status_id) }</span>
                }
            </td>
            {
                matchMobile && <td className={classes.break}>&nbsp;</td>
            }
            <td className={classes.priceWrapper}>
                {
                    loading
                        ?
                        <Skeleton />
                        :
                        <Price
                            className={classes.price}
                            price={data.total?.grand_total?.value}
                            currency={data.total?.grand_total?.currency} />
                }
            </td>
            <td className={classes.actionsWrapper}>
                {
                    loading
                        ?
                        <Skeleton />
                        :
                        <span className={classes.actions}>{t('order_history.overview.order.view')}</span>
                }
            </td>
        </tr>
    );
};

export default Order;
