import { createReducer } from "@reduxjs/toolkit";

import * as actions from './actions';

const initialState = {
    popularcategories: {},
    categories: {},
    pages: {},
    results: 0,
    blogs: []
};

export default createReducer(initialState, {
    [actions.setCategories]: (state, action ) => {
        const { payload } = action;

        return {
            ...state,
            categories: payload
        }
    },
    [actions.setPopularCategories]: (state, action ) => {
        const { payload } = action;

        return {
            ...state,
            popularcategories: payload
        }
    },

    [actions.setCmsResultPages]: ( state, action ) => {
        const { payload } = action;

        return {
            ...state,
            pages: payload
        }
    },

    [actions.setCmsResultBlogs]: ( state, action ) => {
        const { payload } = action;

        return {
            ...state,
            blogs: payload
        }
    },

    [actions.setSearchResults]: (state, action ) => {
        const { payload } = action;

        return {
            ...state,
            results: payload
        }
    },

    [actions.clearSearchResults]: () => {
        return initialState;
    }
})