import { useRequest, useStorefront } from '@kega/sps-core';

import { t } from "../lib/translations";
import useSystemMessage from "./useSystemMessage";

const useContactForm = () => {
    const { api: { essentielContact } } = useStorefront();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();
    const { setSystemSuccessMessage, setSystemErrorMessage } = useSystemMessage();

    const contactForm = async (data, headers = {}) => {
        try {
            setRequest();
            const result = await essentielContact.addContact(data, headers);

            if (result) {
                setSuccess();
                setSystemSuccessMessage(result.result || t('contactForm.success'));
                return result;
            }
            else {
                setError(new Error(t('Something went wrong, please try again later')));
                setSystemErrorMessage(t('Something went wrong, please try again later'));
            }

        } catch (error) {
            console.log('[SendContact]', error);
            setError(error);
        }
    };

    return {
        error,
        loading,
        contactForm
    };
}

export default useContactForm;
