import Drawer from "../../../../components/drawer/Drawer";
import { MenuIcon, CrossIcon } from '../../../../components/icons';
import NavigationBar from "../NavigationBar";

import useUi from "../../../../hooks/useUi";
import useScrollLock from "../../../../hooks/useScrollLock";

import classNames from "classnames";
import classes from './MobileNavigation.module.css';

const MobileNavigation = () => {
    const { openMenu, closeMenu, isMenuOpen } = useUi();
    useScrollLock(isMenuOpen);

    const ShowMenu = () => {
        openMenu();
        document.body.classList.add('menu_open');
    }

    const CloseMenu = () => {
        closeMenu();
        document.body.classList.remove('menu_open');
    }

    return (
        <>
            <div className={classes.icon} onClick={ShowMenu}>
                <MenuIcon title={"Mobile Navigation"} height="16" width="23" />
            </div>

            <Drawer open={true} onClose={CloseMenu} className={classNames(classes.drawer, isMenuOpen ? classes.open : null)} >
                <div className={classes.topbar}>
                    <div onClick={() => CloseMenu()}>
                        <CrossIcon title={"Mobile Navigation"} size={16} />
                    </div>
                </div>
                <NavigationBar />
            </Drawer>
        </>
    )
}

export default MobileNavigation;
