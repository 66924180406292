import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfig } from '@kega/sps-core';

import { t } from '../../../lib/translations';

import useStoreViews from '../../../hooks/useStoreViews';

import Step from './Step';

import classes from './Progressbar.module.css';

const Progressbar = () => {
    const [current, setCurrent] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const config = useConfig();
    const storeCode  = config.get('storeCode');

    const { getUrl } = useStoreViews();

    const steps = {
        '/checkout/shipment': { key: 'shipment', value: 0, label: t('checkout.progressbar.shipment') },
        '/checkout/payment': { key: 'payment', value: 50, label: t('checkout.progressbar.payment') },
        '/checkout/success': { key: 'success', value: 100, label: t('checkout.progressbar.success') }
    };

    const onStepClick = (path, value) => {
        if (current > value) {
            navigate(getUrl(path));
        }
    };

    useEffect(() => {
        let path = location.pathname.replace('/'+storeCode, '');

        const step = steps[path];

        if (step) {
            setCurrent(step.value);
        }

    }, [location]);


    return (
        <div className={classes.root}>
            {
                Object.keys(steps).map((stepPath) => {
                    const { key, value, label } = steps[stepPath];

                    return (
                        <Step
                            key={key}
                            active={current === value}
                            complete={current > value}
                            onClick={() => onStepClick(stepPath, value)}
                        >
                            <span>{ label }</span>
                        </Step>
                    )
                })
            }
        </div>
    );
}

export default Progressbar;
