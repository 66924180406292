import { useConfig } from "@kega/sps-core";


const StructuredData = () => {
    const config = useConfig();
    const phone = config.get('phone');
    const country = config.get('country');
    
    const data = {
        "@context": "https://schema.org/",
        "@type": "Organization",
        "url": window.location.href,
        "@id": "#Essentiel",
        "name": "Essentiel",
        "logo": window.location.origin + '/images/new-essentiel-logo.svg',
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": phone,
            "contactType": "customer service",
            "areaServed": country.toUpperCase()
        },
        "parentOrganization": {
            "@type": "Organization",
            "@id": "#Essentiel"
        }
    };

    return (
        <>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />
        </>
    );
}
 
export default StructuredData;