import { useConfig } from '@kega/sps-core';
import { connectInfiniteHits } from 'react-instantsearch-dom';

import useStoreViews from '../../../hooks/useStoreViews';

const StructuredData = ({ title, hits }) => {
    const config = useConfig();
    const { prefix_product } = config.get('url');
    const { getUrl } = useStoreViews();

    const defaultCurrency = config.get('currency');

    const productData = hits.map((product) => {
        return {
            "@type": "ListItem",
            "position": product.__position,
            "item": { 
                "@type": "Product",
                "name": product?.name,
                "image": [
                    product?.media_gallery
                        ?
                        product?.media_gallery && product.media_gallery.map((image) => {
                            return (image?.url ? image?.url : image)
                        })
                        :
                        product?.image_url
                ],
                "url": getUrl(`${prefix_product}/${product.url_key}`),
                'itemCondition': 'http://schema.org/NewCondition',
                "model": "CAMI.C1BL",
                "brand": {
                    "@type": "Brand",
                    "name": "Essentiel",
                    "logo": `${window.location.origin}/images/new-essentiel-logo.svg`,
                },
                "offers": {
                    "@type": "Offer",
                    "price": product.price_range ? product.price_range.minimum_price.regular_price.value : product?.price[defaultCurrency].default,
                    "priceCurrency": defaultCurrency,
                    "availability": "InStock"
                }
            }
        };

    })
    const data = {
        "@context": "https://schema.org/",
        "@type": "ItemList",
        "name": title,
        "numberOfItems": hits.length,
        "itemListElement": [
            ...(productData)
        ], 
        
    };

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />
    )
}

export default connectInfiniteHits(StructuredData);