import { Link } from "react-router-dom";

import AddressList from "./address_list/AddressList";

import { Container, Row, Col } from "../../../../components/grid";
import { LinkButton } from "../../../../components/form";

import { t } from "../../../../lib/translations";

import useStoreViews from "../../../../hooks/useStoreViews";

const Overview = () => {
    const { getUrl } = useStoreViews();

    return (
        <>
            <Container xl margins g-xs={0} g-sm={1}>
                <Row>
                    <Col>
                        <h1>{t("address_book.title")}</h1>
                    </Col>
                </Row>
                <AddressList />
                <Row>
                    <Col xs={12} md={3}>
                        <LinkButton
                            style={{ width: "100%" }}
                            to={getUrl("/account/addressbook/create")}
                            variant="secondary"
                            linkComponent={Link}
                        >
                            {t("address_book.new_address")}
                        </LinkButton>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Overview;
