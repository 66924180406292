import classNames from "classnames";

import classes from "./Table.module.css";

const Table = ({ className, children }) => {
    return (
        <table className={classNames(classes.root, className)}>
            {children}
        </table>
    )
}

export default Table;
