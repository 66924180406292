import { useState, useEffect } from "react";

import { t } from "../../../lib/translations";

import useFetchUpsellProducts from "../../../hooks/useFetchUpsellProducts";
import useMediaQuery from "../../../hooks/useMediaQuery";

import LoadingSkeleton from "../../../modules/algolia/loadingskeleton/LoadingSkeleton";

import { Slider, SliderItem } from "../../../components/slider";
import ProductTile from "../ProductTile";
import { ChevronIcon } from "../../icons";

import classes from './Upsell.module.css';
import classNames from "classnames";

const Upsell = ({ parentSku, className }) => {
    const [upsellProducts, setUpsellProducts] = useState([]);
    const { loading, fetchUpsellProducts } = useFetchUpsellProducts();

    const { match } = useMediaQuery(`(min-width: 769px)`)

    useEffect(() => {
        const fetchContent = async () => {
            const response = await fetchUpsellProducts(parentSku);
            setUpsellProducts(response.result);
        }

        if (parentSku && !upsellProducts?.items) {
            fetchContent();
        }
    }, [parentSku, setUpsellProducts]);


    return (
        <div className={classNames(classes.root, className)}>
            {
                loading
                    ?
                    <div className={classes.upsell_skeleton}>
                        <LoadingSkeleton count={[match ? 4 : 3]} className="upsell_loading_skeleton" />
                    </div>
                    : upsellProducts?.items && upsellProducts?.items.length > 0 &&
                    <>
                        <div className={classes.title}>
                            {t('product.upsell.title')}
                        </div>
                        <Slider
                            itemsToShow={match ? 4 : 2}
                            scrollbar={true}
                            controls={true}
                            navigation={false}
                            automate={false}
                            className={classes.upsell_products}
                            ComponentNext={<div className={classes.next}><ChevronIcon title={"Upsell Next"} width={10} height={13} /></div>}
                            ComponentPrev={<div className={classes.prev}><ChevronIcon title={"Upsell Prev"} width={10} height={13} /></div>}
                        >
                            {
                                upsellProducts.items.map((product, index) => {
                                    return (
                                        <SliderItem key={index} className={classes.product}>
                                            <ProductTile className={classes.upsell_product_price} product={product}/>
                                        </SliderItem>
                                    )
                                })
                            }
                        </Slider>
                    </>
            }
        </div>
    )
}

export default Upsell;
