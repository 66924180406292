import { Link } from 'react-router-dom';

import { useCustomer } from '@kega/sps-core';
import { Portal } from '@kega/sps-elements';

import AccountNav from "../../../account/account_nav/AccountNav";
import Drawer from '../../../../components/drawer/Drawer';
import { CrossIcon } from '../../../../components/icons';

import { t } from '../../../../lib/translations';

import useStoreViews from '../../../../hooks/useStoreViews';
import useScrollLock from '../../../../hooks/useScrollLock';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import useUi from '../../../../hooks/useUi';

import classNames from 'classnames';
import classes from './CustomerDropdown.module.css';

const CustomerDropdown = () => {
    const { authenticated } = useCustomer();
    const { getUrl } = useStoreViews();
    const { closeCustomerNav, isCustomerNavOpen } = useUi();
    const { match } = useMediaQuery(`(min-width: 1024px)`)

    useScrollLock(!match && isCustomerNavOpen);


    return (
        <>
            {
                match

                    ?

                    <div className={classNames(classes.root, isCustomerNavOpen ? classes.open : null)}>
                        <div className={classes.title} role="heading">{t('header.customer.title')}</div>
                        <div className={classes.inner_container}>
                            {
                                authenticated
                                    ?
                                    <AccountNav onClick={closeCustomerNav} />
                                    :
                                    <Link className={classes.link} onClick={closeCustomerNav} to={getUrl('/account/login')}>{t('header.customer.login')}</Link>
                            }
                        </div>
                        <Portal>
                            {
                                isCustomerNavOpen && <div className={classes.overlay} onClick={closeCustomerNav}></div>
                            }
                        </Portal>
                    </div>

                    :

                    <Drawer open={isCustomerNavOpen} onClose={closeCustomerNav}>
                        <div className={classes.topbar}>
                            <div onClick={() => closeCustomerNav()}>
                                <CrossIcon title={"Customer"} size={16} />
                            </div>
                        </div>
                        <div className={classes.inner_wrapper}>
                            <div className={classes.title} role="heading">{t('header.customer.title')}</div>
                            <div className={classes.inner_container}>
                                {
                                    authenticated
                                        ?
                                        <AccountNav onClick={closeCustomerNav} />
                                        :
                                        <Link className={classes.link} onClick={closeCustomerNav} to={getUrl('/account/login')}>{t('header.customer.login')}</Link>
                                }
                            </div>
                        </div>
                    </Drawer>
            }
        </>

    );
}

export default CustomerDropdown;
