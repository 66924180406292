import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import { useConfig, useProduct } from '@kega/sps-core';

import { default as ProductDetailsModule } from '../../modules/productdetails/ProductDetails';

import NotFound from '../error/notfound/NotFound';
import ScrollToTop from "../../utils/ScrollToTop";

const ProductDetails = () => {
    const config = useConfig();
    const { product_key } = useParams();
    const { state } = useLocation();

    const { product, loading } = useProduct({
        product_key: product_key,
        product_state: state
    }, {
        custom_attributes: [
            'att_text_perfion_filtercolour',
            'perfion_composition1',
            'perfion_component1',
            'att_text_perfion_component1',
            'perfion_composition2',
            'perfion_component2',
            'att_text_perfion_component2',
            'perfion_composition3',
            'perfion_component3',
            'att_text_perfion_component3',
            'perfion_composition4',
            'perfion_component4',
            'att_text_perfion_component4',
            'perfion_composition5',
            'perfion_component5',
            'att_text_perfion_component5',
            'perfion_modelinformation',
            'perfion_websizefitting',
            'perfion_sizewarning',
            'att_text_perfion_sizewarning',
            'perfion_colourcode',
            'att_text_perfion_colourcode',
            'lsr_item_id',
            'perfion_composition_old',
            'perfion_washinstructions_old',
            'perfion_modelsizetext',
            'perfion_productgroupcode',
            'att_text_perfion_productgroupcode',
            'is_expected',
            'perfion_googlecolour',
            'att_text_perfion_googlecolour',
            'is_preorder',
            'preorder_date',
            'discount_percentage',
            'allow_personalisation'
        ]
    });

    useEffect(() => {
        document.body.classList.add('product_view');
        return () => document.body.classList.remove('product_view');
    });

    const storeViews = config.get('storeViews');
    const defaultCurrency = config.get('currency');

    if (product === null) { return <>{!loading && <NotFound />}</>; }

    return (
        <>
            <ScrollToTop />
            <Helmet>
                <title>{product?.meta_title}</title>
                <meta name="title" content={product?.meta_title} />
                <meta name="description" content={product?.meta_description} />
                <meta name="keywords" content={product?.meta_keyword} />

                <link  rel="canonical" href={window.location.href} />

                {
                    storeViews
                    &&
                    Object.entries(storeViews).map((storeCode) => {
                        const { url: { prefix_product } } = storeViews[storeCode[0]];
                        const href = `/${storeCode[0]}/${prefix_product}/${product_key}`;
                        if (!storeCode[0].endsWith('_kiosk')) {
                            return (
                                <link
                                    key={href}
                                    rel="alternate"
                                    hrefLang={storeCode[1].hreflang}
                                    href={window.location.origin + href}/>
                            );
                        }
                    })
                }

                <meta property="product:price:amount" content={product.price_range ? product.price_range?.minimum_price?.final_price?.value : product?.price[defaultCurrency]?.default}/>
                <meta property="product:price:currency" content={defaultCurrency} />

                <meta property="price" content={product.price_range ? product.price_range?.minimum_price?.final_price?.value : product?.price[defaultCurrency]?.default} />
                <meta property="priceCurrency" content={defaultCurrency} />

                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="product" />
                <meta property="og:title" content={product?.meta_title} />
                <meta property="og:description" content={product?.meta_description} />
                <meta property="og:site_name" content="Essentiel"/>
                <meta property="og:image" content={product?.image?.url} />
            </Helmet>

            <ProductDetailsModule product={product} loading={loading} />

        </>
    )
}

export default ProductDetails;
