const setPaymentMethodOnCart = `
    mutation setPaymentMethodOnCart($cartId: String!, $paymentMethod: String!) {
        setPaymentMethodOnCart(
            input: {
                cart_id: $cartId
                payment_method: {
                    code: $paymentMethod
                }
            }
        ) {
            cart {
                selected_payment_method {
                    code
                    title
                }
                payment_method_title
                total_applied_tax {
                   amount {
                      currency 
                      value 
                   }
                }
            }
        }
    }
`

export default setPaymentMethodOnCart;