import { useMemo, useRef, useEffect, useState } from 'react';
import { connectDynamicWidgets } from 'react-instantsearch-dom';

import { Accordion, AccordionItem, AccordionHeader, AccordionContent, AccordionIcon } from '@kega/sps-elements';

import { t } from '../../../lib/translations';
import useMediaQuery from '../../../hooks/useMediaQuery';

import MultiFilter from './types/multi/MultiFilter';

import { ChevronIcon } from "../../../components/icons";

import classNames from 'classnames';
import classes from './Filters.module.css';

const Filters = ({ attributesToRender, configuredFacets = [], filteredCount, setFilteredCount, children }) => {
    const { match: desktop } = useMediaQuery('(min-width: 769px)');
    const rootRef = useRef(null);

    const handleClickOutside = (event) => {
        if (rootRef.current && !rootRef.current.contains(event.target)) {
            const html = [].slice.call(document.querySelectorAll('.accordion-item > div:nth-child(2)'));

            const longest = html.sort(
                function (a, b) {
                    return b.offsetHeight - a.offsetHeight;
                }
            )[0];

            if (longest.offsetHeight > 0) {
                longest.parentNode.querySelector('div.header-trigger').click()
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const filters = useMemo(() => {

        const components = new Map()

        attributesToRender.map((attribute) => {

            let filter;

            filter = <MultiFilter
                attribute={attribute}
                searchable={false}
                headingText={t('productlist.filter.' + attribute)}
                searchText={t('algolia.productlist.filter.search', { attribute: t('productlist.filter.' + attribute).toLowerCase() })}
                emptyText={t('productlist.filter.no_results')}
                setFilteredCount={setFilteredCount} />;

            components.set(attribute, filter);

        })

        return components;
    }, [attributesToRender]);

    return (
        <div className={classes.root} ref={rootRef}>
            <Accordion className={classes.accordion_container} onlyOne>
                {
                    attributesToRender.map((attribute, index) => {
                        return (
                            filters.has(attribute) &&
                            <AccordionItem key={attribute} className={classNames(classes.filter, 'accordion-item')} resizeOnOpen contentClassName={'content-wrapper'}>
                                <AccordionHeader className={classNames(classes.heading, 'header-trigger')} role="heading">
                                    <h5>
                                        {t('productlist.filter.' + attribute)}
                                        {
                                            filteredCount[attribute] > 0 &&
                                            <span className={classes.filtered_count}>
                                                {filteredCount[attribute]}
                                            </span>
                                        }
                                    </h5>
                                    <AccordionIcon>
                                        <ChevronIcon title={"Accordion Filters"} size={8} color={'#9C9EA1'}/>
                                    </AccordionIcon>
                                </AccordionHeader>
                                <AccordionContent className={classes.content}>
                                    {filters.get(attribute)}
                                </AccordionContent>
                            </AccordionItem>
                        );
                    })
                }
            </Accordion>
        </div>
    );
}

export default connectDynamicWidgets(Filters);
