import { Builder } from "@builder.io/react/lite";

import { default as DefaultNewsletter } from "../../../../components/newsletter/Newsletter";

export const Newsletter = (props) => {
    const { className, enable_caption, redirect_url } = props;

    return (
        <DefaultNewsletter enableCaption={enable_caption} redirectUrl={redirect_url} />
    );
};

Builder.registerComponent(Newsletter, {
    name: "Newsletter",
    static: true,
    defaultStyles: {
        marginTop: '0px',
    },
    inputs: [
        {
            type: "boolean",
            name: 'enable_caption',
            friendlyName: 'Enable caption',
            defaultValue: 0
        },
        {
            type: 'string',
            name: 'redirect_url',
            friendlyName: 'Redirect Url'
        },
    ]
});
