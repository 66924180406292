import addProductsToWishlistMutation from "../queries/addProductsToWishlist.gql";
import getWishlists from "../queries/getWishlists.gql";
import removeProductsFromWishlistGql from "../queries/removeProductsFromWishlist.gql";

import parseErrors from "@kega/sps-connector-magento2/src/utils/parseErrors";

export default ({ client, tokens }) => {
    const api = {};

    api.addProductToWishlist = async (wishlistId, { product, quantity = 1 }) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: addProductsToWishlistMutation,
                variables: {
                    wishlistId,
                    wishlistItems: [{
                        sku: Array.isArray(product.sku) ? product.sku[0] : product.sku,
                        quantity: quantity
                    }]
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(normalizeData(data.addProductsToWishlist.wishlist));

            }).catch((error) => {
                reject(error);
            });
        });
    }

    api.removeProductFromWishlist = async (wishlistId = 0, wishlistItemId) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: removeProductsFromWishlistGql,
                variables: {
                    wishlistId,
                    wishlistItemsIds: [wishlistItemId]
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(normalizeData(data.removeProductsFromWishlist.wishlist));

            }).catch((error) => {
                reject(error);
            });
        });
    }

    api.getWishlist = async () => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: getWishlists,
                variables: {}
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                const wishlists = data.customer?.wishlists || [];
                const wishlist = (typeof wishlists[0] !== 'undefined') ? wishlists[0] : {};

                resolve(normalizeData(wishlist));

            }).catch((error) => {
                reject(error);
            });
        });
    }

    return api;
};

const normalizeData = (data = {}) => {
    let normalizedData = { ...data };

    if (normalizedData.hasOwnProperty('items_v2')) {
        normalizedData.items = normalizedData.items_v2.items || [];
    } else if (!normalizedData.items) {
        normalizedData.items = [];
    }

    normalizedData.itemsBySku = normalizedData.items.reduce(
        (itemsBySku, item) => {
            const { product } = item;

            return (product && product.sku)
                ? {
                    ...itemsBySku,
                    [product.sku]: item
                } : itemsBySku;
        }, {}
    )

    return normalizedData;
};
