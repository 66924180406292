import { useEffect } from 'react';
import { Loader } from '@kega/sps-elements';
import { useConfig, useFetchCustomer, useShippingMethods } from "@kega/sps-core";

import { t } from '../../../lib/translations';

import ShipmentMethod from './shipmentmethode/ShipmentMethod';

import ErrorMessage from '../../../components/messages/ErrorMessage';

import classes from './Shipment.module.css';

const Shipment = ({ selected, setSelected, shippingMethodError, fetchLoading, fetchError, methodes = [] }) => {
    const { setShippingMethods, shippingMethod, error, loading } = useShippingMethods();
    const { customer: { addresses = [] } } = useFetchCustomer();
    const config = useConfig();

    const defaultShippingMethod = config.get('default_shipping_method');

    useEffect( () => {
        if (shippingMethod && !selected) {
            setSelected({
                carrier: shippingMethod?.shipping_addresses[0].selected_shipping_method?.carrier_code,
                method: shippingMethod?.shipping_addresses[0].selected_shipping_method?.method_code
            });
        }
    }, []);

    useEffect( () => {
        if (methodes && methodes.length > 0 && !selected) {
            setShippingMethod(methodes[0]);
        } else {
            if (defaultShippingMethod) {
                setShippingMethod(defaultShippingMethod);
            }
        }

    }, [methodes, defaultShippingMethod]);

    const setShippingMethod = async (method) => {
        setSelected({
            carrier: method.carrier_code,
            method: method.method_code
        });
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <h4>{t('checkout.shipment.header')}</h4>
            </div>
            {
                shippingMethodError !== null
                &&
                <ErrorMessage className={classes.error}>{shippingMethodError?.message}</ErrorMessage>
            }

            {
                fetchError !== null
                &&
                <ErrorMessage className={classes.error}>{fetchError?.message}</ErrorMessage>
            }

            {
                fetchLoading
                &&
                <Loader variant="primary" />
            }

            {

                methodes.length > 0 && !fetchLoading
                    ?
                    methodes.map((methode, index) => {
                        return <ShipmentMethod key={'m' + index} data={methode} selected={(selected?.carrier === methode.carrier_code)} onClick={() => setShippingMethod(methode)} />
                    })
                    :
                    <ShipmentMethod data={defaultShippingMethod} selected={(selected?.carrier === defaultShippingMethod.carrier_code)} onClick={() => setShippingMethod(defaultShippingMethod)} />
            }
        </div>
    );
}

export default Shipment;
