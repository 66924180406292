import { Link } from 'react-router-dom';

import Card from '../../../../components/card/Card';
import { LinkButton } from '../../../../components/form';

import { t } from '../../../../lib/translations';

import useStoreViews from '../../../../hooks/useStoreViews';

import classes from "./User.module.css";

const User = ({ customer }) => {
    const { getUrl } = useStoreViews()
    const { firstname, lastname, email } = customer;
    return (
        <div className={classes.root}>
            <Card className={classes.card_wrapper}>
                <div className={classes.name}>
                    {(firstname && lastname) && firstname + ' ' + lastname}
                </div>
                <div>
                    {email}
                </div>
                <Link className={classes.link} to={getUrl('/account/account_information')}>{t('Edit')}</Link>
            </Card>
            <LinkButton
                className={classes.button}
                linkComponent={Link}
                to={getUrl('account/changepassword')}
                variant='secondary'
            >
                {t('account.change_password.title')}
            </LinkButton>
        </div >
    );
}

export default User;
