import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useCategories } from '@kega/sps-core';
import useMediaQuery from "../../../hooks/useMediaQuery";
import useUi from "../../../hooks/useUi";
import useStoreViews from "../../../hooks/useStoreViews";

import { Col, Row, Container } from '../../../components/grid';

import SubmenuImage from "./submenuimage/SubmenuImage";

import classNames from "classnames";
import classes from './NavigationBar.module.css';

const NavigationBar = () => {
    const { openMenu, closeMenu, isMenuOpen } = useUi();
    const { match: matchMobile } = useMediaQuery('(max-width: 1024px)');
    const { match: matchDesktop } = useMediaQuery('(min-width: 1441px)');
    const categories = useCategories({
        category_id: 2
    }, {
        custom_attributes: [
            'display_mode',
            'highlighted_category',
            'disable_clickable',
            'category_submenu_image',
            'image_text',
            'image_button_text',
            'image_button_href',
            'menu_item_redirect',
            'menu_item_redirect_rel',
            'menu_item_redirect_target',
            'category_seo_name',
            'hide_on_desktop'
        ]
    });

    useEffect(() => {

    }, []);

    const expandedMenus = categories?.map(() => {
        return false;
    })
    const [expanded, setExpanded] = useState(expandedMenus);

    const { getUrl, isExternalLink } = useStoreViews();

    const topLinkClassNames = (highlighted_category, disable_clickable, children) => {
        return classNames(classes.top_link, highlighted_category ? classes.highlighted : '', disable_clickable ? classes.disabled : '', children.length > 1 ? classes.parent : '');
    }

    const subLinkClassNames = (highlighted_category, disable_clickable, children) => {
        return classNames(classes.sub_link, highlighted_category ? classes.highlighted : '', disable_clickable ? classes.disabled : '', children.length > 1 ? classes.parent : '');
    }

    const subMenuCollapse = (e) => {
        if (!matchMobile) {
            CloseMenu();
            return false;
        }

        if (e.target.classList.contains(classes.parent)) {
            e.target.nextSibling.classList.add(classes.sub_visible);

            setTimeout(function () {
                e.target.nextSibling.classList.add(classes.sub_open);
            }, 50);

            return e.preventDefault();
        } else {
            CloseMenu();
        }
    }

    const Back = (e, url_path) => {
        if (!matchMobile) {
            return false;
        }
        url_path = '#' + url_path.replaceAll('/', '\\/');
        e.target.closest(url_path).classList.remove(classes.sub_open);
    }

    const onSubMenuTransitionEnd = (e, url_path) => {
        url_path = '#' + url_path.replaceAll('/', '\\/');
        if (!e.target.closest(url_path).classList.contains(classes.sub_open)) {
            e.target.closest(url_path).classList.remove(classes.sub_visible);
        }
    };

    const ShowMenu = () => {
        if (isMenuOpen) {
            document.body.classList.add(classes.still_open);
        }
        setTimeout(function () {
            openMenu();
            document.body.classList.add(classes.menu_open);
            document.body.classList.add("menu_open");
        }, 300);
    };

    const CloseMenu = () => {
        closeMenu();
        document.body.classList.remove(classes.still_open);
        document.body.classList.remove(classes.menu_open);
        document.body.classList.remove('menu_open');
    }

    const renderCols = (parent, children, col_number) => {
        let cols = [];

        for (let i = 0; i < col_number; i++) {
            cols.push([]);
        }

        children.map(({ name, url_path, highlighted_category, disable_clickable, menu_item_redirect, menu_item_redirect_rel, menu_item_redirect_target, canonical_url,hide_on_desktop, children = [] }, index) => {
            const urlPath = menu_item_redirect ?? (url_path ?? canonical_url);
            const isExternal = isExternalLink(urlPath);

            if (!matchMobile && hide_on_desktop) {
                return null;
            }

            const col = (
                <div key={url_path} className={classes.sub_menu}>
                    {
                        matchMobile
                            ?
                            isExternal
                                ?
                                <a
                                    href={`${urlPath}`}
                                    className={subLinkClassNames(highlighted_category, disable_clickable, children)}
                                    onClick={(e) => subMenuCollapse(e)}
                                    rel={menu_item_redirect_rel}
                                    target={menu_item_redirect_target}
                                >
                                    {name}
                                </a>
                                :
                                <NavLink
                                    key={url_path}
                                    to={getUrl(`${urlPath}`)}
                                    className={subLinkClassNames(highlighted_category, false, children)}
                                    onClick={(e) => subMenuCollapse(e)}
                                    rel={menu_item_redirect_rel}
                                    target={menu_item_redirect_target}
                                >
                                    {name}
                                </NavLink>

                            :
                            isExternal
                                ?
                                <a
                                    href={`${urlPath}`}
                                    className={subLinkClassNames(highlighted_category, disable_clickable, children)}
                                    onClick={(e) => subMenuCollapse(e)}
                                    rel={menu_item_redirect_rel}
                                    target={menu_item_redirect_target}
                                >
                                    {name}
                                </a>
                                :
                                <NavLink
                                    key={url_path}
                                    to={getUrl(`${urlPath}`)}
                                    className={subLinkClassNames(highlighted_category, disable_clickable, children)}
                                    onClick={CloseMenu}
                                    rel={menu_item_redirect_rel}
                                    target={menu_item_redirect_target}
                                >
                                    {name}
                                </NavLink>
                    }
                    <div
                        className={matchMobile ? classes.sub_menu_container : null}
                        id={url_path}
                        onTransitionEnd={(e) => onSubMenuTransitionEnd(e, url_path)}
                    >
                        <div className={classes.menu_column_container}>
                            <div className={matchMobile ? classes.menu_column_row : null}>
                                <div className={matchMobile ? classes.menu_column : null}>
                                    {matchMobile
                                        &&
                                        <>
                                            <div onClick={(e) => Back(e, url_path)} className={classes.back}>
                                                Back to {parent}
                                            </div>
                                            <div className={classes.current_category}>
                                                {name}
                                            </div>
                                        </>
                                    }
                                    <ul>
                                        {
                                            children.map(({ name, url_path, menu_item_redirect, menu_item_redirect_rel, menu_item_redirect_target }) => {
                                                return <li key={url_path}><NavLink className={classes.sub_sub_link} key={url_path} onClick={CloseMenu} to={getUrl(`${menu_item_redirect ?? url_path}`)} rel={menu_item_redirect_rel} target={menu_item_redirect_target}>{name}</NavLink></li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

            cols[index % col_number].push(col);
        });

        return cols.map((col, index) => {
            return (
                <Col className={classes.sub_menu_column} key={index} lg={3}>
                    {col}
                </Col>
            )
        });
    }

    const onMenuHover = (index, children = false) => {
        expanded[index] = children ? !expanded[index] : false;
        setExpanded(expanded);
    }

    if (categories.length > 0) {
        setTimeout(() => {
            window.prerenderReady = true
        }, 1000);
    }

    return (
        <nav className={classes.root} onMouseLeave={!matchMobile ? CloseMenu : null}>
            <Container margins g-xs={2} g-xl={0}>
                <Row>
                    <Col xs={12} className={classes.menu_container} >
                        <ul className={classes.menu}>
                            {
                                categories.map(({ name, url_path, highlighted_category, disable_clickable, category_submenu_image, image_text, image_button_text, image_button_href, menu_item_redirect, menu_item_redirect_rel, menu_item_redirect_target, hide_on_desktop, children = [] }, index) => {
                                    const isExternal = isExternalLink(menu_item_redirect ?? url_path);

                                    if (!matchMobile && hide_on_desktop) {
                                        return null;
                                    }

                                    return (
                                        isExternal
                                            ?
                                            <li data-link-text={name} className={classes.top_link_wrapper} key={url_path} onMouseEnter={() => onMenuHover(index, children.length >= 1)} onMouseLeave={() => onMenuHover(index)}>
                                                <a
                                                    href={`${menu_item_redirect}`}
                                                    className={topLinkClassNames(highlighted_category, disable_clickable, children)}
                                                    onClick={(e) => subMenuCollapse(e)}
                                                    rel={menu_item_redirect_rel}
                                                    target={menu_item_redirect_target}
                                                >
                                                    {name}
                                                </a>
                                            </li>
                                            :
                                            <li data-link-text={name} className={classes.top_link_wrapper} key={url_path} onMouseEnter={() => onMenuHover(index, children.length >= 1)} onMouseLeave={() => onMenuHover(index)}>
                                                <NavLink key={url_path}
                                                    to={getUrl(`${menu_item_redirect ?? url_path}`)}
                                                    className={topLinkClassNames(highlighted_category, disable_clickable, children)}
                                                    onClick={(e) => subMenuCollapse(e)}
                                                    onMouseEnter={!matchMobile ? ShowMenu : null}
                                                    rel={menu_item_redirect_rel}
                                                    target={menu_item_redirect_target}
                                                    aria-haspopup={children.length >= 1}
                                                    aria-expanded={expanded.length > 0 ? expanded[index] : false}
                                                >
                                                    {name}
                                                </NavLink>
                                                {
                                                    children.length >= 1
                                                    &&
                                                    <div className={classes.sub_menu_container}
                                                        id={url_path}
                                                        onTransitionEnd={(e) => onSubMenuTransitionEnd(e, url_path)}
                                                    >
                                                        <Container margins g-xs={0}>
                                                            <Row className={classes.sub_menu_row}>
                                                                <Col lg={(category_submenu_image && matchDesktop) ? 8 : 12} className={classes.menu_column}>
                                                                    <Container margins g-xs={4} className={classes.menu_column_container}>
                                                                        {matchMobile
                                                                            &&
                                                                            <>
                                                                                <div onClick={(e) => Back(e, url_path)} className={classes.back}>
                                                                                    Back to menu
                                                                                </div>
                                                                                <div className={classes.current_category}>
                                                                                    {name}
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        <Row className={classes.menu_column_row}>
                                                                            {
                                                                                renderCols(name, children, 4)
                                                                            }
                                                                        </Row>
                                                                    </Container>
                                                                </Col>
                                                                {
                                                                    category_submenu_image
                                                                    &&
                                                                    (matchDesktop &&
                                                                        <Col md={4} className={classes.submenu_column}>
                                                                            <SubmenuImage image={category_submenu_image} imageText={image_text} buttonText={image_button_text} buttonLink={getUrl(image_button_href)} handleClick={CloseMenu} />
                                                                        </Col>
                                                                    )
                                                                }
                                                            </Row>

                                                        </Container>
                                                    </div>
                                                }
                                            </li>
                                    );
                                })
                            }
                        </ul>
                    </Col>
                </Row>
            </Container>
        </nav >
    )
}

export default NavigationBar;
