const getShippingMethodsQuery =  `
    query getShippingMethods($cartId: String!) {
        cart(
            cart_id: $cartId
        ) {
            shipping_addresses {
                available_shipping_methods {
                    amount {
                        currency
                        value
                    }              
                    carrier_code
                    method_code
                    carrier_title
                }
            }
        }
    }
`;

export default getShippingMethodsQuery;