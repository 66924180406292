import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useCart, useCartId } from '@kega/sps-core';

import { t } from "../../lib/translations";

import useCheckoutData from "../../hooks/checkout/useCheckoutData";

import { default as CartModule } from '../../modules/cart/Cart';

const Cart = () => {
    const { fetchCart, totals, items } = useCart();
    const { extraCartData } = useCheckoutData();

    const getCartId = useCartId();
    let cartId = getCartId();

    useEffect( () => {
        if(cartId){
            fetchCart(cartId, extraCartData);
        }
    }, [cartId]);

    return (
        <>
            <Helmet>
                <title>{t('checkout.cart.title')}</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <CartModule
                items={items}
                totals={totals} />
        </>
    )
}

export default Cart;
