class Translations {

    constructor() {
        this.language = 'nl';
        this.data = [];
        this.regions = null;
        this.regionsEng = null;
        this.languages = null;
    }

    setLanguage = async (language) => {
        this.language = language;

        try {
            const translation = await import(
                /* webpackChunkName: "translations" */
            "./"+language+".json");

            this.data = this.flatten(translation.default);

            this.regions = new Intl.DisplayNames(language, { type: 'region' });
            this.regionsEng = new Intl.DisplayNames('en-GB', { type: 'region' });
            this.languages = new Intl.DisplayNames(language, {type: 'language', languageDisplay: 'standard'});
        } catch (error) {
            console.log(error);
        }

    }

    flatten = (obj, prefix = '') => {
        return Object.keys(obj).reduce((translations, k) => {
    
            const pre = prefix.length ? prefix + '.' : '';
    
            if (typeof obj[k] === 'object') { 
                Object.assign(translations, this.flatten(obj[k], pre + k))
            } else { 
                translations[pre + k] = obj[k];
            }
    
            return translations;
    
        }, {});
    }

    get = (key, params = null, lang = this.language) => {

        if (this.data.hasOwnProperty(key)) {
            
            let text = this.data[key];

            if (params) {
                Object.keys(params).forEach((key) => {
                    text = text.replace(new RegExp(`{{${key}}}`, 'g'), params[key]);
                });
                return text;
            } else {
                return text;
            }
            
        } else {
            return key;
        }
    }

    translateRegion = (code) => {
        if (this.data.hasOwnProperty('region_' + code)) {
            return this.get('region_' + code);
        } else if (this.regions && code) {
            return this.regions.of(code.toUpperCase());
        } else {
            return code;
        }
    }

    regionNameInEnglish = (code) => {
        if (this.data.hasOwnProperty('region_' + code + '_eng')) {
            return this.get('region_' + code + '_eng');
        } else if (this.regionsEng && code) {
            return this.regionsEng.of(code.toUpperCase());
        } else {
            return code;
        }
    }

    translateLanguage = (code) => {
        if (this.data.hasOwnProperty('language_' + code + '_native')) {
            return this.get('language_' + code + '_native');
        } else if (this.languages && code) {
            return this.languages.of(code.toUpperCase());
        } else {
            return code;
        }
    }

}

const translations = new Translations();

const setLanguage = async (lang) => { return translations.setLanguage(lang); }
const t = (key, params = null) => { return translations.get(key, params); }
const translateRegion = (code) => { return translations.translateRegion(code); }
const regionNameInEnglish = (code) => { return translations.regionNameInEnglish(code); }
const translateLanguage = (code) => { return translations.translateLanguage(code); }

export {
    setLanguage,
    translateRegion,
    regionNameInEnglish,
    translateLanguage,
    t
}