import IconWrapper from '../wrapper/IconWrapper';

const CrossIcon = ({ color = null, title, ...props }) => {
    return (
        <IconWrapper color={color} {...props}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" role={title ? null : "presentation"}>
                {title && <title>{title}</title>}
                <path d="M15.7 1.1L14.5 0.1L7.8 6.6L1.1 0L0 1.1L6.7 7.8L0 14.5L1.1 15.6L7.8 9L14.5 15.5L15.7 14.5L9 7.8L15.7 1.1Z" fill={color ? color : 'black'} />
            </svg>
        </IconWrapper>
    );
}

export default CrossIcon;
