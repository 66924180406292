import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCustomer } from '@kega/sps-core';

import useAddToWishlist from '../../../hooks/wishlist/useAddToWishlist';
import useRemoveFromWishlist from '../../../hooks/wishlist/useRemoveFromWishlist';
import useWishlistList from '../../../hooks/wishlist/useWishlistList';

import { WishlistIcon, WishlistIconFilled } from '../../icons';

import classNames from 'classnames';
import classes from './WishlistButton.module.css';
import useStoreViews from "../../../hooks/useStoreViews";

const WishlistButton = ({ product, size = 12, className = '' }) => {
    const [wishlist, addWishlist] = useState(false);
    const [sku, setSku] = useState(null);
    const { addToWishlist, success } = useAddToWishlist();
    const { removeFromWishlist } = useRemoveFromWishlist();
    const getWishlistList = useWishlistList();
    const navigate = useNavigate();
    const { authenticated } = useCustomer();
    const { getUrl } = useStoreViews();
    const location = useLocation();
    const returnPath = location.pathname.replace(getUrl(), '');

    let wishlistList = getWishlistList();

    useEffect(() => {
        Array.isArray(product.sku) ? setSku(product.sku[0]) : setSku(product.sku);

        if (Object.keys(wishlistList).includes(sku)) {
            addWishlist(true);
        }
    }, [sku, wishlistList])

    const wishlistAction = async (product) => {
        if (!authenticated) {
            navigate(getUrl('/account/login'), {
                state: {
                    returnPathWishlist: returnPath
                }
            });
        } else {
            if (!wishlist) {
                await addToWishlist({ product, quantity: 1 });
                addWishlist(success);
            } else {
                await removeFromWishlist(wishlistList[sku].id);
                addWishlist(false);
            }
        }

    }

    const iconClassNames = classNames(classes.icon, wishlist ? classes.active : '');
    const rootClassNames = classNames(classes.root, className);

    if (!sku) {
        return false;
    }

    return (
        <div className={rootClassNames} onClick={() => wishlistAction(product)} role="button" tabIndex="0">
            {
                wishlist
                    ? <WishlistIconFilled title={"Wishlist Filled"} className={iconClassNames} color="currentColor" size={size} />
                    : <WishlistIcon title={"Wishlist"} className={iconClassNames} color="currentColor" size={size} />
            }
        </div>
    );
}

export default WishlistButton;
