import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Skeleton from "react-loading-skeleton";

import Total from "./total/Total";

import { Col, Container, Row } from "../../../../components/grid";
import { t } from "../../../../lib/translations";

import useI18n from "../../../../hooks/useI18n";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import useOrder from "../../../../hooks/customer/useOrder";
import useStoreOrder from "../../../../hooks/customer/useStoreOrder";
import useSystemMessage from "../../../../hooks/useSystemMessage";
import useStoreViews from "../../../../hooks/useStoreViews";

import classes from './Details.module.css';
import Item from "./item/Item";
import Table from "../../../../components/table/Table";
import classNames from "classnames";
import Address from "../../../../components/address/Address";
import Card from "../../../../components/card/Card";

const Overview = () => {
    const SHOW_LIMIT = 2;

    const additionalItemData = `
        product {
            media_gallery {
                url
            }
            perfion_colourdescription
            perfion_filtercolour
            size
            att_text_perfion_filtercolour
            att_text_perfion_productgroupcode
            att_text_size
        }
    `;

    const [showAll, setShowAll] = useState(false);
    const [ order, setOrder ] = useState(null);
    const params = useParams();
    const { setSystemErrorMessage } = useSystemMessage();
    const navigate = useNavigate();
    const location = useLocation();
    const { getUrl } = useStoreViews();
    const { formatDate } = useI18n();
    const { match: matchMobile } = useMediaQuery(`(max-width: 640px)`);

    const isStoreOrder = location?.state?.storeOrder;
    const orderId = params.id || null;
    const idType = location?.state?.id_type;

    const { success, error, loading, order: onlineOrder, fetchOrder } = useOrder({ order_id: orderId }, { additionalItemData });
    const { success: storeOrderSuccess, error: storeOrderError, loading: storeOrderLoading, order: storeOrder, fetchStoreOrder } = useStoreOrder({ order_id: orderId, id_type: idType });

    const handleNoOrder = () => {
        setSystemErrorMessage(t('order_history.details.no_exists'));
        navigate(getUrl('/account/orders'));
    };

    useEffect(async () => {
        if (!orderId) {
            handleNoOrder();
        } else {
            if (isStoreOrder) {
                if (!storeOrder && !storeOrderLoading) {
                    if (!storeOrderSuccess && !storeOrderError) {
                        const response = await fetchStoreOrder();
                        setOrder(response);
                    } else {
                        handleNoOrder();
                    }
                }
            } else {
                if (!onlineOrder && !loading) {
                    if (!success && !error) {
                        const response = await fetchOrder();
                        setOrder(response);
                    } else {
                        handleNoOrder();
                    }
                }
            }
        }
    }, [error, loading, order, orderId, success]);

    if (!orderId || (!loading && !order)) {
        return null;
    }

    let showItems = [];
    if (order?.items) {
        showItems = showAll
            ? order.items
            : order.items.slice(0, SHOW_LIMIT);
    } else if (loading) {
        for (let i = 0; i < SHOW_LIMIT; i++) {
            showItems.push({});
        }
    }

    const showAllItems = showAll || (order?.items && order.items.length <= SHOW_LIMIT) || loading;

    const orderDate = formatDate(order?.order_date);
    const orderTotals = order?.total || {};
    const orderDiscounts = orderTotals.discounts || [];

    const shipment = order?.shipments
        ? (order.shipments[0] || null)
        : null;
    const track = shipment?.tracking
        ? (shipment.tracking[0] || null)
        : null;

    return (
        <>
            <Container xl margins g-xs={0} g-sm={1}>
                <Row>
                    <Col>
                        <h1>{t('order_history.details.title')}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <div className={classes.topWrapper}>
                            <div className={classes.dateNumberWrapper}>
                                {
                                    loading && <Skeleton />
                                }
                                {
                                    !loading && (
                                        <>
                                            <span className={classes.date}>{orderDate}</span>
                                            <span className={classes.separator}>|</span>
                                            <span>{t('order_history.details.order_number', { number: isStoreOrder ? order.id : order.number })}</span>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <Card className={classes.summary}>
                            <div className={classNames(classes.items, { [classes.showAll]: showAllItems })}>
                                <Table>
                                    <tbody>
                                        {
                                            showItems.map((item, index) => <Item key={index} loading={loading} {...item} />)
                                        }
                                    </tbody>
                                </Table>
                                <div className={classes.showAllOverlay} onClick={() => setShowAll(true)} role="button" tabIndex="0" />
                            </div>
                            <div className={classes.totals}>
                                <div className={classes.subtotals}>
                                    <Total
                                        loading={loading}
                                        label={t('order_history.details.subtotal.subtotal')}
                                        amount={orderTotals.subtotal?.value}
                                        currency={orderTotals.subtotal?.currency} />
                                    <Total
                                        loading={loading}
                                        label={t('order_history.details.subtotal.shipping')}
                                        amount={orderTotals.total_shipping?.value}
                                        currency={orderTotals.total_shipping?.currency} />
                                    {
                                        orderDiscounts.map((orderDiscount, index) => (
                                            <Total
                                                key={index}
                                                loading={loading}
                                                label={orderDiscount.label}
                                                amount={-1 * Number(orderDiscount.amount?.value || 0)}
                                                currency={orderDiscount.amount?.currency} />
                                        ))
                                    }
                                    <Total
                                        loading={loading}
                                        label={t('order_history.details.subtotal.tax')}
                                        amount={orderTotals.total_tax?.value}
                                        currency={orderTotals.total_tax?.currency} />
                                </div>
                                <div className={classes.grandTotal}>
                                    <Total
                                        loading={loading}
                                        className={classes.grandTotalPrice}
                                        label={t('order_history.details.subtotal.grand')}
                                        amount={orderTotals.grand_total?.value}
                                        currency={orderTotals.grand_total?.currency} />
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div className={classNames(classes.property, classes.shippingAddress)}>
                            {
                                !matchMobile && <h2>{t('order_history.details.properties.shipping_address')}</h2>
                            }
                            <Card>
                                {
                                    matchMobile && <h2>{t('order_history.details.properties.shipping_address')}</h2>
                                }
                                <Address
                                    className={classes.address}
                                    loading={loading}
                                    address={order?.shipping_address} />
                            </Card>
                        </div>
                        <div className={classNames(classes.property, classes.billingAddress)}>
                            {
                                !matchMobile && <h2>{t('order_history.details.properties.billing_address')}</h2>
                            }
                            <Card>
                                {
                                    matchMobile && <h2>{t('order_history.details.properties.billing_address')}</h2>
                                }
                                <Address
                                    className={classes.address}
                                    loading={loading}
                                    address={order?.billing_address} />
                            </Card>
                        </div>
                        <div className={classNames(classes.property, classes.shippingMethod)}>
                            {
                                !matchMobile && <h2>{t('order_history.details.properties.shipping_method')}</h2>
                            }
                            <Card>
                                {
                                    matchMobile && <h2>{t('order_history.details.properties.shipping_method')}</h2>
                                }
                                {
                                    loading
                                        ? <Skeleton />
                                        : <span>{order.shipping_method}</span>
                                }
                            </Card>
                        </div>
                        <div className={classNames(classes.property, classes.paymentMethods)}>
                            {
                                !matchMobile && <h2>{t('order_history.details.properties.payment_method')}</h2>
                            }
                            <Card>
                                {
                                    matchMobile && <h2>{t('order_history.details.properties.payment_method')}</h2>
                                }
                                {
                                    loading && <Skeleton />
                                }
                                {
                                    !loading && order?.payment_methods && order.payment_methods.map((payment_method, index) => (
                                        <span key={index} className={classes.paymentMethod}>{payment_method.name}</span>
                                    ))
                                }
                                {
                                    !loading && !order?.payment_methods && <span>{t('order_history.details.properties.no_payment')}</span>
                                }
                            </Card>
                        </div>
                        <div className={classNames(classes.property, classes.trackOrder)}>
                            {
                                !matchMobile && <h2>{t('order_history.details.properties.track_your_order')}</h2>
                            }
                            <Card>
                                {
                                    matchMobile && <h2>{t('order_history.details.properties.track_your_order')}</h2>
                                }
                                {
                                    loading
                                        ? <Skeleton />
                                        : <span>{track?.number || t('order_history.details.properties.no_track')}</span>
                                }
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Overview;
