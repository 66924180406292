import setBillingAddressOnCartMutation from "../queries/checkout/setBillingAddressOnCart.gql";
import setShippingAddressOnCartMutation from "../queries/checkout/setShippingAddressOnCart.gql";
import setShippingMethodOnCartMutation from "../queries/checkout/setShippingMethodOnCart.gql";
import setGuestEmailOnCartMutation from "../queries/checkout/setGuestEmailOnCart.gql";
import setPaymentMethodOnCartMutation from "../queries/checkout/setPaymentMethodOnCart.gql";

import getShippingMethodsQuery from "../queries/checkout/getShippingMethodsQuery.gql";
import restoreQuote from "../queries/checkout/restoreQuote.gql";
import checkoutAgreements from "../queries/checkout/checkoutAgreements.gql";
import setPaymentMethodAndPlaceOrder from "../queries/checkout/setPaymentMethodAndPlaceOrder.gql";
import setPaymentMethodAndPlaceOrderWithoutStateData from "../queries/checkout/setPaymentMethodAndPlaceOrderWithoutStateData.gql";
import createCustomerFromOrder from "../queries/checkout/createCustomerFromOrder.gql";
import estimateShippingMethodsQuery from "../queries/checkout/estimateShippingMethodsQuery.gql";
import giftcardBalanceQuery from "../queries/checkout/giftcardBalanceQuery.gql";

import boletoPayment from "../queries/checkout/paymentMethod/boletoPayment";
import idealPayment from "../queries/checkout/paymentMethod/idealPayment.gql";
import creditCardPayment from "../queries/checkout/paymentMethod/creditCardPayment";
import oneClickPayment from "../queries/checkout/paymentMethod/oneClickPayment";
import posPayment from "../queries/checkout/paymentMethod/posPayment";
import applyCoupon from "../queries/checkout/applyCoupon.gql";
import hppPayment from "../queries/checkout/paymentMethod/hppPayment";
import vertexResultQuery from "../queries/checkout/vertexResultQuery.gql";
import freePayment from "../queries/checkout/paymentMethod/freePayment";

export default ({ client, tokens }) => {
    const checkout = {};

    checkout.setBillingAddress = async ({ cartId, address = {}, addressId = null, sameAsShipping = false }) => {

        const { token = null } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {};

            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }

            const payload = {
                query: setBillingAddressOnCartMutation,
                variables: {
                    cartId: cartId,
                    address: address,
                    addressId: addressId,
                    sameAsShipping: sameAsShipping
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data.setBillingAddressOnCart.cart.billing_address ?? {});

            }).catch((error) => {
                reject(error);
            });

        });
    }

    checkout.setShippingAddress = async ({ cartId, address = {}, addressId = null, customerNotes = null }) => {

        const { token = null } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {};

            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }

            const payload = {
                query: setShippingAddressOnCartMutation,
                variables: {
                    cartId: cartId,
                    address: address,
                    addressId: addressId,
                    customerNotes: customerNotes
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                const result = data.setShippingAddressesOnCart.cart.shipping_addresses[0] ?? {};

                if (addressId) {
                    result.customerAddressId = addressId;
                }

                resolve(result);

            }).catch((error) => {
                reject(error);
            });

        });
    }

    checkout.setShippingMethod = async ({ cartId, carrier, method }) => {

        const { token = null } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {};

            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }

            const payload = {
                query: setShippingMethodOnCartMutation,
                variables: {
                    cartId: cartId,
                    shippingMethod: {
                        carrier_code: carrier,
                        method_code: method
                    }
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data.setShippingMethodsOnCart.cart ?? {});

            }).catch((error) => {
                reject(error);
            });

        });
    }

    checkout.setGuestEmail = async ({ cartId, email }) => {
        return new Promise((resolve, reject) => {
            const payload = {
                query: setGuestEmailOnCartMutation,
                variables: {
                    cartId: cartId,
                    email: email
                }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data.setGuestEmailOnCart.cart.email);

            }).catch((error) => {
                reject(error);
            });

        });
    }

    checkout.setPaymentMethod = async ({ cartId, paymentMethod }) => {

        const { token = null } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {};

            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }

            const payload = {
                query: setPaymentMethodOnCartMutation,
                variables: {
                    cartId: cartId,
                    paymentMethod: paymentMethod
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data);

            }).catch((error) => {
                reject(error);
            });

        });
    }

    checkout.placeOrder = async ({ cartId, stateData, brandConfig }, options = {}, headers = {}) => {

        const { token = null } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            if (token) {
                headers.Authorization = 'Bearer ' + token;
            }

            const brand = stateData.paymentMethod.brand;
            const brandCode = brandConfig[brand] ?? null;
            const terminalId = stateData.paymentMethod.terminal_id ?? null;

            let useStateData = true;
            let additional = null;
            switch (stateData.paymentMethod.type) {
                default:
                    console.log('Method not implemented', stateData.paymentMethod.type)
                    break;
                case 'ideal':
                    additional = idealPayment();
                    break;
                case 'scheme':
                case 'cc':
                case 'bcmc':
                    additional = creditCardPayment({
                        cc_type: brandCode
                    });
                    break;
                case 'bcmc_mobile':
                    additional = hppPayment({
                        brand_code: '"bcmc_mobile"'
                    });
                    break;
                case 'oneclick':
                    additional = oneClickPayment({
                    });
                    break;
                case 'adyen_pos_cloud':
                    useStateData = false;
                    additional = posPayment({
                        terminal_id: '"' + terminalId + '"',
                        chain_calls: true
                    });
                    break;
                case 'adyen_boleto':
                    additional = boletoPayment();
                    break;
                case 'giftcard':
                    additional = hppPayment({
                        brand_code: '"genericgiftcard"'
                    })
                    break;
                case 'paysafecard':
                    additional = hppPayment({
                        brand_code: '"paysafecard"'
                    })
                    break;
                case 'klarna':
                    additional = hppPayment({
                        brand_code: '"klarna"'
                    })
                    break;
                case 'paypal':
                    additional = hppPayment({
                        brand_code: '"paypal"'
                    })
                    break;
                case 'klarna_paynow':
                    additional = hppPayment({
                        brand_code: '"klarna_paynow"'
                    })
                    break;
                case 'klarna_account':
                    additional = hppPayment({
                        brand_code: '"klarna_account"'
                    })
                    break;
                case 'sofort':
                    additional = hppPayment({
                        brand_code: '"directEbanking"'
                    })
                    break;
                case 'giropay':
                    additional = hppPayment({
                        brand_code: '"giropay"'
                    })
                    break;
                case 'applepay':
                    additional = hppPayment({
                        brand_code: '"applepay"'
                    })
                    break;
                case 'free':
                    useStateData = false;
                    additional = freePayment({})
                    break;
            }

            let paymentMethodQuery = useStateData ? setPaymentMethodAndPlaceOrder(additional) : setPaymentMethodAndPlaceOrderWithoutStateData(additional);

            let requestVariables = {
                cartId: cartId,
                ...options
            }

            if (useStateData) {
                requestVariables.stateData =  JSON.stringify(stateData);
            }

            const payload = {
                query: paymentMethodQuery,
                variables: requestVariables
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }
                resolve(data);

            }).catch((error) => {
                reject(error);
            });

        });
    }

    checkout.cartFromOrder = async ({ orderNumber, cartId }) => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: restoreQuote,
                variables: { orderNumber, cartId }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }

                resolve(data ?? {});
            }).catch((error) => {
                reject(error);
            });

        });
    }

    checkout.getCheckoutAgreements = async () => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: checkoutAgreements
            }

            client.post('/graphql', payload).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }

                resolve(data ?? {});
            }).catch((error) => {
                reject(error);
            });
        })
    }

    checkout.getShippingMethods = async ({ cartId }) => {

        const { token = null } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {};

            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }

            const payload = {
                query: getShippingMethodsQuery,
                variables: {
                    cartId: cartId
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data?.cart?.shipping_addresses[0]?.available_shipping_methods);

            }).catch((error) => {
                reject(error);
            });

        });
    }

    checkout.estimateShippingMethods = async ({ cartId }) => {

        return new Promise((resolve, reject) => {

            const headers = {};

            const payload = {
                query: estimateShippingMethodsQuery,
                variables: {
                    cartId: cartId
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data?.estimateShipping?.items);

            }).catch((error) => {
                reject(error);
            });

        });
    }

    checkout.getVertexResults = async ({ cartId }) => {

        return new Promise((resolve, reject) => {

            const headers = {};

            const payload = {
                query: vertexResultQuery,
                variables: {
                    cartId: cartId
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data?.vertexResult);

            }).catch((error) => {
                reject(error);
            });

        });
    }

    checkout.checkGiftCardBalance = async (cardNumber, pin ) => {

        return new Promise((resolve, reject) => {

            const headers = {};

            const payload = {
                query: giftcardBalanceQuery,
                variables: {
                    number: cardNumber,
                    pin: pin
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data?.checkGiftcardBalance);

            }).catch((error) => {
                reject(error);
            });

        });
    }

    checkout.applyCoupon = async (cartId, couponCode, options) => {

        const { token = null } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            let headers = {};
            if (token) {
                headers['Authorization'] = 'Bearer ' + token
            }

            const payload = {
                query: applyCoupon(options?.custom_attributes),
                variables: {
                    cartId: cartId,
                    couponCode: couponCode
                }
            }

            client.post('/graphql', payload, headers).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }

                resolve({
                    cart: normalizeCart(data?.applyCoupon?.cart),
                    errors: data?.applyCoupon?.errors,
                    success: data?.applyCoupon?.success
                } ?? {});
            }).catch((error) => {
                reject(error);
            });
        })

    }

    checkout.createCustomerFromOrder = async ({ orderId, password }) => {
        const { token = null } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {};

            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }

            const payload = {
                query: createCustomerFromOrder,
                variables: {
                    orderId: orderId,
                    password: password
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data?.createCustomerFromOrder ?? {});

            }).catch((error) => {
                reject(error);
            });

        });
    }

    return checkout;
};

const normalizeCart = (cart) => {
    const { id, items, applied_coupons, prices, ...cartData } = cart;

    let itemsData = items.map(({ uid, configurable_options, product, quantity, prices, discounts, configured_variant, ...props }) => {

        const { sku, name, ...productData } = product;

        return {
            id: uid,
            sku: sku,
            name: name,
            prices: prices,
            discounts: discounts,
            quantity: quantity,
            configurable_options: configurable_options,
            configured_variant: configured_variant,
            ...props,
            ...productData
        };
    });

    let totalsData = {
        discounts: prices?.discounts,
        applied_taxes: prices?.applied_taxes,
        total: prices?.grand_total?.value ?? 0,
        subtotal_excluding_tax: prices?.subtotal_excluding_tax?.value ?? 0,
        subtotal: prices?.subtotal_including_tax?.value ?? 0
    };

    return {
        cartId: id,
        items: itemsData,
        totals: totalsData,
        coupons: applied_coupons,
        ...cartData
    }
}
