import { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from "react-redux";
import { useStorefront, useCart, useCustomer, useConfig } from '@kega/sps-core';

import { Accordion, AccordionItem, AccordionHeader, AccordionContent, AccordionIcon, Form } from '@kega/sps-elements';

import { Loader } from '@kega/sps-elements';
import AdyenCheckout from '@adyen/adyen-web';

import { t } from '../../../lib/translations';
import { consoleLog } from "../../../lib/utils";

import usePlaceOrder from "../../../hooks/checkout/usePlaceOrder";
import useAdyenCheckout from "../../../hooks/adyen/useAdyenCheckout";
import useTagManager from "../../../hooks/useTagManager";
import useCheckoutData from "../../../hooks/checkout/useCheckoutData";
import useSetAddresses from "../../../hooks/checkout/useSetAddresses";
import useCheckBalance from "../../../hooks/checkout/useCheckBalance";
import useStoreViews from "../../../hooks/useStoreViews";
import useVertex from "../../../hooks/checkout/useVertex";
import useLogin from "../../../hooks/customer/useLogin";

import CheckoutButton from "../../../modules/checkout/checkoutbutton/CheckoutButton";
import PromoCode from "../../cart/promo-code/PromoCode";
import BillingAddress from "../billingaddress/BillingAddress";

import { ChevronIcon } from '../../../components/icons';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { Button, Field, Input, Select } from "../../../components/form";

import '@adyen/adyen-web/dist/adyen.css';
import './adyen.override.css';

import classNames from "classnames";
import classes from './AdyenForm.module.css';

const AdyenForm = ({ methodSelected, checkboxRef, onCheckboxChange, checkoutFormRef, setMethodSelected, setSending, sending, acceptedTerms }) => {

    const { uid } = useCustomer();
    const config = useConfig();

    const { ecommerceEvent } = useTagManager();

    const { success, placeOrder, error: placeOrderError, loadingReCaptcha } = usePlaceOrder();
    const { onPaymentCompleted } = useAdyenCheckout();
    const { selectedBillingAddressType, setSelectedBillingAddressTypeState, checkoutCreateAccountData, guestEmail } = useCheckoutData();
    const { shippingAddress, billingAddress } = useSelector((state) => state.checkout);
    const { customBillingAddress, setCustomAddressForBilling } = useCheckoutData();

    const { login } = useLogin();
    const { dataLayer } = useTagManager();

    const { api: { adyen } } = useStorefront();

    const { cartId, items, totals } = useCart();
    const { essentielCheckout } = useSelector((state) => state );

    const { setAddressBilling } = useSetAddresses();
    const { isKiosk, currentStoreCode } = useStoreViews();
    const { getVertexResults } = useVertex();

    const { checkGiftcard, loading: giftCardLoading } = useCheckBalance();

    const [adyenPaymentMethods, setAdyenPaymentMethods] = useState(null);
    const [paymentMethodsInfo, setPaymentMethodsInfo] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [paymentMethodsError, setPaymentMethodsError] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(false);
    const [showPaymentBtn, setShowPaymentBtn] = useState(true);
    const [currentBillingAddress, setCurrentBillingAddress] = useState(customBillingAddress ?? null);
    const [giftcardBalance, setGiftcardBalance] = useState(null);
    const [connectedTerminals, setConnectedTerminals] = useState([]);
    const [taxError, setTaxError] = useState(false);

    const paymentContainerRef = useRef(null);
    const paymentRef = useRef(null);
    const posTerminalRef = useRef(null);

    const paymentAdditionalContainerRef = useRef(null);
    const billingFormRef = useRef(null);

    const { environment, clientKey, paymentMethods } = config.get('adyen');

    const locale = config.get('locale');
    const checkoutLocale = config.get('checkoutLocale') ?? 'en_US';
    const currency = config.get('currency');

    const posPmType = 'adyen_pos_cloud';
    const posPmName = 'Adyen POS Cloud';

    let checkoutComponent;
    let methodComponent;

    const pushLayer = (totals, items, paymentType) => {
        const defaultCurrency = config?.get('currency') ?? 'EUR';

        try {

            let data = {
                currency:     defaultCurrency,
                value:        totals.total,
                payment_type: paymentType,
                items:        []
            }

            items.forEach((item, index) => {
                const discounts = item.prices.discounts ?? null;

                data.items.push({
                    item_id:        item.sku,
                    item_name:      item.name,
                    affiliation:    "",
                    coupon:         discounts ? discounts[0].label : '',
                    discount:       discounts ? discounts[0].amount.value : 0,
                    index:          index,
                    item_brand:     "",
                    item_category:  item.att_text_perfion_productgroupcode,
                    item_list_id:   "",
                    item_list_name: "",
                    item_variant:   item.configurable_options.length > 0 ? item.configurable_options[0].value_label : '',
                    location_id:    "",
                    price:          item.price_range.minimum_price.regular_price.value,
                    quantity:       item.quantity
                })
            });

            ecommerceEvent('add_payment_info', data);
        } catch (error) {
            console.log('gtm error', 'add_payment_info', error)
            //
        }
    }

    // dependency on payment for timing
    useEffect( () => {
        setPaymentMethodsError( null  )
        if( currentStoreCode.startsWith('us')) {
            setShowPaymentBtn(false);

            getVertexResults(cartId).then( (result) => {
                if (result.success) {
                    setTaxError(false);
                    setShowPaymentBtn(true);
                }
                else {
                    setTaxError(true);
                    setPaymentMethodsError( { message: t('checkout.cart.error.tax_not_calculated') }  )
                }
            })
        }

    },[adyenPaymentMethods, shippingAddress])

    useEffect( () => {
        checkoutFormRef.current = document.querySelector('#method_' + selectedMethod + '_container')
        createConfig();
    }, [selectedMethod])

    const onBillingAddressChange = (address) => {
        setCurrentBillingAddress(address);
        setCustomAddressForBilling(address);
    }

    const checkGiftcardBalance = async ({ valid, values }) => {
        if (valid) {
            const response = await checkGiftcard(values);
            setGiftcardBalance(response?.balance ? response?.balance.value : 0);
        }
    }

    const onMethodSelect = (method) => {
        if (!sending) {
            setMethodSelected(true);
            setSelectedMethod(method);
        }
    }

    useEffect(() => {

        const getConnectedTerminals = async  () => {
            const terminals = await adyen.getConnectedTerminals(cartId);

            // demo
            // terminals.items.push({
            //     id: 'V400m-324689394',
            //     name: 'V400m-324689394'
            // });
            setConnectedTerminals(terminals.items);
        }

        const getPaymentMethods = async () => {
            try {
                setLoadingPaymentMethods(true);

                const result = await adyen.getPaymentMethods({
                    cartId: cartId,
                });

                let methods = result.adyenPaymentMethods.paymentMethodsResponse;

                if (isKiosk) {
                    methods.paymentMethods.push({
                        name : posPmName,
                        type: posPmType,
                        configuration : null,
                        details : null,
                        issuers: null,
                        brand: null,
                        brands: null
                    });
                }

                if (totals.subtotal === 0 || totals.total === 0) {
                    methods.paymentMethods.push({
                        name : 'Free',
                        type: 'free',
                        configuration : null,
                        details : null,
                        issuers: null,
                        brand: null,
                        brands: null
                    });
                }

                setPaymentMethodsInfo(result.adyenPaymentMethods.paymentMethodsExtraDetails);
                setAdyenPaymentMethods(methods);

            } catch (error) {
                setLoadingPaymentMethods(false);

                if (error.data) {
                    setPaymentMethodsError(error.data.errors);
                } else {
                    setPaymentMethodsError(error);
                }
            }
        }

        if (isKiosk) {
            getConnectedTerminals();
        }

        if (cartId) {
            getPaymentMethods();
        }

    }, [uid, cartId]);


    const onPaymentFormChange = (state) => {
        //setMethodSelected(state?.isValid);
    }

    const submitPayment = async () => {
        if (!paymentRef.current.isValid) {
            const scrollElement = document.getElementById('method_' + selectedMethod + '_container');
            scrollElement.scrollIntoView({ block: "start", behavior: "smooth" });
        } else {
            paymentRef.current.submit();
        }

        if (selectedBillingAddressType === 'billing') {
            const form = billingFormRef.current;

            if (form) {
                form.submit();
            }
        }

        if (selectedBillingAddressType === 'billing_default' || selectedBillingAddressType === 'shipping') {
            await setAddressBilling(currentBillingAddress);
        }
    }

    const createConfig = async () => {

        const configuration = {
            environment:            environment,
            clientKey:              clientKey,
            paymentMethodsResponse: adyenPaymentMethods,
            countryCode:            shippingAddress.country?.code,
            locale:                 checkoutLocale,

            onSubmit: async (state, component, actions) => {

                setSending(true);

                if (state.data.paymentMethod.type === posPmType) {
                    state.data.paymentMethod.terminal_id = posTerminalRef.current?.ref.value;
                }

                try {
                    pushLayer(totals, items, state.data.paymentMethod.type);
                } catch (error) {
                    console.log('pushlayer error', error);
                }

                try {

                    const response = await placeOrder(state.data, {
                        registerNow: checkoutCreateAccountData?.register_now,
                        newsletterSubscribe: checkoutCreateAccountData?.newsletter_subscribe
                    });

                    if (typeof response === 'undefined') {
                        setSending(false);
                    }

                    const lastOrder = response?.placeOrder?.order

                    if (lastOrder) {
                        setOrderId(response.placeOrder.order.order_id);
                        localStorage.setItem('last_order_id', response.placeOrder.order.order_id);

                        // if (checkoutCreateAccountData?.register_now) {
                        //     const token = await login({
                        //         username: guestEmail,
                        //         password: checkoutCreateAccountData?.register_now
                        //     });
                        //
                        //     if (token) {
                        //         try {
                        //             dataLayer.push({
                        //                 'event': 'login',
                        //                 'method': 'checkout'
                        //             });
                        //         } catch (error) {
                        //             //
                        //         }
                        //     }
                        // }
                    }

                    const paymentStatus = await adyen.getPaymentStatus(cartId, response.placeOrder.order.order_number);

                    if (paymentStatus) {
                        if (!paymentStatus.isFinal) {
                            setSending(true);
                            let pmtype = getPaymentMethodCode(state.data.paymentMethod);

                            if (pmtype === 'paypal') {
                                component.handleAction(JSON.parse(paymentStatus.action)).mount('#method_' + pmtype + '_container');
                            } else {
                                checkoutComponent.createFromAction(JSON.parse(paymentStatus.action)).mount('#method_' + pmtype + '_container');
                            }

                        }
                        else {
                            setSending(false);
                            await onPaymentCompleted(paymentStatus, selectedMethod);
                        }
                    } else {
                        console.log('No payment status', paymentStatus, lastOrder);
                    }

                } catch (error) {
                    setSending(false);
                    console.log(error);
                }
            },

            onChange: onPaymentFormChange,

            onPaymentCompleted: async (result) => {
                await onPaymentCompleted(result);
                setSending(false);
            },

            onAdditionalDetails: async (state, component) => {
                let data = state.data;
                const { lastOrderId } = essentielCheckout;

                data.orderId = orderId ?? lastOrderId;

                if (!data.orderId) {
                    data.orderId = localStorage.getItem('last_order_id');
                    localStorage.removeItem('last_order_id');
                }

                const response = await adyen.getPaymentDetails(cartId, JSON.stringify(data));
                await onPaymentCompleted(response.adyenPaymentDetails);
                setSending(false);

                return response;
            },

            onError: (error, component) => {
                setSending(false);
                console.error(error.name, error.message, error.stack, component);
            },

            paymentMethodsConfiguration: {

            }
        }

        if (paymentMethodsInfo) {
            paymentMethodsInfo.map((pm) => {
                if (pm.configuration) {
                    pm.type = getPaymentMethodType(pm);
                    configuration['paymentMethodsConfiguration'][pm.type] = pm.configuration;
                    configuration['paymentMethodsConfiguration']['pos_cloud'] = pm.configuration;
                    configuration['paymentMethodsConfiguration'][pm.type]['showPayButton'] = pm.type === 'paypal';
                    configuration['paymentMethodsConfiguration']['pos_cloud']['showPayButton'] = pm.type === 'paypal';
                }
            })
        }

        if ( configuration['paymentMethodsConfiguration']['applepay'] ) {
            configuration['paymentMethodsConfiguration']['applepay'].showPayButton = true;
        }

        if ( configuration['paymentMethodsConfiguration']['free'] ) {
            configuration['paymentMethodsConfiguration']['free'].showPayButton = false;
        }

        if (!checkoutComponent) {
            checkoutComponent = await AdyenCheckout(configuration);
        }

        adyenPaymentMethods?.paymentMethods?.map((pm) => {
            const pmType = getPaymentMethodType(pm);
            const pmCode = getPaymentMethodCode(pm);

            console.log('selected', selectedMethod, 'code', pmCode)
            if (selectedMethod === pmCode) {

                if (pmCode === 'paypal') {
                    configuration.intent = 'authorize';
                }

                const current = checkoutComponent.create(pmType, configuration);

                paymentRef.current = current;
                checkoutFormRef.current = current;
                methodComponent = current;

                current.mount('#method_' + pmCode + '_container');

                setShowPaymentBtn(true);

                if (pmType === 'paypal' || pmType === 'applepay') {
                    setShowPaymentBtn(false);
                } else if (pmType === 'directEbanking') {
                    setMethodSelected(pm.isValid);
                }
            }
        });

    }

    useEffect( () => {
        if (totals.subtotal === 0 || totals.total === 0) {
            setSelectedMethod('free');

            createConfig().then( () => {
                setMethodSelected(true);
            });
        } else {
            if (selectedMethod === 'free') {
                setSelectedMethod(null);
            }
        }
    },[totals])

    const getPaymentMethodCode = (method) => {
        switch (method.type) {
            case 'scheme':
                return 'card';

            case 'giftcard':
                return method.brand || 'genericgiftcard';

            default:
                return method.type;
        }
    };

    const getPaymentMethodType = (method) => {
        switch (method.type) {
            case 'scheme':
                return 'card';

            default:
                return method.type;
        }
    };

    useEffect(() => {
        if (adyenPaymentMethods !== null) {
            setLoadingPaymentMethods(false)
            createConfig();
        }

    }, [adyenPaymentMethods, paymentMethodsInfo]);

    const paymentMethodsInfoByType = useMemo(() => {
        const res = {};

        paymentMethodsInfo?.forEach((paymentMethodInfo) => {
            if (paymentMethodInfo?.type) {
                res[paymentMethodInfo.type] = paymentMethodInfo;
            }
        });

        return res;
    }, [paymentMethodsInfo]);

    const preventNumbers = (e) => {
        let keyCode = e.key;

        if (e.type === 'paste') {
            keyCode = e.clipboardData.getData('text/plain');
        }

        const isNumber = /^[0-9]*$/i.test(keyCode);

        if (!isNumber) {
            e.preventDefault();
        }
    }

    return (
        <>
            {
                paymentMethodsError !== null
                &&
                <ErrorMessage>
                    {
                        Array.isArray(paymentMethodsError)
                            ?
                            <>
                                {
                                    paymentMethodsError.map(({ message }, index) => {
                                        return <span key={'e' + index}>{message}</span>
                                    })
                                }
                            </>
                            :
                            paymentMethodsError?.message
                    }
                </ErrorMessage>
            }

            {
                loadingPaymentMethods
                    ?
                    <Loader variant="primary"/>
                    :
                    <>
                        {

                            ( adyenPaymentMethods && selectedMethod !== 'free' ) &&
                            adyenPaymentMethods.paymentMethods.map((item, index) => {
                                const pmType = getPaymentMethodCode(item);
                                const extraInfo = paymentMethodsInfoByType[pmType] ?? {};
                                const checked = selectedMethod === pmType;

                                if( (totals.subtotal === 0 || totals.total === 0) && pmType !== 'free') {
                                    return null;
                                }

                                if( (totals.subtotal > 0 || totals.total > 0) && pmType === 'free') {
                                    return null;
                                }

                                return (
                                    <div className={classNames("adyen-checkout__payment-method", (checked) ? "adyen-checkout__payment-method--selected" : "", sending ? 'disabled': '')} id={`method_` + pmType} key={`method_` + pmType}>
                                        <div className="adyen-checkout__payment-method__header" onClick={() => onMethodSelect(pmType)}>
                                            <button className="adyen-checkout__payment-method__header__title" role="radio" type="button" aria-checked={checked ? "true" : "false"} disabled={sending}>
                                                <span className={classNames("adyen-checkout__payment-method__radio", (checked) ? "adyen-checkout__payment-method__radio--selected" : "")} aria-hidden="true"></span>
                                                <span className="adyen-checkout__payment-method__image__wrapper adyen-checkout__payment-method__image__wrapper--outline">
                                                    { extraInfo?.icon?.url && <img className="adyen-checkout__payment-method__image adyen-checkout__image adyen-checkout__image--loaded" src={extraInfo?.icon?.url} alt={item.name}/> }
                                                </span>
                                                <div className="adyen-checkout__payment-method__name_wrapper">
                                                    <span className={classNames("adyen-checkout__payment-method__name", (checked) ? "adyen-checkout__payment-method__name--selected" : "")}>{item.name}</span>
                                                </div>
                                            </button>
                                        </div>
                                        <div className={classNames("adyen-checkout__payment-method__details", (checked) ? "adyen-checkout__payment-method__details--selected" : "")}>
                                            <div className="adyen-checkout__payment-method__details__content">
                                                <div id={`method_` + pmType + `_container`}></div>
                                                {
                                                    (item.type === 'giftcard' && (currency === 'EUR' || currency === 'GBP'))
                                                    &&
                                                    <>
                                                        <div className="giftcard-info">
                                                            <div dangerouslySetInnerHTML={{
                                                                __html: t('checkout.steps.payment.gifcard_info.before',
                                                                    { here: '<a href="https://pages.intersolve.nl/essentiel-' + currency + '/replace" rel="noreferrer" target="_blank">' + t('checkout.steps.payment.gifcard_info.here') + '</a>' }
                                                                )
                                                            }} />
                                                            <br/>
                                                            {t('checkout.steps.payment.gifcard_info.after')}
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    (item.type === 'adyen_pos_cloud')
                                                    &&
                                                    <div className="pos_cloud_info">
                                                        <Select name={'posId'} ref={posTerminalRef}>
                                                            {
                                                                connectedTerminals.map( (terminal) => {
                                                                    return (
                                                                        <option key={terminal.id} value={terminal.name}>{terminal.name}</option>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }

                    </>
            }

            <div className={classes.payment_container}>
                <div ref={paymentContainerRef} className="payment">

                </div>
            </div>


            <Accordion className={classes.accordion_container} onlyOne>
                <AccordionItem className={classes.accordion_item} resizeOnOpen>
                    <AccordionHeader className={classes.heading} role="heading">
                        {t('checkout.steps.payment.use_promo_code')}
                        <AccordionIcon>
                            <ChevronIcon size={8}/>
                        </AccordionIcon>
                    </AccordionHeader>
                    <AccordionContent className={classes.content}>
                        <PromoCode/>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
            <BillingAddress
                selectedBillingAddressType={selectedBillingAddressType}
                setSelectedBillingAddressType={setSelectedBillingAddressTypeState}
                billingFormRef={billingFormRef}
                onSetBillingAddressChange={onBillingAddressChange}
                currentBillingAddress={currentBillingAddress}
                customBillingAddress={customBillingAddress}
            />
            <div id="payment-additional-container" ref={paymentAdditionalContainerRef}></div>
            {showPaymentBtn &&
             <CheckoutButton
                 className={classes.mobile_payment_button}
                 onButtonPress={submitPayment}
                 methodSelected={(methodSelected || selectedMethod === 'free')}
                 checkboxRef={checkboxRef}
                 acceptedTerms={acceptedTerms}
                 onCheckboxChange={onCheckboxChange}
                 sending={loadingReCaptcha || sending}
             />}
        </>
    )
}

export default AdyenForm;
