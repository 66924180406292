import { Builder, BuilderBlocks } from "@builder.io/react/lite";
import { Container, Row, Col } from "../../../../components/grid";

import classNames from "classnames";
import classes from './Layout.module.css';

const defaultBlocks = [
    {
        '@type': '@builder.io/sdk:Element',
        component: {
            name: 'Dummy'
        },
    },
];

export const ThreeColumnGrid = (props) => {
    const { firstblock, secondblock, thirdblock, full_width, background, background_color, builderBlock, gutter } = props;

    if (firstblock.length > 0 && firstblock[0].component.name === "Dummy") {
        firstblock.shift();
    }
    if (secondblock.length > 0 && secondblock[0].component.name === "Dummy") {
        secondblock.shift();
    }
    if (thirdblock.length > 0 && thirdblock[0].component.name === "Dummy") {
        thirdblock.shift();
    }
    return (
        <div style={{ backgroundColor: background ? background_color : 'transparent' }}>
            <Container xl={!full_width ? true : null} margins g-xs={1} g-sm={3} g-md={2} g-xl={4} className={classNames('builder_grid_container', full_width ? classes.full_width : null)} >
                <Row>
                    <Col md={4} className={classes[gutter]}>
                        <BuilderBlocks
                            child
                            blocks={firstblock}
                            parentElementId={builderBlock && builderBlock.id}
                            dataPath={`component.options.firstblock`} />
                    </Col>
                    <Col md={4} className={classes[gutter]}>
                        <BuilderBlocks
                            child
                            blocks={secondblock}
                            parentElementId={builderBlock && builderBlock.id}
                            dataPath={`component.options.secondblock`} />
                    </Col>
                    <Col md={4} className={classes[gutter]}>
                        <BuilderBlocks
                            child
                            blocks={thirdblock}
                            parentElementId={builderBlock && builderBlock.id}
                            dataPath={`component.options.thirdblock`} />
                    </Col>
                </Row>
            </Container >
        </div>
    )
};

Builder.registerComponent(ThreeColumnGrid, {
    name: "Three columns",
    static: true,
    defaultStyles: {
        marginTop: "0px",
    },
    inputs: [
        {
            name: 'firstblock',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'secondblock',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'thirdblock',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'full_width',
            type: 'boolean',
            defaultValue: false,
        },
        {
            name: 'background',
            type: "boolean"
        },
        {
            type: 'color',
            name: 'background_color',
            friendlyName: 'Background color',
            defaultValue: '#F1F1F1',
        },
        {
            type: 'string',
            name: 'gutter',
            friendlyName: 'Gutter between elements',
            required: true,
            enum: [
                {
                    label: 'Keep grid',
                    value: 'grid',
                },
                {
                    label: 'Small gutter (2px)',
                    value: 'sm_gutter',
                },
                {
                    label: 'No gutter',
                    value: 'no_gutter',
                }
            ],
            defaultValue: 'grid',
        },
    ],
});