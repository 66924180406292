import { Builder, BuilderBlocks } from "@builder.io/react/lite";
import { Container, Row, Col } from "../../../../components/grid";

import classNames from "classnames";
import classes from './Layout.module.css';

const defaultBlocks = [
    {
        '@type': '@builder.io/sdk:Element',
        component: {
            name: 'Dummy'
        },
    },
];

export const SixColumnGrid = (props) => {
    const { firstblock, secondblock, thirdblock, fourthblock, fifthblock, sixthblock, full_width, background, background_color, builderBlock, gutter } = props;

    if (firstblock.length > 0 && firstblock[0].component.name === "Dummy") {
        firstblock.shift();
    }
    if (secondblock.length > 0 && secondblock[0].component.name === "Dummy") {
        secondblock.shift();
    }
    if (thirdblock.length > 0 && thirdblock[0].component.name === "Dummy") {
        thirdblock.shift();
    }
    if (fourthblock.length > 0 && fourthblock[0].component.name === "Dummy") {
        fourthblock.shift();
    }
    if (fifthblock.length > 0 && fifthblock[0].component.name === "Dummy") {
        fifthblock.shift();
    }
    if (sixthblock.length > 0 && sixthblock[0].component.name === "Dummy") {
        sixthblock.shift();
    }
    return (
        <div style={{ backgroundColor: background ? background_color : 'transparent' }}>
            <Container xl={!full_width ? true : null} margins g-xs={1} g-sm={3} g-md={2} g-xl={4} className={classNames('builder_grid_container', full_width ? classes.full_width : null)} >
                <Row>
                    <Col md={2} className={classes[gutter]}>
                        <BuilderBlocks
                            child
                            blocks={firstblock}
                            parentElementId={builderBlock && builderBlock.id}
                            dataPath={`component.options.firstblock`} />
                    </Col>
                    <Col md={2} className={classes[gutter]}>
                        <BuilderBlocks
                            child
                            blocks={secondblock}
                            parentElementId={builderBlock && builderBlock.id}
                            dataPath={`component.options.secondblock`} />
                    </Col>
                    <Col md={2} className={classes[gutter]}>
                        <BuilderBlocks
                            child
                            blocks={thirdblock}
                            parentElementId={builderBlock && builderBlock.id}
                            dataPath={`component.options.thirdblock`} />
                    </Col>
                    <Col md={2} className={classes[gutter]}>
                        <BuilderBlocks
                            child
                            blocks={fourthblock}
                            parentElementId={builderBlock && builderBlock.id}
                            dataPath={`component.options.fourthblock`} />
                    </Col>
                    <Col md={2} className={classes[gutter]}>
                        <BuilderBlocks
                            child
                            blocks={fifthblock}
                            parentElementId={builderBlock && builderBlock.id}
                            dataPath={`component.options.fifthblock`} />
                    </Col>
                    <Col md={2} className={classes[gutter]}>
                        <BuilderBlocks
                            child
                            blocks={sixthblock}
                            parentElementId={builderBlock && builderBlock.id}
                            dataPath={`component.options.sixthblock`} />
                    </Col>
                </Row>
            </Container >
        </div>
    )
};

Builder.registerComponent(SixColumnGrid, {
    name: "Six columns",
    static: true,
    defaultStyles: {
        marginTop: "0px",
    },
    inputs: [
        {
            name: 'firstblock',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'secondblock',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'thirdblock',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'fourthblock',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'fifthblock',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'sixthblock',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'full_width',
            type: 'boolean',
            defaultValue: false,
        },
        {
            name: 'background',
            type: "boolean"
        },
        {
            type: 'color',
            name: 'background_color',
            friendlyName: 'Background color',
            defaultValue: '#F1F1F1',
        },
        {
            type: 'string',
            name: 'gutter',
            friendlyName: 'Gutter between elements',
            required: true,
            enum: [
                {
                    label: 'Keep grid',
                    value: 'grid',
                },
                {
                    label: 'Small gutter (2px)',
                    value: 'sm_gutter',
                },
                {
                    label: 'No gutter',
                    value: 'no_gutter',
                }
            ],
            defaultValue: 'grid',
        },
    ],
});