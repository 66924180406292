import { useCustomer } from '@kega/sps-core';

import { t } from '../../../lib/translations';

import useFetchCustomer from "../../../hooks/customer/useFetchCustomer";

import { Col, Row, Container } from '../../../components/grid';

import User from './user/User';
import RecentOrders from './recent_orders/RecentOrders';
import Addresses from './addresses/Addresses';

import classes from "./Overview.module.css";
import { useEffect } from "react";

const Overview = () => {
    const { authenticated } = useCustomer();
    const { customer, fetchCustomerData } = useFetchCustomer();

    useEffect(() => {
        fetchCustomerData();
    }, []);

    return (
        <Container xl margins g-xs={0} g-sm={1}>
            <h1>
                {t('account.overview.title')}
            </h1>
            {
                !authenticated
                    ?
                    <Row>
                        <Col>
                            <h1>{t('account.not_logged_in')}</h1>
                        </Col>
                    </Row>
                    :
                    <>
                        <Row className={classes.row}>
                            <Col>
                                <User customer={customer} />
                            </Col>
                        </Row>
                        <Row className={classes.row}>
                            <Col>
                                <RecentOrders />
                            </Col>
                        </Row>
                        <Row className={classes.row}>
                            <Col>
                                <Addresses customer={customer} />
                            </Col>
                        </Row>
                    </>
            }
        </Container>
    )
}

export default Overview;