import { connectStateResults } from 'react-instantsearch-dom';

import { t } from '../../../lib/translations';

import classes from './Stats.module.css';

const Stats = ({ searchResults, countOnly = false }) => {
    const { nbHits = 0, hitsPerPage = 0, page = 0, hits = [] } = searchResults ?? {};

    const startNumber = (page) * hitsPerPage;
    const toNumber = startNumber + hits.length;

    return (
        !countOnly
            ?
            <div className={classes.stats_wrapper}>
                <span className={classes.stats_text}> {t('productlist.stats.showing')}<>&nbsp;</> {toNumber} <>&nbsp;</>{t('productlist.stats.from')}<>&nbsp;</> {nbHits} <>&nbsp;</>{t('productlist.stats.products')}</span>
                <div className={classes.progress_bar}>
                    <div className={classes.progress_completed} style={{ width: toNumber / nbHits * 100 + '%' }}></div>
                </div>
            </div>
            :
            <div className={classes.root_count}>
                {t('checkout.cart.items', { count: nbHits })}
            </div>
    )
}

export default connectStateResults(Stats)