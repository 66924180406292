import { useRequest, useStorefront } from '@kega/sps-core';
import { useDispatch } from "react-redux";

import { addCartId } from "@kega/sps-core/src/reducers/cart/actions";

const useCartSync = () => {
    const dispatch = useDispatch();

    const setCurrentCartId = (cartId) => {

        try {
            dispatch(addCartId(cartId));
        } catch (error) {
            return false
        }

        return true;
    };

    const compareCartToHash = (cart, hash) => {
        const cartHash = cartToHash(cart)
        return cartHash == hash;
    }

    const cartToHash = (cart) => {
        return  cart.items.map( (item) => { return item.quantity.toString(); } ).join('-');
    }
    return {
        setCurrentCartId,
        compareCartToHash,
        cartToHash
    };
}

export default useCartSync;
