import { Helmet } from 'react-helmet-async';

import { useConfig } from '@kega/sps-core';
import { Loader } from '@kega/sps-elements';

import ScrollToTop from "../../utils/ScrollToTop";

import BuilderPage from '../../modules/builder/BuilderPage';
import BuilderSection from '../../modules/builder/BuilderSection';
import NotFound from '../error/notfound/NotFound';
import useStoreViews from "../../hooks/useStoreViews";

const Home = () => {
    const { currentStoreCode, storeViews } = useStoreViews();


    return (
        <div style={{ overflow: 'hidden' }}>
            <Helmet>
                <link rel="canonical" href={window.location.href} />

                {
                    storeViews
                    &&
                    Object.entries(storeViews).map((storeCode) => {
                        const href = `/${storeCode[0]}`;
                        if (!storeCode[0].endsWith('_kiosk')) {
                            return (
                                <link
                                    key={href}
                                    rel="alternate"
                                    hrefLang={storeCode[1].hreflang}
                                    href={window.location.origin + href}/>
                            );
                        }
                    })
                }

            </Helmet>

            <ScrollToTop />
            <BuilderPage
                model="page"
                url={'homepage_essentiel_antwerp'}
                renderLoading={() => <Loader />}
                renderNotFound={() => <NotFound />}
                setCanonicalUrl={false}
            />
        </div>
    )
}

export default Home;