import { Builder, BuilderBlocks } from "@builder.io/react/lite";

import { Accordion, AccordionContent, AccordionHeader, AccordionIcon, AccordionItem, AccordionItemContext } from '@kega/sps-elements';
import { ChevronIcon } from "../../../../components/icons";

import classes from './BuilderAccordion.module.css';
import { t } from "../../../../lib/translations";

const defaultBlocks = [
    {
        '@type': '@builder.io/sdk:Element',
        component: {
            name: 'Dummy'
        },
    },
];

export const BuilderAccordion = (props) => {
    const { always_open = false, Type, icon_size, tab_item, builderBlock, one_open = false } = props;

    return (
        <>
            {
                tab_item !== undefined
                    ?
                    <Accordion onlyOne={one_open} >

                        {tab_item.map((tab_item, index) => {
                            let block = props[`block${index}`];

                            if(!block) {
                                return null;
                            }

                            if (block.length > 0 && block[0].component.name === "Dummy") {
                                block.shift();
                            }

                            return (
                                <AccordionItem key={index} alwaysOpen={tab_item.always_open} className={classes.accordion_item}>
                                    <AccordionHeader>
                                        <Type className={classes.accordion_title}>{ t(tab_item.name) }</Type>
                                        {
                                            !always_open
                                            &&
                                            <AccordionIcon>
                                                <ChevronIcon title={"Chevron"} size={icon_size} />
                                            </AccordionIcon>
                                        }
                                    </AccordionHeader>
                                    <AccordionContent className={classes.content}>
                                        <BuilderBlocks
                                            child
                                            blocks={block}
                                            parentElementId={builderBlock && builderBlock.id}
                                            dataPath={`component.options.block${index}`} />
                                    </AccordionContent>
                                </AccordionItem>
                            )
                        })}


                    </Accordion>
                    :
                    <div>
                        Configure accordion
                    </div>
            }
        </>
    )
}

Builder.registerComponent(BuilderAccordion, {
    name: 'accordion',
    friendlyName: 'Accordion',
    defaultStyles: {
        marginTop: '0px',
    },
    inputs: [
        {
            name: 'tab_item',
            friendlyName: 'Tab',
            type: 'list',
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                    defaultValue: 'Tab name'
                },
                {
                    name: 'always_open',
                    friendlyName: 'Always Open',
                    type: 'boolean',
                    defaultValue: false,
                }
            ],
            onChange: (options) => {
                if (options.get('tab_item').length > 20) {
                    options.set('tab_item', options.get('tab_item').slice(0, 20));
                }
            },
            helperText: 'Max 20 tabs can be added.'
        },
        {
            type: 'string',
            name: 'Type',
            required: true,
            enum: [
                {
                    label: 'H2',
                    value: 'h2',
                },
                {
                    label: 'H3',
                    value: 'h3',
                },
                {
                    label: 'H4',
                    value: 'h4',
                },
                {
                    label: 'H5',
                    value: 'h5',
                },
                {
                    label: 'H6',
                    value: 'h6',
                }
            ],
            defaultValue: 'h3',
        },
        {
            name: 'one_open',
            friendlyName: 'One tab open at once',
            type: 'boolean',
            defaultValue: false,
        },
        {
            name: 'icon_size',
            friendlyName: 'Accordion icon size',
            type: 'int',
            required: true,
            defaultValue: 8,
        },
        {
            name: 'block0',
            type: 'uiBlocks',
            hideFromUI: true,
            defaultValue: defaultBlocks
        },
        {
            name: 'block1',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block2',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block3',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block4',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block5',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block6',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block7',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block8',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block9',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block10',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block11',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block12',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block13',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block14',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block15',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block16',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block17',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block18',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block19',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
    ]
});