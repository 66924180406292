import { useState, useEffect, useRef } from 'react';

import useWishlistList from '../../../hooks/wishlist/useWishlistList';
import useFetchWishlist from '../../../hooks/wishlist/useFetchWishlist';
import useRemoveFromWishlist from '../../../hooks/wishlist/useRemoveFromWishlist';
import useUi from '../../../hooks/useUi';

import ProductTile from '../../../components/product/ProductTile';

import { t } from '../../../lib/translations';

import DeleteItemModal from './delete_item_modal/DeleteItemModal';
import AddToCartModal from './add_to_cart_modal/AddToCartModal';

import { Col, Row, Container } from '../../../components/grid';
import { CrossIcon } from '../../../components/icons';

import { Button } from '../../../components/form';

import classNames from "classnames";
import classes from "./Wishlist.module.css";

const Wishlist = () => {
    const getWishlistList = useWishlistList();
    const { fetchWishlist } = useFetchWishlist();
    const { loading, removeFromWishlist } = useRemoveFromWishlist();
    const { openAddToCartDrawer } = useUi();

    const [selectedItem, setSelectedItem] = useState();
    const [addToCartProduct, setAddToCartProduct] = useState();

    const modalRef = useRef();

    useEffect(() => {
        fetchWishlist();
    }, [fetchWishlist]);

    let wishlistList = getWishlistList();

    const openModal = (item) => {
        setSelectedItem(wishlistList[item.sku].id);
        modalRef.current?.open();
    };

    const AddToCart = (product) => {
        openAddToCartDrawer();
        setAddToCartProduct(product);

    };

    return (
        <Container xl margins g-xs={0} g-sm={1}>
            <Row>
                <Col>
                    <h1>{t('wishlist.title')}</h1>
                </Col>
            </Row>
            <Row>
                <Col className={classes.col}>
                    <div className={classNames(classes.root, Object.keys(wishlistList).length === 0 && classes.empty)}>
                        {
                            Object.keys(wishlistList).length > 0
                                ?
                                Object.values(wishlistList).map(({ product }, index) =>
                                    <div className={classes.item} key={index}>
                                        <div onClick={() => openModal(product)} className={classes.remove_button} role="button" tabIndex="0">
                                            <CrossIcon title={"Wishlist"} size={14} color="#9C9EA1" />
                                        </div>
                                        <ProductTile product={product} />
                                        <div className={classes.actions}>
                                            <Button variant="secondary" onClick={() => AddToCart(product)} className={classes.atc_btn}>
                                                {
                                                    product.stock_status === 'OUT_OF_STOCK'
                                                        ?
                                                        <>{t('wishlist.button.notify')}</>
                                                        :
                                                        <>{t('wishlist.button.add_to_cart')}</>
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                )
                                :
                                <span>{t('wishlist.no_products')}</span>
                        }
                    </div>
                </Col>
            </Row>

            <DeleteItemModal
                ref={modalRef}
                item={selectedItem}
                endpoint={removeFromWishlist}
                loading={loading} />

            {
                addToCartProduct && <AddToCartModal product={addToCartProduct} />
            }
        </Container>
    )
}

export default Wishlist;
