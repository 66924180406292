const adyenPaymentDetailsQuery = `
mutation getAdyenPaymentDetails($payload: String!, $cartId: String!) {
        adyenPaymentDetails(payload: $payload, cart_id: $cartId) {
          isFinal
          resultCode
          additionalData
          action
        }
      }`;

export default adyenPaymentDetailsQuery;
