const getCategoryListQuery = (attributes = []) => `
    query getCategoryList($parent_id: String!) {
        categoryList(
            filters: {
                parent_id: {
                    eq: $parent_id
                }
            }
        ) {
            ...CategoryTreeFragment
            children {
                ...CategoryTreeFragment
                children {
                    ...CategoryTreeFragment
                }
            }
        }
    }
    
    fragment CategoryTreeFragment on CategoryTree {
        id
        level
        name
        description
        path
        url_path
        url_key
        canonical_url
        include_in_menu
        meta_title
        meta_keywords 
        meta_description
        ${attributes.join('\n')}
    }
`;

export default getCategoryListQuery;