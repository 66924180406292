import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRequestResetPassword } from '@kega/sps-core';
import { Form } from '@kega/sps-elements';

import { t } from '../../../lib/translations';

import { Col, Row, Container } from '../../../components/grid';
import { Button, Input, Field } from '../../../components/form';

import useStoreViews from "../../../hooks/useStoreViews";
import useSystemMessage from "../../../hooks/useSystemMessage";
import classes from "../login/Login.module.css";

const ForgotPassword = () => {
    const { error, loading, requestResetPassword } = useRequestResetPassword();
    const { setSystemSuccessMessage, setSystemErrorMessage } = useSystemMessage();


    const navigate = useNavigate();
    const { getUrl } = useStoreViews();

    const params = new URLSearchParams(window.location.search);
    const tokenError = params.get('token_error');

    useEffect(() => {

        if (tokenError) {
            setSystemErrorMessage(t('account.forgot_password.invalid_token'));
        }

    },[tokenError])

    const onSubmit = async ({ valid, values, resetForm }) => {
        if (valid) {
            try {
                await requestResetPassword(values);

                if (!error) {
                    resetForm();
                    
                    navigate(getUrl('account/login'));
                    setSystemSuccessMessage(t('account.forgot_password.success'));
                }
            } catch (error) {
                // Ignore error
            }
        }

    };

    return (
        <Container xl margins g-xs={1} g-sm={3} g-md={2} g-xl={4} className={classes.root}>
            <Row>
                <Col>
                    <h1 className={classes.title} role="heading">{t('account.forgot_password.title')}</h1>

                    <Form onSubmit={onSubmit}>
                        <Row>
                            <Field>
                                <Input name="email" type="email" label={t('customer.form.email')} placeholder={t('customer.form.email')} required />
                            </Field>
                        </Row>
                        <Row>
                            <Field>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    loading={loading}
                                >
                                    {t('account.forgot_password.button')}
                                </Button>

                            </Field>
                        </Row>

                    </Form>
                    {error ? error.message : ''}
                </Col>
            </Row>

        </Container>
    )
}

export default ForgotPassword;
