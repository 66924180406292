import { useState, useEffect } from 'react';

const isBrowser = typeof window !== 'undefined';

const getScrollbarWidth = () => {
    return isBrowser ? window.innerWidth - document.documentElement.clientWidth : 0;
}

const useScrollLock = (initialLocked = false) => {

    const [locked, setLocked] = useState(initialLocked);

    useEffect(() => {

        if (!locked) {
            return undefined;
        }

        // Save initial body style
        const originalOverflow = document.body.style.overflow;
        const originalPaddingRight = document.body.style.paddingRight;

        // Get the scrollBar width
        const scrollBarWidth = getScrollbarWidth();

        // Avoid width reflow
        if (scrollBarWidth) {
            document.body.style.paddingRight = `${scrollBarWidth}px`;
        }

        // Lock body scroll
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';

        return () => {
            if (scrollBarWidth) {
                document.body.style.paddingRight = originalPaddingRight;
            }

            document.body.style.overflow = originalOverflow;
            document.documentElement.style.overflow = null;
        }

    }, [locked]);

    // Update state if initialValue changes
    useEffect(() => {
        if (locked !== initialLocked) {
            setLocked(initialLocked);
        }

    }, [initialLocked]);

    return {
        locked,
        setLocked
    };
}

export default useScrollLock;
