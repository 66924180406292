import { useState, useEffect, useMemo } from 'react';

const useInViewport = (ref, enabled) => {

    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && !isIntersecting) {
                    setIsIntersecting(entry.isIntersecting)
                }
            }
            ),
        [],
    );

    useEffect(() => {
        if (enabled) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return {
        isIntersecting
    };
}

export default useInViewport;
