import { useConfig } from "@kega/sps-core";

import { t } from '../../lib/translations';

const useInputValidation = () => {
    const config = useConfig();
    const { validation: { password: passwordConfig } } = config.get('form');
    const { validation: { zipcode: zipcodeConfig } } = config.get('form');
    const { validation: { telephone: telephoneConfig } } = config.get('form');
    const { validation: { address: addressConfig } } = config.get('form');

    const maxLength = (length) => (
        (value) => (value && length && value.length > length)
            ? t('form.validation.max_length', { length })
            : null
    );

    const email = (value) => {
        const maxLengthError = maxLength(100)(value);

        if (maxLengthError) {
            return maxLengthError;
        }

        if (value.length === 0) {
            return t('form.validation.email');
        }

        return value
            ? (/^[a-zA-Z0-9. _\-+]*[a-zA-Z0-9 -]@[a-zA-Z0-9 -][a-zA-Z0-9. -]*[a-zA-Z0-9 -]\.[a-zA-Z]{2,4}$/.test(value) ? false : t('form.validation.email'))
            : false;
    };

    const noSpecialChars = (allowDigits = false, allowSpaces = true, allowChars = '') => {
        const regex = new RegExp(`[^A-Za-z${allowDigits ? '0-9' : ''}${allowSpaces ? ' ' : ''}${allowChars}]`);
        const msgAllowedChars = ['A-Z', 'a-z'];

        if (allowDigits) {
            msgAllowedChars.push('0-9');
        }

        if (allowChars) {
            allowChars.split('').forEach(allowChar => msgAllowedChars.push(allowChar));
        }

        return (value) => (
            (typeof value === 'string' && regex.test(value))
                ? t('form.validation.noSpecialChars', { chars: msgAllowedChars.join(' ') })
                : null
        );
    };

    const passwordValidation = (value) => {
        if (!value) {
            return null;
        }

        if (passwordConfig?.minLength && value.length < passwordConfig.minLength) {
            return t('form.validation.password.minLength', { length: passwordConfig.minLength });
        }

        if (passwordConfig?.maxLength && value.length > passwordConfig.maxLength) {
            return t('form.validation.password.maxLength', { length: passwordConfig.maxLength });
        }

        if (passwordConfig?.requiredCharClasses) {
            let counter = 0;

            if (value.match(/[0-9]+/)) {
                counter++;
            }

            if (value.match(/[A-Z]+/)) {
                counter++;
            }

            if (value.match(/[a-z]+/)) {
                counter++;
            }

            if (value.match(/[^a-zA-Z0-9]+/)) {
                counter++;
            }

            if (counter < passwordConfig.requiredCharClasses) {
                return t('form.validation.password.requiredCharClasses', { amount: passwordConfig.requiredCharClasses });
            }
        }

        return null;
    };

    const confirmField = (confirmFieldRef, customError) => {
        return (value) => {
            const inputRef = confirmFieldRef.current?.ref ?? confirmFieldRef.current;
            const confirmValue = inputRef?.value;

            if (value && confirmValue && value !== confirmValue) {
                return customError ?? t('form.validation.confirm', { field: inputRef?.name || '' });
            }

            return null;
        };
    };

    const zipcode = (countryFieldRef, customError) => {

        return (value) => {
            const maxLengthError = maxLength(11)(value);

            if (maxLengthError) {
                return maxLengthError;
            }

            const countryRef = countryFieldRef.current?.ref ?? countryFieldRef.current;
            const countryValue = countryRef?.value?.toUpperCase();
            const countryConfig = countryValue ? zipcodeConfig[countryValue] : null;

            if (!countryConfig) {
                return null
            }

            const regex = new RegExp(countryConfig.pattern);

            return !regex.test(value) ? t(zipcodeConfig.error ?? 'account.address.form.validation.zipcode.default') :
                null;

        };
    };

    const telephone = (countryFieldRef, customError) => {

        return (value) => {
            const maxLengthError = maxLength(30)(value);

            if (maxLengthError) {
                return maxLengthError;
            }

            if(!countryFieldRef) {
                return null
            }

            const countryRef = countryFieldRef.current?.ref ?? countryFieldRef.current;
            const countryValue = countryRef?.value;
            let countryConfig = countryValue ? telephoneConfig[countryValue] : null;

            if (!countryConfig) {
                // try to get general validation
                countryConfig = telephoneConfig['default'];
                if (!countryConfig) {
                    return null
                }
            }

            const regex = new RegExp(countryConfig.pattern);

            return !regex.test(value) ? t(countryConfig.error ?? 'account.address.form.validation.telephone.default') : null;

        };
    };
    const addressValidation = (countryFieldRef, customError) => {

        return (value) => {

            const countryRef = countryFieldRef.current?.ref ?? countryFieldRef.current;
            const countryValue = countryRef?.value;
            let countryConfig = countryValue ? addressConfig[countryValue] : null;

            if (!countryConfig) {
                // try to get general validation
                countryConfig = addressConfig['default'];
                if (!countryConfig) {
                    return null
                }
            }

            const regex = new RegExp(countryConfig.pattern);

            return !regex.test(value) ? t(countryConfig.error ?? 'account.address.form.validation.address.default') : null;

        };
    };

    const requiredField = (value) => value ? (value.length === 0 ? t('form.validation.required_field') : null) : t('form.validation.required_field');

    const containsNoString = (value) => (
        /^\d+$/.test(value) ? t('form.validation.containsNoString') : null
    );

    return {
        noSpecialChars,
        passwordValidation,
        confirmField,
        zipcode,
        telephone,
        requiredField,
        email,
        containsNoString,
        firstname: [ maxLength(17) ],
        lastname: [ maxLength(17) ],
        company: [ maxLength(100) ],
        street: [ maxLength(50) ],
        city: [ maxLength(30) ],
        taxvat: [ maxLength(15) ],
        addressValidation
    }
}

export default useInputValidation;
