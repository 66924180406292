import React, { Component } from "react";
import Error from "../../pages/error/error/Error";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (nextState.hasError !== this.state.hasError) || (nextState.hasError && this.state.hasError);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.path !== this.props.path) && (prevState.hasError && this.state.hasError)) {
            this.setState({
                hasError: false
            })
        }
    }

    render() {
        return this.state.hasError
            ? <Error/>
            : this.props.children;
    }
}

export default React.memo(ErrorBoundary);