import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addStoreOrders } from '../../redux/customer/actions';
import { useRequest, useStorefront } from '@kega/sps-core';

const useStoreOrders = () => {
    const { api: { essentielCustomer } } = useStorefront();
    const dispatch = useDispatch();

    const { store_orders } = useSelector((state) => state.customer);

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const fetchStoreOrders = useCallback( async () => {
        try {
            setRequest();

            const store_orders = await essentielCustomer.getStoreOrders();
           
            if (store_orders) {
                dispatch(addStoreOrders(store_orders));
            }

            setSuccess();
        } catch (error) {
            console.log('[FetchStoreOrders]', error);
            setError(error);
        }
    }, [])

    return {
        error,
        loading,
        store_orders,
        fetchStoreOrders
    };
}

export default useStoreOrders;