const getProducts = (attributes = []) => `
    query getProducts($url_key: [String!]) {
        products(
            filter: {
                url_key: {
                    in: $url_key
                }
            }
        ) {
            items {
                __typename
                id
                sku
                url_key
                name
                stock_status
                swatch_image
                imgproductswatch
                perfion_filtercolour
                perfion_colourdescription
                news_from_date
                news_to_date
                media_gallery {
                    url
                }
                image {
                    url
                }
                description {
                    html
                }
                price_range {
                    minimum_price {
                        regular_price {
                            currency
                            value
                        }
                        final_price {
                            currency
                            value
                        }
                    }
                }
                special_price
                special_to_date
                meta_title
                meta_keyword
                meta_description
                ... on ConfigurableProduct {

                    configurable_options {
                        attribute_code
                        attribute_uid
                        label
                        position
                        values {
                            default_label
                            label
                            swatch_data { value }
                            uid
                        }
                    }

                    variants {

                        attributes {
                            code
                            label
                            uid
                            value_index
                        }

                        product {
                            id
                            sku
                            url_key
                            name
                            size
                            price_range {
                                minimum_price {
                                    regular_price {
                                        currency
                                        value
                                    }
                                }
                            }
                            special_price
                            special_to_date
                            stock_status
                        }
                    }
                }
                related_products {
                    id
                    sku
                    url_key
                    name
                    stock_status
                    swatch_image
                    media_gallery {
                        url
                    }
                    image {
                        url
                    }
                }
                upsell_products {
                    id
                    sku
                    url_key
                    name
                    stock_status
                    swatch_image
                    media_gallery {
                        url
                    }
                    image {
                        url
                    }
                }
                ... on CustomizableProductInterface {
                    options {
                      title
                      required
                      sort_order
                      option_id
                      ... on CustomizableFieldOption {
                        value {
                          uid
                          sku
                          price
                          price_type
                          max_characters
                        }
                      }
                    }
                  }


                ${attributes.join('\n')}
            }
        }
    }
`;

export default getProducts;
