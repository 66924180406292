import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addOrders } from '../../redux/customer/actions';
import { useRequest, useStorefront } from '@kega/sps-core';

const useOrders = (options = {}) => {
    const { api: { essentielCustomer } } = useStorefront();
    const dispatch = useDispatch();

    const { orders, ordersSearchCriteria } = useSelector((state) => state.customer);
    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const fetchOrders = useCallback( async (searchCriteria) => {
        try {
            setRequest();

            const result = await essentielCustomer.getOrders(options, searchCriteria);
            const payload = Array.isArray(result)
                ? { orders: result }
                : result;

            if (payload) {
                dispatch(addOrders(payload));
            }

            setSuccess();
        } catch (error) {
            console.log('[FetchOrders]', error);
            setError(error);
        }
    }, [])

    return {
        error,
        loading,
        orders,
        searchCriteria: ordersSearchCriteria,
        fetchOrders,
        defaults: {
            pageSize: 5,
        }
    };
}

export default useOrders;