import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { reducers } from '@kega/sps-core';

import storage from 'redux-persist/lib/storage';

import customer from './customer/reducer'; // TODO Remove once fix for EA-411 is in place
import essentielCheckout from './checkout/reducer';
import ui from './ui/reducer';
import usp from './usp/reducer';
import wishlist from './wishlist/reducer';
import plp from './plp/reducer';
import product_alert from './product_alert/reducer';
import cache from './cache/reducer';
import system_message from './system_message/reducer';
import algolia_results from './algolia_results/reducer';
import algolia from './algolia/reducer';
import geoip from './geoip/reducer';
import newsletter_overlay from './newsletter_overlay/reducer';
import config from './config/reducer';
import store_config from './store_config/reducer';

reducers.customer = customer; // TODO Remove once fix for EA-411 is in place
reducers.ui = ui;
reducers.usp = usp;
reducers.wishlist = wishlist;
reducers.plp = plp;
reducers.product_alert = product_alert;
reducers.cache = cache;
reducers.system_message = system_message;
reducers.algolia_results = algolia_results;
reducers.algolia = algolia;
reducers.geoip = geoip;
reducers.essentielCheckout = essentielCheckout;
reducers.newsletter_overlay = newsletter_overlay;
reducers.storeConfig = store_config;
reducers.config = config;

const rootReducer = combineReducers(reducers);

const persistedReducer = persistReducer({
    key: 'sp-store',
    version: 1,
    storage: storage,
    whitelist: ['customer', 'cart', 'wishlist', 'cache', 'algolia_results', 'algolia', 'geoip', 'checkout', 'essentielCheckout', 'newsletter_overlay', 'config', 'storeConfig']
}, rootReducer);

const configureStore = () => {
    const store = createStore(persistedReducer);
    const persistor = persistStore(store);
    return { store, persistor }
}

export default configureStore;
