import addProductStockAlertMutation from "../queries/product_alert/addProductStockAlert.gql";
import getProductStockAlertsQuery from "../queries/product_alert/getProductStockAlerts.gql";
import removeProductStockAlertMutation from "../queries/product_alert/removeProductStockAlert.gql";

import parseErrors from "@kega/sps-connector-magento2/src/utils/parseErrors";

export default ({ client, tokens }) => {
    const api = {};

    api.addProductStockAlert = async ({ email, customer_id, product_id, parent_id }) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: addProductStockAlertMutation,
                variables: {
                    email: email,
                    customer_id: customer_id,
                    product_id: product_id,
                    parent_id: parent_id
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(parseAddResponse(data));

            }).catch((error) => {
                reject(error);
            });
        });
    }

    api.getProductStockAlerts = async () => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: getProductStockAlertsQuery
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(parseGetResponse(data));

            }).catch((error) => {
                reject(error);
            });
        });
    }

    api.removeProductStockAlert = async (id) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: removeProductStockAlertMutation,
                variables: {
                    alert_id: id
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(parseRemoveResponse(data));

            }).catch((error) => {
                reject(error);
            });
        });
    }

    return api;
};

const parseAddResponse = (response) => {
    return response?.addProductStockAlert || {};
};

const parseGetResponse = (response) => {
    const alerts = (response?.getProductStockAlerts)
        ? [...response.getProductStockAlerts]
        : [];
    let result = {};

    alerts.forEach((alert) => {
        result[alert.id] = alert;
    });

    return result;
};

const parseRemoveResponse = (response) => {
    return response?.removeProductStockAlert || '';
};
