const vertexResultQuery = `
    query getVertexResult($cartId: String!){
      vertexResult(cart_id: $cartId) {
        success
        items {
          result
          tax_amount
          tax_rate
        }
      }
}`;

export default vertexResultQuery;