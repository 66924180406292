import { useRequest, useStorefront } from '@kega/sps-core';

import { t } from '../lib/translations';
import useSystemMessage from "./useSystemMessage";

const useFetchUpsellProducts = () => {
    const { api: { catalog } } = useStorefront();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();
    const { setSystemErrorMessage } = useSystemMessage();

    const fetchUpsellProducts = async (sku) => {
        try {
            setRequest();

            const result = await catalog.getUpsellProducts(sku);

            if (result) {
                setSuccess();

                return {
                    success: true,
                    result: result.getUpsellProducts
                };
            } else {
                setSystemErrorMessage(t('Something went wrong, please try again later'));
                setError(new Error(t('Something went wrong, please try again later')));
            }
        } catch (error) {
            console.log('[fetchUpsellProducts]', error);
            setError(error);
        }

        return {
            success: false,
            result: {}
        };
    };

    return {
        error,
        loading,
        fetchUpsellProducts
    };
}

export default useFetchUpsellProducts;
