import { useRequest, useStorefront } from '@kega/sps-core';

const useUpdateNewsletter = () => {
    const { api: { essentielCustomer } } = useStorefront();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const updateStatus = async (status) => {
        try {
            setRequest();
            const result = await essentielCustomer.updateCustomerNewsletter(status);
            setSuccess();

            return result;

        } catch (error) {
            console.log('[useUpdateNewsletter] ', error);
            setError(error);

            return null
        }

    };

    return {
        error,
        loading,
        updateStatus
    };
}

export default useUpdateNewsletter;