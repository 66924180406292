export const env = (key, fallback='') => {
    return (process.env.hasOwnProperty('REACT_APP_' + key) ? process.env['REACT_APP_' + key] : fallback);
};

export const repeatComponent = (Component, props = {}, count = 1) => {
    let result = [];

    for (let i = 0; i < count; i++) {
        result.push(<Component key={i} {...props} />);
    }

    return result;
};

export const processErrorMessage = (error, separator = '<br />') => {
    console.log(error);
    return Array.isArray(error)
        ? error.map(singleError => (singleError.message || '').join(separator))
        : (error.message || '');
};

export const decodeHtml = (html) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = html;
    return textarea.value;
};

export const decodeUrl = (url) => {
    return decodeHtml(url);
};

export const strEquals = (str1, str2, caseSensitive = true) => {
    return caseSensitive
        ? (str1 === str2)
        : (str1.toLowerCase() === str2.toLowerCase());
};

export const consoleLog = (...args) => {
    if(env('ENV_NAME') === 'test' || env('ENV_NAME') === 'dev') {
        console.log(args);
    }
}

export const objectToGqlQuery = (obj, recursive = false) => {
    if (!recursive && !obj) {
        return '';
    }

    // Make sure we don't alter integers.
    if (typeof obj === 'number') {
        return obj;
    }

    // Stringify everything other than objects.
    if (typeof obj !== 'object' || Array.isArray(obj)) {
        return JSON.stringify(obj);
    }

    // Iterate through object keys to convert into a string
    // to be interpolated into the query.
    const props = Object.keys(obj).map((key) =>
        `${key}:${objectToGqlQuery(obj[key], true)}`
    ).join(',');

    if (!recursive) {
        return props?.length ? `(${props})` : '';
    }

    return `{${props}}`;
}