import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useConfig } from "@kega/sps-core";

const useStoreViews = () => {
    const config = useConfig();
    const { pathname } = useLocation();

    const storeViews = config.get('storeViews');
    const currentStoreCode = config.get('storeCode');
    const currentStoreView = storeViews.hasOwnProperty(currentStoreCode) ? storeViews[currentStoreCode] : {};
    const defaultStoreCode = config.get('defaultStoreCode');
    const isKiosk = currentStoreCode.includes("_kiosk");

    const urlPathWithoutStore = useMemo(() => {
        let path = pathname || '';
        let pattern = '(\\/)?(.*)';

        if (currentStoreCode) {
            pattern = '(\\/' + currentStoreCode + ')?' + pattern;
        }

        const regex = new RegExp(pattern);

        return path.replace(regex, '$3');
    }, [currentStoreCode, pathname]);

    const isExternalLink = (path) => {
        return path ? path.startsWith('http') : false;
    }

    const getUrl = (path) => {
        if (isExternalLink(path)) {
            return path;
        }

        return `/${currentStoreCode}/${(path || '').replace(/^\/(.*)$/, '$1')}`;

    }


    const checkIfKiosk = storeCode => storeCode.includes("_kiosk");

    return {
        storeViews,
        currentStoreView,
        currentStoreCode,
        defaultStoreCode,
        isKiosk,
        urlPathWithoutStore,
        getUrl,
        checkIfKiosk,
        isExternalLink
    };
};

export default useStoreViews;
