import getUspsQuery from "../queries/getUsps.gql";
import getShowSaleLabelQuery from "../queries/getShowSaleLabelQuery.gql";

export default ({ client }) => {

    const api = {};

    api.getUsp = () => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: getUspsQuery,
                variables: {}
            };

            client.post('/graphql', payload).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(normalizeData(data.storeConfig));

            }).catch((error) => {
                reject(error);
            });
        });
    }

    api.getDisableSaleLabel = () => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: getShowSaleLabelQuery,
                variables: {}
            };

            client.post('/graphql', payload).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data.storeConfig);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    return api;
};

const normalizeData = (storeConfig) => {
    return {
        header: parseUsp(parseJson(storeConfig?.headerusps)),
        pdp: parseUsp(parseJson(storeConfig?.pdpusps))

    }

}

const parseJson = (json) => {
    try {
        return JSON.parse(json)
    } catch (error) {
        return {}
    }
}

const parseUsp = (items) => {

    return Object.values(items).map(({ desktop_usp, mobile_usp, url }) => {
        return {
            desktop_label: desktop_usp,
            mobile_label: mobile_usp,
            href: url
        }
    })

}
