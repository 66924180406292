import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Builder } from '@builder.io/react/lite';
import { Image } from "@builder.io/react";

import useStoreViews from '../../../../hooks/useStoreViews';

import { Container, Row, Col } from '../../../../components/grid';
import Configure from '../configure/Configure';

import classNames from 'classnames';
import classes from './Collections.module.css';

export const Collections = ({ title_text, text_color, background_visual_image, collections, size_xl, size_lg, size_md, size_sm, size_xs }) => {
    const [currentIndex, setCurrentIndex] = useState(-1);
    const { getUrl } = useStoreViews();

    let timeout;

    const SetCollectionImage = (index) => {
        if (currentIndex === -1) {
            timeout = setTimeout(function () {
                setCurrentIndex(index);
            }, 50);
        } else {
            setCurrentIndex(index);
        }
    }

    const SetInitialImage = () => {
        clearTimeout(timeout);
        setCurrentIndex(-1);
    }

    return (
        <div className={classes.root}>
            <Container margins g-xs={1} g-sm={3} g-md={2} g-xl={4} className={classes.collection_container} >
                <Row>
                    <Col xl={size_xl} lg={size_lg} md={size_md} sm={size_sm} xs={size_xs}>
                        {
                            collections !== undefined
                                ?
                                <>
                                    <div className={classes.image_wrapper}>
                                        <div className={classNames(classes.img, currentIndex === -1 ? classes.active : null)}>
                                            <Image lazy aspectRatio={0} image={background_visual_image} />
                                        </div>
                                        {
                                            collections.map((collection, index) => {
                                                return (
                                                    <div key={index} className={classNames(classes.img, classes.hover_img, currentIndex === index ? classes.active : null)}>
                                                        <Image lazy aspectRatio={0} image={collection.image} />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    <div className={classes.text_container} style={{ color: text_color }}>
                                        {
                                            <div className={classes.title} role="heading" dangerouslySetInnerHTML={{ __html: title_text }} />
                                        }
                                        <div className={classes.hover_links_container}>
                                            {
                                                collections.map((collection, index) => {
                                                    return (
                                                        <div className={classes.link} key={index} onMouseEnter={() => SetCollectionImage(index)} onMouseLeave={() => SetInitialImage()}>
                                                            {
                                                                collection?.url ?
                                                                    <Link to={getUrl(collection?.url)} className={classes.link}>
                                                                        {collection?.title}
                                                                    </Link>
                                                                    :
                                                                    <div>{collection?.title}</div>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <Configure name="Collections" />
                        }

                    </Col>
                </Row>
            </Container>
        </div >
    );
}


Builder.registerComponent(Collections, {
    name: "Collections block",
    defaultStyles: {
        marginTop: "0px",
    },
    inputs: [
        {
            type: 'color',
            name: 'text_color',
            friendlyName: 'Text color',
            defaultValue: '#FFFFFF',
        },
        {
            type: 'richText',
            name: 'title_text',
            friendlyName: 'Title Text',
            defaultValue: 'Main title',
        },
        {
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            name: 'background_visual_image',
            friendlyName: 'Background Visual Image',
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
        },
        {
            name: 'collections',
            friendlyName: 'Collections',
            type: "list",
            helperText: "Max 6 Collections are allowed",
            subFields: [{ name: "title", type: "string", defaultValue: 'Collection title' }, { name: 'image', type: "file", allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'], defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d' }, { name: 'url', type: "string" }],
            onChange: (options) => {
                if (options.get('collections').length > 4) {
                    options.set('collections', options.get('collections').slice(0, 6))
                }
            },
        },
        {
            type: 'string',
            name: 'size_xl',
            friendlyName: 'Size (1440px - …)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_lg',
            friendlyName: 'Size (1024px - 1439px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_md',
            friendlyName: 'Size (768px - 1023px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_sm',
            friendlyName: 'Size (640px - 767px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_xs',
            friendlyName: 'Size (0px - 639px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
    ],
});
