import Magento2 from '@kega/sps-connector-magento2';

import Adyen from './api/Adyen';
import Catalog from './api/Catalog';
import EssentielCustomer from './api/Customer';
import EssentielCheckout from './api/Checkout';
import EssentielTheme from './api/Theme';
import EssentielWishlist from './api/Wishlist';
import EssentielProductAlert from './api/ProductAlert';
import EssentielGeoIP from './api/GeoIP';
import EssentielContact from './api/Contact';
import EssentielStoreStock from './api/StoreStock';
import EssentielNewsletter from './api/Newsletter';
import EssentielStore  from './api/Store';
import EssentielCart from './api/Cart';

const configureApi = (config, storeCode) => {

    const options = {
        storeCode: storeCode || config.defaultStoreCode
    };

    const { api: { cart, customer, cms, navigation }, register } = Magento2(config.magento2, options);

    const catalog = register(Catalog);
    const adyen = register(Adyen);
    const essentielCustomer = register(EssentielCustomer);
    const checkout = register(EssentielCheckout);
    const essentielTheme = register(EssentielTheme);
    const essentielWishlist = register(EssentielWishlist);
    const essentielProductAlert = register(EssentielProductAlert);
    const essentielGeoIP = register(EssentielGeoIP);
    const essentielContact = register(EssentielContact);
    const essentielStoreStock = register(EssentielStoreStock);
    const essentielNewsletter = register(EssentielNewsletter);
    const essentielStore = register(EssentielStore);
    const essentielCart = register(EssentielCart);

    return {
        adyen,
        cart,
        customer,
        catalog,
        cms,
        checkout,
        navigation,
        essentielCustomer,
        essentielTheme,
        essentielWishlist,
        essentielProductAlert,
        essentielGeoIP,
        essentielContact,
        essentielStoreStock,
        essentielNewsletter,
        essentielStore,
        essentielCart
    };
}

export default configureApi;
