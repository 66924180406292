import { NavLink, useNavigate } from "react-router-dom";

import { useLogout } from '@kega/sps-core';

import { t } from '../../../lib/translations';
import useStoreViews from "../../../hooks/useStoreViews";

import classNames from "classnames";
import classes from "./AccountNav.module.css";

import { deleteCookie } from "../../../lib/cookies";

const AccountNav = ({ onClick = '', className = '', account = false }) => {
    const { getUrl } = useStoreViews();
    const logout = useLogout();
    const navigate = useNavigate();

    const logOut = () => {
        logout();

        navigate({
            pathname: getUrl('account/login')
        });
    }

    return (
        <nav className={classNames(classes.root, className, account && classes.my_account)}>
            {
                account &&
                <div className={classes.title}>{t('account.navigation.title')}</div>
            }
            <NavLink to={getUrl('/account/overview')} onClick={onClick} role="button" tabIndex="0" className={({ isActive }) =>
                isActive ? classNames(classes.link, classes.active) : classes.link}>
                {t('account.navigation.overview')}
            </NavLink>
            <NavLink to={getUrl('/account/addressbook/overview')} onClick={onClick} role="button" tabIndex="0" className={({ isActive }) =>
                isActive ? classNames(classes.link, classes.active) : classes.link}>
                {t('account.navigation.addressbook')}
            </NavLink>
            <NavLink to={getUrl('/account/orders')} onClick={onClick} role="button" tabIndex="0" className={({ isActive }) =>
                isActive ? classNames(classes.link, classes.active) : classes.link}>
                {t('account.navigation.orders')}
            </NavLink>
            <NavLink to={getUrl('/account/wishlist')} onClick={onClick} role="button" tabIndex="0" className={({ isActive }) =>
                isActive ? classNames(classes.link, classes.active) : classes.link}>
                {t('account.navigation.wishlist')}
            </NavLink>
            <NavLink to={getUrl('/account/newsletter')} onClick={onClick} role="button" tabIndex="0" className={({ isActive }) =>
                isActive ? classNames(classes.link, classes.active) : classes.link}>
                {t('account.navigation.newsletter')}
            </NavLink>
            <div onClick={logOut} className={classes.logout} role="button" tabIndex="0">{t('account.navigation.logout')}</div>
        </nav>
    );
}

export default AccountNav;
