const estimateShippingMethodsQuery = `
    query getShippingEstimates($cartId: String!){
      estimateShipping(cart_id: $cartId) {
        items {
          carrier_code
          method_code
          carrier_title
          amount {
            value
            currency
          }
        }
      }
}`;

export default estimateShippingMethodsQuery;