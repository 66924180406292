import Skeleton from 'react-loading-skeleton';

import Price from "../../../components/price/Price";

import classes from "./Info.module.css"

const Info = ({ product: { name, price_range }, loading }) => {
    return (
        <div className={classes.root}>
            <h1 className={classes.product_title}>
                {
                    (loading && !name)
                        ? <Skeleton />
                        : name
                }
            </h1>
            <div className={classes.price}>
                {
                    (loading && !price_range)
                        ? <Skeleton />
                        : (price_range &&
                           <Price
                               rootClassName={classes.price_wrapper}
                               price={price_range.minimum_price.final_price?.value ? price_range.minimum_price.final_price?.value : price_range.minimum_price.regular_price.value}
                               originalPrice={price_range.minimum_price.regular_price.value}
                               className={classes.price} />
                        )
                }
            </div>
        </div>
    )
}

export default Info;
