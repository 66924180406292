import { useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';

import { setCache, deleteCache, clearCache } from '../redux/cache/actions';

const useCache = () => {

    const dispatch = useDispatch();
    const store = useStore();
    const { cache } = store.getState();

    const get = useCallback((key) => {
        return cache[key] || null;
    }, [cache]);

    const set = useCallback((key, value) => {
        dispatch(setCache({
            key,
            value
        }));
    }, [dispatch]);

    const remove = useCallback((key) => {
        dispatch(deleteCache(key));
    }, [dispatch]);

    const clear = useCallback(() => {
        dispatch(clearCache());
    }, [dispatch]);

    return {
        get,
        set,
        remove,
        clear
    };
}

export default useCache;