import { connectCurrentRefinements } from 'react-instantsearch-dom';

import useMediaQuery from "../../../hooks/useMediaQuery";

import { Slider, SliderItem } from "../../../components/slider";

import classes from './ClearFilters.module.css';

const ClearFilters = ({ items, refine, setFilteredCount }) => {
    if (items.length === 0) { return false }

    const { match: matchMobile } = useMediaQuery(`(max-width: 768px)`);

    const handleOnClick = (item, parentItem = false) => {
        refine([item]);

        const attribute = parentItem ? parentItem.attribute : item.attribute;
        setFilteredCount(filteredCount => ({
            ...filteredCount,
            [attribute]: filteredCount !== 0 ? filteredCount[attribute] - 1 : 0
        }));
    }

    return (
        <div className={classes.root}>
            {
                matchMobile
                    ?
                    <Slider
                        controls={false}
                        navigation={false}
                        scrollbar={true}
                        className={classes.filter_slider}
                    >
                        {
                            items.map((item, index) => {
                                return (
                                    (item.items?.length > 0)
                                        ?
                                        item.items.map((i, index) => {
                                            return (
                                                <SliderItem key={index} className={classes.filter_items}>
                                                    <div
                                                        key={index}
                                                        className={classes.clear}
                                                        onClick={() => handleOnClick(i, item)}
                                                        disabled={!items.length}
                                                        role="button"
                                                        tabIndex="0">
                                                        {i.label}
                                                    </div>
                                                </SliderItem>
                                            );
                                        })
                                        :
                                        <SliderItem key={index} className={classes.filter_items}>
                                            <div
                                                className={classes.clear}
                                                onClick={() => handleOnClick(item)}
                                                disabled={!items.length}
                                                role="button" tabIndex="0">
                                                {item.label}
                                            </div>
                                        </SliderItem>
                                )
                            })
                        }
                    </Slider>
                    :
                    items.map((item) => {
                        return (
                            (item.items?.length > 0)
                                ?
                                item.items.map((i, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={classes.clear}
                                            onClick={() => handleOnClick(i, item)}
                                            disabled={!items.length}
                                            role="button"
                                            tabIndex="0"
                                        >
                                            {i.label}
                                        </div>
                                    );
                                })
                                :
                                <div
                                    className={classes.clear}
                                    onClick={() => handleOnClick(item)}
                                    disabled={!items.length}
                                    role="button" tabIndex="0">
                                    {item.label}
                                </div>

                        )
                    })
            }
        </div>

    );
};

export default connectCurrentRefinements(ClearFilters);
