const getStoreStock = `
    query getStoreStock($itemId: String!, $variantId: String!) {
        getStoreStock(
            itemId: $itemId,
            variantId: $variantId
        ) {
           itemId,
           variantId,
           quantity
        }
    }
`;

export default getStoreStock;
