const setPaymentMethodAndPlaceOrder = (methodData = '' ) => `
    mutation placeOrder($cartId: String!, $stateData: String!, $registerNow: String, $newsletterSubscribe: Boolean) {
        setPaymentMethodOnCart(
            input: {
                cart_id: $cartId
                payment_method: {
                    ${methodData}
                }
            }
        ) {
            cart {
                selected_payment_method {
                    code
                    title
                },
                payment_method_title
                total_applied_tax {
                  amount {
                    currency
                    value
                  }
                }
            }
        }

        placeOrder(
            input: {
                cart_id: $cartId
                register_now: $registerNow
                newsletter_subscribe: $newsletterSubscribe
            }
        ) {
            order {
                order_number
                order_id              
            }
        }
    }
`

export default setPaymentMethodAndPlaceOrder;
