const customer = `
    fragment CustomerFragment on Customer {
      firstname
      lastname
      email
      is_subscribed
    }
`;

export default customer;
