import { Col } from "../../grid";

import classNames from "classnames";
import classes from './Field.module.css';

const Field = ({ className = null, children, ...restProps }) => {
    return (
        <Col className={classNames(classes.root, className)} {...restProps}>
            {children}
        </Col>
    );
}

export default Field;