import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    isNewsletterOverlayOpen: true
};

export default createReducer(initialState, {
    [actions.newsletterOverlayOpen]: (state) => {
        state.isNewsletterOverlayOpen = true;
    },
    
    [actions.newsletterOverlayClose]: (state) => {
        state.isNewsletterOverlayOpen = false;
    }
});
