const updateNewsletterStatus = `
    mutation updateCustomerV2($is_subscribed: Boolean!) {    
      updateCustomerV2(
        input: {
          is_subscribed: $is_subscribed
        }
      ) {
        customer {
          is_subscribed
        }
      }
    }
`;

export default updateNewsletterStatus;