import CustomerFragment from '../fragments/CustomerFragment.gql';

const getRegisterQuery = `
    mutation createCustomerV2($firstname: String!, $lastname: String!, $email: String!, $password: String!, $is_subscribed: Boolean!, $order_number: String) {    
        createCustomerV2(
          input: {
            firstname: $firstname
            lastname: $lastname
            email: $email
            password: $password
            is_subscribed: $is_subscribed
            order_number: $order_number
          }
        ) {
          customer {
            ...CustomerFragment
          }
        }
      }
    ${CustomerFragment}
`;

export default getRegisterQuery;