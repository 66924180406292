import { cartAbstract } from '@kega/sps-connector';
import addToCartMutation from "@kega/sps-connector-magento2/src/queries/addToCart.gql";
import parseErrors from "@kega/sps-connector-magento2/src/utils/parseErrors";

export default ({ client, config, tokens }) => {
    const cart  = Object.create(cartAbstract);

    cart.addItem = async (cartId, { product, quantity, selectedOption, cartItemOptions }, options) => {
        const { token='' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const payload = {
                query: addToCartMutation(options?.custom_attributes, options?.additional_data),
                variables: {
                    cartId,
                    cartItems: [{
                        quantity: quantity,
                        sku: product.sku,
                        ...(selectedOption && { selected_options: [selectedOption] }),
                        ...(cartItemOptions && { ...cartItemOptions })
                    }]
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                const { cart } = data.addProductsToCart;

                const normalizedCart = normalizeCart(cart);

                resolve(normalizedCart);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    return cart;
};

const normalizeCart = (cart) => {
    const { id, items, applied_coupons, prices, ...cartData } = cart;

    let itemsData = items.map(({ uid, configurable_options, product, quantity, prices, discounts, configured_variant, ...props }) => {

        const { sku, name, ...productData } = product;

        return {
            id: uid,
            sku: sku,
            name: name,
            prices: prices,
            discounts: discounts,
            quantity: quantity,
            configurable_options: configurable_options,
            configured_variant: configured_variant,
            ...props,
            ...productData
        };
    });

    let totalsData = {
        discounts: prices?.discounts,
        applied_taxes: prices?.applied_taxes,
        total: prices?.grand_total?.value ?? 0,
        subtotal_excluding_tax: prices?.subtotal_excluding_tax?.value ?? 0,
        subtotal: prices?.subtotal_including_tax?.value ?? 0
    };

    return {
        cartId: id,
        items: itemsData,
        totals: totalsData,
        coupons: applied_coupons,
        ...cartData
    }
}