import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    isCartOpen: false,
    isMenuOpen: false,
    isFiltersOpen: false,
    isCustomerNavOpen: false,
    isSortByOpen: false,
    isZoomGalleryOpen: false,
    isAddToCartDrawerOpen: false,
    isGiftboxDrawerOpen: false,
    isStoreSelectorOpen: false
};

export default createReducer(initialState, {

    [actions.cartOpen]: (state) => {
        state.isCartOpen = true;
    },

    [actions.cartClose]: (state) => {
        state.isCartOpen = false;
    },

    [actions.menuOpen]: (state) => {
        state.isMenuOpen = true;
    },

    [actions.menuClose]: (state) => {
        state.isMenuOpen = false;
    },

    [actions.filtersOpen]: (state) => {
        state.isFiltersOpen = true;
    },

    [actions.filtersClose]: (state) => {
        state.isFiltersOpen = false;
    },

    [actions.customerNavOpen]: (state) => {
        state.isCustomerNavOpen = true;
    },

    [actions.customerNavClose]: (state) => {
        state.isCustomerNavOpen = false;
    },

    [actions.sortByOpen]: (state) => {
        state.isSortByOpen = true;
    },

    [actions.sortByClose]: (state) => {
        state.isSortByOpen = false;
    },
    
    [actions.zoomGalleryOpen]: (state) => {
        state.isZoomGalleryOpen = true;
    },
    
    [actions.zoomGalleryClose]: (state) => {
        state.isZoomGalleryOpen = false;
    },

    [actions.addToCartDrawerOpen]: (state) => {
        state.isAddToCartDrawerOpen = true;
    },
    
    [actions.addToCartDrawerClose]: (state) => {
        state.isAddToCartDrawerOpen = false;
    },

    [actions.giftboxDrawerOpen]: (state) => {
        state.isGiftboxDrawerOpen = true;
    },

    [actions.giftboxDrawerClose]: (state) => {
        state.isGiftboxDrawerOpen = false;
    },

    [actions.storeSelectorOpen]: (state) => {
        state.isStoreSelectorOpen = true;
    },

    [actions.storeSelectorClose]: (state) => {
        state.isStoreSelectorOpen = false;
    }
});
