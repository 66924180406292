import { useState } from "react";
import { t } from "../../../lib/translations";

import { Input } from "../../form";

import classes from './PersonalisedOrder.module.css';

const PersonalisedOrder = ({ setPersonalisedValue, personalizedValue }) => {

    const normalizePersonalisedValue = (value) => {
        const ret = typeof value === 'string' ? value.replace(/[^a-zA-Z0-9]/g, '') : '';
        setPersonalisedValue(ret);
    }

    return (
        <div className={classes.root}>
            <div className={classes.text}>
                {t('product.personalised_order.caption')}<br/>
                {t('product.personalised_order.caption2')}
            </div>
            <div className={classes.input}>
                <Input
                    name="personalised_order"
                    id="personalised_order"
                    type="text"
                    value={personalizedValue}
                    onChange={(e) => normalizePersonalisedValue(e.target.value)}
                    label={t('product.personalised_order.label')}
                    placeholder={t('product.personalised_order.placeholder')}
                    maxLength={7}
                />
            </div>
        </div>
    )
}

export default PersonalisedOrder;