import { useRef } from 'react';
import { Portal } from '@kega/sps-elements';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import useUi from "../../../../hooks/useUi";
import useScrollLock from "../../../../hooks/useScrollLock";
import useMediaQuery from '../../../../hooks/useMediaQuery';

import { Slider, SliderItem } from "../../../../components/slider";
import { CrossIcon, ChevronIcon } from '../../../../components/icons';

import classes from "./GalleryZoom.module.css"

const GalleryZoom = ({ images, activeIndex }) => {
    const { isZoomGalleryOpen, closeZoomGallery } = useUi();
    const resetRef = useRef();

    const { match } = useMediaQuery(`(min-width: 768px)`)

    useScrollLock(isZoomGalleryOpen);

    if (!isZoomGalleryOpen) { return null; }

    return (
        <Portal>
            <div className={classes.root}>
                <div className={classes.close_btn} onClick={closeZoomGallery} role="button" tabIndex="0">
                    <CrossIcon title={"Gallery Zoom"} size={16} />
                </div>
                <Slider
                    itemsToShow="1"
                    scrollbar={false}
                    controls={true}
                    navigation={true}
                    automate={false}
                    activeIndex={activeIndex}
                    className={classes.gallery}
                    ComponentNext={<div className={classes.next}><ChevronIcon title={"Slider Left"} direction='left' size={12} /></div>}
                    ComponentPrev={<div className={classes.prev}><ChevronIcon title={"Slider Right"} direction='right' size={12}  /></div>}
                >
                    {
                        images.sort().map((image, index) =>
                            <SliderItem key={index}>
                                <TransformWrapper
                                    initialPositionX={!match ? -100 : null}
                                    centerOnInit={match ? true : false}
                                    onPanningStop={(e) => {
                                        if (Math.ceil(e.state.scale) > 3) {
                                            resetRef.current.click();
                                        }
                                    }}
                                >
                                    {({ resetTransform }) => (
                                        <>
                                            <div className={classes.reset} ref={resetRef} onClick={() => resetTransform()} role="button" tabIndex="0">x</div>
                                            <TransformComponent>
                                                <img className={classes.zoom_image} src={image.split('&width')[0]} style={{ maxHeight: window.screen.height }} alt=""/>
                                            </TransformComponent>
                                        </>
                                    )}
                                </TransformWrapper>
                            </SliderItem>
                        )
                    }
                </Slider>
            </div>
        </Portal>
    );
}

export default GalleryZoom;
