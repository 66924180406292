const adyenConnectedTerminals = `
query getConnectedTerminals($cartId: String!)
{
    connectedTerminals(cart_id: $cartId) {
      success
      items {
        id
        name
      }
    }
}`;

export default adyenConnectedTerminals;