import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Builder, BuilderBlocks } from "@builder.io/react/lite";

import useStoreViews from "../../../../hooks/useStoreViews";
import useMediaQuery from "../../../../hooks/useMediaQuery";

import Configure from "../configure/Configure";
import { Container, Row, Col } from "../../../../components/grid";

import classNames from "classnames";
import classes from './Tabs.module.css';

const defaultBlocks = [
    {
        '@type': '@builder.io/sdk:Element',
        component: {
            name: 'Dummy'
        },
    },
];

export const Tabs = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { background, background_color, tab_item, builderBlock, alignment } = props;
    const { getUrl } = useStoreViews();
    const { match: matchMobile } = useMediaQuery(`(max-width: 640px)`);

    const structureUrl = (url, name) => {

        if (url.startsWith('#')){

            return (<a href={url}>{name}</a>);
        }
        return  (<Link to={getUrl(url)}>{name}</Link> ) ;
    }

    const isLive = !Builder.isEditing && !Builder.isPreviewing;

    useEffect(() => {
        tab_item !== undefined && tab_item.map((tab_item, index) => {
            (tab_item.open && isLive) && setActiveIndex(index);
        });

    }, [ tab_item, setActiveIndex ])

    return (
        <>
            {
                tab_item !== undefined ?
                    <>
                        <div style={{ backgroundColor: background ? background_color : 'transparent' }}>
                            <Container xl>
                                <Row>
                                    <Col className={classNames(classes.tab_title_container, matchMobile ? classes.align_left : ( alignment ? classes['align_'+alignment] : classes.align_center ))}>
                                        {tab_item.map((tab_item, key) => (
                                            <React.Fragment key={key}>
                                                {
                                                    tab_item?.url
                                                        ?
                                                        <a className={classNames(classes.item, activeIndex === key ? classes.active : null)} href={tab_item?.url} onClick={() => setActiveIndex(key)}>
                                                            {structureUrl(tab_item?.url, tab_item?.name) }
                                                        </a>
                                                        :
                                                        <div className={classNames(classes.item, activeIndex === key ? classes.active : null)} role="button" tabIndex="0" onClick={() => setActiveIndex(key)}>
                                                            {tab_item?.name}
                                                        </div>
                                                }
                                            </React.Fragment>

                                        ))}
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <Container xl margins>
                            {tab_item.map((tab_item, index) => {
                                let block = props[`block${index}`];

                                if (block.length > 0 && block[0].component?.name === "Dummy") {
                                    block.shift()
                                }

                                return (
                                    <Row key={index} className={classNames(classes.row, activeIndex === index ? classes.active : null)}>
                                        <Col>
                                            <BuilderBlocks
                                                child
                                                blocks={block}
                                                parentElementId={builderBlock && builderBlock.id}
                                                dataPath={`component.options.block${index}`} />
                                        </Col>
                                    </Row>
                                )
                            })}
                        </Container>
                    </>
                    :
                    <Configure name="Tabs" />
            }
        </>
    );
};

Builder.registerComponent(Tabs, {
    name: "Tabs",
    static: true,
    defaultStyles: {
        marginTop: '0px',
    },
    inputs: [
        {
            name: 'tab_item',
            friendlyName: 'Tab',
            type: 'list',
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                    defaultValue: 'Tab name'
                },
                {
                    name: 'open',
                    type: "boolean",
                    friendlyName: 'Default open',
                    defaultValue: 0
                },
                {
                    name: 'url',
                    type: 'string',
                    friendlyName: 'Open url instead of tab',
                    defaultValue: null
                },
            ],
            onChange: (options) => {
                if (options.get('tab_item').length > 10) {
                    options.set('tab_item', options.get('tab_item').slice(0, 10));
                }
            },
            helperText: 'Max 10 tabs can be added.'
        },
        {
            name: 'alignment',
            friendlyName: 'Alignment',
            type: 'string',
            defaultValue: 'left',
            enum: [
                {
                    label: 'Left',
                    value: 'left',
                },
                {
                    label: 'Center',
                    value: 'center'
                },
                {
                    label: 'Right',
                    value: 'right'
                },
            ]
        },
        {
            name: 'background',
            type: "boolean"
        },
        {
            type: 'color',
            name: 'background_color',
            friendlyName: 'Background color',
            defaultValue: '#F7F7F7',
        },
        {
            name: 'block0',
            type: 'uiBlocks',
            hideFromUI: true,
            defaultValue: defaultBlocks
        },
        {
            name: 'block1',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block2',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block3',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block4',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block5',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block6',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block7',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block8',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block9',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
    ]
});
