import IconWrapper from '../wrapper/IconWrapper';

const SearchIcon = ({ color = null, title, roleButtonRemoved, ...props }) => {
    return (
        <IconWrapper roleButtonRemoved={roleButtonRemoved}  color={color} {...props}>
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" role="presentation">
                {title && <title>{title}</title>}
                <path d="M11.6 11C12.7 9.8 13.3 8.2 13.3 6.6C13.3 2.9 10.3 0 6.7 0C3 0 0 3 0 6.6C0 10.2 3 13.3 6.7 13.3C8.1 13.3 9.4 12.9 10.6 12.1L15.8 17.3L16.7 16.1L11.6 11ZM11.8 6.6C11.8 9.4 9.5 11.8 6.7 11.8C3.9 11.8 1.5 9.5 1.5 6.6C1.5 3.7 3.8 1.5 6.7 1.5C9.6 1.5 11.8 3.8 11.8 6.6Z" fill={color ? color : 'black'}/>
            </svg>
        </IconWrapper>
    );
}

export default SearchIcon;
