import classNames from 'classnames';
import classes from './SizeOptionsBlocks.module.css';

const SizeOptionsBlocks = ({ code, values = [], selected = null, onChange }) => {
    return (
        <div className={classes.root} >
            {
                values.map(({ id, value, label, disabled, stockStatus }) => {
                    let props = {}
                    if (!disabled && onChange) {
                        props.onClick = onChange.bind(this, code, id);
                    }
                    const swatchClasses = classNames(
                        classes.value,
                        {
                            [classes['selected']]: (selected === id),
                            [classes['disabled']]: disabled,
                            [classes['noStock']]: stockStatus === false
                        }
                    );

                    return (
                        <div key={id} className={classes.container} {...props}>
                            <div className={swatchClasses}>
                                <span>{label}</span>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default SizeOptionsBlocks;
