import { useRequest, useStorefront } from '@kega/sps-core';

import { t } from '../lib/translations';
import useSystemMessage from "./useSystemMessage";

const useKiosk = () => {
    const { api: { essentielStore } } = useStorefront();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();
    const { setSystemErrorMessage } = useSystemMessage();

    const setCurrentStore = async (cartId, storeId) => {

        try {
            setRequest();

            const result = await essentielStore.setCurrentStore(cartId, storeId);

            if (result) {
                setSuccess();
                return result.setCurrentStore;

            } else {
                setSystemErrorMessage(t('Something went wrong, please try again later'));
                setError(new Error(t('Something went wrong, please try again later')));
            }
        } catch (error) {
            setError(error);
        }

        return false;
    };


    return {
        error,
        loading,
        setCurrentStore
    };
}

export default useKiosk;
