import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { LinkButton } from '../../../../components/form';
import Address from '../../../../components/address/Address';

import { t } from '../../../../lib/translations';

import useStoreViews from '../../../../hooks/useStoreViews';

import classes from "./Addresses.module.css";

const Addresses = ({ customer }) => {
    const navigate = useNavigate();

    const { getUrl } = useStoreViews();
    const [defaultBilling, setDefaultBilling] = useState(null);
    const [defaultShipping, setDefaultShipping] = useState(null);

    useEffect(() => {
        customer.addresses.map((address) => {
            address.default_billing && setDefaultBilling(address);
            address.default_shipping && setDefaultShipping(address);
        })
    }, [customer, setDefaultBilling, setDefaultShipping]);

    const toEdit = (address) => {
        navigate(getUrl("account/addressbook/edit"), {
            state: { address: address },
        });
    };

    return (
        <div className={classes.root}>
            <h2>{t('address_book.default_shipping')}</h2>
            <Address className={classes.shipping} address={defaultShipping} onEdit={toEdit} />

            <h2>{t('address_book.default_billing')}</h2>
            <Address address={defaultBilling} onEdit={toEdit} />

            <LinkButton
                className={classes.link}
                linkComponent={Link}
                to={getUrl('account/addressbook/overview')}
                variant='secondary'
            >
                {t('account.overview.addresses.button')}
            </LinkButton>
        </div>
    );
}

export default Addresses;