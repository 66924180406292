import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../../../components/grid';

import { Slider, SliderItem } from '../../../components/slider';

import useUsp from '../../../hooks/useUps';
import useMediaQuery from '../../../hooks/useMediaQuery';

import { CrossIcon } from "../../../components/icons";

import classNames from 'classnames';
import classes from './Usp.module.css';

const Usp = ({ className }) => {
    const usps = useUsp('header');
    const { match } = useMediaQuery('(max-width: 768px)');
    const [open, setOpen] = useState(true);

    const renderItem = (item) => {
        const labelText = !match ? item.desktop_label : (item.mobile_label ? item.mobile_label : item.desktop_label);
        const label = (
            <div className={classes.item}>
                <span className={classes.label}>{labelText}</span>
            </div>
        );

        return (
            <>
                {
                    item.href
                        ?
                        <Link to={item.href}>{label}</Link>
                        :
                        <div>
                            {label}
                        </div>
                }
            </>
        );
    }

    const close = () => {
        setOpen(false);
        document.body.classList.add('usp-hidden');
    }

    return (
        open &&
        <div className={classNames(classes.root, open ? classes.open : null, className)}>
            <Container xl margins className={classes.container}>
                {
                    Object.keys(usps).length > 0 &&
                    <Slider
                        itemsToShow="1"
                        scrollbar={false}
                        controls={false}
                        duration={6000}
                        automate
                    >
                        {Object.values(usps).map((usp, index) =>
                            <SliderItem className={classes.noScrollSnap} key={index}>
                                {renderItem(usp)}
                            </SliderItem>)}
                    </Slider>
                }
            </Container>
            <CrossIcon
                title={"USP"}
                className={classes.close}
                onClick={close}
                size="10"
                color="white" />
        </div>
    );
}

export default Usp;
