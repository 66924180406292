const setBillingAddressOnCartMutation = `
    mutation setBillingAddressOnCart($cartId: String!, $address: CartAddressInput, $addressId: Int, $sameAsShipping: Boolean) {
        setBillingAddressOnCart(
            input: {
                cart_id: $cartId
                billing_address: {
                    address: $address
                    customer_address_id: $addressId
                    same_as_shipping: $sameAsShipping
                }
            }
        ) {
            cart {
                billing_address {
                    firstname
                    lastname
                    company
                    street
                    city
                    postcode
                    telephone
                    country {
                      code
                      label
                    }
                    vat_id
                }
            }
        }
    }
`

export default setBillingAddressOnCartMutation;