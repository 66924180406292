import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

import Card from "../card/Card";

import useI18n from "../../hooks/useI18n";

import classes from "./Address.module.css";

import { t } from "../../lib/translations";

const Address = ({ className, address, addressClassName, loading, onEdit, onDelete }) => {
    const { getCountryNameByCode } = useI18n();

    if (!address && !loading) {
        return (
            <div className={classNames(classes.root, className)}>
                <span>{t("address_book.no_address")}</span>
            </div>
        );
    }

    return (
        <>
            {loading && (
                <Card className={classNames(classes.root, className)}>
                    <div
                        className={classNames(
                            classes.address,
                            addressClassName
                        )}
                    >
                        <Skeleton count={5} />
                    </div>
                    <Skeleton count={2} />
                </Card>
            )}
            {!loading && (
                <Card className={classNames(classes.root, className)}>
                    <address
                        className={classNames(
                            classes.address,
                            addressClassName
                        )}
                    >
                        {address.firstname} {address.lastname} <br />

                        {
                            Array.isArray(address.street) ?
                                <span>
                                    {typeof address.street[0] !== 'undefined' && address.street[0]} &nbsp;
                                    {typeof address.street[1] !== 'undefined' && address.street[1]}
                                </span>
                                :
                                <span>
                                    {address?.street} &nbsp; {address?.street_number}
                                </span>
                        }
                        
                        <br />
                        {address.postcode} {address.city} <br />
                        {
                            address.company
                            &&
                            <>{address.company} <br/></>
                        }
                        {
                            address.vat_id
                            &&
                            <>{address.vat_id} <br/></>
                        }
                        {getCountryNameByCode(address.country_code)} <br />
                        T {address.telephone} <br />
                    </address>
                    <div className={classes.actions}>
                        {onEdit && (
                            <div onClick={() => onEdit(address)} role="button" tabIndex="0">
                                <span>{t("Edit")}</span>
                            </div>
                        )}
                        {onDelete && (
                            <div onClick={() => onDelete(address)} role="button" tabIndex="0">
                                <span>{t("Delete")}</span>
                            </div>
                        )}
                    </div>
                </Card>
            )}
        </>
    );
};

export default Address;
