import { Routes, Route, Navigate } from "react-router-dom";
import { useConfig } from '@kega/sps-core';

import Layout from '../pages/Layout';
import MinimalLayout from '../pages/MinimalLayout';

import Home from '../pages/home/Home';
import Account from '../pages/account/Account';

import Checkout from '../pages/checkout/Checkout';
import Success from "../pages/checkout/success/Success";
import AdyenRedirect from "../pages/checkout/payment/redirect/AdyenRedirect";
import Cart from "../pages/cart/Cart";

import Login from "../modules/account/login/Login";
import Overview from "../modules/account/overview/Overview";
import ProductAlerts from "../modules/account/productalerts/ProductAlerts"; // eslint-disable-line no-unused-vars
import Wishlist from "../modules/account/wishlist/Wishlist";
import ForgotPassword from "../modules/account/forgotpassword/ForgotPassword";
import { default as AddressOverview } from "../modules/account/addressbook/overview/Overview";
import AddressCreate from "../modules/account/addressbook/address/AddressCreate";
import AddressEdit from "../modules/account/addressbook/address/AddressEdit";
import Newsletter from "../modules/account/newsletter/Newsletter";
import ChangePassword from "../modules/account/changepassword/ChangePassword";
import OrderOverview from "../modules/account/orders/overview/Overview";
import OrderDetails from "../modules/account/orders/details/Details";
import ResetPassword from "../modules/account/resetpassword/ResetPassword";
import Redirect from "../modules/account/resetpassword/Redirect";
import UpdateCustomer from "../modules/account/update_customer/UpdateCustomer";

import Builder from "../pages/builder/Builder";
import ProductList from "../pages/productlist/ProductList";
import ProductDetails from "../pages/productdetails/ProductDetails";
import Search from "../pages/search/Search";

import Styleguide from "../pages/styleguide/Styleguide";

import RequireAuth from './RequireAuth';
import GuestRoute from "./GuestRoute";
import Help from "../pages/help/Help"; // eslint-disable-line no-unused-vars
import useStoreViews from "../hooks/useStoreViews";
import NewsletterConfirmSubscription from "../pages/newsletter/ConfirmSubscription";

const AppRoutes = () => {
    const config = useConfig();
    const { prefix_category, prefix_product } = config.get('url')
    const { isKiosk } = useStoreViews();
    if (document.location.pathname.endsWith('/')) {
        return (
            <Navigate replace to={document.location.pathname.slice(0, -1)} />
        )
    }

    return (
        <Routes>
            <Route element={<Layout />} >
                <Route index element={<Home />} />

                {
                    !isKiosk &&
                    <>
                        <Route path="/account" element={<GuestRoute />} >
                            <Route path="/account/login" element={<Login />} />
                            <Route path="/account/forgotpassword" element={<ForgotPassword />} />
                            <Route path="/account/createPassword" element={<Redirect />} />
                            <Route path="/account/resetpassword" element={<ResetPassword />} />
                        </Route>

                        <Route path="/account" element={<RequireAuth />} >
                            <Route element={<Account />}>
                                <Route path="/account/overview" element={<Overview />} />
                                <Route path="/account/newsletter" element={<Newsletter />} />
                                <Route path="/account/account_information" element={<UpdateCustomer />} />
                                <Route path="/account/wishlist" element={<Wishlist />} />
                                {/*<Route path="/account/alerts" element={<ProductAlerts />} />*/}
                                <Route path="/account/addressbook/overview" element={<AddressOverview />} />
                                <Route path="/account/addressbook/create" element={<AddressCreate />} />
                                <Route path="/account/addressbook/edit" element={<AddressEdit />} />
                                <Route path="/account/changepassword" element={<ChangePassword />} />
                                <Route path="/account/orders" element={<OrderOverview />} />
                                <Route path="/account/order/:id" element={<OrderDetails />} />

                                {/* <Route path="/account/orders" element={<Orders />} />
                                <Route path="/account/profile" element={<Profile />} />
                                <Route path="/account/profile/edit" element={<EditProfile />} />
                                <Route path="/account/profile/editpassword" element={<EditPassword />} /> */}
                            </Route>
                        </Route>
                    </>
                }

                <Route path="/customer/account/createPassword" element={<Redirect />} />

                <Route path={"/search"} element={<Search />} />

                <Route path={"/help/:query/all/"} element={<Help />} />

                <Route path={prefix_product + "/:product_key"} element={<ProductDetails />} />

                <Route path={prefix_category + "/:category_key/*"} element={<ProductList />} />

                <Route path="/newsletter/subscriber/confirm/id/:id/code/:code" element={<NewsletterConfirmSubscription />} />

                <Route path="/styleguide" element={<Styleguide />} />

                <Route path=":builder_key/*" element={<Builder />} />

            </Route>

            <Route element={<MinimalLayout />}>

                <Route path={'/adyen'}>
                    <Route path={'/adyen/process/result'} element={<AdyenRedirect />} />
                </Route>
                <Route path={'/checkout'}>
                    <Route path={'/checkout/cart'} element={<Cart />} />
                    <Route element={<Checkout />}>
                        <Route index element={<Navigate replace to={'/checkout/cart'} />} />
                        <Route path={'/checkout/addresses'} />
                        <Route path={'/checkout/shipment'} />
                        <Route path={'/checkout/summary'} />
                        <Route path={'/checkout/payment'} />
                    </Route>
                    <Route path={'/checkout/payment/callback'} element={<AdyenRedirect />} />
                    <Route path={'/checkout/success'} element={<Success />} />
                </Route>
            </Route>
        </Routes>
    );
}

export default AppRoutes;
