const setShippingMethodOnCart = `
    mutation setShippingMethodsOnCart($cartId: String!, $shippingMethod: ShippingMethodInput!) {
        setShippingMethodsOnCart(
            input: {
                cart_id: $cartId
                shipping_methods: [$shippingMethod]
            }
        ) {
            cart {
                shipping_addresses {
                    selected_shipping_method {
                        carrier_code
                        carrier_title
                        method_code
                        method_title
                        amount {
                            value
                            currency
                        }
                    }
                }
            }
        }
    }
`

export default setShippingMethodOnCart;
