import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ResetProductId, UpdateProductId, } from '../../../redux/plp/actions';
import { connectInfiniteHits, connectStateResults } from 'react-instantsearch-dom';

import { t } from '../../../lib/translations/';

import Stats from '../stats/Stats';
import ProductTile from '../../../components/product/ProductTile';
import Button from '../../../components/form/button/Button';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import LoadingSkeleton from '../loadingskeleton/LoadingSkeleton';

import classes from './ProductGrid.module.css';

const AlgoliaLoadingState = connectStateResults(
    ({ isSearchStalled, children }) => (
        isSearchStalled
            ?
            <LoadingSkeleton count={[8]} className="plp_loading_skeleton" />
            :
            children
    )
);

const ProductGrid = ({ hits, hasMore, refineNext, hasPrevious, refinePrevious, category, query = false }) => {
    const dispatch = useDispatch();
    const previousProductId = useSelector(state => state.plp.productId);

    const loadMoreOnClick = () => {
        if (hasMore) {
            refineNext();
            dispatch(ResetProductId());
        }
    };

    const loadPreviousOnClick = (e) => {
        if (hasPrevious) {
            e.preventDefault();
            refinePrevious();
            dispatch(ResetProductId());
        }
    }

    const handleStateOnClick = (id) => {
        dispatch(UpdateProductId(id));
    };

    useEffect(() => {
        const gridTimeout = setTimeout(() => {
            if (!document.getElementById(previousProductId)) {
                return;
            }

            document.getElementById(previousProductId).scrollIntoView({ behavior: "smooth", block: "start" });
            dispatch(ResetProductId());
        }, 200)

        return () => clearTimeout(gridTimeout);
    }, []);

    return (
        <>
            <section className={classes.infinitebutton}>
                {
                    hasPrevious
                        ?
                        <Button
                            variant="secondary"
                            onClick={loadPreviousOnClick}
                            id="previous"
                        >
                            {t('algolia.productlist.load.previous.button')}
                        </Button>
                        :
                        null
                }
            </section>
            <div className={classes.root}>
                <AlgoliaLoadingState>
                    {
                        hits.map((hit) => {
                            return (
                                <React.Fragment key={hit.objectID}>
                                    <div className={classes.item} id={hit.objectID}
                                        onClick={() => handleStateOnClick(hit.objectID)}
                                        role="button"
                                        tabIndex="0"
                                    >
                                        <ProductTile product={hit} wishlistButton={true} query={query} isPLP />
                                    </div>
                                </React.Fragment>
                            );
                        })
                    }
                </AlgoliaLoadingState>
            </div>

            <Stats />

            <section className={classes.infinitebutton}>
                {
                    hasMore
                        ?
                        <Button
                            variant="secondary"
                            onClick={loadMoreOnClick}
                            id="load_more_button"
                        >
                            {t('algolia.productlist.load.more.button')}
                        </Button>
                        :
                        null
                }
            </section>


        </>
    )
}

export default connectInfiniteHits(ProductGrid);
