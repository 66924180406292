const checkoutAgreements = `
    query checkoutAgreements {
        checkoutAgreements {
            agreement_id
            checkbox_text
            content
            content_height
            is_html
            mode
            name
        }
    }
`;

export default checkoutAgreements;
