import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {

};

export default createReducer(initialState, {
    [actions.addValue]: (state, action) => {
        const { payload = [] } = action;
        state[payload.key] = payload.value;
    },

    [actions.addValues]: (state, action) => {
        const { payload = {} }  = action;

        state = {
            ...state,
            ...payload
        }

        return {
            ...state,
            ...payload
        }
    }

});
