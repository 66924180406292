import { useSelector } from 'react-redux';
import { useCartItems, useCartTotals } from '@kega/sps-cart';
import { useConfig, useShippingAddress, useShippingMethods } from "@kega/sps-core";

import { t } from '../../../lib/translations';

import Price from "../../../components/price/Price";

import useStoreViews from '../../../hooks/useStoreViews';

import classNames from "classnames";
import classes from './CartTotals.module.css';
import { consoleLog } from "../../../lib/utils";

const CartTotals = ({ className = '', isCart = false }) => {
    const items = useCartItems();
    const totals = useCartTotals();
    const config = useConfig();
    //const shippingCost = config.get('shippingCost');

    consoleLog('Total', totals, 'items', items);

    const freeShippingFrom = config.get('freeShippingFrom');
    const vatPercentage = config.get('vatPercentage');
    const { essentielCheckout } = useSelector((state) => state);

    const { shippingMethods } = useSelector((state) => state.checkout);

    const { shippingAddress } = useShippingAddress();
    const { shippingMethod } = useShippingMethods();
    const displayTaxBeforeAddress = config.get('displayTaxBeforeAddress');

    const promoCode = essentielCheckout?.couponCode;
    let realShippingCost = 0;
    let shippingMethodKnown = false;

    if (shippingMethod?.shipping_addresses[0]?.selected_shipping_method) {
        const selectedMethodCode = shippingMethod?.shipping_addresses[0]?.selected_shipping_method.method_code;
        shippingMethods.map( (item) => {
            if(item.method_code === selectedMethodCode) {
                realShippingCost = item.amount.value;
                shippingMethodKnown = true;
            }
        })
    } else {
        if(shippingMethods.length > 0) {
            realShippingCost = shippingMethods[0].amount.value;
            shippingMethodKnown = false;
        }
    }


    const displayTotalExcl = (!shippingAddress && !displayTaxBeforeAddress) ? true : false;
    const calculateTotalIncludingShippingCost = (shippingMethod !== false && !shippingMethodKnown);

    let counter = 0;
    items.map(({ quantity }) => {
        counter += quantity;
    })

    let totalTaxAmount = 0;
    let totalDiscountAmount = 0;
    if (totals?.applied_taxes?.length > 0) {
        totals?.applied_taxes?.forEach((item) => {
            totalTaxAmount += item.amount.value;
        })
    }

    items.forEach( (item) => {
        const itemDiscounts = item?.prices?.discounts ?? [];
        itemDiscounts.forEach( (discount) => {
            totalDiscountAmount += discount.amount.value;
        })
    })

    const { currentStoreCode } = useStoreViews();
    const countryUs = currentStoreCode.startsWith('us');

    return (
        totals && (
            !isCart
                ?
                <div className={classNames(classes.root, className)}>
                    <div className={classes.total}>
                        <span>{t('minicart.totals.total')} <span className={classes.counter}>({counter} {(counter === 1) ? t('minicart.totals.item') : t('minicart.totals.items')})</span></span>
                        <span>
                            {
                                (totals?.subtotal !== undefined && totals?.subtotal !== null) && <Price price={ totals.subtotal } />
                            }
                        </span>
                    </div>

                    {
                        totals?.discounts?.length > 0 && (
                            totals?.discounts?.map((discount, index) => {
                                return (
                                    <div className={classes.discounts} key={index}>
                                        <span>{t('checkout.cart.totals.discount')} ({discount.label})</span>
                                        <span>
                                            <Price
                                                className={classes.discount}
                                                price={discount.amount.value} />
                                        </span>
                                    </div>
                                )
                            })
                        )
                    }
                    {
                        realShippingCost !== null &&
                        <div className={classes.shipping}>
                            <span>{t('minicart.totals.shipping')}</span>
                            <span>
                                {
                                    freeShippingFrom === -1
                                        ? <Price price={realShippingCost} />
                                        : (totals.total > freeShippingFrom || realShippingCost === 0)
                                            ?
                                            <div className={classes.free}>{t('Free')}</div>
                                            :
                                            <Price price={realShippingCost} />
                                }
                            </span>
                        </div>

                    }
                </div>
                :
                <div className={classNames(classes.root, classes.totals_cart, className)}>
                    <div className={classes.sub_total}>
                        <span>
                            {countryUs
                                ? t('checkout.cart.totals.sub_total_exc')
                                : t('checkout.cart.totals.sub_total')}
                        </span>
                        <span>
                            {<Price price={ countryUs ? totals?.subtotal_excluding_tax : totals?.subtotal } />}
                        </span>
                    </div>

                    {
                        totals?.discounts?.length > 0 && (
                            totals?.discounts?.map((discount, index) => {
                                return (
                                    <div className={classes.discount_row} key={index}>
                                        <span>{t('checkout.cart.totals.discount')} ({discount.label})</span>
                                        <span>
                                            <Price
                                                className={classes.discount}
                                                price={discount.amount.value} />
                                        </span>
                                    </div>
                                )
                            })
                        )
                    }
                    {
                        realShippingCost !== null &&
                        <div className={classes.shipping}>
                            <span>{t('checkout.cart.totals.shipping')}</span>
                            <span>
                                {
                                    freeShippingFrom === -1
                                        ? <Price price={realShippingCost} />
                                        : (totals.total > freeShippingFrom || realShippingCost === 0)
                                            ?
                                            <div className={classes.free}>{t('Free')}</div>
                                            :
                                            <Price price={realShippingCost} />
                                }
                            </span>
                        </div>
                    }
                    {
                        (
                            (!shippingAddress && displayTaxBeforeAddress && countryUs)
                            ||
                            (shippingAddress && !displayTaxBeforeAddress && countryUs)
                        )
                        &&
                        <div className={classes.vat_amount}>
                            <span>{vatPercentage !== -1 ? t('checkout.cart.totals.total', { percentage: vatPercentage }) : t('checkout.cart.totals.vat')}</span>
                            <span>
                                {
                                    totals?.applied_taxes?.length > 0
                                    &&
                                    <Price price={totalTaxAmount} />
                                }
                            </span>
                        </div>
                    }
                    <div className={classes.total}>
                        <span>
                            {
                                displayTotalExcl ? t('checkout.cart.totals.total_exc') : t('checkout.cart.totals.total')
                            }
                        </span>
                        <span>
                            {
                                (totals?.total !== undefined && totals?.total !== null) && <Price price={ calculateTotalIncludingShippingCost ? totals.total + realShippingCost : totals.total } />
                            }
                        </span>
                    </div>
                    {
                        (totalTaxAmount > 0 && vatPercentage !== -1) &&
                        <div className={classes.tax}>
                            <span>
                                { t('checkout.cart.totals.incvat', { percentage: vatPercentage }) }
                            </span>
                        </div>
                    }
                </div>
        )
    );
}

export default CartTotals;
