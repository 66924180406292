import { t } from '../../../../lib/translations';

import { Radio } from '../../../../components/form';
import Price from '../../../../components/price/Price';

import classes from './ShipmentMethod.module.css';

const ShipmentMethod = ({ data: { carrier_title, carrier_code, amount }, selected, onClick = () => { } }) => {

    const displayPrice = amount?.value === 0 || amount?.value === "0.00"
        ? <div className={classes.price}>{t("Free")}</div>
        : <Price price={amount?.value} rootClassName={classes.price} />;

    return (
        <div className={classes.root} onClick={onClick.bind(this, carrier_code)}>
            <Radio name={carrier_code} className={classes.checkbox} checked={selected} />
            <div className={classes.shipping_info}>
                <span>{carrier_title}</span>
                <span>{t('checkout.steps.payment.standard_shipping_days')}</span>
            </div>
            {displayPrice}
        </div>
    )
}

export default ShipmentMethod;
