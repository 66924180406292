import React, { useState, useEffect } from "react";
import { Builder, BuilderBlocks } from "@builder.io/react/lite";
import { Form } from "@kega/sps-elements";

import { t } from "../../../../lib/translations";

import useNewsletter from "../../../../hooks/useNewsletter";
import useStoreViews from "../../../../hooks/useStoreViews";

import { Button, Input, Select, Field } from "../../../../components/form";
import { Row } from "../../../../components/grid";

import classNames from "classnames";
import classes from './NewsletterUnsubscribe.module.css';

export const NewsletterUnsubscribe = (props) => {
    const { className } = props;
    const newsletterClasses = classNames(classes.root, className);

    const searchParams = new URLSearchParams(document.location.search)
    const email = searchParams.get('email');

    const { unsubscribe } = useNewsletter()
    const { currentStoreCode } = useStoreViews();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);

    const reasons = [
        { key: "too_many",label: t('newsletter.unsubscribe.reason.too_many') },
        { key: "not_relevant",label: t('newsletter.unsubscribe.reason.not_relevant') },
        { key: "other",label: t('newsletter.unsubscribe.reason.other') },
    ];

    const [inputs, setInputs] = useState({
        email: '',
        reason: '',
    });

    const isLive = !Builder.isEditing && !Builder.isPreviewing;

    const onChange = ({ target: { name, value } }) => {
        const newInputs = {
            ...inputs,
            [name]: value
        };

        setInputs(newInputs);
    }

    useEffect(() => {
        const newInputs = {
            ...inputs,
            ['email']: email
        };
        setInputs(newInputs);
    }, [email])

    const OnSubmit = async ({ valid, values, resetForm }) => {

        if (!isLive) {
            return;
        }

        if (valid) {
            try {
                setLoading(true);
                unsubscribe(inputs.email, inputs.reason, currentStoreCode).then( (result) => {
                    setLoading(false);
                    if(result){
                        const status = result.unsubscribeFromNewsletter.status;
                        switch (status) {
                            case 'STATUS_UNSUBSCRIBED':
                            case 'UNSUBSCRIBED':
                            case 'NOT_ACTIVE':
                                setMessage(t('newsletter.unsubscribe.message.success'));
                                break;
                            default:
                                setMessage(t('newsletter.unsubscribe.message.error'));
                                break;
                        }
                    }
                });

            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <>
            <div className={newsletterClasses}>
                <Form onSubmit={OnSubmit}>
                    <Row>
                        <Field>
                            <Input
                                name="email"
                                label={t('customer.form.email')}
                                placeholder={t('customer.form.email')}
                                value={inputs.email}
                                onChange={onChange}
                                required
                            />
                        </Field>
                    </Row>
                    <Row>
                        <Field>
                            <Select name="reason" value={inputs.reason} labelClassName={classes['label']} required={true} onChange={onChange}>
                                <option disabled value="">{t('newsletter.unsubscribe.reason.select')}</option>
                                {reasons.map((reason) =>
                                    <option key={reason.key} value={reason.key}>{ reason.label }</option>
                                )}
                            </Select>
                        </Field>
                    </Row>
                    <Row>
                        <Field md={4}>
                            <Button className={classes.save_btn} type="submit" loading={loading} variant="primary">
                                {t('newsletter.unsubscribe.button_title')}
                            </Button>
                        </Field>

                    </Row>
                    {
                        message &&
                        <span className={classes.success}>{ message }</span>
                    }
                </Form>
            </div>
        </>
    );
};

Builder.registerComponent(NewsletterUnsubscribe, {
    name: "NewsletterUnsubscribe",
    static: true,
    defaultStyles: {
        marginTop: '0px',
    },
    inputs: [
    ]
});
