import { useRef } from "react";
import { useChangePassword } from '@kega/sps-core';

import { Form } from '@kega/sps-elements';

import { Button, Input, Field } from '../../../components/form';
import { Row, Container } from '../../../components/grid';

import useSystemMessage from '../../../hooks/useSystemMessage';
import useInputValidation from '../../../hooks/form/useInputValidation';

import { t } from '../../../lib/translations';

import classes from "./ChangePassword.module.css";

const ChangePassword = () => {
    const { setSystemSuccessMessage } = useSystemMessage();
    const { confirmField, passwordValidation } = useInputValidation();
    const { loading, changePassword } = useChangePassword();

    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const onPasswordChange = () => {
        passwordConfirmRef.current?.triggerChange();
    };

    const submitForm = async ({ valid, values }) => {
        const { currentPassword: oldPassword, newPassword } = values;

        if (valid) {
            await changePassword({ oldPassword, newPassword });
            setSystemSuccessMessage(t('account.change_password.success'));
        }
    }

    return (
        <Container xl margins g-xs={0} g-sm={1} className={classes.change_password_container}>
            <Row>
                <Field>
                    <h1>{t('account.change_password.title')}</h1>
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Field md={8}>
                                <Input
                                    name="currentPassword"
                                    type="password"
                                    label={t('account.change_password.password_current')}
                                    placeholder={t('account.change_password.password_current')}
                                    required />
                            </Field>
                        </Row>
                        <Row>
                            <Field md={8}>
                                <Input
                                    name="newPassword"
                                    type="password"
                                    label={t('account.change_password.password_new')}
                                    placeholder={t('account.change_password.password_new')}
                                    ref={passwordRef}
                                    rules={[ passwordValidation ]}
                                    onChange={onPasswordChange}
                                    required />
                            </Field>
                        </Row>
                        <Row>
                            <Field md={8}>
                                <Input
                                    name="confirm_password"
                                    label={t('account.change_password.password_new_confirm')}
                                    placeholder={t('account.change_password.password_new_confirm')}
                                    ref={passwordConfirmRef}
                                    rules={[ confirmField(passwordRef, t('account.change_password.error.password_confirm')) ]}
                                    type="password" />
                            </Field>
                        </Row>
                        <Row>
                            <Field xl={3} lg={4} md={6}>
                                <Button
                                    className={classes.pw_button}
                                    type="submit"
                                    variant="primary"
                                    loading={loading}
                                >
                                    {t('account.change_password.button')}
                                </Button>

                            </Field>
                        </Row>
                    </Form>
                </Field>
            </Row>

        </Container>
    )

}

export default ChangePassword;