import { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Builder } from "@builder.io/react/lite";

import useStoreViews from "../../../../hooks/useStoreViews";

import classNames from "classnames";
import classes from './SalesBanner.module.css';

export const SalesBanner = ({ tab_item, padding, text_size, cta_enabled, cta_url, cta_url_tab, cta_url_external, text_color, background_color, animation_enabled }) => {
    const { getUrl } = useStoreViews();

    const ref = useRef(null);
    const duplicatedTabItems = Array(15).fill(tab_item).flat();

    useEffect(() => {
        const marqueeElem = ref.current;
        let scrollAmount = 0;
    
        let originalSetWidth = 0;
        marqueeElem.childNodes.forEach((child, index) => {
            if (index < tab_item?.length) { 
                originalSetWidth += child.clientWidth;
            }
        });
    
        const startMarquee = () => {
            scrollAmount += 1; 
            if (scrollAmount >= originalSetWidth) {
                scrollAmount = 0; 
            }
            marqueeElem.style.transform = `translateX(-${scrollAmount}px)`;
            requestAnimationFrame(startMarquee);
        };
    
        startMarquee();
    }, [tab_item]);

    const content = (
        <div className={classNames(classes.root, animation_enabled && classes.tickerwrap)} style={{ backgroundColor: background_color, color: text_color }}>
            <div className={classNames(classes.container, animation_enabled ? classes.ticker : classes.text_align)}>
                <div ref={ref}>
                    {duplicatedTabItems.map((tab_item, key) => (
                        <div key={key} className={classNames(classes['pad-' + padding], animation_enabled && classes.item, classes['textsize-' + text_size])}>
                            {tab_item?.name}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    return (

        cta_enabled ?
            (
                <Link className={classNames(classes.button, classes['textsize-' + text_size])} to={!cta_url_external ? getUrl(cta_url) : null} href={cta_url_external ? cta_url : null} linkComponent={cta_url_external ? null : Link} target={cta_url_tab ? "_blank" : "_self"} aria-label={cta_url_tab ? "Link opens in a new tab" : "Link opens in current tab"} rel="noreferrer">
                    {content}
                </Link>
            )
            :
            content
    );
};

Builder.registerComponent(SalesBanner, {
    name: "SalesBanner",
    static: true,
    defaultStyles: {
        marginTop: '0px',
    },
    inputs: [
        {
            name: 'tab_item',
            friendlyName: 'Tab',
            type: 'list',
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                    defaultValue: 'Tab name'
                }
            ],
            onChange: (options) => {
                if (options.get('tab_item')?.length > 20) {
                    options.set('tab_item', options.get('tab_item').slice(0, 20));
                }
            },
            helperText: 'Max 20 tabs can be added.'
        },
        {
            type: 'string',
            name: 'padding',
            friendlyName: 'Padding',
            required: true,
            enum: [
                {
                    label: 'No padding',
                    value: 'no_padding',
                },
                {
                    label: 'XS',
                    value: 'extra_small',
                },
                {
                    label: 'S',
                    value: 'small',
                },
                {
                    label: 'M',
                    value: 'medium',
                },
                {
                    label: 'L',
                    value: 'large',
                },
                {
                    label: 'XL',
                    value: 'extra_large',
                }
            ],
            defaultValue: 'extra_small',
        },
        {
            type: 'string',
            name: 'text_size',
            friendlyName: 'Text Size',
            required: true,
            enum: [
                {
                    label: 'Size XXL',
                    value: 'size-xxl',
                },
                {
                    label: 'Size XL',
                    value: 'size-xl',
                },
                {
                    label: 'Size L',
                    value: 'size-l',
                },
                {
                    label: 'Size M',
                    value: 'size-m',
                },
                {
                    label: 'Size S',
                    value: 'size-s',
                },
                {
                    label: 'Size XS',
                    value: 'size-xs',
                }
            ],
            defaultValue: 'size-s',
        },
        {
            type: "boolean",
            name: 'cta_enabled',
            friendlyName: 'CTA'
        },
        {
            type: 'string',
            name: 'cta_url',
            friendlyName: 'CTA Url',
            defaultValue: '',
            showIf: (options) => options.get('cta_enabled'),
        },
        {
            type: 'boolean',
            name: 'cta_url_tab',
            friendlyName: 'CTA Url in new tab',
            defaultValue: 0,
            showIf: (options) => options.get('cta_enabled'),
        },
        {
            type: 'boolean',
            name: 'cta_url_external',
            friendlyName: 'CTA External Url',
            defaultValue: 0,
            showIf: (options) => options.get('cta_enabled'),
        },
        {
            type: 'color',
            name: 'text_color',
            friendlyName: 'Text color',
            defaultValue: '#000',
        },
        {
            type: 'color',
            name: 'background_color',
            friendlyName: 'Background color',
            defaultValue: '#E02E2E',
        },
        {
            type: "boolean",
            name: 'animation_enabled',
            friendlyName: 'Animation'
        },
    ]
});
