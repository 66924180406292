import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import useStoreViews from "../../../../../hooks/useStoreViews";
import useFetchCustomer from "../../../../../hooks/customer/useFetchCustomer";

import { Row, Col } from "../../../../../components/grid";
import Address from "../../../../../components/address/Address";

import { t } from "../../../../../lib/translations";
import classes from "./AddressList.module.css";

import DeleteCustomerAddressModal from "../delete_customer_modal/DeleteCustomerAddressModal";
import useDeleteCustomerAddress from "../../../../../hooks/customer/useDeleteCustomerAddress";

const AddressList = () => {
    const { loading, customer = {} } = useFetchCustomer();
    const { addressDelete, loading: deleteLoading } = useDeleteCustomerAddress();
    const [selectedItem, setSelectedItem] = useState();
    const navigate = useNavigate();
    const { getUrl } = useStoreViews();
    const modalRef = useRef();

    const { addresses = [] } = customer;
    let defaultBilling = null,
        defaultShipping = null,
        otherAddresses = [];

    addresses.forEach((address) => {
        if (!address) {
            // Ignore empty addresses in state
        } else if (address.default_billing || address.default_shipping) {
            if (address.default_billing) {
                defaultBilling = address;
            }

            if (address.default_shipping) {
                defaultShipping = address;
            }
        } else {
            otherAddresses.push(address);
        }
    });

    const toEdit = (address) => {
        navigate(getUrl("account/addressbook/edit"), {
            state: { address: address },
        });
    };

    const openModal = (item) => {
        setSelectedItem(item);
        modalRef.current?.open();
    };

    const deleteCustomer = (customerAddressId) => {
        addressDelete(customerAddressId);
        modalRef.current?.close();
    };

    return (
        <>
            {loading || addresses.length > 0 ? (
                <>
                    <Row className={classes.wrapper}>
                        <Col lg={8}>
                            <h2>{t("address_book.default_shipping")}</h2>
                            <div>
                                <Address
                                    loading={loading}
                                    onEdit={toEdit}
                                    address={defaultShipping} />
                            </div>
                        </Col>
                    </Row>
                    <Row className={classes.wrapper}>
                        <Col lg={8}>
                            <h2>{t("address_book.default_billing")}</h2>
                            <div>
                                <Address
                                    loading={loading}
                                    onEdit={toEdit}
                                    address={defaultBilling} />
                            </div>
                        </Col>
                    </Row>
                    <Row className={classes.other}>
                        <Col lg={8}>
                            <h2>{t("address_book.other_addresses")}</h2>
                            <div>
                                {loading && <Address loading address={{}} />}
                                {!loading &&
                                    !!otherAddresses.length &&
                                    otherAddresses.map(
                                        (otherAddress, index) => (
                                            <Address
                                                onDelete={openModal}
                                                key={index}
                                                onEdit={toEdit}
                                                address={otherAddress} />
                                        )
                                    )}
                                {!loading && !otherAddresses.length && (
                                    <span>
                                        {t("address_book.no_addresses")}
                                    </span>
                                )}
                            </div>
                        </Col>
                    </Row>
                </>
            ) : (
                <Row className={classes.wrapper}>
                    <Col>{t("address_book.no_addresses")}</Col>
                </Row>
            )}
            <DeleteCustomerAddressModal
                ref={modalRef}
                item={selectedItem}
                deleteCustomer={deleteCustomer}
                loading={deleteLoading} />
        </>
    );
};

export default AddressList;
