import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useCustomer } from '@kega/sps-core';
import useStoreViews from "../hooks/useStoreViews";

const GuestRoute = () => {
    const { authenticated } = useCustomer();
    const { getUrl } = useStoreViews();
    const location = useLocation();
    const [returnPath, setReturnPath] = useState(null);

    useEffect(() => {
        if (location.state !== null) {
            setReturnPath(getUrl(location.state.returnPathWishlist));
        } else {
            setReturnPath(getUrl('account/overview'));
        }
    }, [setReturnPath])

    if (authenticated && returnPath) {
        return <Navigate replace to={returnPath} />;
    }

    return <Outlet />;
}

export default GuestRoute;
