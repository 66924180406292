import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

import Price from "../../../../../components/price/Price";

import classes from "./Total.module.css";

const Total = ({ loading, className, labelClassMame, priceClassName, label, amount, currency, negative }) => {
    const displayAmount = amount || 0;

    return (
        <div className={classNames(classes.root, className)}>
            <span className={classNames(classes.label, labelClassMame)}>
                {
                    loading
                        ? <Skeleton />
                        : <span>{label}</span>
                }
            </span>
            {
                loading
                    ? <span className={classNames(classes.price, priceClassName)}><Skeleton /></span>
                    : (
                        <Price
                            className={classNames(classes.price, priceClassName)}
                            price={displayAmount}
                            negative={negative}
                            currency={currency} />
                    )
            }
        </div>
    );
};

export default Total;