import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    id: null,
    items: [],
    itemsBySku: []
};

export default createReducer(initialState, {
    [actions.UpdateWishlist]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            ...payload
        };
    },

});
