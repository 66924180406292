import { useEffect, useMemo, useState } from 'react';
import { useConfig } from '@kega/sps-core';
import { useLocation, useNavigate } from 'react-router-dom';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from "react-redux";

import qs from 'qs';
import algoliasearch from 'algoliasearch/lite';

import { t } from "../../lib/translations";
import ScrollToTop from '../../utils/ScrollToTop';

import useUi from '../../hooks/useUi';
import useMediaQuery from '../../hooks/useMediaQuery';

import FiltersDrawer from './filters/FiltersDrawer';
import ProductGrid from './productgrid/ProductGrid';
import ClearFilters from './clearfilters/ClearFilters';
import ClearAllFilters from './clearfilters/ClearAllFilters';
import SortBy from "./sortby/SortBy";
import MobileSortBy from "./sortby/MobileSortBy";
import StructuredData from "./structureddata/StructuredData";

import { Col, Row, Container } from '../../components/grid';
import { ChevronIcon } from '../../components/icons';

import classes from './ProductList.module.css';
import classNames from 'classnames';
import Stats from "./stats/Stats";

const urlToSearchState = ({ search }) => {
    const {
        sortBy = null,
        hitsPerPage = null,
        page = 1,
        q = null,
        ...params
    } = qs.parse(search.replace('?', ''), { comma: true });

    const refinementList = {};
    const range = {};
    Object.keys(params).forEach((key) => {
        const param = params[key];
        if (param?.range) {
            const [min, max] = param.range.split('-');

            range[key] = {
                min: (min === '' ? undefined : min),
                max: (max === '' ? undefined : max),
            }
        } else {
            refinementList[key] = param;
        }

    });

    let state = {
        refinementList: refinementList,
        range: range
    }

    if (range) { state.range = range; }
    if (sortBy) { state.sortBy = sortBy; }
    if (hitsPerPage) { state.hitsPerPage = hitsPerPage; }
    if (page > 1) { state.page = page; }
    if (q) { state.query = q; }

    return state;
};

const searchStateToUrl = (searchState) => {
    const { sortBy = null, hitsPerPage = null, page = 1, query, refinementList = {}, range = {} } = searchState;

    let searchParams = Object.keys(refinementList).reduce((params, key) => {
        if (refinementList[key]) {
            params[key] = refinementList[key]
        }
        return params;
    }, {});

    searchParams = Object.keys(range).reduce((params, key) => {
        if (range[key]) {
            const { min, max } = range[key];
            if (min !== undefined || max !== undefined) {
                params[key] = {
                    range: (min !== undefined ? min : '') + '-' + (max !== undefined ? max : '')
                }
            }

        }
        return params;
    }, searchParams);

    if (sortBy) { searchParams['sortBy'] = sortBy; }
    if (hitsPerPage) { searchParams['hitsPerPage'] = hitsPerPage; }
    if (page > 1) { searchParams['page'] = page; }
    if (query) { searchParams['q'] = query; }

    return '?' + qs.stringify(searchParams, { arrayFormat: 'comma', encodeValuesOnly: true });
}

const AlgoliaProductList = ({ title, description, loading, filters, query }) => {
    const config = useConfig();
    const navigate = useNavigate();
    const { search } = useLocation();
    const location = useLocation();
    const [showAll, setShowAll] = useState(false);
    const { disable_seo_plp_title, disable_seo_plp_description } = useSelector((state) => state.storeConfig);

    const { match: desktop } = useMediaQuery('(min-width: 769px)');

    const { openFilters, openSortBy } = useUi();
    const storeCode = config.get('storeCode');
    const { index, appId, apiKey } = config.get('algolia');
    const { facets: configuredFacets, pagination: { defaultPerPage } } = config.get('productlist');
    const formattedCode = storeCode.replace("_kiosk", "");

    const searchClient = useMemo(() => algoliasearch(appId, apiKey), [appId, apiKey]);

    const searchState = useMemo(() => {
        return urlToSearchState({ search: search });
    }, [search])

    const onSearchStateChange = (nextSearchState) => {
        navigate({
            pathname: location.pathname,
            search: searchStateToUrl(nextSearchState),
        });
    }

    const countFilteredItems = (searchState) => {
        let filteredAmount = 0;

        if (searchState?.refinementList) {
            Object.keys(searchState?.refinementList).forEach((key) => {
                if (searchState?.refinementList[key]) {
                    if (typeof searchState?.refinementList[key] === 'string') {
                        filteredAmount += 1;
                    } else {
                        filteredAmount += searchState?.refinementList[key].length;
                    }
                }
            });
        }

        return filteredAmount;
    }

    const [filteredAmount, setFilteredAmount] = useState(countFilteredItems(searchState));

    useEffect(() => {
        if (searchState?.refinementList) {
            setFilteredAmount(countFilteredItems(searchState));
        }
    }, [searchState]);

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={index + formattedCode + '_products'}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
        >
            <ScrollToTop productList={true} />
            <StructuredData title={title} />
            <Configure
                query={query}
                filters={filters}
                clickAnalytics={true}
                hitsPerPage={60} />
            <Container margins g-xs={1} g-sm={3} g-md={2} g-xl={4} className={classes.filter_container}>
                {
                    (loading || title) &&
                    <Row className={classes.row}>
                        <Col>
                            {
                                !disable_seo_plp_title &&
                                <h1 className={classes.title}>
                                    {
                                        title ?
                                            <>{title}</>
                                            :
                                            <div className={classes.skeleton}>
                                                <Skeleton />
                                            </div>
                                    }
                                </h1>
                            }
                            {
                                !disable_seo_plp_description &&
                                <div className={classNames(description ? classes.desc : classes.hide, showAll && classes.showAll)}>
                                    {
                                        description ?
                                            <>
                                                <span className={classes.desc_text}>{description}</span>
                                                <div className={classes.icon} onClick={() => setShowAll((prev) => !prev)}>
                                                    <div className={classes.chevron}>
                                                        <ChevronIcon color="#D1D3D3" direction={showAll ? "bottom" : "top"} size={12}/>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <div className={classes.skeleton}>
                                                <Skeleton/>
                                            </div>
                                    }
                                </div>
                            }
                        </Col>
                    </Row>
                }

                <Row className={classes.toolbar_row}>
                    <Col xs={12} className={classes.filter_col}>
                        <Stats countOnly={true} />
                        <FiltersDrawer configuredFacets={configuredFacets} maxValuesPerFacet={500} defaultRefinement={index + formattedCode + "_products"} />
                        <div className={classes.toggle_filter}>
                            <div
                                className={classes.toggle_filter_button}
                                onClick={openFilters}
                                role="button"
                                tabIndex="0">
                                {t('algolia.productlist.filter.open_filter')}
                                {
                                    filteredAmount > 0 &&
                                    <span className={classes.filtered_amount}>
                                        {filteredAmount}
                                    </span>
                                }
                                <span>|</span>
                                {t('algolia.productlist.filter.open_sortby')}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className={classes.product_container}>
                <Row>
                    <Col className={classes.product_row}>
                        <ProductGrid category={title} query={query} />
                    </Col>
                </Row>
            </Container>
        </InstantSearch>
    )
}

export default AlgoliaProductList;
