import { useSelector } from "react-redux";
import { CartItems } from "@kega/sps-cart";
import { Link } from "react-router-dom";
import { useClearCart, useConfig, useCustomer } from "@kega/sps-core";
import { useEffect, useState } from "react";
import aa from "search-insights";

import { t } from '../../../lib/translations';

import useStoreViews from "../../../hooks/useStoreViews";
import useI18n from "../../../hooks/useI18n";
import useTagManager from "../../../hooks/useTagManager";
import useQueryIDMapping from "../../../hooks/algolia/useQueryIDMapping";

import CheckoutItem from "../checkoutitem/CheckoutItem";
import CartTotals from "../../minicart/carttotals/CartTotals";
import CheckoutRegister from "../register/CheckoutRegister";
import BuilderSection from "../../builder/BuilderSection";

import { Container, Row, Col } from '../../../components/grid';
import Card from "../../../components/card/Card";
import Drawer from "../../../components/drawer/Drawer";
import { LinkButton } from "../../../components/form";
import { CrossIcon } from "../../../components/icons";

import classes from './Success.module.css';
import checkoutClasses from '../../../modules/checkout/Checkout.module.css'
import classNames from "classnames";

const Success = ({ items, totals }) => {
    const { checkout, essentielCheckout } = useSelector((state) => state);
    const clearCart = useClearCart();
    const config = useConfig();
    const { getUrl, isKiosk } = useStoreViews();
    const { getCountryNameByCode } = useI18n();
    const { ecommerceEvent } = useTagManager();
    const { clear, queryIDS } = useQueryIDMapping();
    const { authenticated } = useCustomer();

    const [isOpen, setIsOpen] = useState(true);
    const { index } = config.get('algolia');
    const storeCode = config.get('storeCode');
    const formattedCode = storeCode.replace('_kiosk', '');

    const { shippingAddress, billingAddress, shippingMethod } = checkout;
    const { lastOrderId, guestEmail, paymentMethodTitle } = essentielCheckout;

    const pushLayer = (totals, items, paymentType) => {

        const defaultCurrency = config.get('currency') ?? 'EUR';

        try {

            let data = {
                transaction_id: lastOrderId,
                currency: defaultCurrency,
                value: totals.total,
                tax: totals.applied_taxes?.length > 0 ? totals.applied_taxes[0] : 0,
                shipping: totals.shipping ?? 0,
                items: [
                ]
            }

            items.forEach((item, index) => {
                const discounts = item.prices.discounts ?? null;
                data.items.push({
                    item_id: item.sku,
                    item_name: item.name,
                    affiliation: "",
                    coupon: discounts ? discounts[0].label : '',
                    discount: discounts ? discounts[0].amount.value : 0,
                    index: index,
                    item_brand: "",
                    item_category: item.att_text_perfion_productgroupcode,
                    item_list_id: "",
                    item_list_name: "",
                    item_variant: item.configurable_options.length > 0 ? item.configurable_options[0].value_label : '',
                    location_id: "",
                    price: item.price_range.minimum_price.regular_price.value,
                    quantity: item.quantity
                })
            });

            ecommerceEvent('purchase', data);
        } catch (error) {
            console.log('gtm error','purchase', error)
        }

        try {
            const objectIds = items.map((item) => {
                return item.sku;
            });

            const objectData = items.map((item) => {
                const discounts = item.prices.discounts ?? null;

                return {
                    queryID: queryIDS[item.sku],
                    price: item.price_range.minimum_price.regular_price.value,
                    discount: discounts ? discounts[0].amount.value : 0,
                    quantity: item.quantity
                }
            });

            console.log(objectData);

            aa(queryIDS ? 'purchasedObjectIDsAfterSearch' : 'purchasedObjectIDs', {
                eventName: queryIDS ? 'Products purchased (after search)' : 'Products purchased',
                index: index + formattedCode + '_products',
                objectIDs: objectIds,
                objectData: objectData,
                currency: defaultCurrency,
            });
        } catch (error) {
            //
        }
    }

    useEffect(() => {
        pushLayer(totals, items)
        return () => {
            clearCart();
            clear();
        }
    }, [])

    return (
        <div className={checkoutClasses.root}>
            <Container xl g-xs={2} g-lg={6}>
                <Row>
                    <Col md={12}>
                        <div className={checkoutClasses.checkout_title}>
                            <h1>{t("checkout.success.title")}</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} order-xs={2} order-md={1}>
                        <Card className={classes.checkout_success_card}>
                            <div className={classes.heading}>
                                <h3>
                                    {t('checkout.success.order_number')}
                                </h3>
                            </div>
                            <div className={classes.card_content}>
                                {lastOrderId}
                            </div>
                        </Card>
                        <Card className={classes.checkout_success_card}>
                            <div className={classes.heading}>
                                <h3>
                                    {t('checkout.success.shipping_address')}
                                </h3>
                            </div>
                            <div className={classes.card_content}>
                                <div className={classes.shipping_address}>
                                    {
                                        shippingAddress &&
                                        <p>
                                            {shippingAddress.firstname} {shippingAddress.lastname} <br/>
                                            { !!shippingAddress.company && (<>{shippingAddress.company} <br/></>) }
                                            {shippingAddress.street} <br/>
                                            {shippingAddress.postcode} {shippingAddress.city} <br/>
                                            {getCountryNameByCode(shippingAddress.country.code)} <br/>
                                            T +{shippingAddress.telephone}
                                        </p>
                                    }
                                </div>
                                <div className={classes.shipping_estimation}>
                                    <h5>{t('checkout.success.shipping_estimate')}</h5>
                                    {t('checkout.steps.payment.standard_shipping_days')}
                                </div>
                                <div className={classes.shipping_method}>
                                    <h5>{t('checkout.success.shipping_method')}</h5>
                                    {
                                        shippingMethod?.shipping_addresses.length > 0 &&
                                        shippingMethod.shipping_addresses[0].selected_shipping_method?.method_title
                                    }
                                </div>
                            </div>
                        </Card>
                        <Card className={classes.checkout_success_card}>
                            <div className={classes.heading}>
                                <h3>
                                    {t('checkout.success.billing_address')}
                                </h3>
                            </div>
                            <div className={classes.card_content}>
                                <div className={classes.billing_address}>
                                    {
                                        billingAddress &&
                                        <p>
                                            {billingAddress.firstname} {billingAddress.lastname} <br/>
                                            { !!billingAddress.company && (<>{billingAddress.company} <br/></>) }
                                            { !!billingAddress.vat_id && (<>{billingAddress.vat_id} <br/></>) }
                                            {billingAddress.street} <br/>
                                            {billingAddress.postcode} {billingAddress.city} <br/>
                                            {getCountryNameByCode(billingAddress.country.code)} <br/>
                                            T +{billingAddress.telephone}
                                        </p>
                                    }
                                </div>
                                <div className={classes.payment_method}>
                                    <h5>{t('checkout.success.payment_method')}</h5>
                                    {paymentMethodTitle}
                                </div>
                            </div>
                        </Card>
                        {
                            (!authenticated && !isKiosk) &&
                            <Drawer open={isOpen} drawerClassName={classes.drawer}>
                                <div className={classes.container}>
                                    <div className={classes.icon}>
                                        <CrossIcon className={classes.close} size={16} onClick={() => setIsOpen(false)} />
                                    </div>
                                    <BuilderSection model="success-register-drawer-top" />
                                    <CheckoutRegister
                                        className={classes.checkout_register}
                                        lastOrderId={lastOrderId}
                                        shippingAddress={shippingAddress}
                                        guestEmail={guestEmail}
                                    />
                                </div>
                                <BuilderSection model="success-register-drawer-bottom" />
                            </Drawer>
                        }
                    </Col>
                    <Col md={6} order-xs={1} order-md={2}>
                        <Card className={classNames(classes.checkout_success_card, classes.success_items)}>
                            <div className={classes.heading}>
                                <h3>
                                    {t('checkout.success.cart_items')}
                                </h3>
                            </div>
                            <div className={classes.card_content_cart}>
                                <CartItems
                                    className={classes.checkout_success_items}
                                    itemComponent={CheckoutItem} />
                                <div className={classes.totals_wrapper}>
                                    <CartTotals isCart className={classes.totals} />
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={classes.success_actions}>
                        <LinkButton variant="secondary" linkComponent={Link} to={getUrl('/frequently-asked-questions')}>
                            {t('checkout.success.check_faq')}
                        </LinkButton>
                        <LinkButton variant="primary" linkComponent={Link} to={getUrl('/')}>
                            {t('checkout.success.back_to_home')}
                        </LinkButton>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Success;