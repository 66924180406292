import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCartId, useConfig, useRequest, useStorefront } from '@kega/sps-core';
import { addShippingMethods } from "@kega/sps-core/src/reducers/checkout/actions";

const useVertex = () => {
    const { api: { checkout } } = useStorefront();
    const dispatch = useDispatch();
    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const getVertexResults = useCallback( async (cartId) => {
        try {
            setRequest();
            const result = await checkout.getVertexResults({ cartId: cartId });
            return result;
        } catch (error) {
            console.log('[getVertexResults]', error);
            setError(error);
        }
    }, []);

    return {
        error,
        loading,
        getVertexResults
    };
}

export default useVertex;