import React, { useEffect, useState } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';

import { t } from '../../../lib/translations';

import useFetchProducts from "../../../hooks/useFetchProducts";

import LoadingSkeleton from '../../../modules/algolia/loadingskeleton/LoadingSkeleton';
import ConfigurableOrder from '../../../modules/productdetails/configurableorder/ConfigurableOrder';
import ProductTile from '../../product/ProductTile';

import classes from './GiftboxList.module.css';

const GiftboxList = ({ hits }) => {
    const { fetchProducts, loading, products: stateProducts } = useFetchProducts();
    const [products, setProducts] = useState(stateProducts ? stateProducts : []);
    const urlsKeys = hits.map((hit) => {
        return hit?.url_key;
    });

    useEffect( () => {
        const fetchContent = async () => {
            const result = await fetchProducts(urlsKeys, {
                custom_attributes: [
                    'att_text_perfion_filtercolour',
                    'perfion_composition1',
                    'perfion_component1',
                    'att_text_perfion_component1',
                    'perfion_composition2',
                    'perfion_component2',
                    'att_text_perfion_component2',
                    'perfion_composition3',
                    'perfion_component3',
                    'att_text_perfion_component3',
                    'perfion_composition4',
                    'perfion_component4',
                    'att_text_perfion_component4',
                    'perfion_composition5',
                    'perfion_component5',
                    'att_text_perfion_component5',
                    'perfion_modelinformation',
                    'perfion_websizefitting',
                    'perfion_sizewarning',
                    'att_text_perfion_sizewarning',
                    'perfion_colourcode',
                    'att_text_perfion_colourcode',
                    'lsr_item_id',
                    'perfion_composition_old',
                    'perfion_washinstructions_old',
                    'perfion_modelsizetext',
                    'perfion_productgroupcode',
                    'att_text_perfion_productgroupcode',
                    'perfion_googlecolour',
                    'att_text_perfion_googlecolour'
                ]
            });
            setProducts(result?.result);
        }

        if (urlsKeys.length > 0 && !loading) {
            fetchContent();
        }

    }, [hits]);

    return (
        <div className={classes.root}>
            {
                hits && products && products.length > 0
                    ?
                    products.map((product, index) => {
                        const { variants, configurable_options, perfion_modelsizetext } = product;

                        return (
                            <div
                                key={index}
                                className={classes.item}
                                role="button"
                                tabIndex="0"
                            >
                                <ProductTile
                                    className={classes.giftbox_tile}
                                    product={product}
                                    wishlistButton={false}
                                    giftBox
                                />
                                <div className={classes.dimension_container}>
                                    {perfion_modelsizetext && (
                                        <span className={classes.dimension_info}>{perfion_modelsizetext}</span>
                                    )}
                                </div>
                                <div className={classes.content}>
                                    <ConfigurableOrder
                                        product={product}
                                        variants={variants}
                                        options={configurable_options}
                                        giftBox
                                    />
                                </div>
                            </div>
                        );
                    })
                    :
                    loading
                        ?
                        <div className={classes.loading_skeleton}>
                            <LoadingSkeleton count={[3]} className="plp_loading_skeleton"/>
                        </div>
                        :
                        <div className={classes.empty_state}>
                            <p>{t('checkout.cart.giftbox.no_products_found')}</p>
                        </div>
            }
        </div>
    );
};

export default connectInfiniteHits(GiftboxList);