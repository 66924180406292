import { useRef, useState } from "react";
import Button from "../../form/button/Button";
import SubscribeDrawer from "./subscribe_drawer/SubscribeDrawer";

import useAddProductStockAlert from "../../../hooks/product_alert/useAddProductStockAlert";
import { useCustomer } from "@kega/sps-core";

import { t } from "../../../lib/translations";

const SubscribeStockAlert = ({ product, gallery, selectedOption = null }) => {
    const drawerRef = useRef();
    const { authenticated } = useCustomer();
    const { loading, addProductStockAlert } = useAddProductStockAlert();
    const [selectedSize, setSelectedSize] = useState(true);
    const [isHovered, setIsHovered] = useState(false);

    const handleHover = () => {
        if (!selectedOption) {
            setIsHovered(!isHovered);
        }
    };

    const onClickSubscribe = () => {
        if(!selectedOption){
            return
        }
        if (product?.attributes) {
            if (!product  || !selectedSize) {
                return;
            }
        } else {
            if (!product || !product?.id || !selectedSize) {
                return;
            }
        }

        if (authenticated) {
            setSelectedSize(false)
            addProductStockAlert({
                product_id: (product?.product?.id ? product?.product?.id : product?.id)
            });
        } else {
            setSelectedSize(true);
            if (drawerRef.current) {
                drawerRef.current.open();
            }
        }
    };

    return (
        <>
            <Button
                variant="primary"
                onClick={onClickSubscribe}
                style={{ width: '100%' }}
                disabled={!product && !selectedSize}
                loading={loading}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
            >
                {(isHovered && selectedSize) ? t('product.select_size') : t('product_alert.subscribe')}
            </Button>
            <SubscribeDrawer productId={product ? (product?.product?.id || product.id) : null} ref={drawerRef} gallery={gallery} product={product ? (product?.product || product.id) : null} />
        </>
    );
}

export default SubscribeStockAlert;