import { Navigate, Outlet } from "react-router-dom";
import { useCustomer } from '@kega/sps-core';
import useStoreViews from "../hooks/useStoreViews";
import { Builder } from "@builder.io/react/lite";

const RequireAuth = () => {
    const { authenticated } = useCustomer();
    const { getUrl } = useStoreViews();
    const isLive = !Builder.isEditing && !Builder.isPreviewing;

    if (!authenticated && isLive) {
        return <Navigate to={getUrl('/account/login')} />;
    }

    return <Outlet />;
}

export default RequireAuth;
