const getCustomerOrderQuery = (additionalItemData = '', additionalData = '') =>  `
    query getCustomer($id: String!) {
        customer {
            allorders(filter: {number: {eq: $id}}) {
                total_count
                items {
                    id
                    number
                    order_date
                    status
                    items {
                        product_name
                        product_sku
                        product_url_key
                        product_sale_price {
                            value
                        }
                        product_sale_price {
                            value
                            currency
                        }
                        quantity_ordered
                        quantity_invoiced
                        quantity_shipped
                        quantity_refunded
                        quantity_returned
                        selected_options {
                            label,
                            value
                        }
                        status
                        ${additionalItemData}
                    }
                    carrier
                    billing_address {
                        firstname
                        middlename
                        lastname
                        street
                        postcode
                        city
                        country_code
                        telephone
                        region
                        region_id
                        
                    }
                    shipping_address {
                        firstname
                        middlename
                        lastname
                        street
                        postcode
                        city
                        country_code
                        telephone
                        region
                        region_id
                        
                    }
                    shipping_method
                    shipments {
                        id
                        number
                        items {
                            product_name
                            quantity_shipped
                        }
                        tracking {
                            carrier
                            number
                            title
                        }
                    }
                    payment_methods {
                        name
                        type
                    }
                    total {
                        base_grand_total {
                            value
                            currency
                        }
                        grand_total {
                            value
                            currency
                        }
                        total_tax {
                            value
                        }
                        subtotal {
                            value
                            currency
                        }
                        taxes {
                            amount {
                                value
                                currency
                            }
                            title
                            rate
                        }
                        total_shipping {
                            value
                        }
                        shipping_handling {
                            amount_including_tax {
                                value
                            }
                            amount_excluding_tax {
                                value
                            }
                            total_amount {
                                value
                            }
                            taxes {
                                amount {
                                    value
                                }
                                title
                                rate
                            }
                        }
                        discounts {
                            amount {
                                value
                                currency
                            }
                            label
                        }
                    }
                    ${additionalData}
                }
            }
        }
    }
`;

export default getCustomerOrderQuery;