import { useCartId, useRequest, useStorefront } from "@kega/sps-core";
import { useDispatch } from "react-redux";
import { updateCart } from "@kega/sps-core/src/reducers/cart/actions";

const useCartFromOrder = () => {
    const { api: { checkout, cart } } = useStorefront();
    const dispatch = useDispatch();

    const getCartId = useCartId();
    const cartId = getCartId();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const cartFromOrder = async (orderNumber) => {

        if (!orderNumber || !cartId) {
            return;
        }

        try {
            setRequest();

            await checkout.cartFromOrder({ orderNumber, cartId: cartId });

            const result = await cart.getDetails();

            setSuccess();

            if (result) {
                dispatch(updateCart(result));
            }

        } catch (error) {
            console.log('[CartFromOrder]', error);
            setError(error);
        }
    };

    return {
        loading,
        error,
        cartFromOrder
    }
}

export default useCartFromOrder;
