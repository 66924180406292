import useMediaQuery from '../../../hooks/useMediaQuery';

import { Col, Row } from '../../../components/grid';

import classes from './ErrorComponent.module.css'


const ErrorComponent = ({ fullHeight = false, children }) => {
    const { match } = useMediaQuery('(max-width: 768px)');
    const imageWrapperClass = fullHeight ? `${classes.imageWrapper} ${classes.fullHeight}` : classes.imageWrapper;

    return (
        <Row>
            <Col className={imageWrapperClass}>
                {
                    match && (
                        <div className={classes.content}>
                            {children}
                        </div>
                    )
                }
            </Col>
            {
                !match && (
                    <Col className={classes.content}>
                        {children}
                    </Col>
                )
            }
        </Row>
    )
}

export default ErrorComponent
