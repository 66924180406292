import IconWrapper from "../wrapper/IconWrapper";

const PlusIcon = ({ color = null, title, ...props }) => {
    return (
        <IconWrapper color={color} {...props}>
            <svg width="10" height="10" viewBox="0 0 9.22 9.22" fill="none" xmlns="http://www.w3.org/2000/svg" role={title ? null : "presentation"}>
                {title && <title>{title}</title>}
                <path d="M8.76812 5.16188L8.76812 4.17193L5.16188 4.10122V0.565686L4.10122 0.494975L4.17193 4.10122L0.494973 4.10122L0.565683 5.16188L4.17193 5.09117L4.17193 8.76812H5.16188L5.16188 5.09117L8.76812 5.16188Z" fill={color ?? 'black'}/>
            </svg>
        </IconWrapper>
    );
};

export default PlusIcon;
