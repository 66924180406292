import { Helmet } from 'react-helmet-async';

import ScrollToTop from "../../utils/ScrollToTop";

import { default as AccountModule } from '../../modules/account/Account';

const Account = () => {

    return (
        <>
            <Helmet>
                <title>Account</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <ScrollToTop />
            <AccountModule />
        </>
    )
}

export default Account;