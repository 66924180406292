import { useEffect } from "react";
import { useStoreErrors, useLogout, useClearCart } from '@kega/sps-core';
import { useNavigate } from "react-router-dom";
import useStoreViews from "../../hooks/useStoreViews";

import useSystemMessage from "../../hooks/useSystemMessage";
import useCheckoutData from "../../hooks/checkout/useCheckoutData";

const Errors = () => {
    const navigate = useNavigate();
    const logout = useLogout();
    const { listenError } = useStoreErrors();
    const { getUrl } = useStoreViews();
    const { setSystemErrorMessage } = useSystemMessage();
    const { clear: clearCheckoutData } = useCheckoutData();

    const clear = useClearCart();

    useEffect(() => {

        const removeErrorListener = listenError(({ detail }) => {
            const { error, path, message } = detail;

            if (error === 'graphql-authorization') {
                try {
                    if (!path.includes('revokeCustomerToken')) {
                        console.log('Logout on error');
                        logout();
                    }
                } catch (error) {
                    // eslint-disable-line
                }

                navigate({
                    pathname: getUrl('/account/login')
                });

            }

            if ((message === 'Can\'t assign cart to store in different website.') ||  message === ('The cart isn\'t active')) {
                try {
                    if (path.includes('cart')) {
                        clear();
                        clearCheckoutData();
                        return false;
                    }
                } catch (error) {
                    // eslint-disable-line
                }
            }

            if (error === 'graphql-no-such-entity') {
                try {
                    if (path.includes('cart')) {
                        clear();
                        clearCheckoutData();
                        return false;
                    }
                } catch (error) {
                    // eslint-disable-line
                }
            }
            console.log('set system message', message)
            setSystemErrorMessage(message)
        });

        return () => {
            removeErrorListener();
        }

    }, [listenError, logout, getUrl]);

    return null;
}

export default Errors;
