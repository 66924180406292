import { connectCurrentRefinements } from 'react-instantsearch-dom';

import { t } from '../../../lib/translations';

import classes from './ClearFilters.module.css';

const ClearAllFilters = ({ items, refine, setFilteredCount }) => {
    if (items.length === 0) { return false }

    return (
        <div
            className={classes.clearall}
            onClick={() => {
                refine(items);
                setFilteredCount([]);
            }}
            disabled={!items.length}
            role="button"
            tabIndex="0">
            {t('productlist.filter.clear')}
        </div>
    );
};

export default connectCurrentRefinements(ClearAllFilters);
