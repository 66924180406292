import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CartItems } from '@kega/sps-cart';
import { useConfig } from '@kega/sps-core';
import { Accordion, AccordionContent, AccordionHeader, AccordionIcon, AccordionItem } from "@kega/sps-elements";

import { t } from '../../lib/translations';

import useMediaQuery from '../../hooks/useMediaQuery';
import useStoreViews from '../../hooks/useStoreViews';
import useTagManager from '../../hooks/useTagManager';

import CartItem from "../minicart/cartitem/CartItem";
import CartTotals from "../minicart/carttotals/CartTotals";
import CartPageContent from './cartpagecontent/CartPageContent';
import PromoCode from "./promo-code/PromoCode";
import CartTop from "./cart-top/CartTop";

import { LinkButton } from "../../components/form";
import { Col, Row, Container } from '../../components/grid';
import { ChevronIcon } from "../../components/icons";

import classes from './Cart.module.css';

const Cart = ({ items, totals, quantity }) => {
    const { getUrl } = useStoreViews();
    const { ecommerceEvent } = useTagManager();
    const { match: desktop } = useMediaQuery('(min-width: 768px)');
    const [cartTitle, setCartTitle] = useState(null);

    const config = useConfig();
    const defaultCurrency = config?.get('currency') ?? 'EUR';

    useEffect(() => {
        try {
            const data = {
                currency: defaultCurrency,
                value: totals?.total,
                tax: totals?.applied_taxes?.length > 0 ? totals.applied_taxes[0] : 0,
                shipping: totals?.shipping ?? 0,
                items: [
                ]
            }

            items.forEach( (item, index) => {
                const discounts = item.prices.discounts ?? null;

                data.items.push({
                    item_id: item.sku,
                    item_name: item.name,
                    affiliation: "",
                    coupon: discounts ? discounts[0].label : '',
                    discount: discounts ? discounts[0].amount.value : 0,
                    index: index,
                    item_brand: "",
                    item_category: item.att_text_perfion_productgroupcode,
                    item_list_id: "",
                    item_list_name: "",
                    item_variant: item.configurable_options.length > 0 ? item.configurable_options[0].value_label : '',
                    location_id: "",
                    price: item.price_range.minimum_price.regular_price.value,
                    quantity: item.quantity
                })
            });


            ecommerceEvent('view_cart', data);
        } catch (error) {
            console.log(error)
        }

    }, [items]);

    return (
        <Container xl g-xs={0} className={classes.root}>
            <Row>
                <Col md={12}>
                    <h1>{ cartTitle ?? t('checkout.cart.title')}</h1>
                    <CartTop onContentLoaded={ (data) => {
                        if(data && data.title) {
                            setCartTitle(data.title)
                        }
                    }
                    } />

                    {items.length === 0 ?
                        <div className={classes.empty_cart}>
                            <p>{t('checkout.cart.empty_cart')}</p>
                            <p><Link variant="secondary" linkComponent={Link} to={getUrl('/')}>{t('checkout.cart.empty_link')}</Link></p>
                        </div>
                        :
                        <>
                            <div className={classes.cart_content}>
                                <CartItems
                                    className={classes.cart_items}
                                    itemComponent={CartItem} />

                                <Accordion className={classes.accordion_container}>
                                    <AccordionItem className={classes.accordion_item} resizeOnOpen>
                                        <AccordionHeader className={classes.heading} role="heading">
                                            {t('checkout.steps.payment.use_promo_code')}
                                            <AccordionIcon>
                                                <ChevronIcon size={8} />
                                            </AccordionIcon>
                                        </AccordionHeader>
                                        <AccordionContent className={classes.content}>
                                            <PromoCode />
                                        </AccordionContent>
                                    </AccordionItem>
                                </Accordion>

                                <CartPageContent />
                            </div>
                            <div className={classes.totals_wrapper}>
                                <CartTotals className={classes.cart_page_totals} isCart={true} />
                            </div>

                            <div className={classes.cart_actions}>
                                <LinkButton className={classes.button_continue_shopping} variant="secondary" linkComponent={Link} to={getUrl('/')}>
                                    {t('checkout.cart.continue_shopping')}
                                </LinkButton>
                                <LinkButton className={classes.button_continue_checkout} variant="primary" linkComponent={Link} to={getUrl('/checkout/shipment')}>
                                    {t('checkout.cart.continue_checkout')}
                                </LinkButton>
                            </div>
                        </>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default Cart;
