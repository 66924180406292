import { useState } from "react";
import { useSelector } from "react-redux";

import { Form } from "@kega/sps-elements";
import { useConfig } from "@kega/sps-core";

import { Button, Input , Field, Textarea, Select } from "../form";
import { Row } from "../grid";

import useContactForm from "../../hooks/useContactForm";
import useInputValidation from "../../hooks/form/useInputValidation";
import useReCaptcha from "../../hooks/useReCaptcha";

import { t } from "../../lib/translations";

const ContactForm = () => {
    const { loading, contactForm } = useContactForm();
    const [formData, setFormData] = useState({ firstname: "",lastname: "",company: "",email: "", telephone: "", topic: "", order_number: "",message: "" });
    const { recaptcha_contact: contactReCaptcha } = useSelector((state) => state.storeConfig);
    const { firstname, telephone, requiredField } = useInputValidation();
    const config = useConfig();

    const { recaptchakey } = config.get('google');

    const language = config.get('language');

    const { loadToken, loading: loadingReCaptcha } = useReCaptcha({
        key: recaptchakey,
        action: 'contact',
        language: language,
        autoLoad: false,
        reCaptchaEnabled: contactReCaptcha
    });

    const handleInputChange = (event)  => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async ({ resetForm, valid }) => {
        // Pass formData to the useContactForm hook for further processing
        const recaptchaToken = await loadToken();
        let headers = {};
        if (recaptchaToken) {
            headers = {
                'X-ReCaptcha': recaptchaToken
            }
        }

        if (valid) {
            await contactForm(formData, headers);
            setFormData({ firstname: "",lastname: "",company: "",email: "", telephone: "", topic: "", order_number: "",message: "" });
            resetForm();
        } else {
            console.log('form not valid');
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Field>
                    <Input
                        type="text"
                        name="firstname"
                        label={t("customer.form.firstname")}
                        value={formData.firstname}
                        onChange={handleInputChange}
                        rules={[ ...firstname, requiredField ]}
                        required
                        placeholder={t("customer.form.firstname")} />
                </Field>
                <Field>
                    <Input
                        type="text"
                        name="lastname"
                        label={t("customer.form.lastname")}
                        value={formData.lastname}
                        onChange={handleInputChange}
                        required
                        placeholder={t("customer.form.lastname")} />
                </Field>
            </Row>
            <Field>
                <Input
                    type="text"
                    name="company"
                    label={t("contactForm.fields.company")}
                    value={formData.company}
                    onChange={handleInputChange}
                    placeholder={t("contactForm.fields.company")} />
            </Field>
            <Field>
                <Input
                    type="email"
                    name="email"
                    label={t("customer.form.email")}
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    placeholder={t("customer.form.email")} />
            </Field>
            <Field>
                <Input
                    type="tel"
                    name="telephone"
                    label={t("customer.form.telephone")}
                    value={formData.telephone}
                    onChange={handleInputChange}
                    rules={[ telephone(null) ]}
                    placeholder={t("customer.form.telephone")} />
            </Field>
            <Field>
                <Select
                    name="topic"
                    value={formData.topic}
                    onChange={handleInputChange}
                    required
                >
                    <option value="">{t("contactForm.fields.topic")}</option>
                    <option value="orders">{t("contactForm.fields.topics.order")}</option>
                    <option value="returns">{t("contactForm.fields.topics.returns")}</option>
                    <option value="product_questions">{t("contactForm.fields.topics.product_questions")}</option>
                    <option value="stock_availability">{t("contactForm.fields.topics.stock")}</option>
                    <option value="marketing">{t("contactForm.fields.topics.marketing")}</option>
                    <option value="wholesale">{t("contactForm.fields.topics.wholesale")}</option>
                    <option value="careers">{t("contactForm.fields.topics.careers")}</option>
                    <option value="re_ssentiel">{t("contactForm.fields.topics.reessentiel")}</option>
                    <option value="other">{t("contactForm.fields.topics.other")}</option>
                </Select>
            </Field>
            {formData.topic === 'orders' || formData.topic === 'returns' ? (
                <Field>
                    <Input
                        type="text"
                        name="order_number"
                        label={t("contactForm.fields.ordernr")}
                        value={formData.order_number}
                        onChange={handleInputChange}
                        placeholder={t("contactForm.fields.ordernr")} />
                </Field>
            ) : null}
            <Field>
                <Textarea
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                    placeholder={t("contactForm.fields.message")} />
            </Field>
            <Button
                type="submit"
                variant="primary"
                loading={loading}
            >{t("contactForm.fields.submit")}
            </Button>
        </Form>
    );
};

export default ContactForm;
