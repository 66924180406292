const restoreQuote = `
    mutation restoreQuote($cartId: String!, $orderNumber: String!) {
        restoreQuote(
            input: {
                cart_id: $cartId
                order_number: $orderNumber
            }
        ) {
            success
        }
    }
`;

export default restoreQuote;
