const addProductStockAlertMutation = `
    mutation addProductStockAlert($email: String, $customer_id: Int, $parent_id: Int, $product_id: Int!){
        addProductStockAlert(
            input: {
                email: $email,
                customer_id: $customer_id,
                parent_id: $parent_id,
                product_id: $product_id
            }
        ) {
            alert {
                id
                name
                sku
                price
                image
                image_url
                url_key
            }
            message
        }
    }
`;

export default addProductStockAlertMutation;
