import { createReducer } from '@reduxjs/toolkit';
import { clearCart } from "@kega/sps-core/src/reducers/cart/actions";

import * as actions from './actions';

const initialState = {
    lastOrderId: null,
    guestEmail: null,
    paymentMethodTitle: null,
    selectedBillingAddressType: 'shipping',
    checkoutCreateAccountData: null,
    couponCode: null,
    products: null,
    checkoutAgreements: null,
};

export default createReducer(initialState, {

    [actions.setLastOrderId]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            lastOrderId: payload
        }
    },

    [actions.setCouponCode]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            couponCode: payload
        }
    },

    [actions.removeCouponCode]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            couponCode: null
        }
    },

    [actions.setPaymentMethodTitle]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            paymentMethodTitle: payload
        }
    },

    [actions.setCheckoutAgreements]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            checkoutAgreements: payload
        }
    },

    [actions.setGuestEmail]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            guestEmail: payload
        }
    },

    [actions.setProducts]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            products: payload
        }
    },

    [actions.setSelectedBillingAddressType]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            selectedBillingAddressType: payload
        }
    },

    [actions.setCustomBillingAddress]: (state, action) => {

        const { payload } = action;
        return {
            ...state,
            customBillingAddress: payload
        }
    },

    [actions.setCheckoutCreateAccountData]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            checkoutCreateAccountData: payload
        }
    },

    [actions.clearCheckoutData]: () => {
        return initialState;
    },

    [clearCart]: () => {
        return initialState;
    }

});
