const adyenPaymentStatusQuery = `
query getAdyenPaymentStatus($orderNumber: String!, $cartId: String!) {
        adyenPaymentStatus(orderNumber: $orderNumber, cartId: $cartId) {
          isFinal
          resultCode
          additionalData
          action
        }
      }`;

export default adyenPaymentStatusQuery;
