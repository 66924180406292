import { Builder, BuilderBlocks } from "@builder.io/react/lite";

import Configure from "../configure/Configure";
import { Container, Row, Col } from "../../../../components/grid";

import classNames from "classnames";
import classes from './Layout.module.css';

const defaultBlocks = [
    {
        '@type': '@builder.io/sdk:Element',
        component: {
            name: 'Dummy'
        },
    },
];

export const FlexibleLayout = (props) => {
    const { full_width, background, background_color, layout_blocks, gutter, builderBlock } = props;

    return (
        <>
            {
                layout_blocks !== undefined ?
                    <>
                        <div style={{ backgroundColor: background ? background_color : 'transparent' }}>
                            <Container xl={!full_width ? true : null} margins={!full_width ? true : null} g-xs={1} g-sm={3} g-md={2} g-xl={4}>
                                <Row>
                                    {layout_blocks.map((layoutBlock, index) => {
                                        let block = props[`block${index}`];

                                        if (block.length > 0 && block[0].component.name === "Dummy") {
                                            block.shift()
                                        }

                                        return (
                                            <Col key={index} xl={layoutBlock.size_xl} lg={layoutBlock.size_lg} md={layoutBlock.size_md} sm={layoutBlock.size_sm} xs={layoutBlock.size_xs} className={classes[gutter]}>
                                                <BuilderBlocks
                                                    child
                                                    blocks={block}
                                                    parentElementId={builderBlock && builderBlock.id}
                                                    dataPath={`component.options.block${index}`} />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Container>
                        </div>


                    </>
                    :
                    <Configure name="Flexible layout" />
            }
        </>
    );
};

Builder.registerComponent(FlexibleLayout, {
    name: "Flexible layout",
    static: true,
    defaultStyles: {
        marginTop: '0px',
    },
    inputs: [
        {
            name: 'layout_blocks',
            friendlyName: 'Layout block',
            type: 'list',
            subFields: [
                {
                    type: 'string',
                    name: 'size_xl',
                    friendlyName: 'Size (1440px - …)',
                    required: true,
                    enum: [
                        {
                            label: '0/12',
                            value: '0',
                        },
                        {
                            label: '1/12',
                            value: '1',
                        },
                        {
                            label: '2/12',
                            value: '2',
                        },
                        {
                            label: '3/12',
                            value: '3',
                        },
                        {
                            label: '4/12',
                            value: '4',
                        },
                        {
                            label: '5/12',
                            value: '5',
                        },
                        {
                            label: '6/12',
                            value: '6',
                        },
                        {
                            label: '7/12',
                            value: '7',
                        },
                        {
                            label: '8/12',
                            value: '8',
                        },
                        {
                            label: '9/12',
                            value: '9',
                        },
                        {
                            label: '10/12',
                            value: '10',
                        },
                        {
                            label: '11/12',
                            value: '11',
                        },
                        {
                            label: '12/12',
                            value: '12',
                        }
                    ],
                    defaultValue: '12',
                },
                {
                    type: 'string',
                    name: 'size_lg',
                    friendlyName: 'Size (1024px - 1439px)',
                    required: true,
                    enum: [
                        {
                            label: '0/12',
                            value: '0',
                        },
                        {
                            label: '1/12',
                            value: '1',
                        },
                        {
                            label: '2/12',
                            value: '2',
                        },
                        {
                            label: '3/12',
                            value: '3',
                        },
                        {
                            label: '4/12',
                            value: '4',
                        },
                        {
                            label: '5/12',
                            value: '5',
                        },
                        {
                            label: '6/12',
                            value: '6',
                        },
                        {
                            label: '7/12',
                            value: '7',
                        },
                        {
                            label: '8/12',
                            value: '8',
                        },
                        {
                            label: '9/12',
                            value: '9',
                        },
                        {
                            label: '10/12',
                            value: '10',
                        },
                        {
                            label: '11/12',
                            value: '11',
                        },
                        {
                            label: '12/12',
                            value: '12',
                        }
                    ],
                    defaultValue: '12',
                },
                {
                    type: 'string',
                    name: 'size_md',
                    friendlyName: 'Size (768px - 1023px)',
                    required: true,
                    enum: [
                        {
                            label: '0/12',
                            value: '0',
                        },
                        {
                            label: '1/12',
                            value: '1',
                        },
                        {
                            label: '2/12',
                            value: '2',
                        },
                        {
                            label: '3/12',
                            value: '3',
                        },
                        {
                            label: '4/12',
                            value: '4',
                        },
                        {
                            label: '5/12',
                            value: '5',
                        },
                        {
                            label: '6/12',
                            value: '6',
                        },
                        {
                            label: '7/12',
                            value: '7',
                        },
                        {
                            label: '8/12',
                            value: '8',
                        },
                        {
                            label: '9/12',
                            value: '9',
                        },
                        {
                            label: '10/12',
                            value: '10',
                        },
                        {
                            label: '11/12',
                            value: '11',
                        },
                        {
                            label: '12/12',
                            value: '12',
                        }
                    ],
                    defaultValue: '12',
                },
                {
                    type: 'string',
                    name: 'size_sm',
                    friendlyName: 'Size (640px - 767px)',
                    required: true,
                    enum: [
                        {
                            label: '0/12',
                            value: '0',
                        },
                        {
                            label: '1/12',
                            value: '1',
                        },
                        {
                            label: '2/12',
                            value: '2',
                        },
                        {
                            label: '3/12',
                            value: '3',
                        },
                        {
                            label: '4/12',
                            value: '4',
                        },
                        {
                            label: '5/12',
                            value: '5',
                        },
                        {
                            label: '6/12',
                            value: '6',
                        },
                        {
                            label: '7/12',
                            value: '7',
                        },
                        {
                            label: '8/12',
                            value: '8',
                        },
                        {
                            label: '9/12',
                            value: '9',
                        },
                        {
                            label: '10/12',
                            value: '10',
                        },
                        {
                            label: '11/12',
                            value: '11',
                        },
                        {
                            label: '12/12',
                            value: '12',
                        }
                    ],
                    defaultValue: '12',
                },
                {
                    type: 'string',
                    name: 'size_xs',
                    friendlyName: 'Size (0px - 639px)',
                    required: true,
                    enum: [
                        {
                            label: '0/12',
                            value: '0',
                        },
                        {
                            label: '1/12',
                            value: '1',
                        },
                        {
                            label: '2/12',
                            value: '2',
                        },
                        {
                            label: '3/12',
                            value: '3',
                        },
                        {
                            label: '4/12',
                            value: '4',
                        },
                        {
                            label: '5/12',
                            value: '5',
                        },
                        {
                            label: '6/12',
                            value: '6',
                        },
                        {
                            label: '7/12',
                            value: '7',
                        },
                        {
                            label: '8/12',
                            value: '8',
                        },
                        {
                            label: '9/12',
                            value: '9',
                        },
                        {
                            label: '10/12',
                            value: '10',
                        },
                        {
                            label: '11/12',
                            value: '11',
                        },
                        {
                            label: '12/12',
                            value: '12',
                        }
                    ],
                    defaultValue: '12',
                },
            ],
            onChange: (options) => {
                if (options.get('layout_blocks').length > 12) {
                    options.set('layout_blocks', options.get('layout_blocks').slice(0, 12));
                }
            },
            helperText: 'Max 12 layout columns can be added.'
        },
        {
            name: 'full_width',
            type: 'boolean',
            defaultValue: false,
        },
        {
            name: 'background',
            type: "boolean"
        },
        {
            type: 'color',
            name: 'background_color',
            friendlyName: 'Background color',
            defaultValue: '#F7F7F7',
        },
        {
            type: 'string',
            name: 'gutter',
            friendlyName: 'Gutter between elements',
            required: true,
            enum: [
                {
                    label: 'Keep grid',
                    value: 'grid',
                },
                {
                    label: 'Small gutter (2px)',
                    value: 'sm_gutter',
                },
                {
                    label: 'No gutter',
                    value: 'no_gutter',
                }
            ],
            defaultValue: 'grid',
        },
        {
            name: 'block0',
            type: 'uiBlocks',
            hideFromUI: true,
            defaultValue: defaultBlocks
        },
        {
            name: 'block1',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block2',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block3',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block4',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block5',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block6',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block7',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block8',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block9',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block10',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'block11',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
    ]
});
