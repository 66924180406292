import { useMemo, useRef, useEffect } from "react";
import { connectSortBy } from 'react-instantsearch-dom';

import { Accordion, AccordionItem, AccordionHeader, AccordionContent, AccordionIcon } from '@kega/sps-elements';

import { t } from "../../../lib/translations";

import { ChevronIcon } from "../../../components/icons";

import classNames from 'classnames';
import classes from './Sortby.module.css';
import { Radio } from "../../../components/form";

const SortBy = ({ className, defaultRefinement, items, refine, createURL, mobile }) => {
    const currentSort = useMemo(() => {
        return items.filter((item) =>  {
            return item.isRefined === true
        })[0]?.label;
    }, [defaultRefinement, items]);
    
    const rootRef = useRef(null);

    const handleClickOutside = (event) => {
        if (rootRef.current && !rootRef.current.contains(event.target)) {

            const wrapper = document.querySelector('.sortcontent-wrapper > div:nth-child(2)');

            if (wrapper.offsetHeight > 0) {
                wrapper.parentNode.querySelector('div.header-trigger').click()
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <>
            {
                <div className={classNames(className, classes.root)} ref={rootRef}>
                    <Accordion className={classes.accordion_container} onlyOne>
                        <AccordionItem className={classNames(classes.sortby, 'sortcontent-wrapper')} resizeOnOpen contentClassName={'sortcontent-wrapper'}>
                            <AccordionHeader className={classNames(classes.heading, 'header-trigger')} role="heading">
                                <h5>
                                    <span>{t('productlist.sortby.placeholder')}</span>
                                    {
                                        currentSort &&
                                        <span className={classes.active_sort}>{t(currentSort)}</span>
                                    }
                                </h5>
                                <AccordionIcon>
                                    <ChevronIcon title={"Accordion SortBy"} size={8} color={'#9C9EA1'} />
                                </AccordionIcon>
                            </AccordionHeader>
                            <AccordionContent className={classes.content}>
                                <ul>
                                    {
                                        items.map(({ value, label, isRefined }) => {
                                            return (
                                                <li key={label}>
                                                    <Radio
                                                        id={value}
                                                        name="sortby"
                                                        checked={isRefined}
                                                        onChange={() => refine(value)}
                                                        label={label}
                                                        role="radio"
                                                        tabIndex="0"
                                                    />
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </div>
            }
        </>
    );
}

export default connectSortBy(SortBy);
