import getStoreStock from '../queries/getStoreStock.gql';

export default ({ client }) => {

    const api = {};

    api.getStoreStock = (itemId,variantId) => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: getStoreStock,
                variables: {
                    itemId: String(itemId),
                    variantId: String(variantId)
                }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }

                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    return api;
};
