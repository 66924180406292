import React from "react";

import classNames from 'classnames';

import classes from './IconWrapper.module.css';

const IconWrapper = ({ roleButtonRemoved = false, children, size = 24, height = null, width = null, color = null, direction = null, className = null, onClick = null }) => {
    const wrapperClasses = classNames(classes.root, className);

    let directions = {
        right: 'rotate(90deg)',
        left: 'rotate(-90deg)',
        top: 'rotate(0deg)',
        bottom: 'rotate(180deg)'
    }

    let svgStyle = {}

    if (color !== null) { svgStyle.fill = color; }

    return (
        <span
            className={wrapperClasses} style={{ width: (width || size) + 'px', height: (height || size) + 'px', transform: (direction ? directions[direction] : '') }}
            onClick={onClick}
            {...(!roleButtonRemoved && { role: "button" })}
            tabIndex="0"
        >
            {React.cloneElement(children, { style: svgStyle })}
        </span>
    );
}

export default IconWrapper;
