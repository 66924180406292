import confirmNewsletterSubscription from "../queries/confirmNewsletterSubscription.gql";
import unsubscribeFromNewsletter from "../queries/unsubscribeFromNewsletter.gql";

export default ({ client }) => {
    const api = {};

    api.confirmSubscription = (id, code) => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: confirmNewsletterSubscription,
                variables: {
                    id: Number(id),
                    code: String(code)
                }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }

                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    api.unsubscribe = (email, reason, storeCode) => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: unsubscribeFromNewsletter,
                variables: {
                    email: email,
                    reason: reason,
                    storeCode: storeCode
                }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }
                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    return api;
};
