const updateCustomer = `
    mutation updateCustomerV2($firstname: String!, $lastname: String!) {    
      updateCustomerV2(
        input: {
         firstname: $firstname
         lastname: $lastname
        }
      ) {
        customer {
         firstname
         lastname
        }
      }
    }
`;

export default updateCustomer;