import { useEffect, useMemo } from 'react';
import { useCustomer, useConfig } from '@kega/sps-core';
import { useSelector } from 'react-redux';

import { setCookie } from "../lib/cookies";

const useCookies = () => {
    const config = useConfig();
    const { url } = config.get('magento2');

    const domain = useMemo(() => {
        const [subdomain, host, ext] = url.split('.'); // eslint-disable-line no-unused-vars
        return host + '.' + ext;
    }, [url])

    useEffect(() => {
        // Obtain the store query param from the current URL
        const params = new URLSearchParams(window.location.search);
        const storeQueryParam = params.get('store');

        if (storeQueryParam) {
            // Set the store query param value in localstorage (pwa_kiosk_id)
            localStorage.setItem('pwa_kiosk_id', storeQueryParam);
        }
    }, [domain]);

}

export default useCookies;
