import { Button as DefaultButton } from '@kega/sps-elements';

import classNames from 'classnames';
import classes from './Button.module.css';

const Button = ({ variant, className, ...rest_props }) => {
    const buttonClasses = classNames(classes['button'], classes[variant], className);

    return (
        <DefaultButton className={buttonClasses} variant={variant} {...rest_props}  />
    );

}

export default Button;
