import Skeleton from 'react-loading-skeleton';

import classes from './LoadingOrder.module.css';

const LoadingOrder = () => {
    const renderSwatches = (count) => {
        const swatches = [];

        for (let i = 0; i < count; i++) {
            swatches.push(
                <Skeleton key={i} className={classes.swatch}/>
            )
        }

        return swatches;
    };

    return (
        <div className={classes.root}>
            <Skeleton className={classes.label} />
            <div className={classes.swatches}>
                {renderSwatches(4)}
            </div>
            <Skeleton className={classes.button} />
        </div>
    );
};

export default LoadingOrder;