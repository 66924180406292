import { forwardRef, useRef, useState } from 'react';
import { Input as DefaultInput } from '@kega/sps-elements';
import uniqid from 'uniqid';

import { EyeIcon } from '../../icons';

import classNames from 'classnames';
import classes from './Input.module.css';

const Input = ({ name = '', type = 'text', placeholder, required, error, className = '', rootClassName = '', onChange = () => { }, onBlur, ...rest_props }, ref) => {
    const inputRef = ref ?? useRef(null);
    const [inputType, setInputType] = useState(type);
    const inputClasses = classNames(classes.input, error ? classes.error : '', className);
    const rootInputClasses = classNames(classes.root, rootClassName);

    const togglePassword = () => {
        if (inputType === 'password') {
            setInputType('text');
        } else {
            setInputType('password');
        }
    };

    const onInputBlur = (event) => {
        const { target: { value } } = event;

        if (value && inputRef.current) {
            if (ref) {
                inputRef.current.value = value.trim();
            }
            else {
                inputRef.current?.setValue(value.trim());
            }
        }

        if (onBlur) {
            onBlur(event);
        }
    };

    return (
        <DefaultInput
            type={inputType}
            className={inputClasses}
            id={name + '_'+uniqid()}
            name={name}
            labelClassName={classes.label}
            rootClassName={rootInputClasses}
            placeholder={placeholder}
            labelAnimated
            required={required}
            onBlur={onInputBlur}
            ref={inputRef}
            aria-label={placeholder}
            error={error}
            errorMessage={error}
            onChange={onChange}
            {...rest_props} />
    );

}

export default forwardRef(Input);
