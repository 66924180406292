import { useCartId, useRequest, useStorefront } from '@kega/sps-core';
import { setGuestEmail as setGuestEmailState } from "../../redux/checkout/actions";
import { useDispatch } from "react-redux";

const useSetGuestEmail = () => {
    const { api: { checkout } } = useStorefront();
    const dispatch = useDispatch();

    const getCartId = useCartId();
    const cartId = getCartId();

    const { error, loading, success, setRequest, setSuccess, setError } = useRequest();

    const setGuestEmail = async (email) => {

        try {
            setRequest();
            const response = await checkout.setGuestEmail({ cartId: cartId, email: email });

            if (response) {
                dispatch(setGuestEmailState(response));
            }

            setSuccess();
        } catch (error) {
            console.log('[SetGuestEmail]', error);
            setError(error);
        }
    };

    return {
        error,
        loading,
        success,
        setGuestEmail
    };
}

export default useSetGuestEmail;
