import SearchIcon from "../../../../components/icons/icons/SearchIcon";

import classes from './Suggestion.module.css';

const Suggestion = ({ data, onClick }) => {
    const { query, _highlightResult = {} } = data;

    return (
        <div className={classes.root} onClick={onClick} role="button" tabIndex="0">
            <SearchIcon title={"Suggestion Search"} className={classes.icon} size={17} />
            <span dangerouslySetInnerHTML={{ __html: (_highlightResult.query?.value || query) }} />
        </div>
    )
}

export default Suggestion;
