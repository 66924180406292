import { ChevronIcon } from '../../../components/icons';

import classNames from 'classnames';
import classes from './Progressbar.module.css';

const Step = ({ children, active, complete, onClick }) => {
    const stepClassName = classNames(classes.step, {
        [classes.active]: active,
        [classes.complete]: complete
    });

    return (
        <div className={stepClassName} onClick={onClick}>
            { children }
        </div>
    );
}

export default Step;