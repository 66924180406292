// TODO Remove once EA-428 is implemented
const getCustomerStoreOrders = `
    query {
        getStoreOrders {
            id
            number
            order_date
            status
            total {
                grand_total {
                  value
                  currency
                }
            }
            id_type
        }
    }
`;

export default getCustomerStoreOrders;
