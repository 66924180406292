import { useDispatch, useSelector } from 'react-redux';

import {
    cartOpen,
    cartClose,
    menuOpen,
    menuClose,
    filtersOpen,
    filtersClose,
    customerNavOpen,
    customerNavClose,
    sortByOpen,
    sortByClose,
    zoomGalleryOpen,
    zoomGalleryClose,
    addToCartDrawerOpen,
    addToCartDrawerClose,
    giftboxDrawerOpen,
    giftboxDrawerClose,
    storeSelectorOpen,
    storeSelectorClose
} from '../redux/ui/actions';

const useUi = () => {
    const dispatch = useDispatch();

    const {
        isCartOpen,
        isMenuOpen,
        isFiltersOpen,
        isCustomerNavOpen,
        isSortByOpen,
        isZoomGalleryOpen,
        isAddToCartDrawerOpen,
        isGiftboxDrawerOpen,
        isStoreSelectorOpen
    } = useSelector((state) => state.ui);

    const openCart = () => { dispatch(cartOpen()); }
    const closeCart = () => { dispatch(cartClose()); }

    const openMenu = () => { dispatch(menuOpen()); }
    const closeMenu = () => { dispatch(menuClose()); }


    const openFilters = () => { dispatch(filtersOpen()); }
    const closeFilters = () => { dispatch(filtersClose()); }

    const openCustomerNav = () => { dispatch(customerNavOpen()); }
    const closeCustomerNav = () => { dispatch(customerNavClose()); }

    const openSortBy = () => { dispatch(sortByOpen()); }
    const closeSortBy = () => { dispatch(sortByClose()); }

    const openZoomGallery = () => { dispatch(zoomGalleryOpen()); }
    const closeZoomGallery = () => { dispatch(zoomGalleryClose()); }

    const openAddToCartDrawer = () => { dispatch(addToCartDrawerOpen()); }
    const closeAddToCartDrawer = () => { dispatch(addToCartDrawerClose()); }

    const openGiftboxDrawer = () => { dispatch(giftboxDrawerOpen()); }
    const closeGiftboxDrawer = () => { dispatch(giftboxDrawerClose()); }

    const openStoreSelector = () => { dispatch(storeSelectorOpen()) }
    const closeStoreSelector = () => { dispatch(storeSelectorClose()) }

    return {

        isCartOpen,
        openCart,
        closeCart,

        isMenuOpen,
        openMenu,
        closeMenu,

        isFiltersOpen,
        openFilters,
        closeFilters,

        isCustomerNavOpen,
        openCustomerNav,
        closeCustomerNav,

        isSortByOpen,
        openSortBy,
        closeSortBy,

        isZoomGalleryOpen,
        openZoomGallery,
        closeZoomGallery,

        isAddToCartDrawerOpen,
        openAddToCartDrawer,
        closeAddToCartDrawer,

        isGiftboxDrawerOpen,
        openGiftboxDrawer,
        closeGiftboxDrawer,

        isStoreSelectorOpen,
        openStoreSelector,
        closeStoreSelector
    };
}

export default useUi;
