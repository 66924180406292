import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { ProductImage } from '@kega/sps-components';

import useUi from '../../../hooks/useUi';
import useMediaQuery from '../../../hooks/useMediaQuery';

import { Slider, SliderItem } from "../../../components/slider";
import Labels from "../../../components/product/labels/Labels";

import GalleryZoom from './galleryzoom/GalleryZoom';

import classes from "./Gallery.module.css"
import classNames from "classnames";

const Gallery = ({ className = '', images, width, height, alt, matchGallerySlide, loading, product, ComponentNext = null, ComponentPrev = null, isPLP = false, url, state, product_page = false }) => {
    const [zoomImageIndex, setZoomImageIndex] = useState(0);
    const { openZoomGallery } = useUi();
    const { match: matchMobile } = useMediaQuery(`(max-width: 640px)`);

    const openZoom = (index) => {
        setZoomImageIndex(index);
        openZoomGallery();
    }

    const renderItem = (image, index) => (
        loading ? (
            <Skeleton
                containerClassName={classes.skeleton}
                style={{ aspectRatio: `${width}/${height}` }}
            />
        ) : (
            isPLP ? (
                <Link to={url} state={state}>
                    <ProductImage
                        src={image}
                        width={width}
                        height={height}
                        alt={alt}
                        role="button"
                        tabIndex="0"
                        className={classes.product_img}
                    />
                </Link>
            ) : (
                <ProductImage
                    src={image}
                    width={width}
                    height={height}
                    alt={alt}
                    role="button"
                    tabIndex="0"
                    className={classes.product_img}
                    onClick={() => openZoom(index)}
                />
            )
        )
    );


    if (loading) {
        images = ['loadingImg1', 'loadingImg2', 'loadingImg3', 'loadingImg4'];
    }

    return (
        <>
            {
                !matchGallerySlide ?
                    <div className={classNames(classes.gallery_container, className)}>
                        {
                            images.map((image, index) => {
                                return (
                                    <div key={image} className={classes.gallery_image}>
                                        {renderItem(image, index)}
                                        {
                                            index === 0 &&
                                            <Labels product={product} product_page={product_page} />
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                    :
                    <div className={classes.slider_container}>
                        <Slider
                            itemsToShow="1"
                            scrollbar={true}
                            navigation={!isPLP && matchMobile}
                            controls={true}
                            automate={false}
                            isPLP={isPLP}
                            className={classNames(!isPLP && classes.gallery, className)}
                            controlsClassName={isPLP && classes.controls}
                            ComponentNext={ComponentNext ?? <div className={classes.next}></div>}
                            ComponentPrev={ComponentPrev ?? <div className={classes.prev}></div>}
                        >
                            {
                                images.map((image, index) =>
                                    <SliderItem key={index} className={classes.gallery_image} >
                                        {renderItem(image, index)}
                                    </SliderItem>)
                            }
                        </Slider>
                        <Labels product={product} product_page={product_page} />
                    </div>
            }
            {
                !isPLP && <GalleryZoom images={images} activeIndex={zoomImageIndex} />
            } 
        </>
    );
}

export default Gallery;