import AddToCart from '../../../components/product/addtocart/AddToCart';
import WishlistButton from '../../../components/wishlist/wishlist_button/WishlistButton';
import SubscribeStockAlert from '../../../components/product/subscribe_stock_alert/SubscribeStockAlert';

import classes from "./SimpleOrder.module.css"

const getStockStatus = (product) => {
    return (product && product.stock_status === 'IN_STOCK');
};

const SimpleOrder = ({ product, gallery }) => {
    const inStock = getStockStatus(product);

    return (
        <div className={classes.root}>

            <div className={classes.addtocart_container}>
                { inStock && <AddToCart product={product} /> }
                { !inStock && <SubscribeStockAlert product={product} gallery={gallery} /> }
                <WishlistButton product={product} className={classes.wishlist_button} size={17} />
            </div>

        </div>
    )
}

export default SimpleOrder;