import { useRequest, useStorefront } from "@kega/sps-core";
import useSystemMessage from "../useSystemMessage";
import { t } from "../../lib/translations";

const useCheckBalance = () => {
    const { api: { checkout } } = useStorefront();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const { setSystemErrorMessage } = useSystemMessage();

    const checkGiftcard = async ({ cardNumber, pin }) => {

        try {
            setRequest();
            const result = await checkout.checkGiftCardBalance(cardNumber, pin);

            setSuccess();

            if (!result?.success) {
                setSystemErrorMessage(t('Could not retrieve giftcard balance, please try again.'));
            }

            return result;

        } catch (error) {
            console.log('[checkGiftcard]', error);
            setError(error);
            setSystemErrorMessage(t('Something went wrong, please try again later'));
        }
    };

    return {
        loading,
        error,
        checkGiftcard,
    }
}

export default useCheckBalance;
