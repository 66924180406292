import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { Container } from '../../components/grid';

import Usp from './usp/Usp';
import NavigationBar from './navigation/NavigationBar';
import MobileNavigation from "./navigation/mobilenavigation/MobileNavigation";
import Customer from './customer/Customer';
import Wishlist from './wishlist/Wishlist';
import Search from './search/Search';
import Cart from './cart/Cart';

import useMediaQuery from "../../hooks/useMediaQuery";
import useUi from "../../hooks/useUi";

import classes from './Header.module.css';
import useStoreViews from "../../hooks/useStoreViews";
import classNames from 'classnames';

const Header = () => {
    const { match } = useMediaQuery('(min-width: 1025px)');
    const { getUrl } = useStoreViews();
    const { isKiosk } = useStoreViews();
    const { closeCustomerNav } = useUi();

    const customerRef = useRef(null);

    useEffect(() => {
        window.addEventListener("scroll", scrollSticky);
        return () => {
            window.removeEventListener("scroll", scrollSticky);
        };
    }, []);

    const scrollSticky = () => {
        const headerElement = document.getElementsByTagName("header")[0];
        if (headerElement) {
            if (window.scrollY >= 10) {
                headerElement.classList.add(classes.sticky);
                headerElement.classList.add('sticky-header');
                headerElement.classList.remove(classes.notsticky);

                headerElement.style.top = document.querySelector('.'+classes.usp)
                    ? (-1 * document.querySelector('.' + classes.usp).offsetHeight).toString() + 'px'
                    : "-33px";
            } else {
                headerElement.classList.add(classes.notsticky);
                headerElement.classList.remove(classes.sticky);
                headerElement.classList.remove('sticky-header');
                headerElement.style.top = "0px";
            }
        }
    }

    const handleCustomerDropdown = (e) => {
        if (!customerRef.current.contains(e.target)) {
            closeCustomerNav();
        }
    }

    return (
        <header className={classes.root} onClick={handleCustomerDropdown}>
            <Usp className={classes.usp} />
            <Container margins g-xs={1} g-sm={3} g-md={2} g-xl={4} className={classes.topmenu}>
                {
                    !match &&
                    <div className={`${classes.left} ${classes.right}`}>
                        <Search />
                        <div ref={customerRef} className={classes.left}>
                            <Customer />
                        </div>
                    </div>
                }

                <div className={classes.logo}>
                    <Link className={classes.store_logo_wrapper} to={getUrl('/')} >
                        <img className={classes.store_logo} src="/images/new-essentiel-logo.svg" height={match ? "42" : "38"} width={match ? "140" : "109"} alt="Essentiel Antwerp" />
                    </Link>
                </div>
                
                {
                    match
                    &&
                    <div className={classes.navbar}>
                        <NavigationBar />
                    </div>
                }
                <div className={classes.right}>
                    {match &&
                        <>
                            <Search />
                            {
                                !isKiosk &&
                                <div ref={customerRef}>
                                    <Customer />
                                </div>
                            }
                        </>
                    }
                    {
                        !isKiosk &&
                        <Wishlist />
                    }
                    <Cart />
                    {
                        !match
                        &&
                        <MobileNavigation />
                    }
                </div>
            </Container>
        </header>
    )
}

export default Header;