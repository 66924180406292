import { useDispatch } from 'react-redux';
import { useRequest, useStorefront } from '@kega/sps-core';

import { deleteAddress } from '../../redux/customer/actions';

const useDeleteCustomerAddress = () => {
    const { api: { essentielCustomer } } = useStorefront();
    const dispatch = useDispatch();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const addressDelete = async (addressId) => {
        try {
            setRequest();
            const result = await essentielCustomer.deleteAddress(addressId);
            dispatch(deleteAddress(addressId));
            setSuccess();

            return result;

        } catch (error) {
            console.log('[useDeleteCustomerAddress] ', error);
            setError(error);

            return null;
        }

    };

    return {
        error,
        loading,
        addressDelete
    };
}

export default useDeleteCustomerAddress;