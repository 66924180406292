const resetPassword = `
    mutation resetPassword($email: String!, $resetPasswordToken: String!, $newPassword: String!) {
        resetPassword(
            email: $email
            resetPasswordToken: $resetPasswordToken
            newPassword: $newPassword
          )
    }
`;

export default resetPassword;