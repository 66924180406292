import { NavLink } from 'react-router-dom';

import { useNavigation } from '@kega/sps-core';
import FooterLinks from './footerlinks/FooterLinks';
import Copyright from './copyright/Copyright';

import { Col, Row, Container } from '../../components/grid';
import Newsletter from '../../components/newsletter/Newsletter';
import StoreSelector from "../../components/store_selector/StoreSelector";

import useMediaQuery from '../../hooks/useMediaQuery';

import { t } from "../../lib/translations";

import classNames from 'classnames';
import classes from './Footer.module.css';
import useStoreViews from "../../hooks/useStoreViews";

const Footer = () => {
    const links = useNavigation('footer');
    const { match: matchMobile } = useMediaQuery('(max-width: 1024px)');
    const { match: matchDesktop } = useMediaQuery('(min-width: 1441px)');
    const { getUrl } = useStoreViews();

    return (
        <footer className={classes.root}>
            <Container margins g-xs={1} g-sm={3} g-md={2} g-xl={4} >
                <Row>
                    <Col lg="4" xl="3">
                        <Row className={classes.newsletter_row}>
                            <Col>
                                <Newsletter className={classes.newsletter} />
                                {
                                    !matchMobile && <StoreSelector className={classes.store_selector} />
                                }
                            </Col>
                        </Row>
                    </Col>
                    {matchDesktop && <Col xl="1" />}
                    <Col>
                        {
                            matchMobile &&
                            <Row>
                                <Col className={classes.store_column}>
                                    <div className={classNames(classes.links_column, classes.store)}>
                                        <NavLink to={getUrl('ourstores')} className={classes.storelocator}>{t('Store locator')}</NavLink>
                                    </div>
                                </Col>
                            </Row>
                        }

                        <Row className={classes.col_container}>
                            {
                                // links?.length > 0 &&
                                // links.map((itemColumn) => {
                                //     return (
                                //         <Col md={3} key={itemColumn.title} className={classes.links_container}>
                                //             <div className={classes.links_column}>
                                //                 <FooterLinks column={itemColumn} />
                                //             </div>
                                //             {itemColumn.position === 3 && <div className={classes.copyright}><Copyright /></div>}
                                //         </Col>
                                //     );
                                // })
                                <FooterLinks links={links} />
                            }
                        </Row>

                        {
                            matchMobile &&
                            <StoreSelector colClassName={classes.store_select_column}
                                wrapperClassName={classes.links_column} />
                        }

                        {
                            matchMobile &&
                            <Row>
                                <Col lg={3} className={classes.copyright}>
                                    <Copyright />
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>

            </Container>
        </footer >
    )
}

export default Footer;
