import { Fragment, useEffect, useMemo, useState } from 'react';
import { connectRefinementList, Highlight } from 'react-instantsearch-dom';

import { t } from '../../../../../lib/translations';

import { SearchIcon } from '../../../../../components/icons';
import { Input } from '../../../../../components/form';

import classNames from 'classnames';
import classes from './MultiFilter.module.css';

const MultiFilter = ({
    headingText,
    searchText,
    emptyText,
    setFilteredCount,
    attribute,
    searchable,
    items,
    isFromSearch,
    refine,
    searchForItems,
    createURL,
    limit = 999,
    showMore,
    showMoreLimit,
    colLength = 1
}) => {

    const [itemCount, setItemCount] = useState(showMore ? limit : showMoreLimit);

    const filteredItems = useMemo(() => {
        return items.slice(0, itemCount);
    }, [items, itemCount])

    const isRefinedExists = useMemo(() => {
        return items.some(item => item.isRefined);
    }, [items, itemCount]);

    const refinedItemsCount = useMemo(() => {
        return items.filter(item => item.isRefined).length;
    }, [items, itemCount]);

    const showMoreItems = () => {
        setItemCount(showMoreLimit);
    }

    const showLessItems = () => {
        setItemCount(limit);
    }

    useEffect(() => {
        setFilteredCount(filteredCount => ({
            ...filteredCount,
            [attribute]: refinedItemsCount
        }));
    }, [refinedItemsCount]);

    const renderCols = (filteredItems, colnumber) => {
        const cols = Array.from(Array(colnumber), () => []);

        if (colnumber === 0) {
            colnumber =  colnumber + 1;
        }

        filteredItems.map((item, index) => {
            return (
                cols[index % colnumber].push(
                    <Fragment key={'nav' + index}>
                        <a
                            key={item.label}
                            className={classNames(classes.link, item.isRefined ? classes.active : null)}
                            href={createURL(item.value)}
                            role="button"
                            tabIndex="0"
                            onClick={(event) => {
                                event.preventDefault();
                                refine(item.value);
                                setFilteredCount(filteredCount => ({
                                    ...filteredCount,
                                    [attribute]: refinedItemsCount
                                }));
                            }}>
                            {isFromSearch ? <Highlight attribute="label" hit={item}/> :
                                <span className={classes.value}>{item.label}</span>}

                            <span className={classes.count}>{item.count}</span>
                        </a>
                    </Fragment>
                ))
        })

        return cols.map((col, index) => {
            return <div key={'c' + index} className={classes.menu_column}>{col}</div>;
        });
    }

    return (
        <div className={classes.root}>
            {
                searchable &&
                <div className={classes.search}>
                    <Input
                        type="search"
                        className={classes.search_input}
                        placeholder={searchText}
                        onChange={(event) => searchForItems(event.currentTarget.value)} />
                    <SearchIcon title={"MultiFilter Search"} size={18} className={classes.search_icon} />
                </div>
            }
            {
                filteredItems.length
                    ?
                    <div className={classes.values}>
                        {
                            renderCols(filteredItems, (Math.round(filteredItems.length / colLength)))
                        }
                    </div>
                    :
                    <div className={classes.no_values}>
                        {emptyText}
                    </div>
            }

            {(showMore && filteredItems.length === limit) &&
                <button className={classes.showmoreless} onClick={showMoreItems}>{t('productlist.filter.showmore')}</button>
            }

            {(showMore && filteredItems.length > limit) &&
             <button className={classes.showmoreless} onClick={showLessItems}>{t('productlist.filter.showless')}</button>
            }


            {
                isRefinedExists &&
                <div
                    className={classes.clear}
                    onClick={(event) => {
                        event.preventDefault();
                        refine([]);
                        setFilteredCount(filteredCount => ({
                            ...filteredCount,
                            [attribute]: 0
                        }));
                    }}
                    role="button"
                    tabIndex="0">
                    {t('productlist.filter.clear_filter', { filter: headingText })}
                </div>
            }
        </div>
    );
}

export default connectRefinementList(MultiFilter);
