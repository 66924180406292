const createCustomerFromOrder = `
    mutation createCustomer($password: String!, $orderId: String!) {
        createCustomerFromOrder(
            input: {
                password: $password, 
                order_increment_id: $orderId            
            }
        ) {
         success
         customer {
                email
            }
        }
    }`

export default createCustomerFromOrder;