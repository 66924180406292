import { useState, useRef } from "react";
import { useShippingAddress, useShippingMethods, useCart } from '@kega/sps-core';

import { t, translateRegion } from "../../../../lib/translations";

import useSetAddresses from "../../../../hooks/checkout/useSetAddresses";
import useStoreViews from "../../../../hooks/useStoreViews";
import useUi from "../../../../hooks/useUi";
import useCheckoutData from "../../../../hooks/checkout/useCheckoutData";
import useSetGuestEmail from "../../../../hooks/checkout/useSetGuestEmail";

import Shipment from "../../shipment/Shipment";
import Email from "./email/Email";

import AddressForm from "../../../../components/address_form/AddressForm";
import { Row } from "../../../../components/grid";
import { Field } from "../../../../components/form";

import classes from "./Guest.module.css";

const Guest = ({ handleContinue, stepPath, selected, setSelected, fetchError, fetchLoading, methodes, setGuestUser, guestUser }) => {
    const { setShippingMethods, error } = useShippingMethods();
    const { setFromSingleAddress } = useSetAddresses();
    const { fetchCart, cartId } = useCart();
    const { isKiosk, currentStoreView, currentStoreCode } = useStoreViews();
    const { openStoreSelector } = useUi();
    const { extraCartData } = useCheckoutData();
    const { setGuestEmail } = useSetGuestEmail();

    const [emailValid, setEmailValid] = useState(true);
    const [saving, setSaving] = useState(false);

    const { shippingAddress } = useShippingAddress();
    const emailRef = useRef();

    const onAddressSaved = async () => {
        setSaving(true);

        if (emailRef?.current) {
            if (emailValid) {
                await setGuestEmail(emailRef?.current?.ref?.value);
            }
        }

        // set shipping method
        await setShippingMethods({
            cartId:  cartId,
            carrier: selected.carrier,
            method:  selected.method
        }).then((response) => {
            fetchCart(cartId, extraCartData).then(() => {
                setSaving(false);
                handleContinue(stepPath)
            })

        })
    }

    return (
        <div className={classes.address_form}>
            <h4>{t("checkout.information.heading")}</h4>
            <Row>
                <Field className={classes.email_field}>
                    <Email
                        emailRef={emailRef}
                        setEmailValid={setEmailValid}
                        emailValid={emailValid}
                        setGuestUser={setGuestUser}
                        guestUser={guestUser}
                    />
                </Field>
            </Row>
            {
                (isKiosk || guestUser) &&
                <Row className={classes.address_field}>
                    <h4>{t("checkout.information.form_heading")}</h4>
                    <div className={classes.store_locator}>
                        <div className={classes.store_locator_text}>
                            {
                                currentStoreCode === 'eu'
                                    ?
                                    <>
                                        {t('checkout.information.change_location.EU.text') + ' '}
                                        <span className={classes.open_store_locator} onClick={openStoreSelector}>
                                            {t('checkout.information.change_location.link')}
                                        </span>
                                    </>
                                    :
                                    <>
                                        {t('checkout.information.change_location.text') + ' '}
                                        <strong>{translateRegion(currentStoreView.country) + '.'}</strong><br/>
                                        {t('checkout.information.change_location.store', { country: translateRegion(currentStoreView.country) }) + ' '}
                                        <span className={classes.open_store_locator} onClick={openStoreSelector}>
                                            {t('checkout.information.change_location.link')}
                                        </span>
                                    </>
                            }
                        </div>
                        <div className={classes.store_locator_caption}>
                            {t('checkout.information.change_location.caption')}
                        </div>
                    </div>
                    <AddressForm
                        address={shippingAddress}
                        buttonTitle={t("checkout.information.btn_next")}
                        endpoint={setFromSingleAddress}
                        parentCallback={onAddressSaved}
                        redirect={false}
                        showSaveAs={false}
                        checkoutForm={true}
                        saving={saving}
                        onSuccess={t('checkout.information.form.saved')}
                        selected={selected}
                        fetchLoading={fetchLoading}
                        setEmailValid={setEmailValid}
                        emailValid={emailValid}
                        emailRef={emailRef}
                        isAutoFocused={true}
                    >
                        <Shipment
                            setSelected={setSelected}
                            shippingMethodError={error}
                            selected={selected}
                            fetchError={fetchError}
                            fetchLoading={fetchLoading}
                            methodes={methodes}
                        />
                    </AddressForm>
                </Row>
            }
        </div>
    );
}

export default Guest;
