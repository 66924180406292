import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import useStoreViews from "../../../hooks/useStoreViews";

const Redirect = () => {
    const navigate = useNavigate();
    const { getUrl } = useStoreViews();

    useEffect(() => {

        const params = new URLSearchParams(window.location.search);
        let stateParams = {};

        params.forEach(function(value, key) {
            stateParams[key] = value.replace(' ','+');
        });
        navigate(getUrl("account/resetpassword"), {
            state: stateParams,
            replace: true
        });
    }, [])

    return null;

}

export default Redirect;
