const getCustomerOrders = (searchCriteria = '', additionalData = '') => `
    query getCustomer {
        customer {
            allorders${searchCriteria}{
                total_count
                items {
                  id
                  number
                  order_date
                  total {
                    grand_total {
                      value
                      currency
                    }
                  }
                  status
                  ${additionalData}
                }
                page_info {
                    page_size
                    current_page
                    total_pages
                }                
            }
        }
    }
`;

export default getCustomerOrders;