const adyenPaymentSessionQuery = `
    query adyenPaymentSession($cartId: String!) {
        adyenPaymentSession(
            cart_id: $cartId
        ) {
            id
            sessionData
        }
    }
`;

export default adyenPaymentSessionQuery;
