import { useConfig } from "@kega/sps-core";

const useI18n = () => {
    const config = useConfig();

    const getCountryNameByCode = (code) => {
        const language = config.get('language');

        return code
            ? (new Intl.DisplayNames([language], { type: 'region' })).of(code)
            : null;
    }

    const formatDate = (date) => {
        const locale = config.get('locale');

        if (!date || typeof date !== 'string') {
            return null;
        }

        // Convert to format accepted by "new Date"
        date = date.replace(" ", "T") + "Z";

        return (new Date(date)).toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' })
    };

    return {
        getCountryNameByCode,
        formatDate
    }
};

export default useI18n;