import { Link } from "react-router-dom";
import { useEffect } from "react";

import ErrorPage from "../Error";

import { t } from "../../../lib/translations";

import useStoreViews from "../../../hooks/useStoreViews";

const NotFound = () => {
    const { getUrl } = useStoreViews();

    useEffect(() => {
        if (!window.prerenderReady) {
            window.prerenderReady = true
        }
    }, []);

    return (
        <ErrorPage
            pageTitle={t('error_page.notfound.page_title')}
            statusCode={404}
        >

            <h2>{t('error_page.notfound.title')}</h2>

            <p>{t('error_page.notfound.description')}</p>
            <p>
                <Link to={getUrl('/')}>{t('error_page.notfound.go_back')}</Link>
            </p>
        </ErrorPage>
    )
}

export default NotFound;
