import { useRef } from "react";
import { Link } from "react-router-dom";

import { Builder } from "@builder.io/react/lite";
import { Image } from "@builder.io/react";

import { Container, Row, Col } from "../../../../components/grid";
import { LinkButton } from '../../../../components/form';

import useStoreViews from "../../../../hooks/useStoreViews";
import useInViewport from "../../../../hooks/useInViewport";

import classNames from "classnames";
import classes from './FlashBlock.module.css';

export const FlashBlock = ({ padding, position, heading_text_size, text_size, cta_size, text_color, title_enabled, title_text, paragraph_enabled, paragraph_text, cta_enabled, cta_text, cta_url, cta_url_tab, cta_url_external, cta_type, background_color, max_width, fade, background_visual, background_visual_image, layover_enabled, layover_color, size_xl, size_lg, size_md, size_sm, size_xs }) => {
    const ref = useRef(null);
    const { getUrl } = useStoreViews();

    const { isIntersecting } = useInViewport(ref, fade);

    return (
        <div className={classes.root}>
            <Container margins g-xs={1} g-sm={3} g-md={2} g-xl={4} className={classes.flash_container} >
                <Row className={classes.row}>
                    <Col xl={size_xl} lg={size_lg} md={size_md} sm={size_sm} xs={size_xs} className={classes.col_wrapper}>
                        {
                            background_visual &&
                            <div className={classes.image_wrapper}>
                                <Image aspectRatio={0} image={background_visual_image} />
                            </div>
                        }
                        {
                            layover_enabled &&
                            <div className={classes.layover} style={{ backgroundColor: layover_color }}></div>
                        }
                        <div ref={ref} className={classNames(classes.text_container, classes['pad-' + padding], classes['pos-' + position], classes['textsize-' + text_size], background_visual ? classes.bg_enabled : null, fade ? classes.animate : null, isIntersecting ? classes.visible : null)} style={{ backgroundColor: background_color, color: text_color, maxWidth: background_visual ? max_width : null }}>
                            {
                                title_enabled &&
                                <div className={classNames(classes.title, classes['textsize-' + heading_text_size])} dangerouslySetInnerHTML={{ __html: title_text }} role="heading" />
                            }
                            {
                                paragraph_enabled &&
                                <div className={classes.paragraph} dangerouslySetInnerHTML={{ __html: paragraph_text }} />
                            }
                            {
                                cta_enabled &&
                                <>
                                    {
                                        cta_type === 'text'
                                            ?
                                            <>
                                                {
                                                    cta_url_external
                                                        ?
                                                        <a href={getUrl(cta_url)} className={classNames(classes.text_link, classes['textsize-' + cta_size])} dangerouslySetInnerHTML={{ __html: cta_text }} target={cta_url_tab ? "_blank" : "_self"} aria-label={cta_url_tab ? "Link opens in a new tab" : "Link opens in current tab"} rel="noreferrer"></a>
                                                        :
                                                        <Link to={getUrl(cta_url)} className={classNames(classes.text_link, classes['textsize-' + cta_size])} dangerouslySetInnerHTML={{ __html: cta_text }} target={cta_url_tab ? "_blank" : "_self"} aria-label={cta_url_tab ? "Link opens in a new tab" : "Link opens in current tab"} rel="noreferrer"></Link>
                                                }
                                            </>

                                            :
                                            <LinkButton to={!cta_url_external ?  getUrl(cta_url) : null} href={cta_url_external ? cta_url : null} linkComponent={cta_url_external ? null : Link} variant={cta_type} className={classNames(classes.button, classes['textsize-' + cta_size])} target={cta_url_tab ? "_blank" : "_self"} aria-label={cta_url_tab ? "Link opens in a new tab" : "Link opens in current tab"} rel="noreferrer"><div dangerouslySetInnerHTML={{ __html: cta_text }} /></LinkButton>
                                    }
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            </Container >
        </div>)
};

Builder.registerComponent(FlashBlock, {
    name: "Flash Block",
    static: true,
    defaultStyles: {
        marginTop: "0px",
    },
    inputs: [
        {
            type: 'string',
            name: 'padding',
            friendlyName: 'Padding',
            required: true,
            enum: [
                {
                    label: 'No padding',
                    value: 'no_padding',
                },
                {
                    label: 'XS',
                    value: 'extra_small',
                },
                {
                    label: 'S',
                    value: 'small',
                },
                {
                    label: 'M',
                    value: 'medium',
                },
                {
                    label: 'L',
                    value: 'large',
                },
                {
                    label: 'XL',
                    value: 'extra_large',
                }
            ],
            defaultValue: 'extra_small',
        },
        {
            type: 'string',
            name: 'position',
            friendlyName: 'Position Text',
            required: true,
            enum: [
                {
                    label: 'Top Left',
                    value: 'top-left',
                },
                {
                    label: 'Top Center',
                    value: 'top-center',
                },
                {
                    label: 'Top Right',
                    value: 'top-right',
                },
                {
                    label: 'Center Left',
                    value: 'center-left',
                },
                {
                    label: 'Center Center',
                    value: 'center-center',
                },
                {
                    label: 'Center Right',
                    value: 'center-right',
                },
                {
                    label: 'Bottom Left',
                    value: 'bottom-left',
                },
                {
                    label: 'Bottom Center',
                    value: 'bottom-center',
                },
                {
                    label: 'Bottom Right',
                    value: 'bottom-right',
                }
            ],
            defaultValue: 'center-center',
        },
        {
            type: 'string',
            name: 'heading_text_size',
            friendlyName: 'Heading Text Size',
            required: true,
            enum: [
                {
                    label: 'Size XL',
                    value: 'size-xl',
                },
                {
                    label: 'Size L',
                    value: 'size-l',
                },
                {
                    label: 'Size M',
                    value: 'size-m',
                },
                {
                    label: 'Size S',
                    value: 'size-s',
                },
                {
                    label: 'Size XS',
                    value: 'size-xs',
                }
            ],
            defaultValue: 'size-m',
        },
        {
            type: 'string',
            name: 'text_size',
            friendlyName: 'Text Size',
            required: true,
            enum: [
                {
                    label: 'Size XXL',
                    value: 'size-xxl',
                },
                {
                    label: 'Size XL',
                    value: 'size-xl',
                },
                {
                    label: 'Size L',
                    value: 'size-l',
                },
                {
                    label: 'Size M',
                    value: 'size-m',
                },
                {
                    label: 'Size S',
                    value: 'size-s',
                },
                {
                    label: 'Size XS',
                    value: 'size-xs',
                }
            ],
            defaultValue: 'size-s',
        },
        {
            type: 'string',
            name: 'cta_size',
            friendlyName: 'CTA Text Size',
            required: true,
            enum: [
                {
                    label: 'Size XXL',
                    value: 'size-xxl',
                },
                {
                    label: 'Size XL',
                    value: 'size-xl',
                },
                {
                    label: 'Size L',
                    value: 'size-l',
                },
                {
                    label: 'Size M',
                    value: 'size-m',
                },
                {
                    label: 'Size S',
                    value: 'size-s',
                },
                {
                    label: 'Size XS',
                    value: 'size-xs',
                }
            ],
            defaultValue: 'size-s',
        },
        {
            type: 'color',
            name: 'text_color',
            friendlyName: 'Text color',
            defaultValue: '#000',
        },
        {
            type: "boolean",
            name: 'title_enabled',
            friendlyName: 'Title',
            defaultValue: 1
        },
        {
            type: 'richText',
            name: 'title_text',
            friendlyName: 'Title Text',
            defaultValue: 'Title',
            showIf: (options) => options.get('title_enabled'),
        },
        {
            type: "boolean",
            name: 'paragraph_enabled',
            friendlyName: 'Paragraph',
            defaultValue: 1
        },
        {
            type: 'richText',
            name: 'paragraph_text',
            friendlyName: 'Paragraph Text',
            defaultValue: 'Paragraph',
            showIf: (options) => options.get('paragraph_enabled'),
        },
        {
            type: "boolean",
            name: 'cta_enabled',
            friendlyName: 'CTA'
        },
        {
            type: 'richText',
            name: 'cta_text',
            friendlyName: 'CTA Text',
            defaultValue: 'View',
            showIf: (options) => options.get('cta_enabled'),
        },
        {
            type: 'string',
            name: 'cta_url',
            friendlyName: 'CTA Url',
            defaultValue: '',
            showIf: (options) => options.get('cta_enabled'),
        },
        {
            type: 'boolean',
            name: 'cta_url_tab',
            friendlyName: 'CTA Url in new tab',
            defaultValue: 0,
            showIf: (options) => options.get('cta_enabled'),
        },
        {
            type: 'boolean',
            name: 'cta_url_external',
            friendlyName: 'CTA External Url',
            defaultValue: 0,
            showIf: (options) => options.get('cta_enabled'),
        },
        {
            type: 'string',
            name: 'cta_type',
            friendlyName: 'CTA Type',
            required: true,
            showIf: (options) => options.get('cta_enabled'),
            enum: [
                {
                    label: 'Primary',
                    value: 'primary',
                },
                {
                    label: 'Secondary',
                    value: 'secondary',
                },
                {
                    label: 'Third',
                    value: 'third',
                },
                {
                    label: 'Fourth',
                    value: 'fourth',
                },
                {
                    label: 'Text link',
                    value: 'text',
                }
            ],
            defaultValue: 'secondary',
        },
        {
            type: 'color',
            name: 'background_color',
            friendlyName: 'Background color',
            defaultValue: '#FFFFFF',
        },
        {
            type: 'string',
            name: 'max_width',
            friendlyName: 'Max width text block',
            defaultValue: '65%',
            helperText: '% and px is ideal to work with',
        },
        {
            type: "boolean",
            name: 'fade',
            friendlyName: 'Fade in Text block',
        },
        {
            type: "boolean",
            name: 'background_visual',
            friendlyName: 'Background Visual'
        },
        {
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            name: 'background_visual_image',
            friendlyName: 'Background Visual Image',
            showIf: (options) => options.get('background_visual'),
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
        },
        {
            type: "boolean",
            name: 'layover_enabled',
            friendlyName: 'Layover'
        },
        {
            type: 'color',
            name: 'layover_color',
            friendlyName: 'Layover color',
            defaultValue: 'rgba(0, 0, 0, 0.35)',
            showIf: (options) => options.get('layover_enabled'),
        },
        {
            type: 'string',
            name: 'size_xl',
            friendlyName: 'Size (1440px - …)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_lg',
            friendlyName: 'Size (1024px - 1439px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_md',
            friendlyName: 'Size (768px - 1023px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_sm',
            friendlyName: 'Size (640px - 767px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_xs',
            friendlyName: 'Size (0px - 639px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },

    ],
});
