import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import { t } from "../../../lib/translations";

import useI18n from "../../../hooks/useI18n";
import useSetAddresses from "../../../hooks/checkout/useSetAddresses";
import useFetchCustomer from "../../../hooks/customer/useFetchCustomer";

import { Radio } from "../../../components/form";
import AddressForm from "../../../components/address_form/AddressForm";
import AddressList from "../information/loggedin/addresslist/AddressList";
import Modal from "../../../components/modal/Modal";
import AddAddress from "../information/loggedin/addaddress/AddAddress";
import useCreateCustomerAddress from "../../../hooks/customer/useCreateCustomerAddress";

import classes from "./BillingAddress.module.css";
import useStoreViews from "../../../hooks/useStoreViews";

const BillingAddress = ({ setSelectedBillingAddressType, selectedBillingAddressType, onSetBillingAddressChange = null, currentBillingAddress, customBillingAddress, billingFormRef }) => {
    const { shippingAddress } = useSelector((state) => state.checkout);
    const { customer: { authenticated, addresses = [] }, fetchCustomerData } = useFetchCustomer();
    const { firstname, lastname, street, postcode, city, country } = shippingAddress || {};
    const { getCountryNameByCode } = useI18n();
    const { setAddressBilling } = useSetAddresses();
    const { create } = useCreateCustomerAddress();
    const { currentStoreView } = useStoreViews();
    const { allowedCountries = {} } = currentStoreView;

    const [modalOpen, setModalOpen] = useState(false);

    const defaultBillingAddress = useMemo(() => {
        return addresses.filter((address) => allowedCountries.includes(address.country_code.toLowerCase()) && address.default_billing)?.[0] || null;
    }, [addresses]);

    useEffect(() => {
        if (authenticated) {
            setSelectedBillingAddressType('billing_default');
            onSetBillingAddressData(currentBillingAddress || defaultBillingAddress || customBillingAddress || shippingAddress);
        }
    }, [authenticated]);

    const onSubmit = async (inputs) => {
        await setAddressBilling(inputs);
    }

    const onSetBillingAddressData = (address) => {
        if (onSetBillingAddressChange) {
            onSetBillingAddressChange(address);
        }
    }

    const onBillingIsShipping = () => {
        setSelectedBillingAddressType('shipping');
        onSetBillingAddressData(shippingAddress);
    }

    const handleAddAddresses = async (inputs) => {
        await create(inputs);

        await setAddressBilling(inputs).then(async (response) => {
            setModalOpen(false);

            const newCustomerData = await fetchCustomerData();
            if (newCustomerData?.addresses?.length > 0) {
                const newestAddress = newCustomerData.addresses[newCustomerData.addresses.length - 1];

                onSetBillingAddressData(newestAddress);
            }
        });
    }

    return (
        <div className={classes.root}>
            <h5 className={classes.title}>
                {t('checkout.steps.payment.billing_address.title')}
            </h5>
            {
                authenticated
                    ?
                    <div className={classes.default_billing_address}>
                        <div className={classes.default_billing_radio}>
                            <Radio
                                name="billing_default"
                                label={t('checkout.steps.payment.billing_address.default_billing')}
                                checked={true}
                            />
                        </div>

                        <div className={classes.address}>
                            {
                                selectedBillingAddressType === 'billing_default' &&
                                <AddressList
                                    className={classes.address_list}
                                    selectedAddress={currentBillingAddress?.id || defaultBillingAddress?.id || customBillingAddress?.id || shippingAddress?.customerAddressId}
                                    onAddressChange={onSetBillingAddressData}
                                    billingAddress={true}
                                    onAdd={() => setModalOpen(true)}
                                />
                            }
                        </div>

                        <Modal
                            titleClass={classes.title}
                            modalClassName={classes.checkout_modal_address_logged_in}
                            title={t('checkout.steps.payment.billing_address.new_address')}
                            className={classes.closeIconClass}
                            open={modalOpen}
                            onClose={() => setModalOpen(false)}
                        >
                            <AddAddress
                                loggedInCheckoutForm={true}
                                billingAddressForm={true}
                                handleAddAddresses={handleAddAddresses}
                                onSetBillingAddressData={onSetBillingAddressData}
                            />
                        </Modal>
                    </div>
                    :
                    <>
                        <div className={classes.shipping_address}>
                            <Radio
                                name="shipping"
                                label={t('checkout.steps.payment.billing_address.same_as')}
                                checked={selectedBillingAddressType === 'shipping'}
                                onClick={onBillingIsShipping}
                            />

                            {
                                selectedBillingAddressType === 'shipping' && shippingAddress &&
                                <p>
                                    {firstname} {lastname} <br/>
                                    {
                                        Array.isArray(street) &&
                                        <span>
                                            {typeof street[0] !== 'undefined' && street[0]} &nbsp;
                                            {typeof street[1] !== 'undefined' && street[1]}
                                        </span>
                                    }
                                    <br/>
                                    {postcode} {city} <br/>
                                    {getCountryNameByCode(country?.code)}
                                </p>
                            }
                        </div>
                        <div className={classes.billing_address}>
                            <Radio
                                name="billing"
                                label={t('checkout.steps.payment.billing_address.new_address')}
                                checked={selectedBillingAddressType === 'billing'}
                                onClick={() => setSelectedBillingAddressType('billing')}
                            />

                            <div className={(selectedBillingAddressType === 'billing') ? '' : classes.hide}>
                                <AddressForm
                                    formParentRef={billingFormRef}
                                    redirect={false}
                                    selected={true}
                                    showSaveAs={false}
                                    showSaveButton={true}
                                    checkoutForm={true}
                                    billingAddressForm={true}
                                    parentCallback={onSubmit}
                                    onSetBillingAddressData={onSetBillingAddressData}
                                />
                            </div>
                        </div>
                    </>
            }
        </div>
    );
}

export default BillingAddress;