import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Builder, builder, BuilderComponent } from '@builder.io/react';
import Skeleton from 'react-loading-skeleton'
import { Giftbox } from './components/giftbox/Giftbox';
import 'react-loading-skeleton/dist/skeleton.css'

import useBuilder from "../../hooks/useBuilder";
import useStoreViews from "../../hooks/useStoreViews";

const BuilderSection = ({ model = null, rawContent = null, attributes = {}, loader = false, renderLoading = null, renderNotFound = null, onContentLoaded = null }) => {
    useBuilder();

    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(false);
    const { currentStoreCode } = useStoreViews();
    const { pathname } = useLocation();

    const userAttributes = {
        urlPath: pathname,
        locale: currentStoreCode,
        ...attributes
    };

    const isLive = !Builder.isEditing && !Builder.isPreviewing;

    useEffect(() => {
        const fetchContent = async () => {
            try {
                setLoading(true);
                const content = await builder.get(model, { userAttributes: userAttributes }).promise();

                setContent(content);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }

        if (rawContent) {
            setContent(rawContent);
        } else {
            fetchContent();
        }
    }, [model, rawContent, pathname, currentStoreCode]);

    if (loading) {
        if (loader) {
            const loaderConfig = (loader && typeof loader === 'object') ? loader : {};
            const loaderProps = {
                style: {
                    height: 100
                },
                ...loaderConfig
            };

            return <Skeleton {...loaderProps} />;
        }

        return renderLoading && renderLoading();
    }

    if (isLive && !content) {
        return renderNotFound && renderNotFound();
    }

    return (
        <BuilderComponent model={model} content={content} contentLoaded={onContentLoaded} />
    );
}

export default BuilderSection;
