import classNames from 'classnames';
import classes from './Grid.module.css';

const Row = ({ children, className, ...restProps }) => {

    const rowClassName = classNames(classes.row, className);

    return (
        <div className={rowClassName} {...restProps}>
            { children }
        </div>
    );
}

export default Row;