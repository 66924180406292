import { useConfig } from '@kega/sps-core';

import StoreSelector from "../../components/store_selector/StoreSelector";

import classNames from "classnames";
import classes from './Footer.module.css';

const MinimizedFooter = () => {
    const config = useConfig();

    return (
        <footer className={classNames(classes.root, classes.minimized)}>
            <StoreSelector className={classes.store_selector} />
        </footer >
    )
}

export default MinimizedFooter;
