import classNames from "classnames";
import classes from './CheckoutSteps.module.css';

const CheckoutSteps = ({ title, description, active, completed, children }) => {
    return (
        <div className={classNames(classes.root, active ? classes.active : null, completed ? classes.completed : null)}>
            {
                title.length > 0
                &&
                <div className={classes.step_head}>
                    <div className={classes.step_head_content}>
                        <div className={classes.step_title}>
                            <h4>{title}</h4>
                        </div>
                        <div className={classes.step_description}>
                            {description}
                        </div>
                    </div>
                </div>
            }
            <div className={classes.step_content}>
                {children}
            </div>
        </div>
    )
}

export default CheckoutSteps;
