import { createReducer } from "@reduxjs/toolkit";

import * as actions from './actions';

const initialState = {
    queryIDS: {}
};

export default createReducer(initialState, {
    [actions.setQueryIDs]: (state, action ) => {
        const { payload } = action;

        return {
            ...state,
            queryIDS: payload
        }
    },

    [actions.clearQueryIDs]: () => {
        return initialState;
    }
})