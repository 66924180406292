const getUpsellProducts = `
    query getUpsellProducts($sku: String!) {
        getUpsellProducts(
            sku: $sku
        ) {
            items {
                id
                name
                sku
                url_key
                special_price
                att_text_perfion_googlecolour
                image {
                    url
                }
                hover_image
                price_range {
                    minimum_price {
                        regular_price {
                            currency
                            value
                        }
                        final_price {
                            currency
                            value
                        }
                    }
                }
            }
        }
    }
`;

export default getUpsellProducts;
