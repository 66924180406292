import IconWrapper from '../wrapper/IconWrapper';

const CustomerIcon = ({ color = null, title, ...props }) => {
    return (
        <IconWrapper color={color} {...props}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" role={title ? null : "presentation"}>
                {title && <title>{title}</title>}
                <path d="M7.60041 7.80247C3.45226 7.80247 0.0942383 11.1605 0.0942383 15.3086V16H15.2053V15.3086C15.1066 11.0617 11.8473 7.80247 7.60041 7.80247ZM1.47695 14.6173C1.77325 11.6543 4.43992 9.28395 7.60041 9.18518C10.6621 9.18518 13.23 11.5556 13.6251 14.6173H1.47695Z" fill={color ? color : 'black'} />
                <path d="M7.50175 7.7037C9.57583 7.7037 11.3536 6.02469 11.3536 3.85185C11.3536 1.77778 9.57583 0 7.50175 0C5.42768 0 3.6499 1.77778 3.6499 3.85185C3.6499 6.02469 5.42768 7.7037 7.50175 7.7037ZM5.03262 3.75309C5.03262 2.37037 6.11904 1.28395 7.50175 1.28395C8.88447 1.28395 9.97089 2.37037 9.97089 3.75309C9.97089 5.1358 8.88447 6.22222 7.50175 6.22222C6.11904 6.22222 5.03262 5.1358 5.03262 3.75309Z" fill={color ? color : 'black'} />
            </svg>
        </IconWrapper>
    );
}

export default CustomerIcon;
