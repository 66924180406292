import { useEffect } from "react";
import { useSelector } from "react-redux";

import { t } from "../../../lib/translations";
import classes from './ShipmentComplete.module.css';

import { useCart, useConfig } from "@kega/sps-core";

import useTagManager from "../../../hooks/useTagManager";

const ShipmentComplete = () => {
    const { shippingMethod } = useSelector(state => state.checkout);
    const method = shippingMethod?.shipping_addresses[0]?.selected_shipping_method
    const { totals, items } = useCart();

    const { ecommerceEvent } = useTagManager();
    const { config } = useConfig();

    const pushLayer = (totals, items) => {
        const defaultCurrency = config?.get('currency') ?? 'EUR';

        try {

            let data = {
                currency: defaultCurrency,
                value: totals.total,
                shipping_tier: method?.carrier_title,
                items: [
                ]
            }

            items.forEach( (item, index) => {
                const discounts = item.prices.discounts ?? null;

                data.items.push({
                    item_id: item.sku,
                    item_name: item.name,
                    affiliation: "",
                    coupon: discounts ? discounts[0].label : '',
                    discount: discounts ? discounts[0].amount.value : 0,
                    index: index,
                    item_brand: "",
                    item_category: item.att_text_perfion_productgroupcode,
                    item_list_id: "",
                    item_list_name: "",
                    item_variant: item.configurable_options.length > 0 ? item.configurable_options[0].value_label : '',
                    location_id: "",
                    price: item.price_range.minimum_price.regular_price.value,
                    quantity: item.quantity
                })
            });

            ecommerceEvent('add_shipping_info', data);
        } catch (error) {
            console.log('gtm error','add_shipping_info', error)
            //
        }
    }

    useEffect( () => {
        pushLayer(totals, items);
    },[config])



    return (
        <div className={classes.shipping_step_completed}>
            <h4 className={classes.completed_title}>
                {t("checkout.steps.payment.standard_shipping")}
            </h4>
            <p className={classes.standard_shipping_days}>{t("checkout.steps.payment.standard_shipping_days")}</p>
        </div>
    );
}

export default ShipmentComplete;
