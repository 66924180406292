import { Textarea as DefaultTextarea } from '@kega/sps-elements';

import classNames from 'classnames';
import classes from './Textarea.module.css';

const Textarea = ({ placeholder, required, error, ...rest_props }) => {
    const inputClasses = classNames(classes.input, error ? classes.error : '');

    return (
        <DefaultTextarea className={inputClasses} labelClassName={classes['label']} placeholder={placeholder} labelAnimated required={required} error={error} {...rest_props} />
    );

}

export default Textarea;
