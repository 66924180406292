import { Checkbox as DefaultCheckbox } from '@kega/sps-elements';
import { forwardRef } from "react";

import classNames from 'classnames';
import classes from './Checkbox.module.css';

const Checkbox = ({ required, className, ...props }, ref) => {
    const checkboxClassName = classNames(classes.input, className);
    return (
        <DefaultCheckbox ref={ref} className={checkboxClassName} labelClassName={classes['label']} required={required} {...props} />
    );

}

export default forwardRef(Checkbox);
