import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { t } from "../../../../lib/translations";

import useStoreViews from "../../../../hooks/useStoreViews";
import useUpdateCustomerAddress from "../../../../hooks/customer/useUpdateCustomerAddress";
import useFetchCustomer from "../../../../hooks/customer/useFetchCustomer";

import { Container } from "../../../../components/grid";
import AddressForm from "../../../../components/address_form/AddressForm";

import classes from "./Address.module.css";

const AddressEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { getUrl } = useStoreViews();
    const { update } = useUpdateCustomerAddress();
    const { currentStoreCode } = useStoreViews();
    const { fetchCustomerData } = useFetchCustomer();

    useEffect(() => {
        if (!location.state) {
            navigate(getUrl("account/addressbook/overview"));
        }
    });

    return (
        <Container margins gy-xs={2} g-xs={0} g-md={2} sm={6} className={classes.address_form_edit} >
            <h1>{t('Edit address')}</h1>
            <AddressForm
                buttonTitle={t('customer.address.form.save.edit')}
                address={location.state.address}
                endpoint={update}
                parentCallback={fetchCustomerData}
                onSuccess={t('customer.address.form.saved.edit')} />
        </Container>
    );
};

export default AddressEdit;