import { CustomerIcon } from "../../../components/icons";
import CustomerDropdown from "./dropdown/CustomerDropdown";

import useUi from "../../../hooks/useUi";

import classes from './Customer.module.css';

const Customer = () => {
    const { openCustomerNav, closeCustomerNav, isCustomerNavOpen } = useUi();

    const toggleMenu = () => {
        if (isCustomerNavOpen) {
            closeCustomerNav();
        } else {
            openCustomerNav();
        }
    }

    return (
        <div className={classes.root}>
            <CustomerIcon title={"Customer"} height="16" width="15" onClick={toggleMenu} />
            <CustomerDropdown />
        </div>
    )
}

export default Customer;
