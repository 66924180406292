import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNewsletterSubscribe } from '@kega/sps-core';
import { Form } from '@kega/sps-elements';

import { t } from '../../lib/translations';

import useNewsletterOverlay from "../../hooks/useNewsletterOverlay";
import useStoreViews from "../../hooks/useStoreViews";
import useInputValidation from "../../hooks/form/useInputValidation";
import useMediaQuery from "../../hooks/useMediaQuery";

import { Button, Input } from '../../components/form';

import classNames from 'classnames';
import classes from './Newsletter.module.css';

const Newsletter = ({ enableCaption = true, redirectUrl = null, modalRef, className }) => {
    const { match } = useMediaQuery('(min-width: 1025px)');
    const { subscribe, loading, error: subscribeError } = useNewsletterSubscribe();
    const { closeNewsletterOverlay } = useNewsletterOverlay();
    const navigate = useNavigate();
    const { getUrl } = useStoreViews();
    const [show, setShow] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const { email } = useInputValidation();

    const newsletterClasses = classNames(classes.root, className);

    const submitForm = async ({ valid, values, resetForm }) => {
        if (valid) {
            try {
                await subscribe(values.newsletter);
                
                setSubscribed(true);

                if (redirectUrl) {
                    navigate(getUrl(redirectUrl));
                }

                setTimeout(() => {
                    setSubscribed(false);
                    resetForm();
                    closeNewsletterOverlay();
                    modalRef.current.close();
                }, 2300);

            } catch (error) {
                console.log(error);
            }
        }
    };

    const showSubmitButton = (e) => {
        if (e.target.value.length > 0) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    return (
        <div className={newsletterClasses}>
            <Form onSubmit={submitForm}>
                {
                    enableCaption === true
                    &&
                    <div className={classes.title}>
                        {t('newsletter.caption')}
                    </div>
                }
                <Input
                    name="newsletter"
                    label={t('customer.form.email')}
                    placeholder={t('customer.form.email')}
                    type="email"
                    rules={[ email ]}
                    onChange={showSubmitButton}
                    disabled={loading ? 'disabled' : ''}
                    error={subscribeError != null ?? true}
                    errorMessage={subscribeError && subscribeError.message} />
                <div className={classNames(classes.button_wrapper, show && classes.show_button)}>
                    <Button
                        type="submit"
                        loading={loading}
                        variant={match ? "secondary" : "primary"}
                    >
                        {t('Subscribe')}
                    </Button>
                </div>
                {subscribed && <span className={classes.success}>{t('newsletter.success.subscribe')}</span>}
            </Form>
        </div>
    )
}

export default Newsletter;
