import React, { useMemo } from "react";
import { Configure, InstantSearch } from "react-instantsearch-dom";
import { Helmet } from 'react-helmet-async';
import { useConfig } from '@kega/sps-core';
import { useParams } from "react-router-dom";
import algoliasearch from "algoliasearch/lite";

import useStoreViews from '../../hooks/useStoreViews';

import { Container } from "../../components/grid";
import CmsPageGrid from "../../modules/algolia/searchresults/cmspage_results/cmspagegrid/CmsPageGrid";
import ScrollToTop from "../../utils/ScrollToTop";


const Help = () => {
    const config = useConfig();
    const { getUrl } = useStoreViews();
    const params = useParams();

    const { index, appId, apiKey } = config.get('algolia');
    const storeCode = config.get('storeCode');
    const baseUrl = config.get('baseUrl');
    const storeViews = config.get('storeViews');

    const searchClient = useMemo(() => algoliasearch(appId, apiKey), [appId, apiKey]);

    const query = params.query;

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, follow"/>

                <link rel="canonical" href={`${baseUrl}${getUrl('/search')}`}/>

                {
                    storeViews
                    &&
                    Object.keys(storeViews).map((storeCode) => {
                        const href = getUrl('/search', {}, storeCode);
                        if (!storeCode.endsWith('_kiosk')) {
                            return <link key={href} rel="alternate" hrefLang={storeCode.includes('_')
                                ? storeCode.split('_').reverse().join('-')
                                : storeCode} href={window.location.origin + href}/>
                        }
                    })
                }
            </Helmet>

            <ScrollToTop />
            <InstantSearch
                searchClient={searchClient}
                indexName={index + storeCode + '_pages'}
            >
                <Configure
                    query={query}
                />
                <Container>
                    <CmsPageGrid query={query} />
                </Container>
            </InstantSearch>

        </>
    );
};

export default Help;