import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import { useConfig } from '@kega/sps-core';

import useCategory from "../../hooks/useCategory.js";

import NotFound from '../error/notfound/NotFound';

// import AlgoliaProductList from "../../modules/algolia/ProductList";
import { default as AlgoliaProductList } from '../../modules/algolia/ProductList.js';

const ProductListPage = () => {
    const [ category, setCategory ] = useState([{}]);
    const config = useConfig();
    const storeViews = config.get('storeViews');
    const currentStoreCode = config.get('storeCode');

    const { category_key } = useParams();
    const { pathname } = useLocation();
    const { loading, fetchCategory } = useCategory();

    const category_path = pathname.split(currentStoreCode + '/')[1];

    useEffect(async() => {
        const response = await fetchCategory(category_path, {
            custom_attributes: [
                'category_seo_name'
            ]
        });
        setCategory(response);
    }, [ category_path ]);

    if (category === undefined) { return <>{!loading && <NotFound />}</>; }

    return (
        <>
            <Helmet>
                <title>{category?.meta_title}</title>

                <meta name="title" content={category?.meta_title} />
                <meta name="description" content={category?.meta_description} />
                <meta name="keywords" content={category?.meta_keywords} />
                <link rel="canonical" href={window.location.href} />
                {
                    category?.meta_robots && <meta name="robots" content={category.meta_robots} />
                }

                {
                    storeViews
                    &&
                    Object.entries(storeViews).map((storeCode) => {
                        const { url: { prefix_category } } = storeViews[storeCode[0]];
                        const href = `/${storeCode[0]}/${prefix_category}/${category_key}`;
                        if (!storeCode[0].endsWith('_kiosk')) {
                            return (
                                <link
                                    key={href}
                                    rel="alternate"
                                    hrefLang={storeCode[1].hreflang}
                                    href={window.location.origin + href}/>
                            );
                        }
                    })
                }

            </Helmet>

            <AlgoliaProductList title={category?.category_seo_name || category?.name} description={category?.meta_description}  loading={loading} filters={'category_url_paths:' + category_path} /*filters="categoryIds:28"*/ />

        </>
    )
}

export default ProductListPage;
