import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCartId, useConfig, useRequest, useStorefront } from '@kega/sps-core';
import { addShippingMethods } from "@kega/sps-core/src/reducers/checkout/actions";

const useEstimateShippingMethods = () => {
    const { api: { checkout } } = useStorefront();
    const dispatch = useDispatch();

    const { shippingMethods } = useSelector((state) => state.checkout);

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const estimateShippingMethods = useCallback( async (params) => {
        try {
            setRequest();
            const result = await checkout.estimateShippingMethods(params);
            dispatch(addShippingMethods(result));
            setSuccess(result);
            return result;
        } catch (error) {
            console.log('[EstimateShippingMethods]', error);
            setError(error);
        }
    }, []);

    return {
        error,
        loading,
        methodes: shippingMethods,
        estimateShippingMethods
    };
}

export default useEstimateShippingMethods;