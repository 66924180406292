import { Link, useNavigate } from "react-router-dom";

import { t } from "../../../../lib/translations";

import useStoreViews from "../../../../hooks/useStoreViews";

import { LinkButton } from "../../../../components/form";
import Page from "../../../header/search/page/Page";

import classes from './CmsPageResult.module.css';

const CmsPageResult = ({ data, mobile, query, blockTitle }) => {
    const { getUrl } = useStoreViews();
    const navigate = useNavigate();



    const routeChange = (pageItem) => {
        navigate(getUrl('/' + pageItem.slug));
    };

    return (
        <div className={classes.root}>
            {
                !mobile
                    ?
                    <h5 className={classes.help_block_title}>{t(blockTitle ?? 'header.search.help_block_title')}</h5>
                    :
                    null
            }
            {data.map((pageItem) => (
                <Page key={pageItem.objectId} data={pageItem} onClick={() => routeChange(pageItem)} />
            ))}
            <div className={classes.button_container}>
                {
                    data.length > 3
                        ?
                        <LinkButton
                            to={getUrl(`/help/${query}/all`)}
                            linkComponent={Link}
                            variant="secondary"
                        >
                            {t('header.search.cms.block.button.title')}
                        </LinkButton>
                        :
                        null
                }
            </div>
        </div>
    )
};

export default CmsPageResult;
