import { Builder } from "@builder.io/react/lite";
import { Image } from "@builder.io/react";

import { Link } from "react-router-dom";
import { Container, Row, Col } from '../../../../components/grid';

import classes from "./CategoryImage.module.css";

export const CategoryImage = ({ title, image, cta_url,  cta_url_tab, cta_url_external }) => {
    const img =
        <>
            <Image lazy aspectRatio={0} image={image} altText={title} />
            <div className={classes.text_label}>{title}</div>
        </>;
    return (
        <Container>
            <Row>
                <Col className={classes.col_wrapper}>
                    {
                        cta_url_external
                            ?
                            <a href={cta_url} target={cta_url_tab ? '_blank' : '_self'} rel='noreferrer'>{img}</a>
                            :
                            <Link to={cta_url} target={cta_url_tab ? '_blank' : '_self'} rel='noreferrer'>{img}</Link>
                    }
                </Col>
            </Row>
        </Container>
    );
};

Builder.registerComponent(CategoryImage, {
    name: "Category Image",
    static: true,
    defaultStyles: {
        marginTop: "0px",
    },
    inputs: [
        {
            type: 'string',
            name: 'title',
            friendlyName: 'Title',
            required: true
        },
        {
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            name: 'image',
            friendlyName: 'Image',
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
            required: true
        },
        {
            type: 'string',
            name: 'cta_url',
            friendlyName: 'CTA Url',
            defaultValue: '',
            required: true
        },
        {
            type: 'boolean',
            name: 'cta_url_tab',
            friendlyName: 'CTA Url in new tab',
            defaultValue: 0
        },
        {
            type: 'boolean',
            name: 'cta_url_external',
            friendlyName: 'CTA External Url',
            defaultValue: 0
        },
    ],
});
