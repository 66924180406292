import { ProductDescription } from '@kega/sps-components';

import { t } from '../../../lib/translations';

import classes from "./GeneralInfo.module.css";

const GeneralInfo = ({ product  }) => {
    let fabric = [];
    for (let i = 1; i <= 5; i++) {
        if (product['perfion_composition'+i] && product['att_text_perfion_component'+i]) {
            fabric.push({
                label: product['att_text_perfion_component'+i],
                value: product['perfion_composition'+i]
            })
        }
    }

    
    return (
        <>
            {
                (product?.sku) &&
                <div className={classes.block}>
                    <div className={classes.title} role="heading">
                        {t('product.info.generalinfo.sku_title')}
                    </div>
                    {product?.sku}
                </div>
            }
            {
                product?.description &&
                <div className={classes.block}>
                    <div className={classes.title} role="heading">
                        {t('product.info.generalinfo.description_title')}
                    </div>
                    {
                        product?.description && <ProductDescription className={classes.description} content={product?.description.html} html={true} />
                    }
                </div>
            }
            {
                (fabric.length > 0 || product?.perfion_composition_old) &&
                <div className={classes.block}>
                    <div className={classes.title} role="heading">
                        {t('product.info.generalinfo.fabric')}
                    </div>

                    {
                        product?.perfion_composition_old ?
                            <div dangerouslySetInnerHTML={{ __html: product?.perfion_composition_old }} />
                            :
                            fabric.length > 0 ?
                                <ul>
                                    {
                                        fabric.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    {item.label}: {item.value}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                : ''
                    }
                </div>
            }
            {
                product?.perfion_washinstructions_old &&
                <div className={classes.block}>
                    <div className={classes.title} role="heading">
                        {t('product.info.generalinfo.washing_instructions')}
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: product?.perfion_washinstructions_old }} />
                </div>
            }
            {
                product?.perfion_modelinformation &&
                <div className={classes.block}>
                    <div className={classes.title} role="heading">
                        {t('product.info.generalinfo.model_information')}
                    </div>
                    {product?.perfion_modelinformation}
                </div>
            }
            {
                product?.perfion_websizefitting &&
                <div className={classes.block}>
                    <div className={classes.title} role="heading">
                        {t('product.info.generalinfo.websize_fitting')}
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: product?.perfion_websizefitting }} />
                </div>
            }
            {
                product?.att_text_perfion_sizewarning === 'Normal' ? '' : product?.perfion_modelsizetext &&
                <div className={classes.block}>
                    <div dangerouslySetInnerHTML={{ __html: product?.perfion_modelsizetext }} />
                </div>
            }
        </>
    );
}

export default GeneralInfo;
