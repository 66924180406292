import { useRequest, useStorefront } from '@kega/sps-core';

const useEmailAvailable = () => {
    const { api: { essentielCustomer } } = useStorefront();

    const { success, loading, error, setRequest, setSuccess, setError } = useRequest();

    const isEmailAvailable = async (email) => {
        try {
            setRequest();
            const result = await essentielCustomer.isEmailAvailable(email);
            setSuccess(result);
            return result;
        } catch (error) {
            console.log('[isEmailAvailable] ', error);
            setError(error);
        }
    };

    return {
        success,
        loading,
        error,
        isEmailAvailable
    };
};

export default useEmailAvailable;
