import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useConfig } from '@kega/sps-core';

import qs from 'qs';

import useStoreViews from '../../hooks/useStoreViews';

import { t } from '../../lib/translations';
import { default as AlgoliaProductList } from '../../modules/algolia/ProductList.js';
import SearchResults from "../../modules/algolia/searchresults/SearchResults";


const Search = () => {
    const config = useConfig();
    const { search } = useLocation();
    const { getUrl } = useStoreViews();

    const baseUrl = config.get('baseUrl');
    const storeViews = config.get('storeViews');

    const { q } = qs.parse(search.replace('?', ''), { comma: true })

    return (
        <>
            <Helmet>
                <title>{t('search.page_title') + ': ' + q}</title>
                <meta name="robots" content="noindex, follow"/>

                <link rel="canonical" href={`${baseUrl}${getUrl('/search')}`}/>

                {
                    storeViews
                    &&
                    Object.keys(storeViews).map((storeCode) => {
                        const href = getUrl('/search', {}, storeCode);
                        return <link key={href} rel="alternate" hrefLang={storeCode.includes('_') ? storeCode.split('_').reverse().join('-') : storeCode} href={window.location.origin + href}/>
                    })
                }

            </Helmet>

            {
                q ?
                    <SearchResults query={q} />
                    :
                    <AlgoliaProductList
                        title={t('search.page_title') + ': ' + q}
                        query={q}
                    />
            }
        </>
    )
}

export default Search;