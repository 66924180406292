import { t } from "../../../lib/translations";

import useLabels from "../../../hooks/useLabels";

import classNames from 'classnames';
import classes from './Labels.module.css';

const Labels = ({ product, product_page = false }) => {
    const { labels } = useLabels(product);
    let labels_wrapper = !product_page ? 'labels_wrapper' : 'labels_wrapper_pdp';

    return (
        <div className={classes[labels_wrapper]}>
            {
                Object.keys(labels).map((item, index) => {
                    return (
                        labels[item].length > 0 &&
                        <div key={index} className={classes[item]}>
                            {
                                labels[item].map((label, index) => {
                                    return (
                                        <div key={index} className={classNames(classes.label, classes[label.id])}>{t(label.label)}</div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}

export default Labels;
