import { Link } from "react-router-dom";

import ErrorPage from "../Error";

import { t } from "../../../lib/translations";

import useStoreViews from "../../../hooks/useStoreViews";

const Error = () => {
    const { getUrl } = useStoreViews();

    return (
        <ErrorPage
            pageTitle={t('error_page.error.page_title')}
            statusCode={500}
            fullHeight
        >
            <h2>{t('error_page.error.title')}</h2>
            <p>{t('error_page.error.description')}</p>
            <p>
                <Link to={getUrl('/')}>{t('error_page.error.go_back')}</Link>
            </p>
        </ErrorPage>
    )
}

export default Error;