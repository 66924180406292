import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useStorefront } from '@kega/sps-core';
import { addValue, addValues } from "../redux/store_config/actions";
const useStoreConfig = () => {
    const { api: { essentielStore } } = useStorefront();

    const dispatch = useDispatch();

    const  getConfig = async (key) => {
        const keys = Array.isArray(key) ? key : [key];

        const response = await essentielStore.getStoreConfig(keys);

        if(response.storeConfig) {
            dispatch(addValues(response.storeConfig));
        }
        return response.storeConfig[key];

    }

    return {
        getConfig
    }
}

export default useStoreConfig;