import { useEffect, useState } from 'react';

import { useCms, useConfig } from '@kega/sps-core';
import { Helmet } from 'react-helmet-async';
import { Builder, builder, BuilderComponent } from '@builder.io/react';

import StructuredData from './structureddata/StructuredData';
import useStoreViews from '../../hooks/useStoreViews';

import useCache from '../../hooks/useCache';
import { consoleLog } from "../../lib/utils";

builder.init('661980337514468f8025436cda38935b');
builder.apiVersion = 'v3';

const BuilderPage = ({ model = 'page', url = null, renderLoading = null, renderNotFound = null, setCanonicalUrl = true }) => {
    const [pageContent, setPageContent] = useState(null);
    const [page, setPage] = useState(null);
    const { currentStoreCode, storeViews } = useStoreViews();
    const cache = useCache();
    const now = Date.now();
    const { getUrl } = useStoreViews();
    const startedSchedule = now > pageContent?.startDate;
    const endSchedule = now > pageContent?.endDate;

    const isLive = !Builder.isEditing && !Builder.isPreviewing;

    const { success, error, loading, fetchCms } = useCms();

    const cacheKey = model + (url + `store-${currentStoreCode}` !== null ? ':' + url + `store-${currentStoreCode}` : '');
    let useCachedPage = false;

    useEffect(() => {
        /**
         * Temporary fix because of BuilderIO bug that crashed when cached page is loaded
         * https://kega.atlassian.net/browse/EA-1127
         */
        const cached = cache.get(cacheKey);

        // Reset previous state to null or set the cached current page
        // if (cached && typeof cached === 'string') {
        //     const cacheData = JSON.parse(cached);
        //
        //     if(cacheData.dt) {
        //         // do not use cache if older than 24 hours
        //         if(Date.now() < (cacheData.dt + 86400000)) {
        //             useCachedPage = true;
        //             setPageContent(cacheData.content);
        //         }
        //     }
        //
        // }
    }, [cacheKey, setPageContent]);

    let content = null;

    useEffect(async() => {
        try {

            if (!isLive) { return false; }

            if (!useCachedPage) {
                consoleLog('Fetching CMS Page', url);
                const response = await fetchCms(url);

                content = JSON.parse(response.content);

                content.data = content.data || {};

                content.data.blocksString = content.data?.blocksString.replaceAll('{base_url}', getUrl() );

                content.data.blocks = JSON.parse(content.data?.blocksString);

                /**
                 * Temporary fix for BuilderIO bug that causes the page to crash
                 * https://kega.atlassian.net/browse/EA-1127
                 */
                if (content.data.inputs) {
                    content.data.inputs = [];
                }

                cache.set(cacheKey, JSON.stringify({
                    'dt' : Date.now(),
                    'content' : content
                }));
                setPageContent(content);
                setPage(response);
            }

        } catch (e) {
            content = null
            console.log('No valid CMS content');
        }
    }, [url, fetchCms]);

    if (loading && !pageContent) {
        return renderLoading && renderLoading();
    }

    if (isLive && (success && !pageContent) || !isLive && url === 'notfound' || error && isLive) {
        return renderNotFound && renderNotFound();
    }

    if (isLive && (pageContent === null)) { return null; }

    if (isLive && (pageContent?.startDate && !startedSchedule) || isLive && (pageContent?.endDate && endSchedule)) {
        return renderNotFound && renderNotFound();
    }

    return (
        <>
            <Helmet>
                <title>{page?.title || ''}</title>
                <meta name="title" content={page?.meta_title || page?.title || ''} />
                <meta name="description" content={page?.meta_description || ''} />
                <meta name="keywords" content={page?.meta_keywords || 'Essentiel Antwerp'} />

                <meta property="og:type" content="website"/>
                <meta property="og:title" content={page?.meta_title || page?.title || ''} />
                <meta property="og:description" content={page?.meta_description || ''} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:site_name" content="Essentiel"/>
                {
                    setCanonicalUrl && <link rel="canonical" href={window.location.href} />
                }

                {
                    storeViews && url !== 'homepage_essentiel_antwerp'
                    &&
                    Object.entries(storeViews).map((storeCode) => {
                        const href = `/${storeCode[0]}/`;
                        if (!storeCode[0].endsWith('_kiosk')) {
                            return (
                                <link
                                    key={href}
                                    rel="alternate"
                                    hrefLang={storeCode[1].hreflang}
                                    href={window.location.origin + href  + url} />
                            );
                        }
                    })
                }

            </Helmet>

            <BuilderComponent model={model} content={pageContent} />
            <StructuredData />
        </>
    );
}

export default BuilderPage;
