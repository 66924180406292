import IconWrapper from '../wrapper/IconWrapper';

const ChevronIcon = ({ color = null, title, ...props }) => {
    return (
        <IconWrapper color={color} {...props}>
            <svg width="8" height="6" viewBox="0 0 8 6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" role={title ? null : "presentation"}>
                {title && <title>{title}</title>}
                <path d="M-2.71312e-08 0.62069L0.831169 -3.15319e-07L4.05195 4.24138L7.16883 -3.65587e-08L8 0.62069L4.05195 6L-2.71312e-08 0.62069Z" fill={color ? color : 'black'} />
            </svg>
        </IconWrapper>
    );
}

export default ChevronIcon;
