import WishlistFragment from './fragments/WishlistFragment.gql';

const addProductsToWishlistMutation = `
    mutation addProductsToWishlist($wishlistId: ID!, $wishlistItems:[WishlistItemInput!]!) {
        addProductsToWishlist(
            wishlistId: $wishlistId
            wishlistItems: $wishlistItems
        ) {
            wishlist {
                ...WishlistFragment
            }
        }
    }

    ${WishlistFragment}
`;

export default addProductsToWishlistMutation;
