import IconWrapper from "../wrapper/IconWrapper";

const MinusIcon = ({ color = null, title, ...props }) => {
    return (
        <IconWrapper color={color} {...props}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" role={title ? null : "presentation"}>
                {title && <title>{title}</title>}
                <path d="M12.9925 2.4V0.66H0.887531V2.4H12.9925Z" fill={color ?? 'black'} />
            </svg>
        </IconWrapper>
    );
};

export default MinusIcon;
