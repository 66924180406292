import { useCallback } from 'react';
import { useRequest, useStorefront } from '@kega/sps-core';


const useCategory = () => {
    const { api: { catalog } } = useStorefront();


    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const fetchCategory = useCallback(async (url_path, options) => {
    
        try {
            setRequest();
            const result = await catalog.getCategory({ url_path }, options);

            if (result) {
                return result;
            }

            setSuccess();
        } catch (error) {
            console.log('[fetchCategory]', error);
            setError(error);
        }
    }, [] );

    return {
        error,
        loading,
        fetchCategory
    };
}

export default useCategory;
