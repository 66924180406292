import CartFragment from "@kega/sps-connector-magento2/src/queries/fragments/CartFragment.gql";

const applyCoupon = (attributes = [], additionalData = []) => `
    mutation applyCouponCode($cartId: String!, $couponCode: String!) {
        applyCoupon(
          cartId: $cartId, couponCode: $couponCode
        ) {
            cart {
                ...CartFragment
            }
            errors
            messages
            success
        }
    }

    ${CartFragment(attributes, additionalData)}
`;

export default applyCoupon;