import { useMemo, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useConfig } from '@kega/sps-core';
import { useNavigate, useLocation } from "react-router-dom";
import { createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import classNames from 'classnames';
import algoliasearch from 'algoliasearch/lite';
import { ResetProductId } from '../../../redux/plp/actions';
import {
    setCategories,
    setPopularCategories,
    setCmsResultPages,
    setCmsResultBlogs,
    setSearchResults,
} from "../../../redux/algolia_results/actions";

import useStoreViews from "../../../hooks/useStoreViews";
import useTagManager from "../../../hooks/useTagManager";

import { t } from '../../../lib/translations';

import SearchSection from './searchsection/SearchSection';

import { SearchIcon, CrossIcon } from '../../../components/icons';
import { Input } from "../../../components/form";
import Category from './category/Category';
import Page from './page/Page';
import Product from './product/Product';
import Suggestion from './suggestion/Suggestion';
import Drawer from "../../../components/drawer/Drawer";
import { Button } from "../../../components/form";

import SearchEmptyContent from './searchemptycontent/SearchEmptyContent';

import qs from 'qs';
import classes from './Search.module.css';

const Search = (hasMore) => {
    const [autocompleteState, setAutocompleteState] = useState({});
    const { ecommerceEvent } = useTagManager();
    const { search } = useLocation();
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const hasQuery = !!autocompleteState?.query;

    const config = useConfig();

    const { currentStoreCode: storeCode, getUrl, isKiosk } = useStoreViews();

    const loadMoreOnClick = () => {
        if (hasMore) {
            const query = autocompleteState.query || '';
            const encodedQuery = encodeURIComponent(query);
            navigate(`/${storeCode}/search?q=${encodedQuery}`)
            dispatch(ResetProductId());
            setIsOpen(false);
        }
    };


    let indexStoreCode = storeCode;
    if (isKiosk) {
        indexStoreCode = indexStoreCode.replace('_kiosk', '');
    }

    const { prefix_product, prefix_category } = config.get('url');

    const currency = config.get('currency');

    const { index, appId, apiKey } = config.get('algolia');

    const { q = '' } = qs.parse(search.replace('?', ''), { comma: true })

    const searchClient = useMemo(
        () => algoliasearch(appId, apiKey),
        [appId, apiKey]
    );

    const collectionTitles = {
        categories : t('header.search.source.categories'),
        suggestions : t('header.search.source.suggestions'),
        products : t('header.search.source.products'),
        blogs : t('header.search.source.blogs'),
        pages : t('header.search.source.pages'),
    }

    const pushLayer = (product) => {
        const price = product.price;

        const finalPrice = price ? price[currency].default : product.price_range.minimum_price.regular_price.value;
        const discount = price === finalPrice ? 0 : finalPrice > 0 && finalPrice < price ? parseFloat(Math.round(price - finalPrice * 100) / 100) : 0;

        try {
            ecommerceEvent('select_item', {
                items: [{
                    item_id: product.sku.length > 0 && product.sku[0],
                    item_name: product.name,
                    item_category: product.perfion_productgroupcode,
                    price: price ? price[currency].default : product.price_range.minimum_price.regular_price.value,
                    discount: discount,
                    currency: currency
                }]
            });
        } catch (error) {
            //
        }
    }

    const autocomplete = useMemo(() => {

        return createAutocomplete({

            placeholder: t('header.search.placeholder'),

            debug: false,

            initialState: {
                query: q
            },

            autoFocus: true,

            openOnFocus: true,

            onSubmit: ({ state }) => {
                const queryParam = encodeURIComponent(state.query);
                navigate(`/${storeCode}/search?q=${state.query}`);
            },

            onStateChange: ({ state }) => {
                setAutocompleteState(state);
            },

            shouldPanelOpen: () => true,

            getSources: () => {
                return [
                    {
                        sourceId: 'suggestions',

                        getItems: ({ query }) => {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [{
                                    indexName: index + indexStoreCode + '_products_query_suggestions',
                                    query,
                                    params: {
                                        hitsPerPage: 5,
                                        highlightPreTag: '<strong>',
                                        highlightPostTag: '</strong>',
                                    },
                                }],
                            });
                        },

                        renderItem: (item, source) => {
                            const { onClick } = autocomplete.getItemProps({ item, source });
                            return <Suggestion key={item.objectID} data={item} onClick={onClick} />
                        },

                        onSelect: (props) => {
                            const { item, setIsOpen: setAutocompleteIsOpen, setQuery, refresh } = props;
                            setQuery(item.query);
                            refresh();
                            setAutocompleteIsOpen(true);
                        },
                    },
                    {
                        sourceId: 'pages',

                        getItems: ({ query }) => {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [{
                                    indexName: index + indexStoreCode + '_pages',
                                    query,
                                    params: {
                                        filters: 'article:0',
                                        hitsPerPage: 4,
                                        highlightPreTag: '<strong>',
                                        highlightPostTag: '</strong>',
                                    }
                                }],
                            });
                        },

                        renderItem: (item, source) => {
                            const { onClick } = autocomplete.getItemProps({ item, source });
                            return <Page key={item.objectID} data={item} onClick={onClick} />
                        },

                        onSelect: (props) => {
                            const { item, setQuery } = props;
                            const { slug } = item;

                            const { name, price } = item;
                            const prices = (price && price[currency]) ? price[currency] : {};
                            const defaultPrice = prices.default || 0;


                            setQuery('');

                            try {
                                ecommerceEvent('select_item', {
                                    items: [{
                                        item_id: item.objectID,
                                        item_name: name,
                                        price: defaultPrice
                                    }]
                                });
                            } catch (error) {
                                console.log('error onselect', error);

                                //
                            }

                            navigate(getUrl(slug));
                        },
                    },
                    {
                        sourceId: 'blogs',

                        getItems: ({ query }) => {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [{
                                    indexName: index + indexStoreCode + '_pages',
                                    query,
                                    params: {
                                        filters: 'article:1',
                                        hitsPerPage: 4,
                                        highlightPreTag: '<strong>',
                                        highlightPostTag: '</strong>',
                                    }
                                }],
                            });
                        },

                        renderItem: (item, source) => {
                            const { onClick } = autocomplete.getItemProps({ item, source });
                            return <Page key={item.objectID} data={item} onClick={onClick} />
                        },

                        onSelect: (props) => {
                            const { item, setQuery } = props;
                            const { slug } = item;

                            const { price } = item;
                            const prices = (price && price[currency]) ? price[currency] : {};
                            const defaultPrice = prices.default || 0;
                            const discount = price === defaultPrice ? 0 : defaultPrice > 0 && defaultPrice < price ? parseFloat(Math.round(price - defaultPrice * 100) / 100) : 0;

                            setQuery('');

                            try {
                                ecommerceEvent('select_item', {
                                    items: [{
                                        item_id: item.sku.length > 0 && item.sku[0],
                                        item_name: item.name,
                                        item_category: item.perfion_productgroupcode,
                                        price: price ? price[currency].default : item.price_range.minimum_price.regular_price.value,
                                        discount: '',
                                        currency: currency
                                    }]
                                });
                            } catch (error) {
                                console.log('error onselect', error);

                                //
                            }

                            navigate(getUrl(slug));
                        },
                    },
                    {
                        sourceId: 'products',

                        getItems: ({ query }) => {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [{
                                    indexName: index + indexStoreCode + '_products',
                                    clickAnalytics: true,
                                    query,
                                    params: {
                                        hitsPerPage: 1000,
                                        highlightPreTag: '<strong>',
                                        highlightPostTag: '</strong>',
                                    },
                                }],
                            });
                        },

                        renderItem: (item, source) => {
                            const { onClick } = autocomplete.getItemProps({ item, source });
                            return <Product key={item.objectID} data={item} onClick={onClick} />
                        },

                        onSelect: (props) => {
                            const { item, setQuery } = props;
                            
                            pushLayer(item);
                            setQuery('');
                        },
                    },
                    {
                        sourceId: 'categories',

                        getItems({ query }) {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [{
                                    indexName: index + indexStoreCode + '_categories',
                                    query,
                                    params: {
                                        hitsPerPage: 8,
                                        highlightPreTag: '<strong>',
                                        highlightPostTag: '</strong>',
                                    },
                                }],
                            });
                        },

                        renderItem: (item, source) => {
                            const { onClick } = autocomplete.getItemProps({ item, source });
                            return <Category key={item.objectID} data={item} onClick={onClick} />
                        },

                        onSelect: (props) => {
                            const { item, setQuery } = props;

                            setQuery('');
                            if (!item.url_key && item.url) {
                                document.location = item.url;
                            }
                            else {
                                navigate(getUrl(prefix_category + '/' + item.url_key));
                            }
                        },
                    }
                ];
            },

            plugins: []
        })

    }, []);

    const renderCollection = ({ items = [], source = null }) => {
        const limitedResults = items ? items.slice(0, 4) : [];
        return limitedResults.map((item) => {
            if (source?.renderItem) {
                return source.renderItem(item, source);
            }

            return (
                <div
                    key={item.objectID}
                    {...autocomplete.getItemProps({ item, source, })}>
                    {item.name}
                </div>
            );
        });
    };

    let querySuggestions = null,
        resultCount = 0,
        resultCollections = [];

    autocompleteState.collections?.map((collection) => {
        const { source } = collection;

        switch (source.sourceId) {
            case 'suggestions':
                querySuggestions = collection;
                break;
            case 'categories':
                dispatch(setCategories(collection));
                break;
            case 'pages':
                dispatch(setCmsResultPages(collection));
                break;
            case 'blogs':
                dispatch(setCmsResultBlogs(collection));
                break;
        }
        resultCollections.push(collection);
        resultCount += collection.items?.length || 0;
        dispatch(setSearchResults(resultCount));

    });

    return (
        <div className={classes.search_root}>
            <button className={classes.search_button} type="submit" onClick={() => setIsOpen(true)} >
                <SearchIcon roleButtonRemoved={true} size={16} />
            </button>
            <Drawer
                drawerClassName={classes.drawer_content}
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <div {...autocomplete.getRootProps({})}>

                    <form
                        className={classes.search_input}
                        ref={formRef}
                        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
                    >
                        <SearchIcon roleButtonRemoved={true} size={17} />
                        <div className={classes.search_field}>
                            <Input
                                label={t('header.search.placeholder')}
                                type="search"
                                ref={inputRef}
                                {...autocomplete.getInputProps({ inputElement: inputRef.current })} />
                        </div>
                        <button
                            className={classes.search_button}
                            type="submit"
                            onClick={() => setIsOpen(false)}
                        >
                            <CrossIcon title={"Search"} size={16} />
                        </button>
                    </form>

                    {
                        isOpen
                        &&
                        <div className={classes.results} {...autocomplete.getPanelProps({})}>
                            {
                                !hasQuery && (
                                    <div className={classes.categories_wrapper}>
                                        {
                                            <div className={classes.empty_search_content} onClick={() => setIsOpen(false)} >
                                                <SearchEmptyContent />
                                            </div>
                                        }
                                    </div>
                                )
                            }
                            {
                                hasQuery && (
                                    <>
                                        {
                                            (querySuggestions && querySuggestions.items?.length > 0) && (
                                                <div className={classes.suggestions_wrapper}>
                                                    <h2>{t('header.search.suggested_searches')}</h2>
                                                    {
                                                        renderCollection(querySuggestions)
                                                    }
                                                </div>
                                            )
                                        }
                                        <div className={classes.results_wrapper}>
                                            <h2>{t('header.search.search_results', { count: resultCount })}</h2>
                                            {
                                                resultCollections.map((collection, index) => {
                                                    const { source, items } = collection;

                                                    if (source.sourceId === 'categories' || source.sourceId === 'suggestions') {
                                                        return null;
                                                    }

                                                    return (
                                                        <div className={classNames(classes.source, source.sourceId)} key={`source-${index}`}>
                                                            { items.length > 0 && collectionTitles[source.sourceId] && <h3 className={ classNames(classes.collection_title) }>{ collectionTitles[source.sourceId] } </h3> }
                                                            <div className={classes.source_items} onClick={() => setIsOpen(false)} role="button" tabIndex="0">
                                                                {
                                                                    items.length > 0
                                                                        ?
                                                                        renderCollection(collection)
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            <section className={classes.search_button_result}>
                                                {
                                                    hasMore && resultCount > 0 ? (
                                                        <Button
                                                            variant="secondary"
                                                            onClick={loadMoreOnClick}
                                                            id="previous"
                                                        >
                                                            {t('algolia.productlist.show.all.results.button')}
                                                        </Button>
                                                    )
                                                        :
                                                        null
                                                }
                                            </section>

                                        </div>
                                    </>
                                )
                            }
                        </div>
                    }
                </div>
            </Drawer>
        </div>
    );
}

export default Search;
