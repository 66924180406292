import { useDispatch } from 'react-redux';
import { useRequest, useStorefront } from '@kega/sps-core';
import useWishlistId from "../../hooks/wishlist/useWishlistId";

import { UpdateWishlist } from '../../redux/wishlist/actions';

const useRemoveFromWishlist = () => {
    const { api: { essentielWishlist } } = useStorefront();

    const dispatch = useDispatch();
    const getWishlistId = useWishlistId();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const removeFromWishlist = async (itemId) => {
        const wishlistId = getWishlistId();

        if (wishlistId === null || typeof wishlistId === 'undefined') {
            return;
        }

        try {
            setRequest();
            const result = await essentielWishlist.removeProductFromWishlist(wishlistId, itemId);

            if (result) {
                dispatch(UpdateWishlist(result));
            }

            setSuccess();
        } catch (error) {
            console.log('[RemoveFromWishlist]', error);
            setError(error);
        }
    };

    return {
        error,
        loading,
        removeFromWishlist
    };
}

export default useRemoveFromWishlist;
