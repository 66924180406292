import { useEffect, useMemo } from 'react';

import { t } from "../../../../../lib/translations";

import useStoreViews from '../../../../../hooks/useStoreViews';

import { PlusIcon } from '../../../../../components/icons';
import Address from '../../../../../components/address/Address';
import useFetchCustomer from "../../../../../hooks/customer/useFetchCustomer";

import classNames from 'classnames';
import classes from './AddressList.module.css';

const AddressList = ({ className = '', onAddressChange, selectedAddress, onAdd = () => { }, billingAddress = false }) => {
    const { customer: { addresses = [] } } = useFetchCustomer();
    const { currentStoreView } = useStoreViews();
    const { allowedCountries = {} } = currentStoreView;

    const availableAddresses = useMemo(() => {
        return !billingAddress
            ? addresses.filter((address) => allowedCountries.includes(address.country_code.toLowerCase()))
            : addresses;
    }, [addresses])

    const selectAddress = async (id) => {
        onAddressChange(id);
    }

    return (
        <div className={classNames(classes.root, className)}>
            {
                availableAddresses.map((address) => {
                    return (
                        <div key={address.id}>
                            <button
                                className={classNames(classes.address, (selectedAddress === address.id ? classes.address_selected : null))}
                                onClick={selectAddress.bind(this, !billingAddress ? address.id : address)}
                            >
                                <Address address={address} />
                            </button>
                        </div>
                    );
                })
            }
            <button className={classNames(classes.address, classes.add)} onClick={onAdd}><PlusIcon size={11} color="#000000" className={classes.add_icon} /> {t('checkout.information.addaddress')}</button>
        </div>
    )
}

export default AddressList;
