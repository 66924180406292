import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form } from '@kega/sps-elements';
import { useCartId, useConfig } from "@kega/sps-core";
import { useSelector } from "react-redux";

import { t } from '../../../lib/translations';
import ScrollToTop from '../../../utils/ScrollToTop';

import useCreateMergeCarts from '../../../hooks/useCreateMergeCarts';
import useFetchWishlist from '../../../hooks/wishlist/useFetchWishlist';
import useStoreViews from "../../../hooks/useStoreViews";
import useSystemMessage from '../../../hooks/useSystemMessage';
import useTagManager from '../../../hooks/useTagManager';
import useLogin from "../../../hooks/customer/useLogin";
import useReCaptcha from "../../../hooks/useReCaptcha";
import useCartSync from "../../../hooks/useCartSync";

import Register from '../register/Register';

import { Col, Row, Container } from '../../../components/grid';
import { Button, Input, Field } from '../../../components/form';

import classes from './Login.module.css';

const Login = () => {
    const navigate = useNavigate();
    const { createMergeCart } = useCreateMergeCarts();
    const { fetchWishlist } = useFetchWishlist();
    const { dataLayer } = useTagManager();
    const [loggingin, setLoggingIn] = useState(false);
    const { login } = useLogin();
    const location = useLocation();
    const getCartId = useCartId();
    const [returnPath, setReturnPath] = useState(null);
    const { recaptcha_customer_login: loginReCaptcha } = useSelector((state) => state.storeConfig);

    const { getUrl } = useStoreViews();
    const { setSystemSuccessMessage } = useSystemMessage();
    const { setCurrentCartId } = useCartSync();

    const config = useConfig();
    const { recaptchakey } = config.get('google');
    const language = config.get('language');
    const storeCode = config.get('storeCode');

    const { loadToken, loading: loadingReCaptcha } = useReCaptcha({
        key: recaptchakey,
        action: 'login',
        language: language,
        autoLoad: false,
        reCaptchaEnabled: loginReCaptcha !== null
    });

    useEffect(() => {

        if (location.state !== null) {
            setReturnPath(getUrl(location.state.returnPathWishlist));
        } else {
            setReturnPath(getUrl('account/overview'));
        }
    }, [setReturnPath])

    const onSubmit = async ({ valid, values }) => {
        if (valid) {
            let headers = {};

            if (loginReCaptcha) {
                const recaptchaToken = await loadToken();

                if (recaptchaToken) {
                    headers = {
                        'X-ReCaptcha': recaptchaToken
                    }
                }
            }

            setLoggingIn(true);
            const token = await login({ username: values.email, password: values.pass }, headers);

            if (token) {
                // Magento specific create and merge cart function
                await createMergeCart();
                const newCartId = getCartId();

                localStorage.setItem(storeCode + '_new_cart_id', newCartId);

                await fetchWishlist(token);

                navigate(returnPath);

                setSystemSuccessMessage(t('account.login.success'));
                setLoggingIn(false);

                try {
                    dataLayer.push({
                        'event': 'login',
                        'method': 'normal'
                    });
                } catch (error) {
                    //
                }
            } else {
                setLoggingIn(false);
            }
        }
    };

    return (
        <Container xl margins g-xs={1} g-sm={3} g-md={2} g-xl={4} className={classes.root}>
            <ScrollToTop/>
            <Row>
                <Col md={5}>
                    <h1 className={classes.title}>{t('account.login.title')}</h1>

                    <Form onSubmit={onSubmit}>
                        <Row>
                            <Field>
                                <Input name="email" type="email" label={t('customer.form.email')} placeholder={t('customer.form.email')} required />
                            </Field>
                        </Row>
                        <Row>
                            <Field>
                                <Input name="pass" type="password" label={t('customer.form.password')} placeholder={t('customer.form.password')} required />
                            </Field>
                        </Row>

                        <Row>
                            <Col md={12} className={classes.action_wrapper}>
                                <Link className={classes.forgot} to={getUrl('account/forgotpassword')}>
                                    {t('account.login.forgot_password')}
                                </Link>
                            </Col>
                            <Col md={12}>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    loading={loggingin || loadingReCaptcha}
                                >
                                    {t('account.login.button')}
                                </Button>

                            </Col>
                        </Row>

                    </Form>
                </Col>
                <Col md={2}>
                    <div className={classes.login_divider}></div>
                </Col>
                <Col md={5} className={classes.signup_block}>
                    <h1 className={classes.title}>{t('account.register.title')}</h1>
                    <Register />
                </Col>
            </Row>
        </Container>
    )
}

export default Login;
