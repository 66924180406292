import { useCartId, useRequest, useStorefront } from "@kega/sps-core";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "@kega/sps-core/src/reducers/cart/actions";
import { removeCouponCode, setCouponCode } from "../../redux/checkout/actions";
import useSystemMessage from "../useSystemMessage";

const useApplyCoupon = () => {
    const { api: { checkout } } = useStorefront();
    const dispatch = useDispatch();
    const { couponCode } = useSelector((state) => state.essentielCheckout);

    const getCartId = useCartId();
    const cartId = getCartId();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const applyCoupon = async (couponCode, options) => {

        try {
            setRequest();
            const result = await checkout.applyCoupon(cartId, couponCode, options);

            setSuccess();

            if (result && result?.success) {
                dispatch(updateCart(result.cart));
                dispatch(setCouponCode(couponCode));

                return result;
            } else{
                dispatch(removeCouponCode(couponCode));
                dispatch(updateCart(result.cart));
            }

            return null;
        } catch (error) {
            console.log('[ApplyCoupon]', error);
            setError(error);
        }
    };

    const removeCouponCodeFromCart = async (options) => {

        try {
            dispatch(removeCouponCode(null));

            return null;
        } catch (error) {
            console.log('[ApplyCoupon]', error);
            setError(error);
        }
    };

    return {
        loading,
        error,
        applyCoupon,
        removeCouponCodeFromCart,
        appliedCoupon: couponCode
    }
}

export default useApplyCoupon;
