import { useStore } from 'react-redux';

const useWishlistId = () => {
    const store = useStore();

    return () => {
        const { wishlist } = store.getState();

        return wishlist.id;
    };
}

export default useWishlistId;
