import { useEffect } from "react";
import { useCart, useCartId } from '@kega/sps-core';

import useUi from '../../../hooks/useUi';
import useScrollLock from '../../../hooks/useScrollLock';
import useCheckoutData from "../../../hooks/checkout/useCheckoutData";

import MiniCart from '../../minicart/MiniCart';
import { ShoppingbagIcon } from '../../../components/icons';

import classes from './Cart.module.css';

const Cart = () => {
    const { isCartOpen, openCart } = useUi();
    const { quantity, fetchCart } = useCart();
    const { extraCartData } = useCheckoutData();
    const getCartId = useCartId();
    let cartId = getCartId();

    useEffect(async () => {

        if(cartId){
            await fetchCart(cartId, extraCartData);
        }

    }, [cartId]);

    useScrollLock(isCartOpen);

    return (
        <>
            <div className={classes.root} onClick={() => { openCart(); }} role="button" tabIndex="0">
                <ShoppingbagIcon title={"Shopping bag"} height="16" width="18" />
                <span className={classes.qty}>{quantity}</span>
            </div>

            <MiniCart />

        </>
    )
}

export default Cart;
