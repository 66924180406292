import { Helmet } from 'react-helmet-async';
import { useSelector } from "react-redux";

import { t } from '../../../lib/translations';

import { default as SuccessModule } from '../../../modules/checkout/success/Success';

const Success = () => {
    const { items, totals } = useSelector((state) => state.cart);

    return (
        <>
            <Helmet>
                <title>{t('checkout.success.page_title')}</title>
                <meta name="description" content={''} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <SuccessModule
                items={items}
                totals={totals}
            />
        </>
    )
}

export default Success;
