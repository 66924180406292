import IconWrapper from '../wrapper/IconWrapper';

const WishlistIcon = ({ color = null, title, ...props }) => {
    return (
        <IconWrapper color={color} {...props}>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" role={title ? null : "presentation"}>
                {title && <title>{title}</title>}
                <path d="M8.95379 15.7134L8.66718 15.5223C6.37433 13.8982 1.11988 9.69466 0.642207 6.06432C0.355601 3.00718 1.88417 1.09647 3.6038 0.523262C5.13237 0.0455845 7.23415 0.427726 8.85825 2.81611C10.4824 0.332191 12.6797 -0.049951 14.2082 0.523262C15.9279 1.09647 17.4564 3.00718 17.1698 6.06432C16.7877 9.69466 11.4377 13.8982 9.14486 15.5223L8.95379 15.7134ZM4.84577 1.28755C4.55916 1.28755 4.17702 1.38308 3.89041 1.47862C2.55292 1.86076 1.31095 3.38932 1.59756 5.87325C1.9797 9.12145 6.94755 13.0384 8.95379 14.4714C10.8645 13.0384 15.9279 9.12145 16.31 5.87325C16.5966 3.38932 15.3547 1.76522 14.0172 1.38308C12.6797 0.905403 10.8645 1.38308 9.43147 3.96254L9.04932 4.72682L8.66718 3.96254C7.42522 1.95629 5.99219 1.28755 4.84577 1.28755Z" fill={color ? color : 'black'} />
                <path d="M8.95392 16L8.57178 15.7134C6.27893 14.0893 0.928946 9.88573 0.451269 6.06432C0.0691272 2.91165 1.78876 0.905404 3.5084 0.236656C5.03697 -0.241021 7.13875 0.0455849 8.85839 2.33844C10.578 0.0455849 12.7753 -0.336557 14.2084 0.236656C16.0235 0.809869 17.6476 2.81611 17.361 6.06432C16.9789 9.7902 11.5334 14.0893 9.24053 15.7134L8.95392 16ZM4.8459 0.523262C4.36822 0.523262 3.89054 0.618798 3.69947 0.714333C2.07537 1.28755 0.546804 3.10272 0.928946 5.96878C1.31109 9.59913 6.56554 13.7072 8.85839 15.2357L8.95392 15.3313L9.04946 15.2357C11.2468 13.6116 16.5968 9.50359 16.9789 5.96878C17.2655 3.10272 15.8325 1.19201 14.2084 0.714333C13.5396 0.523262 11.1512 -0.0499504 9.14499 2.91165L8.95392 3.19825L8.76285 2.91165C7.42535 1.00094 5.89679 0.523262 4.8459 0.523262ZM8.95392 14.8536L8.76285 14.6625C6.66107 13.1339 1.69323 9.21699 1.31109 5.87325C1.02448 3.19825 2.36198 1.57415 3.79501 1.09647C4.08162 1.00094 4.46376 0.905404 4.75036 0.905404C5.61018 0.905404 7.23428 1.28755 8.66732 3.67593L8.95392 4.15361L9.14499 3.77147C10.6736 1.09647 12.6798 0.618798 14.0173 1.09647C15.4503 1.57415 16.7878 3.19825 16.5012 5.87325C16.1191 9.12145 11.1512 13.1339 9.04946 14.6625L8.95392 14.8536ZM4.8459 1.47862C4.55929 1.47862 4.27269 1.47862 3.98608 1.57415C2.74412 1.95629 1.59769 3.48486 1.78877 5.87325C2.17091 8.93038 7.13875 12.9429 8.85839 14.1848C10.578 12.9429 15.6414 8.93038 15.928 5.87325C16.2146 3.48486 15.0682 2.05183 13.7307 1.57415C12.4887 1.19201 10.7691 1.57415 9.4316 4.05807L8.95392 5.10896L8.38071 4.05807C7.04321 1.86076 5.61018 1.47862 4.8459 1.47862Z" fill={color ? color : 'black'} />
            </svg>
        </IconWrapper>
    );
}

export default WishlistIcon;
