import { useRef, useState, useEffect } from 'react';

import useMediaQuery from "../../hooks/useMediaQuery";

import classNames from 'classnames';
import classes from './Slider.module.css';

const Slider = ({ slideItemClass = null, children, controls = true, scrollbar = true, navigation = false, automate = false, activeIndex = null, duration = 5000, itemsToShow = children.length, ComponentNext = null, ComponentPrev = null, className = null, isPLP = false, controlsClassName = null }) => {
    const { match: match } = useMediaQuery(`(max-width: 1024px)`);

    const sliderRef = useRef(null);
    const slideWidth = useRef(0);

    const [showPrev, setShowPrev] = useState(controls);
    const [showNext, setShowNext] = useState(controls);
    const [mouseOver, setMouseOver] = useState(false);
    const [visible, setVisible] = useState(false);
    const [activeBullet, setActiveBullet] = useState(0);

    let scrollInterval = null;
    let zoomScrollTimout = null;

    useEffect(() => {
        if (isPLP) {
            setShowPrev(match ? true : mouseOver);
            setShowNext(match ? true : mouseOver);
            checkScroll();
        }

    }, [mouseOver, match])

    useEffect(() => {
        slideWidth.current = findWidth();

        if (controls) {
            checkScroll();
        }

        if (automate) {
            scrollInterval = setInterval(automateScroll, duration);
        }

        if (activeIndex) {
            zoomScrollTimout = setTimeout(zoomScroll, 50);
        }

        return () => {
            if (scrollInterval) {
                clearInterval(scrollInterval);
            }

            if (zoomScrollTimout) {
                clearTimeout(zoomScrollTimout);
            }
        }
    }, [])

    const findWidth = () => {
        const children = sliderRef.current.children;
        const sliderLeft = sliderRef.current.getBoundingClientRect().left;

        let index = 0;
        for (let c = 0; c < children.length; c++) {
            const child = children[c];
            if ((child.getBoundingClientRect().left - sliderLeft) === 0) {
                index = c;
            }
        }

        return children[index].clientWidth;
    }

    const next = () => { sliderRef.current.scrollBy(findWidth(), 0); }

    const prev = () => { sliderRef.current.scrollBy(findWidth() * -1, 0); }

    const automateScroll = () => {
        if (sliderRef.current) {
            const firstChild = sliderRef.current.firstElementChild;
            
            if (firstChild) {
                sliderRef.current.style.transition = `transform 0.5s ease-in-out`;
    
                sliderRef.current.style.transform = `translateX(calc(-${slideWidth.current * 2}px))`;
    
                setTimeout(() => {
                    sliderRef.current.style.transition = `none`;
                    sliderRef.current.style.transform = `translateX(calc(${slideWidth.current * 2}px))`;
    
                    setTimeout(() => {
                        if (sliderRef.current) {
                            sliderRef.current.appendChild(firstChild);
                            sliderRef.current.style.transition = `transform 0.5s ease-in-out`;
                            sliderRef.current.style.transform = `translateX(0)`;
                        }
                    }, 50);
                }, 200);
            }
        }
    };

    const zoomScroll = () => {
        sliderRef.current.scrollTo({ top: 0, left: sliderRef.current.children[activeIndex].getBoundingClientRect().left, behavior: "instant" });
        setVisible(true);
    }

    const checkScroll = () => {
        const slides = [...sliderRef.current.children];

        if (sliderRef.current.scrollLeft <= 0) {
            setShowPrev(false);
        } else if ((Math.ceil(sliderRef.current.scrollLeft)) >= (sliderRef.current.scrollWidth - sliderRef.current.clientWidth)) {
            setShowNext(false);
        } else {
            if (!showPrev) { setShowPrev(true); }
            if (!showNext) { setShowNext(true); }
        }

        slides.map((currentSlide, index) => {
            if (currentSlide.offsetLeft === Math.round(sliderRef.current.scrollLeft)) {
                setActiveBullet(index)
            }
        })
    }

    const bulletClick = (index) => {
        sliderRef.current.scrollBy(sliderRef.current.children[index].getBoundingClientRect().left, 0);
    }

    return (
        <div
            className={classNames(classes.slider_wrapper, (activeIndex && !visible ? classes.hidden : null))}
            onMouseOver={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
        >
            <div className={classNames(classes.root, className)}>

                {
                    controls
                    &&
                    <>
                        {
                            showPrev
                            &&
                            <div className={classNames(classes.prev, controlsClassName)} onClick={prev} role="button" tabIndex="0">
                                {
                                    ComponentPrev
                                        ?
                                        ComponentPrev
                                        :
                                        <button type="button"> prev </button>
                                }
                            </div>
                        }

                        {
                            showNext
                            &&
                            <div className={classNames(classes.next, controlsClassName)} onClick={next} role="button" tabIndex="0">
                                {
                                    ComponentNext
                                        ?
                                        ComponentNext
                                        :
                                        <button type="button" > next </button>
                                }
                            </div>
                        }
                    </>
                }

                <div ref={sliderRef} onScroll={(controls ? checkScroll : null)} className={classNames(classes.slides, slideItemClass, (!scrollbar ? classes.noscrollbar : null), classes['show-' + itemsToShow])}>
                    {children}
                </div>
            </div>

            {
                navigation &&

                <div className={classes.bullet_container}>
                    {
                        children.map((child, index) => {
                            return (
                                <div key={index} className={classNames(classes.bullet, activeBullet === index ? classes.active : null)} onClick={() => bulletClick(index)} role="button" tabIndex="0"></div>
                            );
                        })
                    }
                </div>
            }
        </div>
    );
}

export default Slider;
