import useUi from '../../../../hooks/useUi';

import Drawer from '../../../../components/drawer/Drawer';
import { CrossIcon } from '../../../../components/icons';

import ConfigurableOrder from "../../../productdetails/configurableorder/ConfigurableOrder";
import ProductTile from '../../../../components/product/ProductTile';

import classes from "./AddToCartModal.module.css";

const AddToCartModal = ({ product }) => {
    const { variants, configurable_options } = product;
    const { isAddToCartDrawerOpen, closeAddToCartDrawer } = useUi();

    return (
        <Drawer open={isAddToCartDrawerOpen} onClose={closeAddToCartDrawer} drawerClassName={classes.drawer}>
            <div className={classes.topbar}>
                <div onClick={() => closeAddToCartDrawer()} role="button" tabIndex="0">
                    <CrossIcon title={"ATC Modal"} size={16} />
                </div>
            </div>
            <ProductTile product={product} />
            <div className={classes.content}>
                <ConfigurableOrder variants={variants} options={configurable_options} product={product}/>
            </div>
        </Drawer>
    );
}

export default AddToCartModal;
