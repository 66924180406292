import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useConfig } from "@kega/sps-core";

import useStoreViews from '../../../hooks/useStoreViews';

import classNames from 'classnames';
import classes from './ColorRelated.module.css';

const ColorRelated = ({ product, colorRelated = null, color = null, className }) => {
    const { name, url_key, imgproductswatch, perfion_googlecolour, perfion_colourdescription, att_text_perfion_googlecolour, att_text_perfion_filtercolour } = product;

    const color_related_products = useMemo(() => {
        return colorRelated ? colorRelated : product.color_related_products;
    }, [colorRelated, product]);

    color = att_text_perfion_googlecolour ?? (product.color ?? (att_text_perfion_filtercolour ?? color));

    if (color && Array.isArray(color)) {
        color = color.join(' / ');
    }
    else {
        color = color ? color.replace(/,/g, ' / ') : ''
    }

    const config = useConfig();
    const { prefix_product } = config.get('url');
    const { getUrl } = useStoreViews();

    if (!color_related_products && !imgproductswatch) { return null; }

    return (
        <div className={classNames(classes.root, className)}>
            {
                colorRelated?.currentColor || (color && imgproductswatch)
                &&
                <div className={classes.current_color}>{colorRelated?.currentColor ? colorRelated.currentColor : color}</div>
            }

            <div className={classes.container}>
                <div className={classNames(classes.swatch_item, classes.current, (!imgproductswatch && colorRelated?.currentColor || (color && imgproductswatch)) && classes.text_swatch)}>
                    {
                        imgproductswatch ? (
                            <Link to={getUrl(`${prefix_product}/${url_key}`)}>
                                <img
                                    width={16}
                                    height={16}
                                    title={name + (perfion_colourdescription ? ' ' + perfion_colourdescription : '')}
                                    alt={name || perfion_colourdescription}
                                    src={imgproductswatch}
                                />
                            </Link>
                        ) : (
                            <span className={classes.current_color}>
                                {colorRelated?.currentColor || color || att_text_perfion_googlecolour}
                            </span>
                        )}
                </div>
                {
                    color_related_products &&
                    Object.values(color_related_products).map((colorProduct, index, array) => {
                        return (
                            <div className={classNames(classes.swatch_item, (!colorProduct.swatch_image && colorProduct?.color) && classes.text_swatch)} key={colorProduct.url_key}>
                                <Link to={getUrl(`${prefix_product}/${colorProduct.url_key}`)}>
                                    {colorProduct.swatch_image ? (
                                        <img
                                            width={12}
                                            height={12}
                                            title={colorProduct.name + ' ' + colorProduct.color}
                                            alt={colorProduct?.name || name}
                                            src={colorProduct.swatch_image}
                                        />
                                    ) : (
                                        <span className={classes.color_text}>{colorProduct?.color && colorProduct.color.replace(/,/g, ' / ')}</span>
                                    )}
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default ColorRelated;
