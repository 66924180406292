import { useDispatch, useSelector } from 'react-redux';

import {
    newsletterOverlayOpen,
    newsletterOverlayClose
} from '../redux/newsletter_overlay/actions';

const useNewsletterOverlay = () => {
    const dispatch = useDispatch();

    const { isNewsletterOverlayOpen } = useSelector((state) => state.newsletter_overlay);

    const openNewsletterOverlay = () => { dispatch(newsletterOverlayOpen()); }
    const closeNewsletterOverlay = () => { dispatch(newsletterOverlayClose()); }

    return {
        isNewsletterOverlayOpen,
        openNewsletterOverlay,
        closeNewsletterOverlay
    };
}

export default useNewsletterOverlay;
