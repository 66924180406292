import IconWrapper from '../wrapper/IconWrapper';

const MenuIcon = ({ color = null, title, ...props }) => {
    return (
        <IconWrapper color={color} {...props}>
            <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg" role={title ? null : "presentation"}>
                {title && <title>{title}</title>}
                <path d="M22.5992 0H0V1.6H22.5992V0Z" fill={color ? color : 'black'} />
                <path d="M22.5992 6.90039H0V8.50039H22.5992V6.90039Z" fill={color ? color : 'black'} />
                <path d="M22.5992 14H0V15.6H22.5992V14Z" fill={color ? color : 'black'} />
            </svg>
        </IconWrapper>
    );
}

export default MenuIcon;
