import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    usp: []
};

export default createReducer(initialState, {
    [actions.AddUsp]: (state, action) => {
        const { payload = [] } = action;
        state.usp = payload;
    }

});
