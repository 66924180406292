import Skeleton from "react-loading-skeleton"

import classes from './LoadingSkeleton.module.css';

const LoadingSkeleton = ({ count, className }) => {
    const output = new Array();
    for (let i = 0; i < count; i++) {
        output.push(<Skeleton key={i} className={classes[className]} />)
    }
    return output;
};

export default LoadingSkeleton;