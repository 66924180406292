
import useCreateCustomerAddress from "../../../../hooks/customer/useCreateCustomerAddress";

import { Container } from "../../../../components/grid";
import AddressForm from "../../../../components/address_form/AddressForm";

import useStoreViews from "../../../../hooks/useStoreViews";

import classes from "./Address.module.css";
import { t } from "../../../../lib/translations";


const AddressCreate = () => {
    const { create } = useCreateCustomerAddress();
    const { currentStoreCode } = useStoreViews();
    return (
        <Container margins gy-xs={2} g-xs={0} g-md={2} sm={6} className={classes.address_form_create}>
            <h1>{t('Create address')}</h1>
            <AddressForm
                buttonTitle={t('customer.address.form.save.new')}
                endpoint={create}
                onSuccess={t('customer.address.form.saved.new')} />
        </Container>
    );
}

export default AddressCreate;