import { useRef, useState } from 'react';
import { useSelector } from "react-redux";

import { useNavigate } from 'react-router-dom';
import { useConfig } from '@kega/sps-core';

import { Form } from '@kega/sps-elements';

import { t } from '../../../lib/translations';

import { Input, Checkbox, Button, Field } from '../../../components/form';
import { Row } from "../../../components/grid";
import HelperText from '../../../components/helper_text/HelperText';

import useCreateCustomer from '../../../hooks/customer/useCreateCustomer';
import useCreateMergeCarts from '../../../hooks/useCreateMergeCarts';
import useFetchWishlist from '../../../hooks/wishlist/useFetchWishlist';
import useTagManager from '../../../hooks/useTagManager';
import useStoreViews from "../../../hooks/useStoreViews";
import useLogin from "../../../hooks/customer/useLogin";
import useInputModifiers from "../../../hooks/form/useInputModifiers";
import useInputValidation from "../../../hooks/form/useInputValidation";
import useSystemMessage from "../../../hooks/useSystemMessage";
import useMediaQuery from "../../../hooks/useMediaQuery";
import useReCaptcha from "../../../hooks/useReCaptcha";

import classes from './Register.module.css';

const Register = ({ className = '', checkoutSuccess = null, shippingAddress = null, email = null }) => {
    const { create } = useCreateCustomer();
    const { createMergeCart } = useCreateMergeCarts();
    const { fetchWishlist } = useFetchWishlist();
    const { dataLayer } = useTagManager();
    const { login } = useLogin();
    const navigate = useNavigate();
    const { getUrl } = useStoreViews();
    const { normalizeSpecialChars } = useInputModifiers();
    const { firstname, lastname, requiredField, confirmField, passwordValidation, email: emailValidation } = useInputValidation();
    const { recaptcha_customer_login: loginReCaptcha } = useSelector((state) => state.storeConfig);

    const { setSystemSuccessMessage } = useSystemMessage();

    const [loading, setLoading] = useState(false);

    const { match: matchMobile } = useMediaQuery(`(max-width: 640px)`);

    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const config = useConfig();
    const { recaptchakey: recaptchaKey } = config.get('google');
    const language = config.get('language');

    const { loadToken, loading: loadingReCaptcha } = useReCaptcha({
        key:              recaptchaKey,
        action:           'login',
        language:         language,
        autoLoad:         false,
        reCaptchaEnabled: loginReCaptcha !== null
    });

    const onPasswordChange = () => {
        passwordConfirmRef.current?.triggerChange();
    };

    const onSubmit = async ({ valid, values, resetForm }) => {
        if (valid) {
            setLoading(true);

            const response = await create(values);

            try {
                dataLayer.push({
                    'event': 'sign_up',
                    'method': 'normal'
                });
            } catch (error) {
                //
            }

            if (response) {
                setSystemSuccessMessage(t('account.register.success'));

                let headers = {};

                if (loginReCaptcha) {
                    const recaptchaToken = await loadToken();

                    if (recaptchaToken) {
                        headers = {
                            'X-ReCaptcha': recaptchaToken
                        }
                    }
                }

                const token = await login({ username: values.email, password: values.password }, headers);

                if (token) {
                    // Magento specific creat and merge cart function
                    await createMergeCart();
                    await fetchWishlist(token);


                    try {
                        dataLayer.push({
                            'event': 'login',
                            'method': 'normal'
                        });
                    } catch (error) {
                        //
                    }

                    setLoading(false);

                    navigate({
                        pathname: getUrl('/account/overview')
                    });
                }

                resetForm();
            } else {
                setLoading(false);
            }
        }
    }
    return (
        <>
            <Form onSubmit={onSubmit} className={className}>
                <Row>
                    <Field>
                        <Input
                            name="firstname"
                            placeholder={t('customer.form.firstname')}
                            label={t('customer.form.firstname')}
                            modifiers={[ normalizeSpecialChars() ]}
                            value={shippingAddress?.firstname ?? ''}
                            rules={[ ...firstname, requiredField ]}
                            required
                        />
                    </Field>
                    { !matchMobile
                      &&
                        <Field>
                            <Input
                                name="lastname"
                                placeholder={t('customer.form.lastname')}
                                label={t('customer.form.lastname')}
                                modifiers={[ normalizeSpecialChars() ]}
                                value={shippingAddress?.lastname ?? ''}
                                rules={[ ...lastname, requiredField ]}
                                required
                            />
                        </Field>
                    }
                </Row>
                {
                    matchMobile &&
                        <Row>
                            <Field>
                                <Input
                                    name="lastname"
                                    placeholder={t('customer.form.lastname')}
                                    label={t('customer.form.lastname')}
                                    modifiers={[ normalizeSpecialChars() ]}
                                    value={shippingAddress?.lastname ?? ''}
                                    rules={[ ...lastname, requiredField ]}
                                    required
                                />
                            </Field>
                        </Row>
                }
                <Row>
                    <Field>
                        <Input
                            name="email"
                            type="email"
                            placeholder={t('customer.form.email')}
                            label={t('customer.form.email')}
                            rules={[ emailValidation ]}
                            value={email ?? ''}
                            required
                        />
                    </Field>
                </Row>
                <Row className={checkoutSuccess ? classes.checkout_register : ''}>
                    <Field>
                        <Input
                            name="password"
                            placeholder={t('customer.form.password')}
                            label={t('customer.form.password')}
                            type="password"
                            ref={passwordRef}
                            onChange={onPasswordChange}
                            rules={[ passwordValidation ]}
                            required
                        />
                    </Field>
                </Row>
                <Row className={checkoutSuccess ? classes.checkout_register : ''}>
                    <Field>
                        <Input
                            name="confirm_password"
                            placeholder={t('account.register.password_confirm')}
                            label={t('account.register.password_confirm')}
                            type="password" ref={passwordConfirmRef}
                            rules={[ confirmField(passwordRef, t('account.register.error.password_confirm')) ]}
                            required
                        />
                    </Field>
                </Row>
                <Row className={checkoutSuccess ? classes.checkout_register : ''}>
                    <Field className={classes.check_wrapper}>
                        <Checkbox name="is_subscribed" label={!checkoutSuccess ? t('newsletter.subscribe') : t('checkout.success.register.subscribe')} checked={false} />
                    </Field>
                </Row>

                {
                    !checkoutSuccess &&
                    <HelperText text={<div dangerouslySetInnerHTML={{ __html: t('account.register.accept', { privacy: '<a href="' + getUrl('/privacy-policy') + '">' + t('account.register.privacy') + '</a>', terms: '<a href="' + getUrl('/terms-conditions') + '">' + t('account.register.terms') + '</a>' }) }} />} />
                }

                <Button
                    type="submit"
                    loading={loading}
                    variant={checkoutSuccess ? 'secondary' : 'primary'}
                    className={checkoutSuccess ? classes.checkout_register_btn : ''}
                >
                    {t('account.register.button')}
                </Button>
            </Form>
        </>
    );
}

export default Register;
