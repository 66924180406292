import { Drawer as KegaDrawer } from '@kega/sps-elements';

import classes from './Drawer.module.css';
import classNames from 'classnames';

const Drawer = ({ className = '', children, ...props }) => {
    return (
        <KegaDrawer backgroundClassName={classNames(classes.root, className)} {...props}>{children}</KegaDrawer>
    );
};

export default Drawer;
