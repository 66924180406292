import classNames from 'classnames';

import classes from './Card.module.css';

const Card = ({ className, children }) => (
    <div className={classNames(classes.root, className)}>
        {children}
    </div>
);

export default Card;