import { Link } from "react-router-dom";
import {
    AccordionItem,
    AccordionHeader,
    AccordionContent,
    AccordionIcon
} from '@kega/sps-elements';

import { t } from "../../../../lib/translations";

import { ChevronIcon } from "../../../../components/icons";

import useMediaQuery from "../../../../hooks/useMediaQuery";
import useStoreViews from "../../../../hooks/useStoreViews";

import classes from './LinksColumn.module.css';
import classNames from "classnames";

const LinksColumn = ({ column }) => {
    const { match } = useMediaQuery('(min-width: 1025px)');
    const { getUrl } = useStoreViews();

    return (
        <AccordionItem alwaysOpen={match} className={classes.root}>
            <AccordionHeader>
                <div className={classes.title} role="heading">
                    {column.title}
                </div>
                {
                    !match
                    &&
                    <AccordionIcon>
                        <ChevronIcon title={"Chevron Links"} size={8} color="#9C9EA1" />
                    </AccordionIcon>
                }
            </AccordionHeader>
            <AccordionContent>
                <ul className={classes.items}>
                    {column.items.map(item => (
                        <li key={item.label}>
                            {item.external
                                ?
                                <a href={item.href} target={item.newtab ? '_blank' : '_self'} aria-label={item.newtab ? "Link opens in a new tab" : "Link opens in current tab"} rel='noreferrer' className={classes.link}>{item.label}</a>
                                :
                                <Link to={getUrl(item.href)} target={item.newtab ? '_blank' : '_self'} aria-label={item.newtab ? "Link opens in a new tab" : "Link opens in current tab"} rel='noreferrer' className={classes.link}>{item.label}</Link>
                            }
                        </li>
                    ))}

                    {column.position === 2 &&
                        <li>
                            <button
                                id="ot-sdk-btn"
                                className={classNames(classes.link, classes.cookie_setting, "ot-sdk-show-settings override")}
                                aria-label="Open cookie settings"
                            >
                                {t('Cookie settings')}
                            </button>
                        </li>
                    }
                </ul>
            </AccordionContent>
        </AccordionItem>
    );
}

export default LinksColumn;
