import { useCallback } from 'react';

import { useRequest, useStorefront } from '@kega/sps-core';

// TODO Remove once fix for EA-424 is implemented
const useResetPassword = () => {
    const { api: { essentielCustomer } } = useStorefront();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const checkResetToken = useCallback( async ({ email, resetPasswordToken }) => {
        try {
            setRequest();
            const result = await essentielCustomer.checkResetPasswordToken({ email, resetPasswordToken });
            setSuccess();

            return result;
        } catch (error) {
            setError(error);
            return error;
        }

    }, [])

    const reset = useCallback(async ({ email, resetPasswordToken, newPassword }) => {
        try {
            setRequest();
            const result = await essentielCustomer.resetPassword({ email, resetPasswordToken, newPassword });
            setSuccess();

            return result;
        } catch (error) {
            setError(error);
            return error;
        }
    }, []);

    return {
        error,
        loading,
        reset,
        checkResetToken
    };
}

export default useResetPassword;