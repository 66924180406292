import { useEffect } from "react";
import { Link } from 'react-router-dom';

import { Container } from '../../components/grid';

import useStoreViews from '../../hooks/useStoreViews';
import useMediaQuery from '../../hooks/useMediaQuery';

import classNames from 'classnames';
import classes from './Header.module.css';

const MinimizedHeader = () => {
    const { getUrl } = useStoreViews();
    const { match } = useMediaQuery('(max-width: 768px)');

    const unsetScrollSticky = () => {
        const headerElement = document.getElementsByTagName("header")[0];
        if (headerElement) {
            headerElement.classList.add(classes.notsticky);
            headerElement.classList.remove(classes.sticky);
            headerElement.classList.remove('sticky-header');
            headerElement.style.top = "0px";
        }
    };

    useEffect(() => {
        unsetScrollSticky(); // Unset sticky styling from regular header
    }, []);

    return (
        <header className={classNames(classes.root, classes.minimized)}>
            <Container  xl margins g-xs={1} g-md={3}>
                <div className={classes.topmenu}>
                    <Link className={classes.store_logo_wrapper} to={getUrl('/')} >
                        <img className={classes.store_logo} src="/images/new-essentiel-logo.svg" height={match ? "42" : "38"} width={match ? "140" : "109"} alt="Essentiel Antwerp" />
                    </Link>
                </div>
            </Container>
        </header>
    );
};

export default MinimizedHeader;
