import { forwardRef } from "react";
import { t } from "../../lib/translations";
import useStoreViews from "../../hooks/useStoreViews";
import useI18n from "../../hooks/useI18n";
import { Select } from '../form';
import classNames from 'classnames';
import classes from '../form/select/Select.module.css';

const CountryDropdown = ({ required, error, value, customAllowedCountries,  ...rest_props }, ref) => {
    const selectClasses = classNames(classes.input, error ? classes.error : '');
    const { getCountryNameByCode } = useI18n();

    const { currentStoreView } = useStoreViews();
    const allowedCountries = customAllowedCountries ?? currentStoreView.allowedCountries;

    const countriesByName = allowedCountries?.map(code => getCountryNameByCode(code.toUpperCase())).sort();

    const getCountryCode = (countryName) => {
        return allowedCountries?.find(countryCode => getCountryNameByCode(countryCode.toUpperCase()) === countryName);
    }

    return (
        <Select ref={ref} value={value ? value.toLowerCase() : ''} className={selectClasses} labelClassName={classes['label']} showLabel={false} required={required} error={error} {...rest_props}>
            <option disabled value="">{t('customer.address.form.country_code')}</option>
            {countriesByName.map((country) => {
                const code = getCountryCode(country);
                return <option key={code} value={code}>{country}</option>
            })}
        </Select>
    );

}

export default forwardRef(CountryDropdown);
