import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setQueryIDs, clearQueryIDs } from "../../redux/algolia/actions";

const useQueryIDMapping = () => {
    const dispatch = useDispatch();

    const { queryIDS } = useSelector((state) => state.algolia);

    const addId = (sku, queryID) => {
        const newData = {
            ...queryIDS,
            [sku]: queryID
        }

        dispatch(setQueryIDs(newData));
    };

    const clear = () => {
        dispatch(clearQueryIDs());
    };

    return {
        addId,
        clear,
        queryIDS
    };
}

export default useQueryIDMapping;