const getColorRelatedProducts = `
    query getColorRelatedProducts($sku: String!) {
        getColorRelatedProducts(
            sku: $sku
        ) {
            currentColor
            items {
                sku
                name
                color
                swatch_image
                url_key
            }
        }
    }
`;

export default getColorRelatedProducts;
