import { useMemo } from "react";
import { useConfig } from "@kega/sps-core";
import { useRelatedProducts } from "@algolia/recommend-react";
import recommend from "@algolia/recommend";

import { t } from "../../../lib/translations";

import useMediaQuery from "../../../hooks/useMediaQuery";

import { Slider, SliderItem } from "../../slider";
import ProductTile from "../ProductTile";
import { ChevronIcon } from "../../icons";

import classes from "./RelatedProducts.module.css";

const RelatedProducts = ({ code }) => {
    const config = useConfig();
    const { index, appId, apiKey } = config.get("algolia");
    const storeCode = config.get("storeCode");

    const { match } = useMediaQuery(`(min-width: 769px)`)

    const recommendClient = useMemo(
        () => recommend(appId, apiKey),
        [appId, apiKey]
    );

    const { recommendations } = useRelatedProducts({
        recommendClient,
        indexName: `${index}${storeCode}_products`,
        objectIDs: code ? [code] : [],
    });

    const products = useMemo(() => {
        return recommendations.reduce((products, product) => {
            products.push(product);
            return products;
        }, []);
    }, [recommendations]);

    if (!code || products.length === 0) {
        return null;
    }

    return (
        <div className={classes.root}>
            {products.length > 0 && (
                <div>
                    <div className={classes.title}>
                        {t('product.related.title')}
                    </div>
                    <Slider
                        itemsToShow={match ? 4 : 2}
                        scrollbar={true}
                        controls={true}
                        navigation={false}
                        automate={false}
                        className={classes.related_products}
                        slideItemClass={classes.related_product_items}
                        ComponentNext={
                            <div className={classes.next}>
                                <ChevronIcon title={"ChevronNext"} width={10} height={13} />
                            </div>
                        }
                        ComponentPrev={
                            <div className={classes.prev}>
                                <ChevronIcon title={"ChevronPrev"} width={10} height={13} />
                            </div>
                        }
                    >
                        {products.map((product, index) => {
                            return (
                                <SliderItem key={index}className={classes.product}>
                                    <ProductTile className={classes.product_price} product={product} withColor={false} />
                                </SliderItem>
                            );
                        })}
                    </Slider>
                </div>
            )}
        </div>
    );
};

export default RelatedProducts;
