import { Builder } from "@builder.io/react/lite";

import Configure from "../configure/Configure";

import classes from './FaqMenu.module.css';
import { useState } from "react";
import { MinusIcon, PlusIcon } from "../../../../components/icons";
import { Accordion, AccordionContent, AccordionHeader, AccordionIcon, AccordionItem } from '@kega/sps-elements';

export const FaqMenu = ({ hamburger_menu = false, question_category, faq_item }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuClick = () => setMenuOpen(!menuOpen);

    return (
        <>
            {
                faq_item !== undefined ?
                    <Accordion>
                        <AccordionItem alwaysOpen={!hamburger_menu}>
                            <AccordionHeader>
                                {
                                    hamburger_menu
                                    &&
                                    <>
                                        <h1 className={classes.title} onClick={handleMenuClick} role="button" tabIndex="0">
                                            {question_category}
                                        </h1>
                                        <AccordionIcon>
                                            {!menuOpen ? <PlusIcon title={"FaqMenu Plus"} size={24} /> : <MinusIcon title={"FaqMenu Minus"} size={24} />}
                                        </AccordionIcon>
                                    </>
                                }
                            </AccordionHeader>
                            <AccordionContent>
                                {faq_item.map((faq_item, key) => (
                                    <>
                                        <h3 className={classes.question_title}>{faq_item.name}</h3>
                                        <div key={key} className={classes.items}>
                                            <section>{faq_item.url}</section>
                                        </div>
                                    </>
                                ))}
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                    :
                    <Configure name="FAQ items" />
            }
        </>
    );
};

Builder.registerComponent(FaqMenu, {
    name: "Faq Menu",
    static: true,
    defaultStyles: {
        marginTop: '0px',
    },
    inputs: [
        {
            name: 'hamburger_menu',
            friendlyName: 'Hamburger menu',
            type: 'boolean',
            defaultValue: false,
        },
        {
            name: 'question_category',
            friendlyName: 'Question Category',
            type: 'string',
            showIf: (options) => options.get('hamburger_menu'),
            required: true,
        },
        {
            name: 'faq_item',
            friendlyName: 'FAQ Item',
            type: 'list',
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                },
                {
                    name: 'url',
                    type: 'string'
                }
            ],
            onChange: (options) => {
                if (options.get('faq_item').length > 10) {
                    options.set('faq_item', options.get('faq_item').slice(0, 10));
                }
            },
            helperText: 'Max 10 faq items can be added.'
        },
    ]
});
