import { Builder } from "@builder.io/react/lite";
import { Image } from "@builder.io/react";
import { Link } from "react-router-dom";
import { Drawer } from '@kega/sps-elements';

import useStoreViews from "../../../../hooks/useStoreViews";
import useUi from "../../../../hooks/useUi";

import CartTopContent from "../../../cart/cart-top-content/CartTopContent";

import { Container, Row, Col } from "../../../../components/grid";
import { Button, LinkButton } from '../../../../components/form';
import GiftboxProducts from "../../../../components/giftbox/GiftboxProducts";
import { CrossIcon } from "../../../../components/icons";

import classNames from "classnames";
import classes from './Giftbox.module.css'

export const Giftbox = ({ cta_size, enable_slide_in, paragraph_enabled, paragraph_text, cta_enabled, cta_text, cta_url, cta_url_tab, cta_url_external, cta_type, background_visual, background_visual_image, layover_enabled, layover_color, size_xl, size_lg, size_md, size_sm, size_xs }) => {
    const { getUrl } = useStoreViews();
    const { openGiftboxDrawer, closeGiftboxDrawer, isGiftboxDrawerOpen } = useUi();

    return (
        <div className={classes.root}>
            <div className={classes.giftbox_container}>
                {
                    <Image src={background_visual_image} />
                }
                <div className={classes.actions}>
                    {cta_enabled && (
                        cta_type === 'text' ? (
                            cta_url_external ? (
                                <a href={cta_url} className={classNames(classes.text_link, classes['textsize-' + cta_size])} dangerouslySetInnerHTML={{ __html: cta_text }} target={cta_url_tab ? "_blank" : "_self"} aria-label={cta_url_tab ? "Link opens in a new tab" : "Link opens in current tab"} rel="noreferrer" style={{ width: '100%' }}></a>
                            ) : (
                                <Link to={getUrl(cta_url)} className={classNames(classes.text_link, classes['textsize-' + cta_size])} dangerouslySetInnerHTML={{ __html: cta_text }} target={cta_url_tab ? "_blank" : "_self"} aria-label={cta_url_tab ? "Link opens in a new tab" : "Link opens in current tab"} rel="noreferrer"></Link>
                            )
                        ) : (
                            cta_url_external
                                ?
                                <div className={classes.button_container}>
                                    <LinkButton onClick={openGiftboxDrawer} to={!cta_url_external ? getUrl(cta_url) : null} href={cta_url_external ? cta_url : null} linkComponent={cta_url_external ? null : Link} variant={cta_type} className={classNames(classes.button, classes['textsize-' + cta_size])} target={cta_url_tab ? "_blank" : "_self"} aria-label={cta_url_tab ? "Link opens in a new tab" : "Link opens in current tab"} rel="noreferrer">
                                        <div dangerouslySetInnerHTML={{ __html: cta_text }} />
                                    </LinkButton>
                                </div>
                                :
                                <div className={classes.button_container}>
                                    <Button onClick={openGiftboxDrawer} to={!cta_url_external ? getUrl(cta_url) : null} href={cta_url_external ? cta_url : null} variant={cta_type} className={classNames(classes.button, classes['textsize-' + cta_size])} target={cta_url_tab ? "_blank" : "_self"} aria-label={cta_url_tab ? "Link opens in a new tab" : "Link opens in current tab"} rel="noreferrer">
                                        <div dangerouslySetInnerHTML={{ __html: cta_text }} />
                                    </Button>
                                </div>
                        )
                    )}
                </div>
                {
                    enable_slide_in &&
                    <Drawer open={isGiftboxDrawerOpen} onClose={closeGiftboxDrawer} drawerClassName={classes.drawer}>
                        <CrossIcon className={classes.close} size={16} onClick={closeGiftboxDrawer} />
                        <CartTopContent />
                        <GiftboxProducts />
                    </Drawer>
                }
            </div>
        </div >)
};

Builder.registerComponent(Giftbox, {
    name: "Giftbox",
    static: true,
    defaultStyles: {
        marginTop: "0px",
    },
    inputs: [
        {
            type: 'string',
            name: 'position',
            friendlyName: 'Position Text',
            required: true,
            enum: [
                {
                    label: 'Left',
                    value: 'left',
                },
                {
                    label: 'Center',
                    value: 'center',
                },
                {
                    label: 'Right',
                    value: 'right',
                }
            ],
            defaultValue: 'left',
        },
        {
            type: "boolean",
            name: 'enable_slide_in',
            friendlyName: 'Enable Slide In',
            defaultValue: true,
        },
        {
            type: 'string',
            name: 'heading_text_size',
            friendlyName: 'Heading Text Size',
            required: true,
            enum: [
                {
                    label: 'Size XL',
                    value: 'size-xl',
                },
                {
                    label: 'Size L',
                    value: 'size-l',
                },
                {
                    label: 'Size M',
                    value: 'size-m',
                },
                {
                    label: 'Size S',
                    value: 'size-s',
                },
                {
                    label: 'Size XS',
                    value: 'size-xs',
                }
            ],
            defaultValue: 'size-m',
        },
        {
            type: 'string',
            name: 'text_size',
            friendlyName: 'Text Size',
            required: true,
            enum: [
                {
                    label: 'Size XXL',
                    value: 'size-xxl',
                },
                {
                    label: 'Size XL',
                    value: 'size-xl',
                },
                {
                    label: 'Size L',
                    value: 'size-l',
                },
                {
                    label: 'Size M',
                    value: 'size-m',
                },
                {
                    label: 'Size S',
                    value: 'size-s',
                },
                {
                    label: 'Size XS',
                    value: 'size-xs',
                }
            ],
            defaultValue: 'size-s',
        },
        {
            type: 'string',
            name: 'cta_size',
            friendlyName: 'CTA Text Size',
            required: true,
            enum: [
                {
                    label: 'Size XXL',
                    value: 'size-xxl',
                },
                {
                    label: 'Size XL',
                    value: 'size-xl',
                },
                {
                    label: 'Size L',
                    value: 'size-l',
                },
                {
                    label: 'Size M',
                    value: 'size-m',
                },
                {
                    label: 'Size S',
                    value: 'size-s',
                },
                {
                    label: 'Size XS',
                    value: 'size-xs',
                }
            ],
            defaultValue: 'size-s',
        },
        {
            type: 'color',
            name: 'text_color',
            friendlyName: 'Text color',
            defaultValue: '#000',
        },
        {
            type: "boolean",
            name: 'title_enabled',
            friendlyName: 'Title',
            defaultValue: true,
        },
        {
            type: 'richText',
            name: 'title_text',
            friendlyName: 'Title Text',
            defaultValue: 'Title',
            showIf: (options) => options.get('title_enabled'),
        },
        {
            type: "boolean",
            name: 'paragraph_enabled',
            friendlyName: 'Paragraph',
            defaultValue: true,
        },
        {
            type: 'richText',
            name: 'paragraph_text',
            friendlyName: 'Paragraph Text',
            defaultValue: 'Paragraph',
            showIf: (options) => options.get('paragraph_enabled'),
        },
        {
            type: "boolean",
            name: 'cta_enabled',
            friendlyName: 'CTA'
        },
        {
            type: 'richText',
            name: 'cta_text',
            friendlyName: 'CTA Text',
            defaultValue: 'View',
            showIf: (options) => options.get('cta_enabled'),
        },
        {
            type: 'string',
            name: 'cta_url',
            friendlyName: 'CTA Url',
            defaultValue: '',
            showIf: (options) => options.get('cta_enabled'),
        },
        {
            type: 'boolean',
            name: 'cta_url_tab',
            friendlyName: 'CTA Url in new tab',
            defaultValue: 0,
            showIf: (options) => options.get('cta_enabled'),
        },
        {
            type: 'boolean',
            name: 'cta_url_external',
            friendlyName: 'CTA External Url',
            defaultValue: 0,
            showIf: (options) => options.get('cta_enabled'),
        },
        {

            type: 'string',
            name: 'button_position',
            friendlyName: 'Button Position',
            required: true,
            enum: [
                {
                    label: 'Below Image and Paragraph',
                    value: 'below',
                },
                {
                    label: 'Right',
                    value: 'right',
                },
            ],
            defaultValue: 'below',
        },
        {
            type: 'string',
            name: 'cta_type',
            friendlyName: 'CTA Type',
            required: true,
            showIf: (options) => options.get('cta_enabled'),
            enum: [
                {
                    label: 'Primary',
                    value: 'primary',
                },
                {
                    label: 'Secondary',
                    value: 'secondary',
                },
                {
                    label: 'Third',
                    value: 'third',
                },
                {
                    label: 'Fourth',
                    value: 'secondary',
                },
                {
                    label: 'Text link',
                    value: 'text',
                }
            ],
            defaultValue: 'text',
        },
        {
            type: "boolean",
            name: 'background_visual',
            friendlyName: 'Background Visual',
            defaultValue: true,
        },
        {
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            name: 'background_visual_image',
            friendlyName: 'Background Visual Image',
            showIf: (options) => options.get('background_visual'),
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
        },
        {
            type: "boolean",
            name: 'layover_enabled',
            friendlyName: 'Layover'
        },
        {
            type: 'color',
            name: 'layover_color',
            friendlyName: 'Layover color',
            defaultValue: 'rgba(0, 0, 0, 0.35)',
            showIf: (options) => options.get('layover_enabled'),
        },
        {
            type: 'string',
            name: 'size_xl',
            friendlyName: 'Size (1440px - …)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_lg',
            friendlyName: 'Size (1024px - 1439px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_md',
            friendlyName: 'Size (768px - 1023px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_sm',
            friendlyName: 'Size (640px - 767px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },
        {
            type: 'string',
            name: 'size_xs',
            friendlyName: 'Size (0px - 639px)',
            required: true,
            enum: [
                {
                    label: '0/12',
                    value: '0',
                },
                {
                    label: '1/12',
                    value: '1',
                },
                {
                    label: '2/12',
                    value: '2',
                },
                {
                    label: '3/12',
                    value: '3',
                },
                {
                    label: '4/12',
                    value: '4',
                },
                {
                    label: '5/12',
                    value: '5',
                },
                {
                    label: '6/12',
                    value: '6',
                },
                {
                    label: '7/12',
                    value: '7',
                },
                {
                    label: '8/12',
                    value: '8',
                },
                {
                    label: '9/12',
                    value: '9',
                },
                {
                    label: '10/12',
                    value: '10',
                },
                {
                    label: '11/12',
                    value: '11',
                },
                {
                    label: '12/12',
                    value: '12',
                }
            ],
            defaultValue: '12',
        },

    ],
});
