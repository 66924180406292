import parseErrors from "@kega/sps-connector-magento2/src/utils/parseErrors";
import addContactForm from "../queries/addContactForm.gql";

export default ({ client }) => {
    const api = {};

    api.addContact = async ({ firstname, lastname, email, company, telephone, topic, order_number, message }, headers = {}) => {

        return new Promise((resolve, reject) => {
            const payload = {
                query: addContactForm,
                variables: {
                    firstname: firstname,
                    lastname: lastname,
                    email: email,
                    company: company,
                    telephone: telephone,
                    topic: topic,
                    order_number: order_number,
                    message: message
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {

                if (errors) {
                    reject(parseErrors(errors));
                }

                resolve(data.addContact || {});

            }).catch((error) => {
                reject(error);
            });
        });
    }

    return api;
};
