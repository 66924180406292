const getCustomerStoreOrder = (additionalItemData = '', additionalData = '') =>  `
    query getStoreOrder($sales_entry_id: String!, $id_type: String!) {
        getStoreOrder(
            sales_entry_id: $sales_entry_id
            id_type: $id_type
        ) {
            id
            order_date
            number
            status
            shipping_method
            items {
                id
                product_name
                product_type
                product_sale_price {
                    value
                }
                product_sku
                quantity_ordered
                media_gallery {
                    url
                }
                ${additionalItemData}
            }
            shipping_address {
                city
                firstname
                lastname
                middlename
                postcode
                street
                telephone
                region
                region_id
            }
            billing_address {
                city
                firstname
                lastname
                middlename
                postcode
                street
                telephone
                region
                region_id
            }
            total {
                grand_total {
                    value
                }
                subtotal {
                    value
                }
                total_shipping {
                    value
                }
                total_tax {
                    value
                }
                shipping_handling {
                    amount_including_tax {
                        value
                    }
                    amount_excluding_tax {
                        value
                    }
                    total_amount {
                        value
                    }
                }
            }
            ${additionalData}
        }
    }
`;

export default getCustomerStoreOrder;
