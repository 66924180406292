import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { ChevronIcon } from '../components/icons';

import classes from './ScrollToTop.module.css';
import classNames from 'classnames';

const ScrollToTop = ({ productList = false }) => {
    const { pathname } = useLocation();
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        scroll();
        scrollingCheck();

    }, [pathname]);

    const scroll = () => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'auto',
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }

    const scrollingCheck = () => {
        let accumulatedScrollDifference = 0;
 
        window.onscroll = function () {
            if (this.oldScroll > this.scrollY) {
                const scrollDifference = this.oldScroll - this.scrollY;
                accumulatedScrollDifference += scrollDifference;

                if (accumulatedScrollDifference > 50) {
                    setShowButton(true);
                }
            }

            if ((this.oldScroll < this.scrollY - 10) || (this.scrollY === 0)) {
                accumulatedScrollDifference = 0;
                setShowButton(false);
            }

            this.oldScroll = this.scrollY;
        }
    }

    if (!productList) { return null; }

    return (
        <div onClick={scroll} className={classNames(showButton ? classes.icon : classes.hidden)}>
            <ChevronIcon direction="bottom" size={10} />
        </div>
    )
};

export default ScrollToTop;